import jwtDecode from 'jwt-decode';

import apiClient from './client';
import { APIClientBase } from './apiClientBase';

export interface AuthenticationResponse {
  accessToken: string;
  accessTokenIssuedAt: number;
  accessTokenExpiresAt: number;
  refreshTokenId: string;
  groups: string[];
  user: {
    userId: number;
    firstName: string;
    lastName: string;
  };
}

export const getDecodedAuthenticationResponse = (data: any): AuthenticationResponse => {
  /*
  Example access token payload
  {
    "iss": "CCM2",
    "exp": 1611717700,
    "sub": 1,
    "username": "jrosensweig@tccm.org",
    "groups": [
      "Everyone",
      "CCM Core"
    ],
    "jti": "3f57d57f-df26-488c-9a04-fb280c52fb4a", // refresh token Id
    "iat": 1611716800
  }
  */

  const decodedAccessToken = jwtDecode(data.token) as any;

  const authResponse: AuthenticationResponse = {
    accessToken: data.token,
    accessTokenIssuedAt: decodedAccessToken.iat,
    accessTokenExpiresAt: decodedAccessToken.exp,
    groups: decodedAccessToken.groups,
    user: data.user,
    refreshTokenId: decodedAccessToken.jti,
  };

  return authResponse;
};

class Authentication extends APIClientBase {
  private static _instance: Authentication;

  static getInstance(): Authentication {
    if (Authentication._instance === undefined) {
      Authentication._instance = new Authentication();
    }
    return Authentication._instance;
  }

  authenticate = async (username: string, password: string): Promise<AuthenticationResponse> => {
    try {
      const response = await apiClient.post(
        '/membership/authn/tokens',
        {
          username,
          password,
        },
        {
          withCredentials: true,
        },
      );
      const data = this.response(response);
      const authResponse = getDecodedAuthenticationResponse(data);
      return authResponse;
    } catch (err: any) {
      const error = this.error(err);
      throw error;
    }
  };
}

export default Authentication.getInstance();
