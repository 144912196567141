import React, { useEffect, useState } from 'react';
import ProgramEnrollment from '../../models/programEnrollment';
import {
  Button,
  createStyles,
  Grid,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Theme,
  Typography,
} from '@material-ui/core';
import MemberProgram from '../../models/memberProgram';
import { isInPast, removeUnderscoresFromString } from '../../lib/util';
import moment from 'moment';
import { DeleteProgramEnrollmentDialog } from './DeleteProgramEnrollmentDialog';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    row: {
      cursor: 'pointer',
      '& > *': {
        borderBottom: 'unset',
      },
    },
    emptyRow: {
      cursor: 'default',
    },
    emptyRowCell: {
      textAlign: 'center',
    },
    togglePriorEnrollments: {
      textTransform: 'none',
      fontWeight: 'bold',
    },
  }),
);

export const MemberEnrollments: React.FC<{
  selectedMembersEnrollments: ProgramEnrollment[];
  membershipAccountUid: string;
  handleMemberClose: Function;
}> = ({ selectedMembersEnrollments, membershipAccountUid, handleMemberClose }) => {
  const [displayedEnrollments, setDisplayedEnrollments] = useState<ProgramEnrollment[]>([]);
  const [currentEnrollments, setCurrentEnrollments] = useState<ProgramEnrollment[]>([]);
  const [allEnrollments, setAllEnrollments] = useState<ProgramEnrollment[]>([]);
  const [showPriorEnrollments, setShowPriorEnrollments] = useState<boolean>(false);

  const styles = useStyles();
  // Toggles show/hide prior enrollments button and displayed member programs
  const handleShowPriorEnrollments = () => {
    if (!showPriorEnrollments) {
      setDisplayedEnrollments(allEnrollments);
    } else {
      setDisplayedEnrollments(currentEnrollments);
    }
    setShowPriorEnrollments(!showPriorEnrollments);
  };

  useEffect(() => {
    if (selectedMembersEnrollments.length) {
      // Get current enrollments and order by start date
      const currentEnrollments = selectedMembersEnrollments
        .filter((p: ProgramEnrollment) => !p.ended || (p.ended && !isInPast(p.ended)))
        .sort((a: ProgramEnrollment, b: ProgramEnrollment) => {
          if (moment(b.started).isAfter(moment(a.started))) return 1;
          if (moment(b.started).isBefore(moment(a.started))) return -1;
          return 0;
        });

      // Set both current and display programs to state
      setCurrentEnrollments(currentEnrollments);
      setDisplayedEnrollments(currentEnrollments);

      // Sort all member programs by start date
      const allMemberEnrollments = selectedMembersEnrollments.sort(
        (a: ProgramEnrollment, b: ProgramEnrollment) => {
          if (moment(b.started).isAfter(moment(a.started))) return 1;
          if (moment(b.started).isBefore(moment(a.started))) return -1;
          return 0;
        },
      );

      // Set to state
      setAllEnrollments(allMemberEnrollments);
    }
  }, [selectedMembersEnrollments]);

  return (
    <Grid container spacing={2}>
      <Grid item container>
        <Grid item xs={6}>
          <Typography variant="h4">Programs</Typography>
        </Grid>
        <Grid item xs={6}>
          {allEnrollments.length - currentEnrollments.length > 0 && (
            <Button
              color="primary"
              className={styles.togglePriorEnrollments}
              onClick={handleShowPriorEnrollments}
            >
              {showPriorEnrollments ? 'hide ' : 'show '} prior enrollments{' '}
            </Button>
          )}
        </Grid>
        <Grid item xs={12}>
          <MemberEnrollmentsTable
            enrollments={displayedEnrollments}
            membershipAccountUid={membershipAccountUid}
            handleMemberClose={handleMemberClose}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export const MemberEnrollmentsTable: React.FC<{
  enrollments: ProgramEnrollment[];
  membershipAccountUid: string;
  handleMemberClose: Function;
}> = ({ enrollments, membershipAccountUid, handleMemberClose }) => {
  const styles = useStyles();
  const pageSize = 5;
  const [currentPage, setCurrentPage] = useState(0);
  const rowsPerPage = 5;

  useEffect(() => {
    setCurrentPage(0);
  }, [enrollments]);

  const renderPaginationLabel = (
    from: number,
    to: number,
    pageSize: number,
    rowCount: number,
  ): string => {
    if (rowCount > pageSize) {
      return `${from} - ${to}`;
    } else {
      return `${from} - ${from + rowCount - 1}`;
    }
  };

  const handlePageChange = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setCurrentPage(newPage);
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell width={120}>Program Name</TableCell>
              <TableCell width={160}>Effective</TableCell>
              <TableCell width={110}>Role</TableCell>
              <TableCell width={90}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!enrollments.length && (
              <TableRow className={`${styles.row} ${styles.emptyRow}`}>
                <TableCell colSpan={5} className={styles.emptyRowCell}>
                  There are no program enrollments for this member
                </TableCell>
              </TableRow>
            )}
            {enrollments.length > 0 &&
              enrollments
                .slice(currentPage * rowsPerPage, currentPage * rowsPerPage + rowsPerPage)
                .map((pe: ProgramEnrollment, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      {(pe.memberProgram as MemberProgram)?.programLevel.description ?? 'N/A'}
                    </TableCell>
                    <TableCell>
                      {moment(pe.started, 'YYYY-MM-DD').format('MMMM D, YYYY')}{' '}
                      {pe.ended
                        ? ' - ' + moment(pe.ended, 'YYYY-MM-DD').format('MMMM D, YYYY')
                        : ''}
                    </TableCell>
                    <TableCell>{removeUnderscoresFromString(pe.role)}</TableCell>
                    <TableCell>
                      {moment(pe.started).isAfter(moment()) && (
                        <DeleteProgramEnrollmentDialog
                          programEnrollment={pe}
                          membershipAccountUid={membershipAccountUid}
                          handleMemberClose={handleMemberClose}
                        />
                      )}
                    </TableCell>
                  </TableRow>
                ))}
          </TableBody>
        </Table>
      </TableContainer>
      {enrollments.length > pageSize && (
        <TablePagination
          rowsPerPageOptions={[pageSize]}
          component="div"
          count={enrollments.length}
          labelDisplayedRows={({ from, to }) =>
            renderPaginationLabel(from, to, pageSize, enrollments.length)
          }
          rowsPerPage={pageSize}
          page={currentPage}
          onChangePage={handlePageChange}
        />
      )}
    </>
  );
};
