import React, { useState } from 'react';
import { IconButton } from '@material-ui/core';
import { Delete as DeleteIcon } from '@material-ui/icons';
import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

import apiClient from '../../lib/api';
import { showToastMessage } from '../../store/slices/toastMessage';
import ConfirmationDialog from '../ConfirmationDialog';
import { AppSetting } from '../../models/appSettings';

interface AppSettingsDeleteButtonProps {
  appSetting: AppSetting;
}

const AppSettingsDeleteButton: React.FC<AppSettingsDeleteButtonProps> = ({ appSetting }) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const [openDialog, setOpenDialog] = useState(false);

  const deleteAppSetting = useMutation(
    () => apiClient.appSettings.deleteAppSettings(appSetting.uid),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries('appSettings');
        setOpenDialog(false);
      },
      onError: (error: any) => {
        dispatch(
          showToastMessage({
            message: error.message,
            type: 'error',
          }),
        );
      },
    },
  );

  return (
    <>
      <IconButton className="delete-icon" size="small" onClick={() => setOpenDialog(true)}>
        <DeleteIcon />
      </IconButton>

      <ConfirmationDialog
        open={openDialog}
        dangerous={true}
        title="Confirm Delete"
        message={`Are you sure you want to delete the "${appSetting.settingName}" application setting?`}
        onConfirm={() => deleteAppSetting.mutate()}
        onCancel={() => setOpenDialog(false)}
      />
    </>
  );
};

export default AppSettingsDeleteButton;
