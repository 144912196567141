import React, { useState } from 'react';
import { CssBaseline } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';

import LeftMenu from './LeftMenu';
import AppBar from './AppBar';

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      display: 'flex',
    },
    toolbar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
  }),
);

const Layout = (props: any) => {
  const { children, showAppBar, showLeftMenu, isDarkMode, toggleDarkMode } = props;
  const styles = useStyles();
  const [drawerOpen, setDrawerOpen] = useState(true);

  const appBarVisible = showAppBar !== undefined ? showAppBar : false;
  const leftMenuVisible = showLeftMenu !== undefined ? showLeftMenu : false;

  const toggleDrawer = (isOpen: boolean) => {
    setDrawerOpen(isOpen);
  };

  return (
    <div className={styles.root}>
      <CssBaseline />

      {appBarVisible && (
        <AppBar
          isDrawerOpen={drawerOpen}
          onToggleDrawer={toggleDrawer}
          isDarkMode={isDarkMode}
          toggleDarkMode={toggleDarkMode}
        />
      )}
      {leftMenuVisible && <LeftMenu isDrawerOpen={drawerOpen} onToggleDrawer={toggleDrawer} />}

      <main className={styles.content}>
        {appBarVisible && <div className={styles.toolbar} />}
        {children}
      </main>
    </div>
  );
};

export default Layout;
