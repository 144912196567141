import React, { useState } from 'react';
import ProgramEnrollment from '../../models/programEnrollment';
import { IconButton } from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import ConfirmationDialog from '../ConfirmationDialog';
import moment from 'moment';
import { useMutation, useQueryClient } from 'react-query';
import apiClient from '../../lib/api';
import { showToastMessage } from '../../store/slices/toastMessage';
import { useDispatch } from 'react-redux';

export const DeleteProgramEnrollmentDialog: React.FC<{
  programEnrollment: ProgramEnrollment;
  membershipAccountUid: string;
  handleMemberClose: Function;
}> = ({ programEnrollment, membershipAccountUid, handleMemberClose }) => {
  const [openDeleteEnrollmentDialog, setOpenDeleteEnrollmentDialog] = useState(false);
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const deleteProgramEnrollment = useMutation(
    (uid: string) => apiClient.membershipPrograms.deleteProgramEnrollment(uid),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(['membershipAccount', membershipAccountUid]);
        setOpenDeleteEnrollmentDialog(false);
        handleMemberClose();
        dispatch(
          showToastMessage({
            message: `Program enrollment deleted`,
            type: 'success',
          }),
        );
      },
      onError: (error: any) => {
        console.error(error);
        dispatch(
          showToastMessage({
            message: 'Program enrollment could not be deleted',
            type: 'error',
          }),
        );
      },
    },
  );

  return (
    <>
      <IconButton size="small" onClick={() => setOpenDeleteEnrollmentDialog(true)}>
        <Delete />
      </IconButton>
      <ConfirmationDialog
        open={openDeleteEnrollmentDialog}
        dangerous={true}
        title="Delete Program Enrollment"
        cancelText="No"
        message={`Are you sure you want to delete the enrollment starting on ${moment(
          programEnrollment.started,
        ).format('ll')}? Manual updates will need to be performed in Eldorado and Rejoice.`}
        onConfirm={() => deleteProgramEnrollment.mutate(programEnrollment.uid)}
        onCancel={() => setOpenDeleteEnrollmentDialog(false)}
      />
    </>
  );
};
