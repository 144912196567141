import ProgramEnrollment from '../../../../models/programEnrollment';
import {
  Card,
  CardContent,
  createStyles,
  Grid,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import Member from '../../../../models/member';
import Person from '../../../../models/person';
import moment from 'moment';
import React from 'react';
import { removeUnderscoresFromString } from '../../../../lib/util';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    label: {
      fontWeight: 'bold',
      marginRight: '3px',
    },
    member: {
      opacity: 0.6,
      transition: 'all 0.2s ease-in-out',
      '&:hover': {
        opacity: 1,
      },
    },
    cardContent: {
      padding: '16px!important',
      backgroundColor: theme.backgroundColors.body,
    },
    rowWrapper: {
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between',
    },
    header: {
      fontWeight: 'bold',
    },
    role: {
      display: 'flex',
      justifyContent: 'flex-start',
      width: '50%',
    },
    dependent: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignSelf: 'flex-end',
    },
  }),
);

export default function ProgramEnrollments({ enrollments }: { enrollments: ProgramEnrollment[] }) {
  const classes = useStyles();

  return (
    <Grid container spacing={2}>
      {enrollments?.map((enrollment: ProgramEnrollment, index: number) => {
        const member = enrollment.member as Member;
        const person = member.person as Person;

        return (
          <Grid item lg={4} md={6} xs={12} key={index}>
            <Card className={classes.member}>
              <CardContent className={classes.cardContent}>
                <Typography variant="h5" className={classes.header}>
                  {person.firstName} {person.lastName}
                </Typography>
                <div>
                  <span className={classes.label}>Effective: </span>{' '}
                  <span>
                    {moment(enrollment.started).format('ll')}
                    {enrollment.ended && ' - ' + moment(enrollment.ended).format('ll')}
                  </span>
                </div>
                <div className={classes.rowWrapper}>
                  <div>
                    <span className={classes.label}>Role: </span>
                    <span>{removeUnderscoresFromString(enrollment.role)}</span>
                  </div>
                  <div>
                    <span className={classes.label}>Dependent #:</span>{' '}
                    <span>{member.dependentNumber}</span>
                  </div>
                </div>
              </CardContent>
            </Card>
          </Grid>
        );
      })}
    </Grid>
  );
}
