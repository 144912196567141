import { Address, CreateAddress } from '../../models/address';
import { APIClientBase } from './apiClientBase';
import apiClient from './client';

/**
 * This calls a third party called "Melissa Data" to verify an address
 */
class Addresses extends APIClientBase {
  private static _instance: Addresses;

  static getInstance(): Addresses {
    if (Addresses._instance === undefined) {
      Addresses._instance = new Addresses();
    }
    return Addresses._instance;
  }

  create = async (address: CreateAddress): Promise<any> => {
    try {
      await apiClient.post(`/membership/addresses`, address);
    } catch (err: any) {
      const error = this.error(err);
      throw error;
    }
  };

  update = async (address: Address): Promise<any> => {
    try {
      await apiClient.patch(`/membership/addresses/${address.uid}`, address);
    } catch (err: any) {
      const error = this.error(err);
      throw error;
    }
  };

  verifyAddress = async (address: Address): Promise<any> => {
    try {
      const response = await apiClient.get('/membership/webhooks/melissa/address/verify', {
        params: {
          line1: address.address1 || '',
          line2: address.address2 || '',
          city: address.city,
          state: address.state,
          postalCode: address.postalCode,
          format: 'json',
        },
      });
      const data = this.response(response);
      return data;
    } catch (err: any) {
      const error = this.error(err);
      throw error;
    }
  };

  autoCompleteAddress = async (address: Address): Promise<any> => {
    try {
      const response = await apiClient.get('/membership/webhooks/melissa/address/autoComplete', {
        params: {
          line1: address.address1 || '',
          line2: address.address2 || '',
          city: address.city,
          state: address.state,
          postalCode: address.postalCode,
          format: 'json',
        },
      });
      const data = this.response(response);
      return data;
    } catch (err: any) {
      const error = this.error(err);
      throw error;
    }
  };
}

export default Addresses.getInstance();
