import ProgramEnrollment from '../../../models/programEnrollment';
import {
  Card,
  CardContent,
  Checkbox,
  createStyles,
  FormControlLabel,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import React, { useEffect } from 'react';
import Member from '../../../models/member';
import Person from '../../../models/person';
import moment from 'moment';
import { removeUnderscoresFromString } from '../../../lib/util';

interface InactiveEnrollmentProps {
  enrollment: ProgramEnrollment;
  onMemberSelected: Function;
  checked: boolean;
  disabled?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardContent: {
      opacity: 0.6,
      transition: 'all 0.2s ease-in-out',
      '&:hover': {
        opacity: 1,
      },
      backgroundColor: theme.backgroundColors.body,
    },
    label: {
      marginRight: '3px',
      fontWeight: 'bold',
    },
    memberSelector: {
      fontWeight: 'bold',
      marginBottom: '0',
      marginLeft: '3px',
    },
    header: {
      fontWeight: 'bold',
    },
  }),
);

const InactiveEnrollment: React.FC<InactiveEnrollmentProps> = ({
  enrollment,
  onMemberSelected,
  checked,
  disabled = false,
}) => {
  const styles = useStyles();
  const member = enrollment && (enrollment.member as Member);
  const person = member && (member.person as Person);
  const memberNames = person?.firstName + ' ' + person?.lastName;

  useEffect(() => {}, [enrollment]);
  return (
    <Card>
      <CardContent className={styles.cardContent}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            flexGrow: 1,
          }}
        >
          <div style={{ flexGrow: 0, flexShrink: 0, width: '40px' }}>
            <FormControlLabel
              control={
                <Checkbox
                  name="memberSelector"
                  checked={checked}
                  onChange={event => onMemberSelected(event.target.checked, enrollment)}
                  color="primary"
                  disabled={disabled}
                />
              }
              label=""
            />
          </div>
          <div style={{ flexGrow: 1 }}>
            <div>
              <Typography variant="h5" className={styles.header}>
                <span>{memberNames}</span>
              </Typography>
            </div>
            <div>
              <span className={styles.label}>Effective:</span>
              <span>
                {moment(enrollment?.started).format('ll')}
                {enrollment?.ended && ' - ' + moment(enrollment?.ended).format('ll')}
              </span>
            </div>
            <div>
              <span className={styles.label}>Role:</span>
              <span>{removeUnderscoresFromString(enrollment.role)}</span>
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default InactiveEnrollment;
