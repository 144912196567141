/* prettier-ignore */
const avatarColors = [ 
  '#DDA500', '#939393', '#F4A460', '#5F9EA0', '#FA8072', 
  '#2F4F4F', '#D2B48C', '#778899', '#9370DB', '#B8860B', 
  '#6495ED', '#00FA9A', '#ADD8E6', '#008B8B', '#DC143C', 
  '#6B8E23', '#2E8B57', '#9932CC', '#556B2F', '#E3672D'
];

/**
 * Retruns a color based on the index of an avatar when rendering a
 * series of avatars (i.e. in table rows)
 * @param index the index of the avatar for which to return a color
 */
export const getAvatarColor = (index: number) => {
  return avatarColors[index < avatarColors.length ? index : index % avatarColors.length];
};
