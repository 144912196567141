import React, { ReactElement } from 'react';
import {
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      margin: theme.spacing(1),
      position: 'relative',
    },
    deleteButton: {
      background: '#f44336',
      '&:hover': {
        background: '#f44336',
      },
    },
    buttonProgress: {
      color: 'white',
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
  }),
);

interface ConfirmationDialogProps {
  open: boolean;
  title: string;
  message: string | ReactElement;
  cancelText?: string;
  confirmText?: string;
  onConfirm: () => void;
  onCancel?: () => void;
  dangerous?: boolean;
  loading?: boolean;
}

const ConfirmationDialog: React.FC<ConfirmationDialogProps> = props => {
  const {
    open,
    title,
    message,
    cancelText,
    confirmText,
    onConfirm,
    onCancel,
    dangerous,
    loading,
  } = props;
  const styles = useStyles();

  return (
    <Dialog
      open={open}
      onClose={onCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" disableTypography={true}>
        <Typography variant="h2" style={{ margin: 0 }}>
          {title}
        </Typography>
      </DialogTitle>
      <DialogContent>
        {typeof message === 'string' ? <DialogContentText id="alert-dialog-description">{message}</DialogContentText> : message }
      </DialogContent>
      <DialogActions>
        <div className={styles.wrapper}>
          <Button
            variant="contained"
            className={dangerous ? styles.deleteButton : undefined}
            onClick={onConfirm}
            color="primary"
            autoFocus
            disabled={loading}
          >
            {confirmText || 'Yes'}
          </Button>
          {loading && <CircularProgress size={24} className={styles.buttonProgress} />}
        </div>
        <Button variant="contained" onClick={onCancel} color="default" disabled={loading}>
          {cancelText || 'Cancel'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
