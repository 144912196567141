import Person from "../../../models/person";
import React, { useEffect, useState } from "react";
import { createStyles, makeStyles, Theme, Typography } from '@material-ui/core';


const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		name: {
			fontSize: '14px',
			fontWeight: 600,
			lineHeight: '18px',
		},
		age: {
			fontSize: '11px',
			fontWeight: 400,
			lineHeight: '18px',
		},
		wrapper: {
			display: 'flex',
			flexDirection: 'column'
		}
	}),
);
const MemberNameAndAge: React.FC<{ person: Person }> = ({person}) => {

	const styles = useStyles();
	const [age, setAge] = useState<number | undefined>(undefined);

	useEffect(() => {
		if (person.dob) {
			setAge(new Date().getFullYear() - new Date(person.dob).getFullYear());
		}
	}, [person]);

	return (
		<div className={styles.wrapper}>
			<Typography className={styles.name} variant="body1">{person.firstName} {person.lastName}</Typography>
			<Typography className={styles.age} variant="body2">{person.gender}{age &&
          <span>, {age} years old</span>}</Typography>
		</div>
	);
}

export default MemberNameAndAge;