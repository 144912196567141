import MemberProgram from './memberProgram';
import Member from './member';
import moment from 'moment';

export enum Role {
  HeadOfHousehold = 'head_of_household',
  Spouse = 'spouse',
  Child = 'child',
  DependentException = 'dependent_exception',
}

export default interface ProgramEnrollment {
  member: Member | string;
  memberProgram: MemberProgram | string;
  role: Role;
  started: string;
  ended?: string;
  uid: string;
  applicationDate: string;
}

/**
 * Retrieves program enrollments for a given member program identifier
 * @param enrollments The list of enrollments to filter
 * @param memberProgramUId The program identifier to filter on
 * @returns A list of enrollments belonging to the specified program
 */
export const getProgramEnrollmentsByMemberProgramUId = (
  enrollments: ProgramEnrollment[],
  memberProgramUId: string,
) =>
  enrollments.filter(e =>
    typeof e.memberProgram === 'string'
      ? e.memberProgram === memberProgramUId
      : e.memberProgram?.uid === memberProgramUId ?? false,
  );

/**
 * A comparer function for sorting enrollments from newest to oldest by start date
 * @param a The first enrollment to compare
 * @param b The second enrollment to compare
 * @returns -1 if the first enrollment is newer, 1 if older and 0 if the same
 */
export const sortNewestToOldestByStartDate = (a: ProgramEnrollment, b: ProgramEnrollment) => {
  return moment(a.started).isAfter(b.started) ? -1 : moment(a.started).isBefore(b.started) ? 1 : 0;
};

/**
 * A comparer function for sorting enrollments from newest to oldest by end date
 * @param a The first enrollment to compare
 * @param b The second enrollment to compare
 * @returns -1 if the first enrollment is newer, 1 if older and 0 if the same
 */
export const sortNewestToOldestByEndDate = (a: ProgramEnrollment, b: ProgramEnrollment) => {
  if (a.ended === null || a.ended === undefined) {
    return -1;
  } else if (b.ended === null || b.ended === undefined) {
    return 1;
  }
  return moment(a.ended).isAfter(b.ended) ? -1 : moment(a.ended).isBefore(b.ended) ? 1 : 0;
};

/**
 * A comparer function for sorting enrollments from oldest to newest by start date
 * @param a The first enrollment to compare
 * @param b The second enrollment to compare
 * @returns -1 if the first enrollment is older, 1 if newer and 0 if the same
 */
export const sortOldestToNewestByStartDate = (a: ProgramEnrollment, b: ProgramEnrollment) => {
  return moment(a.started).isAfter(b.started) ? 1 : moment(a.started).isBefore(b.started) ? -1 : 0;
};

/**
 * A comparer function for sorting enrollments from oldest to newest by end date
 * @param a The first enrollment to compare
 * @param b The second enrollment to compare
 * @returns -1 if the first enrollment is older, 1 if newer and 0 if the same
 */
export const sortOldestToNewestByEndDate = (a: ProgramEnrollment, b: ProgramEnrollment) => {
  if (a.ended === null || a.ended === undefined) {
    return 1;
  } else if (b.ended === null || b.ended === undefined) {
    return -1;
  }
  return moment(a.ended).isAfter(b.ended) ? 1 : moment(a.ended).isBefore(b.ended) ? -1 : 0;
};
