import {
  Box,
  Button,
  CircularProgress,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import moment from 'moment';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams, Redirect } from 'react-router-dom';
import { Action, Subject } from '@ccm-innovation/auth-membership-service';

import DeleteButton from '../../components/DeleteButton';
import GroupCard from '../../components/groups/GroupCard';
import { GroupDefault } from '../../components/groups/GroupDefault';
import GroupForm from '../../components/groups/GroupForm';
import Page, { Breadcrumb } from '../../components/Page';
import Spacer from '../../components/Spacer';
import StatusBadge, { getStatusVariant } from '../../components/StatusBadge';
import { RootState } from '../../store';
import { deleteGroup, getGroup, saveGroup } from '../../store/slices/group';
import { clearAccounts } from '../../store/slices/membershipAccounts';
import { getOrganization } from '../../store/slices/organization';
import { showToastMessage } from '../../store/slices/toastMessage';
import MembershipAccountsTable from '../membership/accounts/MembershipAccountsTable';
import NotFound from '../NotFound';
import { useSession } from '../../context/session';
interface Params {
  uid: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    pageTitle: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    pageContent: {
      display: 'grid',
      gridAutoFlow: 'row',
      gridGap: '16px',
    },
    title: {
      fontSize: 18,
    },
  }),
);

const GroupView: React.FC = () => {
  const dispatch = useDispatch();
  const { user } = useSession();
  const styles = useStyles();
  const params: Params = useParams();
  const history = useHistory();
  const uid = params.uid;
  const groupsState = useSelector((state: RootState) => state.groups);
  const groupState = useSelector((state: RootState) => state.group);
  const [modalOpen, setModalOpen] = useState(false);
  const [formDisabled, setFormDisabled] = useState(false);
  const [allowDelete, setAllowDelete] = useState(false);

  const { loading, error } = groupState;
  const breadcrumbs: Breadcrumb[] = [
    { href: '/', label: 'Home' },
    { href: '#', label: 'Membership' },
    { href: '/membership/groups', label: 'Groups' },
    { label: groupState.group?.groupName ?? (loading ? 'Loading...' : 'Not Found') },
  ];

  const memberAccountCount = (count: number) => {
    if (count < 1) {
      setAllowDelete(true);
    } else {
      setAllowDelete(false);
    }
  };

  const onSaveGroup = (groupData: any) => {
    // check dates
    if (
      groupData.started !== '' &&
      groupData.ended !== '' &&
      moment(groupData.started).isAfter(moment(groupData.ended))
    ) {
      dispatch(
        showToastMessage({
          message: 'Start date must occur before end date',
          type: 'error',
        }),
      );
      return;
    }

    setFormDisabled(true);
    dispatch(saveGroup(groupData));
  };

  const handleDelete = (groupUId: string | undefined) => {
    setFormDisabled(true);
    if (groupUId) {
      dispatch(deleteGroup(groupUId));
    }
  };

  useLayoutEffect(() => {
    // get the latest version of the group from the API
    dispatch(getGroup(uid));
    // Clear the membership accounts store to prevent a previously loaded list from appearing for a moment
    dispatch(clearAccounts());
  }, [dispatch, groupsState.groups, uid]);

  useEffect(() => {
    if (groupState.saved) {
      setModalOpen(false);
      if (groupState.group?.status === 'deleted') {
        history.push('/membership/groups');
      }
    }
    if (!groupState.loading) {
      setFormDisabled(false);
    }
    if (groupState.group) {
      dispatch(getOrganization(groupState.group.organization));
    }
  }, [dispatch, groupState, history]);

  let body;
  if (loading) {
    body = (
      <Box display="flex" justifyContent="center">
        <CircularProgress />
      </Box>
    );
  } else if (error) {
    body = <NotFound />;
  } else {
    body = (
      <>
        {groupState.group && (
          <>
            <GroupCard group={groupState.group} />

            <Spacer size={10} />
            {user && user.can(Action.View, Subject.MembershipAccount) && (
              <>
                <Typography className={styles.title}>Group Membership Accounts</Typography>
                <MembershipAccountsTable
                  query={{ group: groupState.group.uid }}
                  memberAccountCount={memberAccountCount}
                />
              </>
            )}
          </>
        )}
      </>
    );
  }

  // check permissions
  if (user && user.cannot(Action.View, Subject.Group)) {
    return <Redirect to={{ pathname: '/not-found' }} />;
  }

  return (
    <Page
      title={
        <Box className={styles.pageTitle}>
          {groupState.group && (
            <>
              <div>
                {groupState.group.groupName}
                <StatusBadge
                  label={groupState.group.status}
                  variant={getStatusVariant(groupState.group.status)}
                  marginLeft={2}
                />
                <GroupDefault group={groupState.group} />
              </div>
              <div>
                {user && user.can(Action.Update, Subject.Group) && (
                  <Button color="primary" variant="contained" onClick={() => setModalOpen(true)}>
                    Update Group
                  </Button>
                )}
                {user &&
                  user.can(Action.Destroy, Subject.Group) &&
                  !groupState.group.default &&
                  allowDelete && (
                    <DeleteButton
                      buttonText="Delete Group"
                      model="group"
                      handleDelete={() => handleDelete(groupState.group?.uid)}
                    />
                  )}
              </div>
            </>
          )}
        </Box>
      }
      breadcrumbs={breadcrumbs}
    >
      <Box className={styles.pageContent}>{body}</Box>
      <GroupForm
        onSubmit={onSaveGroup}
        close={() => setModalOpen(false)}
        disabled={formDisabled}
        group={groupState.group}
        open={modalOpen}
      />
    </Page>
  );
};

export default GroupView;
