import { Chip, Theme, Typography, createStyles, makeStyles } from '@material-ui/core';
import { Delete as DeleteIcon } from '@material-ui/icons';

interface MedicalBillReimbursementMedicalBillNumbersListProps {
  medicalBillNumbers: string[];
  onRemove: (medicalBillNumber: string) => void;
  isEditMode: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      flexDirection: 'row',
      justifyContent: 'start',
      gap: theme.spacing(0.5),
    },
  }),
);

const MedicalBillReimbursementMedicalBillNumbersList = ({
  medicalBillNumbers,
  onRemove,
  isEditMode,
}: MedicalBillReimbursementMedicalBillNumbersListProps) => {
  const styles = useStyles();

  return (
    <div className={styles.root}>
      {medicalBillNumbers && medicalBillNumbers.length > 0 ? (
        medicalBillNumbers.map(medicalBillNumber => (
          <Chip
            key={medicalBillNumber}
            label={medicalBillNumber}
            color="primary"
            size="small"
            clickable={false}
            onDelete={isEditMode ? () => onRemove(medicalBillNumber) : undefined}
            deleteIcon={<DeleteIcon aria-label="delete" />}
            aria-label="Text value"
          />
        ))
      ) : (
        <Typography variant="body1" aria-label="Text no medical bill numbers">
          No medical bill numbers.
        </Typography>
      )}
    </div>
  );
};

export default MedicalBillReimbursementMedicalBillNumbersList;
