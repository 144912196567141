import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { Action, Subject } from '@ccm-innovation/auth-membership-service';

import Dialog from '../Dialog';
import StatusBadge from '../StatusBadge';
import Group from '../../models/group';
import { saveGroup } from '../../store/slices/group';
import { RootState } from '../../store';
import { useSession } from '../../context/session';

const useStyles = makeStyles(theme => ({
  defaultGroupForm: {
    width: 475,
  },
  dialogContainer: {
    flexGrow: 1,
    display: 'flex',
  },
}));

interface GroupDefaultProps {
  group: Group;
}

export const GroupDefault: React.FC<GroupDefaultProps> = ({ group }) => {
  const styles = useStyles();
  const { user } = useSession();
  const dispatch = useDispatch();
  const organization = useSelector((state: RootState) => state.organization.organization);
  const [modalOpen, setModalOpen] = useState(false);

  const confirm = () => {
    dispatch(
      saveGroup({
        default: true,
        uid: group.uid,
        groupName: group.groupName,
        status: group.status,
      }),
    );
    setModalOpen(false);
  };

  const renderButtons = () => {
    const yesButton = (
      <Button
        color="primary"
        variant="contained"
        size="large"
        onClick={confirm}
        key="add"
        fullWidth
      >
        Yes
      </Button>
    );
    const noButton = (
      <Button
        color="default"
        variant="contained"
        size="large"
        key="cancel"
        onClick={() => setModalOpen(false)}
        fullWidth
      >
        No
      </Button>
    );
    return [yesButton, noButton];
  };

  return (
    <>
      {group.default ? (
        <StatusBadge label={'Default'} variant={'info'} marginLeft={2} />
      ) : (
        user &&
        user.can(Action.Update, Subject.Organization) && (
          <StatusBadge
            asButton
            label={'Set as Default'}
            marginLeft={2}
            onClick={() => setModalOpen(true)}
          />
        )
      )}

      <Dialog title="Set as Default" open={modalOpen} buttons={renderButtons()}>
        <div className={styles.dialogContainer}>
          <form className={styles.defaultGroupForm}>
            {`Are you sure you want to make this the default group for the "${organization?.organizationName}" organization?`}
          </form>
        </div>
      </Dialog>
    </>
  );
};
