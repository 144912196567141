const USER = 'ccm/user';
const ACCESS_TOKEN = 'ccm/accessToken';
const REFRESH_TOKEN = 'ccm/refreshToken';
const DARK_MODE = 'ccm/darkMode';
const REDIRECT_URL = 'ccm/redirectUrl';

export const itemTypes = { ACCESS_TOKEN, DARK_MODE, REFRESH_TOKEN, USER, REDIRECT_URL };

export default class ClientStorage {
  static store(key: string, value: any, session = false) {
    // setting a value of null or undefined has the same effect as clearing the stored key
    if (value === null || value === undefined) {
      ClientStorage.clear(key, session);
      return;
    }

    const storedValue = {
      type: typeof value,
      value: value,
    };

    if (session) {
      sessionStorage.setItem(key, JSON.stringify(storedValue));
    } else {
      localStorage.setItem(key, JSON.stringify(storedValue));
    }
  }

  static retrieve(key: string, session = false) {
    let value: string | null;

    if (session) {
      value = sessionStorage.getItem(key);
    } else {
      value = localStorage.getItem(key);
    }

    if (value !== null) {
      const parsedValue = JSON.parse(value);
      return parsedValue.value;
    }

    return null;
  }

  static clear(key: string, session = false) {
    if (session) {
      sessionStorage.removeItem(key);
    } else {
      localStorage.removeItem(key);
    }
  }

  static clearAll(session = false) {
    if (session) {
      sessionStorage.clear();
    } else {
      localStorage.clear();
    }
  }
}
