import {
  Paper,
  Box,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  makeStyles,
  createStyles,
  Theme,
  Button,
} from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';
import { useCallback } from 'react';
import ExceptionLogFilterAccount from './ExceptionLogFilterAccount';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    filterControl: {
      margin: theme.spacing(1),
      minWidth: 100,
    },
    filterAccountControl: {
      margin: theme.spacing(1),
      minWidth: 400,
    },
    capitalize: {
      textTransform: 'capitalize',
    },
  }),
);

const logLevels = ['debug', 'error', 'info', 'warn'];

export const ExceptionLogFilter = (props: any) => {
  const styles = useStyles();
  const { queryParams, onFilterChange } = props;

  const handleFilterChange = (event: any) => {
    const target = event.target;
    const value: string = target.value;
    const name: string = target.name;
    const newQueryParams = { ...queryParams };
    if (name === 'logLevel') {
      if (value === 'any') {
        delete newQueryParams.logLevel;
      } else {
        newQueryParams.logLevel = value;
      }
    }
    onFilterChange(newQueryParams);
  };

  const handleFilterDateChange = (name: string, value: Date | null) => {
    const newQueryParams = { ...queryParams };
    if (value === null) {
      delete newQueryParams[name];
    } else {
      const dateObj = moment(value);
      const dateBoundary = name === 'endDate' ? 'endOf' : 'startOf';
      newQueryParams[name] = dateObj.isValid() ? dateObj[dateBoundary]('day').toISOString() : value;
    }
    onFilterChange(newQueryParams);
  };

  const handleAccountIdentifierChange = useCallback(
    (account: { membershipAccountId: string; sfAccountId: string } | null): void => {
      const newQueryParams = { ...queryParams };
      if (!account) {
        delete newQueryParams.membershipAccountId;
        delete newQueryParams.sfAccountId;
      } else {
        const { membershipAccountId, sfAccountId } = account;
        newQueryParams.membershipAccountId = membershipAccountId;
        newQueryParams.sfAccountId = sfAccountId;
      }
      onFilterChange(newQueryParams);
    },
    [queryParams, onFilterChange],
  );

  const resetFilter = () => {
    onFilterChange({ pageSize: queryParams.pageSize });
  };

  return (
    <Paper>
      <Box marginBottom={2} padding={2}>
        <Typography variant="h5">Filter</Typography>
        <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <FormControl className={styles.filterAccountControl}>
            <ExceptionLogFilterAccount
              handleChangeAccount={handleAccountIdentifierChange}
              resetAccount={!queryParams.membershipAccountId}
            />
          </FormControl>
          <FormControl className={styles.filterControl}>
            <InputLabel id="logLevel">Log Level</InputLabel>
            <Select
              labelId="logLevel"
              id="logLevel"
              name="logLevel"
              value={queryParams.logLevel || 'any'}
              onChange={handleFilterChange}
            >
              <MenuItem value={'any'}>Any / All</MenuItem>
              {logLevels.map((logLevel: string, index: number) => (
                <MenuItem key={index} value={logLevel} className={styles.capitalize}>
                  {logLevel}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <FormControl className={styles.filterControl}>
              <KeyboardDatePicker
                id="startDate"
                name="startDate"
                label="Start Date"
                autoOk
                disableToolbar
                variant="inline"
                format="MM/dd/yyyy"
                margin="none"
                value={queryParams.startDate ? moment(queryParams.startDate).toDate() : null}
                onChange={value => handleFilterDateChange('startDate', value)}
                KeyboardButtonProps={{
                  'aria-label': 'change start date',
                }}
              />
            </FormControl>
          </MuiPickersUtilsProvider>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <FormControl className={styles.filterControl}>
              <KeyboardDatePicker
                id="endDate"
                name="endDate"
                label="End Date"
                autoOk
                disableToolbar
                variant="inline"
                format="MM/dd/yyyy"
                margin="none"
                value={queryParams.endDate ? moment(queryParams.endDate).toDate() : null}
                onChange={value => handleFilterDateChange('endDate', value)}
                KeyboardButtonProps={{
                  'aria-label': 'change end date',
                }}
              />
            </FormControl>
          </MuiPickersUtilsProvider>
          <Box
            style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' }}
            marginBottom={1}
            marginLeft={2}
          >
            <Button variant="contained" color="default" size="small" onClick={resetFilter}>
              clear
            </Button>
          </Box>
        </Box>
      </Box>
    </Paper>
  );
};

export default ExceptionLogFilter;
