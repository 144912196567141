import { AxiosInstance } from 'axios';
import { APIClientBase } from '../../../lib/api/apiClientBase';
import client from '../../../lib/api/client';
import { ProviderNetworksApiPort } from './provider-networks.api.port';
import { ProviderNetworkAssignment } from '../types/provider-network-assignment';

export class ProviderNetworksApi extends APIClientBase implements ProviderNetworksApiPort {
  private readonly client: AxiosInstance = client;

  get = async (uid: string): Promise<ProviderNetworkAssignment[]> => {
    try {
      const response = await this.client.get(`/membership/memberPrograms/${uid}/provider-networks`);
      return response.data;
    } catch (err) {
      const error = this.error(err);
      throw error;
    }
  };
  assign = async (uid: string): Promise<void> => {
    try {
      await this.client.post(`/membership/memberPrograms/${uid}/assign-provider-networks`);
      return;
    } catch (err) {
      const error = this.error(err);
      throw error;
    }
  };
}

export default new ProviderNetworksApi();
