export class ImageUtil {
  static async toBase64(file: File): Promise<string> {
    return await new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result!.toString());
      reader.onerror = error => reject(error);
    });
  }

  static async getImageDimensions(base64: string): Promise<{ width: number; height: number }> {
    const image = new Image();
    image.src = base64;

    return new Promise((resolve, reject) => {
      image.onload = function () {
        resolve({ width: image.width, height: image.height });
      };
      image.onerror = function(err) {
        reject(err);
      }
    });
  }
}
