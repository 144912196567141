import React, { ReactElement, useState } from 'react';
import {
  Card,
  CardContent,
  Grid,
  Typography,
  makeStyles,
  createStyles,
  Button,
  Tab,
  Tabs,
  Backdrop,
  Theme,
  Tooltip,
  IconButton,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Fade from '@material-ui/core/Fade';
import Modal from '@material-ui/core/Modal';
import moment from 'moment';
import { Action, Subject, Roles } from '@ccm-innovation/auth-membership-service';

import Person from '../../models/person';
import MemberAddress from './memberInfo/MemberAddress';
import AccountMemberForm from './AccountMemberForm';
import { useMutation, useQueryClient } from 'react-query';
import apiClient from '../../lib/api';
import { useDispatch } from 'react-redux';
import { showToastMessage } from '../../store/slices/toastMessage';
import { formatPhoneNumber, removeUnderscoresFromString } from '../../lib/util';
import { MemberEnrollments } from './MemberEnrollments';
import Member from '../../models/member';
import ProgramEnrollment, { sortNewestToOldestByStartDate } from '../../models/programEnrollment';
import MembershipAccount from '../../models/membershipAccount'; // TODO: this might be deprecated
import { useSession } from '../../context/session';
import ConfirmationDialog from '../ConfirmationDialog';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import MemberProgram, { MemberProgramStatus } from '../../models/memberProgram';
import StatusBadge from '../StatusBadge';
import Spacer from '../Spacer';
import SalesforceContactLink from './memberInfo/MemberSalesforceContactLink';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    modalTitle: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: '2rem',
    },
    modalBody: {
      minWidth: 600,
      outline: 'none',
    },
    memberModalBody: {
      width: 1000,
      outline: 'none',
      maxWidth: '70%',
      maxHeight: '80%',
      overflow: 'auto',
    },
    editMemberModalBody: {
      width: 400,
      outline: 'none',
      maxWidth: '70%',
      maxHeight: '90%',
      overflow: 'auto',
    },
    withdrawModalBody: {
      minWidth: 750,
      outline: 'none',
    },
    formGroup: {
      marginBottom: 16,
    },
    formValueLabel: {
      marginBottom: 16,
    },
    relinkContactButton: {
      marginRight: 'auto',
    },
    dialogButton: {
      marginRight: 16,
      width: 'auto',
      float: 'right',
    },
  }),
);
interface memberModalProps {
  gender?: string; // TODO: this might be deprecated
  handleDateChange?: (date: Date | null) => void; // TODO: this might be deprecated
  handleGender?: any; // TODO: this might be deprecated
  handleMemberClose: any;
  handleRoleChange: any;
  memberModalOpen: boolean;
  membershipAccount?: MembershipAccount; // TODO: this might be deprecated
  membershipAccountUid: string;
  memberTabValue?: number; // TODO: this might be deprecated
  modalTitle: string;
  openMemberWithdrawalDialog: any;
  role?: string; // TODO: this might be deprecated
  selectedDate?: Date | null; // TODO: this might be deprecated
  selectedMember: Member | null;
  selectedMembersEnrollments: ProgramEnrollment[];
  setSelectedMember: React.Dispatch<React.SetStateAction<Member | null>>;
  setSnackbarMessage: Function;
  setSnackbarOpen: Function;
}

/**
 * Retrieves the latest (newest) enrollment for a given list of enrollments
 * @param programEnrollments
 * @returns The latest enrollment or undefined if no enrollments are supplied
 */
export const getLatestEnrollment = (
  programEnrollments: ProgramEnrollment[],
): ProgramEnrollment | undefined => {
  if (programEnrollments.length === 0) return;

  // get the most recent enrollment
  const sortedEnrollments = programEnrollments.sort(sortNewestToOldestByStartDate);
  return sortedEnrollments[0];
};

/**
 * Determines if a member can be reactivated
 * @param programEnrollments The member's enrollments
 * @returns True if rectivation is allowed, false if not
 */
export const canReactivate = (programEnrollments: ProgramEnrollment[]) => {
  const latestEnrollment = getLatestEnrollment(programEnrollments);

  if (latestEnrollment && latestEnrollment.ended !== undefined && latestEnrollment.ended !== null) {
    return true;
  }

  return false;
};

/**
 * Determines if a member can be withdrawn
 * @param programEnrollments The member's enrollments
 * @returns True if a withdrawal is allowed, false if not
 */
export const canWithdraw = (programEnrollments: ProgramEnrollment[]) => {
  const latestEnrollment = getLatestEnrollment(programEnrollments);

  if (
    latestEnrollment &&
    (latestEnrollment.ended === null || latestEnrollment.ended === undefined)
  ) {
    return true;
  }

  return false;
};

/**
 * Returns a particular person model attribute
 * @param member The member containing the person data
 * @param attribute The person attribute to retrieve
 * @returns The attribute's value or an empty string if not found
 */
export const getPersonData = (member: Member, attribute: string) => {
  if (!member || !member.person) {
    return '';
  }

  const person = member.person as Person;
  return person[attribute] || '';
};

/**
 * Returnes a formatted name for the person data associated with a member
 * @param member The member containing the person data
 * @returns The full name of the member
 */
export const getPersonName = (member: Member) => {
  const prefix = getPersonData(member, 'prefix');
  const suffix = getPersonData(member, 'suffix');
  const firstName = getPersonData(member, 'firstName');
  const middleName = getPersonData(member, 'middleName');
  const lastName = getPersonData(member, 'lastName');
  return `${prefix} ${firstName} ${middleName} ${lastName} ${suffix}`.trim();
};

export default function MemberModal(memberModalProps: memberModalProps) {
  const {
    handleMemberClose,
    memberModalOpen,
    membershipAccountUid,
    modalTitle,
    openMemberWithdrawalDialog,
    selectedMember,
    selectedMembersEnrollments,
    setSelectedMember,
    setSnackbarMessage,
    setSnackbarOpen,
  } = memberModalProps;
  const styles = useStyles();
  const { user } = useSession();
  const showSnackbar = (message: string) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };
  const [reactivateMemberOpen, setReactivateMemberOpen] = useState(false);
  const [reactivationConfirmationMessage, setReactivationConfirmationMessage] = useState<
    string | ReactElement
  >('');

  const [salesforceContactLinkOpen, setSalesforceContactLinkOpen] = useState(false);

  const [memberTabValue, setMemberTabValue] = useState(0);
  const person = selectedMember && (selectedMember.person as Person);
  const addresses = person ? person.addresses : [];

  const [editingMember, toggleEditingMember] = useState(false);
  const updateMember = useMutation(accountData =>
    apiClient.membershipAccounts.updateMember(accountData),
  );
  const reactivateMember = useMutation((memberUid: string) =>
    apiClient.members.reactivate(memberUid),
  );
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const handleMemberTabChange = (event: React.ChangeEvent<{}>, tabNumber: number) => {
    setMemberTabValue(tabNumber);
  };

  const showWithdrawMember = () => {
    handleMemberClose();
    openMemberWithdrawalDialog(selectedMember);
  };

  const closeModal = () => {
    setSelectedMember(null);
    handleMemberClose();
  };

  const handleCopyContactId = () => {
    if (person?.sfContactUId) {
      navigator.clipboard.writeText(person.sfContactUId);
      showSnackbar('Copied Contact UID');
    }
  };

  const handleReactivateMember = () => {
    const latestEnrollment = getLatestEnrollment(selectedMembersEnrollments);

    let programDescription = latestEnrollment
      ? (latestEnrollment.memberProgram as MemberProgram).programLevel.description
      : '[unknown program]';
    setReactivationConfirmationMessage(
      <>
        <div>Are you sure you want reactivate this member on {programDescription}?</div>
        {latestEnrollment !== undefined &&
          (latestEnrollment.memberProgram as MemberProgram).status !==
            MemberProgramStatus.Active && (
            <>
              <Spacer size={20} />
              <div>
                This program is not currently active and will be set to Active status by
                reactivating this member. If there are other members on this program that also need
                to be reactivated, they must be reactivated individually. You can also reactivate
                this program and all members on it by closing this dialog and using the Reactivate
                Program feature instead.
              </div>
            </>
          )}
      </>,
    );
    setReactivateMemberOpen(true);
  };

  const readWriteContent = () => {
    if (!selectedMember) return null;

    return (
      <form noValidate autoComplete="off">
        <div className={styles.modalTitle}>
          <Typography variant="h3">{modalTitle}</Typography>
          <IconButton title="cancel" onClick={closeModal}>
            <CloseIcon />
          </IconButton>
        </div>
        <div style={{ flexGrow: 1, display: 'flex', minHeight: 350 }}>
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={memberTabValue}
            onChange={handleMemberTabChange}
            aria-label="Member Information"
            style={{ borderRight: '1px solid #ccc' }}
            indicatorColor="primary"
          >
            <Tab label="Member" id="vertical-tab-0" />
            <Tab label="Addresses" id="vertical-tab-1" />
            <Tab label="Programs" id="vertical-tab-2" />
          </Tabs>
          <div
            role="tabpanel"
            hidden={memberTabValue !== 0}
            id="vertical-tabpanel-0"
            style={{ flex: 1, paddingLeft: 20 }}
          >
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography variant="h6">Name</Typography>
                  <div className={styles.formValueLabel}>{getPersonName(selectedMember)}</div>
                </div>
              </Grid>
              <Grid item xs={4}>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography variant="h6">Email</Typography>
                  <div className={styles.formValueLabel}>
                    <Tooltip title={getPersonData(selectedMember, 'email')}>
                      <Typography noWrap>
                        {getPersonData(selectedMember, 'email') || '-'}
                      </Typography>
                    </Tooltip>
                  </div>
                </div>
              </Grid>
              <Grid item xs={4}>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography variant="h6">Date of Birth</Typography>
                  <div className={styles.formValueLabel}>
                    {getPersonData(selectedMember, 'dob')
                      ? moment(getPersonData(selectedMember, 'dob')).format('MM/DD/YYYY')
                      : '-'}
                  </div>
                </div>
              </Grid>
              <Grid item xs={4}>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography variant="h6">Role</Typography>
                  <div className={styles.formValueLabel}>
                    {selectedMember && selectedMember.role
                      ? removeUnderscoresFromString(selectedMember.role)
                      : '-'}
                  </div>
                </div>
              </Grid>
              <Grid item xs={4}>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography variant="h6">Gender</Typography>
                  <div className={styles.formValueLabel}>
                    {getPersonData(selectedMember, 'gender') || '-'}
                  </div>
                </div>
              </Grid>
              <Grid item xs={4}>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography variant="h6">Phone Number</Typography>
                  {!getPersonData(selectedMember, 'primaryPhone') &&
                  !getPersonData(selectedMember, 'mobilePhone') &&
                  !getPersonData(selectedMember, 'otherPhone') ? (
                    '-'
                  ) : (
                    <>
                      {getPersonData(selectedMember, 'primaryPhone') && (
                        <div>{`${formatPhoneNumber(
                          getPersonData(selectedMember, 'primaryPhone'),
                        )} (primary)`}</div>
                      )}
                      {getPersonData(selectedMember, 'mobilePhone') && (
                        <div>{`${formatPhoneNumber(
                          getPersonData(selectedMember, 'mobilePhone'),
                        )} (mobile)`}</div>
                      )}
                      {getPersonData(selectedMember, 'otherPhone') && (
                        <div>{`${formatPhoneNumber(
                          getPersonData(selectedMember, 'otherPhone'),
                        )} (other)`}</div>
                      )}
                    </>
                  )}
                </div>
              </Grid>
              <Grid item xs={4}>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography variant="h6">Marital Status</Typography>
                  <div className={styles.formValueLabel}>
                    {getPersonData(selectedMember, 'maritalStatus') || '-'}
                  </div>
                </div>
              </Grid>
              <Grid item xs={4}>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography variant="h6">SSN</Typography>
                  <div className={styles.formValueLabel}>
                    {getPersonData(selectedMember, 'ssn')
                      ? `*****${getPersonData(selectedMember, 'ssn').toString().substring(5)}`
                      : '-'}
                  </div>
                </div>
              </Grid>
              <Grid item xs={4}>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography variant="h6">Dependent #</Typography>
                  <div className={styles.formValueLabel}>
                    {selectedMember && selectedMember.dependentNumber
                      ? selectedMember.dependentNumber
                      : '-'}
                  </div>
                </div>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="h6">
                  Contact UID
                  {(selectedMember?.person as Person)?.sfContactUId && (
                    <FileCopyOutlinedIcon
                      style={{ fontSize: '0.9rem', marginLeft: 10, cursor: 'pointer' }}
                      onClick={() => handleCopyContactId()}
                    />
                  )}
                </Typography>
                <div className={styles.formValueLabel}>
                  {(selectedMember?.person as Person)?.sfContactUId || 'n/a'}
                </div>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="h6">Retired</Typography>
                <div className={styles.formValueLabel}>
                  {selectedMember?.retired && (
                    <StatusBadge variant="info" color="info" label="yes" />
                  )}
                  {!selectedMember?.retired && (
                    <StatusBadge variant="default" color="default" label="no" />
                  )}
                </div>
              </Grid>
            </Grid>
            {user && (user.hasRole(Roles.SystemAdmin) || user.hasRole(Roles.BillingAdmin)) && (
              <Button
                variant="outlined"
                size="small"
                className={styles.relinkContactButton}
                onClick={() => setSalesforceContactLinkOpen(true)}
              >
                Link Salesforce Contact
              </Button>
            )}
          </div>
          <div
            role="tabpanel"
            hidden={memberTabValue !== 1}
            id="vertical-tabpanel-1"
            style={{ flex: 1, paddingLeft: 20 }}
          >
            {person && membershipAccountUid && (
              <MemberAddress
                addresses={addresses}
                personUid={person.uid}
                membershipAccountUid={membershipAccountUid}
              />
            )}
          </div>
          <div
            role="tabpanel"
            hidden={memberTabValue !== 2}
            id="vertical-tabpanel-2"
            style={{ flex: 1, paddingLeft: 20 }}
          >
            <MemberEnrollments
              selectedMembersEnrollments={selectedMembersEnrollments}
              membershipAccountUid={membershipAccountUid}
              handleMemberClose={handleMemberClose}
            />
          </div>
        </div>
        {!selectedMember?.archived && (
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            {user &&
              user.can(Action.Withdraw, Subject.Member) &&
              canWithdraw(selectedMembersEnrollments) && (
                <Button
                  variant="contained"
                  color="primary"
                  size="medium"
                  className={styles.dialogButton}
                  onClick={showWithdrawMember}
                >
                  Withdraw
                </Button>
              )}
            {user &&
              user.can(Action.Withdraw, Subject.Member) &&
              canReactivate(selectedMembersEnrollments) && (
                <Button
                  variant="contained"
                  color="primary"
                  size="medium"
                  className={styles.dialogButton}
                  onClick={() => handleReactivateMember()}
                >
                  Reactivate
                </Button>
              )}
            {false && user && user.can(Action.Update, Subject.Member) && (
              <Button
                className={styles.dialogButton}
                title="edit"
                size="medium"
                variant="contained"
                color="primary"
                onClick={() => toggleEditingMember(true)}
                fullWidth
              >
                Edit
              </Button>
            )}
            <Button
              className={styles.dialogButton}
              title="close"
              size="medium"
              variant="contained"
              color="default"
              onClick={closeModal}
              fullWidth
            >
              Close
            </Button>
          </div>
        )}
      </form>
    );
  };

  const editMember = () => {
    return (
      <AccountMemberForm
        member={selectedMember}
        handleSubmit={onUpdateMember}
        toggleModal={toggleEditingMember}
      />
    );
  };

  const onUpdateMember = (accountData: any) => {
    updateMember.mutate(accountData, {
      onSuccess: (data, variables, context) => {
        toggleEditingMember(false);
        dispatch(showToastMessage({ message: 'Member was updated successfully', type: 'success' }));
        queryClient.invalidateQueries(['membershipAccount']);
        toggleEditingMember(false);
      },
      onError: (error: any, variables, context) => {
        dispatch(
          showToastMessage({
            message: error.message || 'An error occurred while updating this member',
            type: 'error',
            duration: 5000,
          }),
        );
        toggleEditingMember(false);
      },
    });
  };

  const onReactivateMember = () => {
    reactivateMember.mutate(selectedMember!.uid, {
      onSuccess: async () => {
        await queryClient.invalidateQueries(['membershipAccount', membershipAccountUid]);
        handleMemberClose();
        setReactivateMemberOpen(false);
        dispatch(
          showToastMessage({
            message: 'Member was reactivated successfully',
            type: 'success',
          }),
        );
      },
      onError: (error: any) => {
        handleMemberClose();
        setReactivateMemberOpen(false);
        dispatch(
          showToastMessage({
            message: error.message,
            type: 'error',
          }),
        );
      },
    });
  };

  return (
    <Modal
      className={styles.modal}
      open={memberModalOpen}
      onClose={closeModal}
      closeAfterTransition
      disableBackdropClick
      disableEscapeKeyDown
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <>
        <Fade in={memberModalOpen}>
          <Card className={styles.memberModalBody}>
            <CardContent>
              {selectedMember && (editingMember ? editMember() : readWriteContent())}
            </CardContent>
          </Card>
        </Fade>

        <SalesforceContactLink
          membershipAccountUid={membershipAccountUid}
          member={selectedMember}
          open={salesforceContactLinkOpen}
          onClose={() => setSalesforceContactLinkOpen(false)}
        ></SalesforceContactLink>

        <ConfirmationDialog
          open={reactivateMemberOpen}
          title="Confirm Reactivation"
          message={reactivationConfirmationMessage}
          onConfirm={onReactivateMember}
          onCancel={() => setReactivateMemberOpen(false)}
          cancelText="no"
          loading={reactivateMember.isLoading}
        />
      </>
    </Modal>
  );
}
