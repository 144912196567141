import React from 'react';
import moment from 'moment';
import { Card, CardContent, Theme, Grid, makeStyles, Typography } from '@material-ui/core';
import Member from '../../../../models/member';
import Person from '../../../../models/person';
import MemberProgram from '../../../../models/memberProgram';

const useStyles = makeStyles((theme: Theme) => ({
  cardWrapper: {
    opacity: 0.8,
    transition: 'all 0.2s ease-in-out',
    '&:hover': {
      opacity: 1,
    },
    borderRadius: 10,
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    minHeight: '92px',
    '&:last-child': {
      paddingBottom: '16px',
    },
    backgroundColor: theme.backgroundColors.body,
  },
  currentProgramWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  btnDetailsWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
  },
  btnDetails: {
    cursor: 'pointer',
  },
  btnPopover: {
    borderRadius: '50%',
  },
  selectable: {
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: '92px',
    '&:last-child': {
      paddingBottom: '16px',
    },
  },
  programDetails: {
    padding: '2px 0',
  },
}));

interface EnrolledProgramProps {
  memberProgram: MemberProgram;
  members?: Member[];
  showPrice?: boolean;
  showOptions?: boolean;
}
const EnrolledProgram = (props: EnrolledProgramProps) => {
  const { members, memberProgram, showPrice, showOptions } = props;
  const classes = useStyles();

  return (
    <Card className={classes.cardWrapper}>
      <CardContent className={classes.cardContent}>
        <Grid item xs={12}>
          <div className={classes.currentProgramWrapper}>
            <Typography
              variant="h5"
              component="div"
              style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}
            >
              <div>
                <strong>
                  {memberProgram.programLevel.description}{' '}
                  <Typography component="span" variant="h6" style={{ marginLeft: 10 }}>
                    {memberProgram.programLevel.name}
                  </Typography>
                </strong>
              </div>
              {showPrice && (
                <div className={classes.programDetails}>
                  <strong>${memberProgram.currentPrice}/mo</strong>
                </div>
              )}
            </Typography>
            {showOptions && memberProgram.programOptions && (
              <div className={classes.programDetails}>
                <strong>Options:</strong>{' '}
                {(memberProgram.programOptions.length &&
                  memberProgram.programOptions.map(option => option.description).join(', ')) ||
                  'N/A'}
              </div>
            )}
            <div>
              <strong>Effective:</strong> {moment(memberProgram.started, 'YYYY-MM-DD').format('LL')}
            </div>
            {members && (
              <div>
                <strong>Enrolled Members: </strong>
                {members.map((member: Member, index: number) => {
                  const person = member.person as Person;
                  return (
                    <span key={index}>
                      {index > 0 ? ', ' : ''}
                      {person.firstName} {person.lastName}
                    </span>
                  );
                })}
              </div>
            )}
          </div>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default EnrolledProgram;
