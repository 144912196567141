import { Action, Subject } from '@ccm-innovation/auth-membership-service';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { Redirect, useParams } from 'react-router-dom';
import { Breadcrumb } from '../../../components/Page';
import { useSession } from '../../../context/session';
import medicalBillReimbursementApi from '../api/medical-bill-reimbursements.api.adapter';
import MedicalBillReimbursementTemplate from '../components/MedicalBillReimbursementTemplate';
import { hookFactory } from '../hooks/medical-bill-reimbursement.hooks';
import {
  MedicalBillReimbursementSalesforceCaseStatus,
  MedicalBillReimbursementStatus,
} from '../medical-bill-reimbursement.constants';
import { MedicalBillReimbursementSalesforceCaseGetResponseDto } from '../types/medical-bill-reimbursement-salesforce-case-get.response.dto';
import { MedicalBillReimbursementUpdateRequestDto } from '../types/medical-bill-reimbursement-update.request.dto';
import { MedicalBillReimbursementWithDetailsResponseDto } from '../types/medical-bill-reimbursement-with-details.response.dto';

interface Params {
  uid: string;
}

const MedicalBillReimbursementView = () => {
  const { user } = useSession();
  const { uid }: Params = useParams();

  const [isEditMode, setIsEditMode] = useState(false);
  const [isModified, setIsModified] = useState(false);

  const [data, setData] = useState<MedicalBillReimbursementWithDetailsResponseDto | undefined>(
    undefined,
  );

  const [adminCaseData, setAdminCaseData] = useState<
    MedicalBillReimbursementSalesforceCaseGetResponseDto | undefined
  >(undefined);

  // Hooks
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const {
    useMedicalBillReimbursementQuery,
    useMedicalBillReimbursementAdminCaseQuery,
    useMedicalBillReimbursementListFilesQuery,
    useCloseMedicalBillReimbursementAdminCaseMutation,
    useUpdateMedicalBillReimbursementMutation,
    useDownloadFileMutation,
  } = hookFactory(medicalBillReimbursementApi, queryClient, dispatch);

  // Queries
  const dataQuery = useMedicalBillReimbursementQuery({ uid });
  const adminCaseDataQuery = useMedicalBillReimbursementAdminCaseQuery({ uid });
  const filesQuery = useMedicalBillReimbursementListFilesQuery({ uid });

  // Mutations
  const closeAdminCaseMutation = useCloseMedicalBillReimbursementAdminCaseMutation();
  const updateMutation = useUpdateMedicalBillReimbursementMutation();
  const downloadFileMutation = useDownloadFileMutation();

  // Computed values
  const breadcrumbs = useMemo<Breadcrumb[]>(
    () => [
      { href: '/', label: 'Home' },
      { href: '#', label: 'Needs Processing' },
      { href: '/medical-bill-reimbursements', label: 'Reimbursement Requests' },
      { label: dataQuery.isLoading ? 'Loading...' : `#${data?.referenceNumber}` || '' },
    ],
    [data, dataQuery.isLoading],
  );

  const isMissing = useMemo<boolean>(() => {
    return !dataQuery.data && !dataQuery.isLoading && dataQuery.isFetched;
  }, [dataQuery]);

  const shouldShowAdminCloseButton = useMemo<boolean>(() => {
    return (
      closeAdminCaseMutation.isLoading ||
      (!adminCaseDataQuery.isFetching &&
        !adminCaseDataQuery.isLoading &&
        !!adminCaseData &&
        adminCaseData.Status !== MedicalBillReimbursementSalesforceCaseStatus.Closed)
    );
  }, [closeAdminCaseMutation, adminCaseDataQuery, adminCaseData]);

  useEffect(() => {
    if (dataQuery.data && !isModified) {
      setData(dataQuery.data);
    }
  }, [dataQuery.data, isModified]);

  useEffect(() => {
    if (adminCaseDataQuery.data) {
      setAdminCaseData(adminCaseDataQuery.data);
    }
  }, [adminCaseDataQuery.data]);

  useEffect(() => {
    if (isEditMode && isModified && updateMutation.isSuccess) {
      setIsEditMode(false);
      setIsModified(false);
      updateMutation.reset();
    }
  }, [isEditMode, isModified, updateMutation]);

  // Event handlers
  const handleSave = useCallback(async () => {
    if (data) {
      const updateRequest: MedicalBillReimbursementUpdateRequestDto = {
        status: data.status,
        medicalBillNumbers: data.medicalBillNumbers,
      };

      await updateMutation.mutateAsync({ uid, data: updateRequest });
    }
  }, [uid, data, updateMutation]);

  const handleUnlock = useCallback(() => {
    setIsEditMode(true);
  }, []);

  const handleCancel = useCallback(() => {
    setData(dataQuery.data);
    setIsEditMode(false);
    setIsModified(false);
  }, [dataQuery.data]);

  const handleStatusChange = useCallback(
    (status: MedicalBillReimbursementStatus) => {
      if (data) {
        setIsModified(true);
        setIsEditMode(true);

        setData({
          ...data,
          status,
        });
      }
    },
    [data],
  );

  const handleMedicalBillNumbersChange = useCallback(
    (medicalBillNumbers: string[]) => {
      if (data) {
        setIsModified(true);
        setIsEditMode(true);

        setData({
          ...data,
          medicalBillNumbers,
        });
      }
    },
    [data],
  );

  const handleAttachmentClick = useCallback(
    (fileId: string) => {
      if (!data?.uid) return;
      downloadFileMutation.mutate({ uid: data?.uid, fileId });
    },
    [data, downloadFileMutation],
  );

  if (isMissing) {
    return <Redirect to={{ pathname: '/not-found' }} />;
  }

  if (user && user.cannot(Action.View, Subject.ReimbursementRequest)) {
    return <Redirect to={{ pathname: '/not-found' }} />;
  }

  return (
    <MedicalBillReimbursementTemplate
      isDataLoading={dataQuery.isFetching || dataQuery.isLoading}
      shouldShowAdminCloseButton={shouldShowAdminCloseButton}
      isSaving={updateMutation.isLoading}
      isClosingAdminCase={closeAdminCaseMutation.isLoading}
      breadcrumbs={breadcrumbs}
      isEditMode={isEditMode}
      isModified={isModified}
      data={data}
      adminCaseId={adminCaseData?.Id}
      files={filesQuery.data}
      onCloseAdminCase={() => data && closeAdminCaseMutation.mutateAsync({ uid: data.uid })}
      onSave={handleSave}
      onUnlock={handleUnlock}
      onCancel={handleCancel}
      onStatusChange={handleStatusChange}
      onMedicalBillNumbersChange={handleMedicalBillNumbersChange}
      onAttachmentClick={handleAttachmentClick}
    />
  );
};

export default MedicalBillReimbursementView;
