import React from 'react';
import { Fade } from '@material-ui/core';
import PageHeader, { Breadcrumb } from './PageHeader';

/**
 * Page
 * @param {*} props
 * The Page component wraps content with a fade transition and includes an optional
 * PageHeader for display of a screen title and/or breadcrumb navigation
 */
const Page = (props: any) => {
  const { title, breadcrumbs, children } = props;
  return (
    <Fade in={true} timeout={1000}>
      <div>
        <PageHeader title={title} breadcrumbs={breadcrumbs} />
        {children}
      </div>
    </Fade>
  );
};

export type { Breadcrumb };
export default Page;
