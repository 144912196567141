import React from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  createStyles,
  makeStyles,
  Typography,
  Container,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(theme =>
  createStyles({
    titleContainer: {
      textAlign: 'center',
      marginBottom: 20,
      '& h1': {
        fontSize: '5rem',
        opacity: 0.35,
      },
      '& h2': {
        opacity: 0.45,
      },
    },
    contentContainer: {
      textAlign: 'center',
    },
    componentContainer: {
      maxWidth: '640px',
    },
  }),
);

const ApplicationError = (props: any) => {
  const { redirect, buttonTitle } = props;
  const styles = useStyles();
  const history = useHistory();

  const handleBack = () => {
    history.push(redirect || '/');
  };
  return (
    <Container className={styles.componentContainer}>
      <Card>
        <CardContent>
          <Box className={styles.titleContainer} marginBottom={5}>
            <Typography variant="h1">🥦</Typography>
            <Typography variant="h2">Application Error</Typography>
          </Box>

          <Box className={styles.contentContainer}>
            <Box marginBottom={5}>
              <Typography variant="h6">
                An error has occurred and this resource cannot be accessed at this time
              </Typography>
            </Box>
            <Box>
              <Button size="large" variant="contained" color="primary" onClick={handleBack}>
                {buttonTitle || 'Go Home'}
              </Button>
            </Box>
          </Box>
        </CardContent>
      </Card>
    </Container>
  );
};

export default ApplicationError;
