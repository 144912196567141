import { makeStyles } from '@material-ui/styles';

const useGlobalStyles = makeStyles({
  fixedTable: { tableLayout: 'fixed' },
  ellipsisCell: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  centeredCell: {
    textAlign: 'center',
  },
  fullWidthCell: {
    width: '100%',
  },
});

export default useGlobalStyles;
