import apiClient, { cancelRequest } from './client';
import { APIClientBase } from './apiClientBase';
import MembershipAccount from '../../models/membershipAccount';
import { AuditEvent } from '../../models/auditEvent';
import BillingMember from '../../models/ccm-billing/billingMember';
import WorkflowInstance from '../../models/workflowInstance';

export interface AccountSearchParams {
  query: MembershipAccountQueryParams;
  signal?: any;
}
export interface MembershipAccountQueryParams {
  /** The account name to search for */
  accountName?: string | null;
  /** Filters the results by the associated group's UID */
  group?: string;
  /** Finds accounts that have a membership number or name matching the query value */
  nameOrNumber?: string;
  /** Free-text search string used for querying on account name, number, netsuite Id, etc. */
  searchQuery?: string;
  /** The pagination page to get results for */
  page?: number;
  /** The amount of results to return in a single page */
  pageSize?: number;
  /** If true, returns 1 more record than `pageSize` to determine if there are additional pages to load */
  plus1?: boolean;
  /** The property in the MembershipAccount model to order the results by */
  orderBy?: string;
  /** The direction in which to sort the results */
  sort?: 'asc' | 'desc';
  /** Expands associated models from a string UID to the full data object */
  expand?: ('organization' | 'group' | 'group.organization')[];
  /** Set to true to include the associated members */
  includeMembers?: boolean;
  /** Set to true to include the associated programs */
  includeMemberPrograms?: boolean;
  /** Set to true to include the associated program enrollments */
  includeProgramEnrollments?: boolean;
  /** Set to true to include the associated addresses */
  includeAddresses?: boolean;
  /** Set to true to include archived accounts */
  includeArchived?: boolean;
  /** Set to true to include inactive accounts */
  includeInactive?: boolean;
}

export interface AuditEventsQueryParams {
  action?: string;
  result?: string;
  startDate?: string;
  endDate?: string;
  page?: number;
  actorUid?: string;
  subject?: string;
  actorName?: string;
  pageSize?: number;
  plus1: boolean;
}

class MembershipAccounts extends APIClientBase {
  private static _instance: MembershipAccounts;

  static getInstance(): MembershipAccounts {
    if (MembershipAccounts._instance === undefined) {
      MembershipAccounts._instance = new MembershipAccounts();
    }
    return MembershipAccounts._instance;
  }

  save = async (accountData: any): Promise<MembershipAccount> => {
    try {
      const url = accountData.uid
        ? `/membership/accounts/${accountData.uid}`
        : '/membership/accounts';
      const response = accountData.uid
        ? await apiClient.put(url, accountData)
        : await apiClient.post(url, accountData);
      const data = this.response(response);
      const account = new MembershipAccount(data);
      return account;
    } catch (err: any) {
      const error = this.error(err);
      throw error;
    }
  };

  get = async (uid: string, query?: any): Promise<MembershipAccount> => {
    try {
      const queryString = this.toQueryString(query);
      const response = await apiClient.get(`/membership/accounts/${uid}?${queryString}`);
      const data = this.response(response);
      const account = new MembershipAccount(data);
      return account;
    } catch (err: any) {
      const error = this.error(err);
      throw error;
    }
  };

  list = async (query: MembershipAccountQueryParams = {}): Promise<MembershipAccount[]> => {
    try {
      const queryString = this.toQueryString(query);
      const response = await apiClient.get(`/membership/accounts?${queryString}`);
      const data = this.response(response);
      const accounts: MembershipAccount[] = [];
      for (let i = 0; i < data.length; i++) {
        accounts.push(data[i]);
      }
      return accounts;
    } catch (err: any) {
      const error = this.error(err);
      throw error;
    }
  };

  search = async ({ query = {}, signal }: AccountSearchParams): Promise<MembershipAccount[]> => {
    try {
      const queryString = this.toQueryString(query);
      const cancelToken = signal ? cancelRequest(signal) : undefined;
      const response = await apiClient.get(`/membership/accounts/search?${queryString}`, {
        cancelToken,
      });
      const data = this.response(response);
      const accounts: MembershipAccount[] = [];
      for (let i = 0; i < data.length; i++) {
        accounts.push(data[i]);
      }
      return accounts;
    } catch (err: any) {
      const error = this.error(err);
      throw error;
    }
  };

  import = async (
    salesforceId: string,
  ): Promise<{ membershipAccount: MembershipAccount; existingAccount?: boolean }> => {
    try {
      let existingAccount = false;
      const url = `/membership/accounts/import/${salesforceId}`;
      const response = await apiClient.get(url);
      const data = this.response(response);
      const membershipAccount = new MembershipAccount(data.membershipAccount);
      if (response.status === 200) {
        existingAccount = true;
      } else if (response.status === 201) {
        existingAccount = false;
      }
      return { membershipAccount, existingAccount };
    } catch (err: any) {
      const error = this.error(err);
      throw error;
    }
  };

  updateMember = async (memberData: any): Promise<boolean> => {
    const uid = memberData.uid;
    try {
      const url = `/membership/persons/${uid}`;
      await apiClient.patch(url, memberData);
      return true;
    } catch (err: any) {
      const error = this.error(err);
      throw error;
    }
  };

  getAuditEvents = async (uid: string, query?: AuditEventsQueryParams): Promise<AuditEvent[]> => {
    try {
      const queryString = this.toQueryString(query);
      const url = `/membership/accounts/${uid}/auditevents?${queryString}`;
      const response = await apiClient.get(url);
      return this.response(response);
    } catch (err: any) {
      const error = this.error(err);
      throw error;
    }
  };

  cancelMembershipAccount = async (
    uid: string,
    reason: string,
    endDate: string,
  ): Promise<boolean> => {
    try {
      const url = `/membership/accounts/${uid}/cancellation`;
      await apiClient.patch(url, { reason, endDate });
      return true;
    } catch (err: any) {
      const error = this.error(err);
      throw error;
    }
  };

  getSalesforceAccountContacts = async (uid: string, query: any): Promise<any> => {
    try {
      const queryString = this.toQueryString(query);
      const response = await apiClient.get(`/membership/accounts/${uid}/sfcontacts?${queryString}`);
      const data = this.response(response);
      return data;
    } catch (err: any) {
      const error = this.error(err);
      throw error;
    }
  };

  importContactAddOn = async (
    uid: string,
    memberProgramUid: string,
    sfContactId: string,
    date: string,
    retired: boolean,
  ): Promise<boolean> => {
    try {
      const url = `/membership/accounts/${uid}/sfcontacts/import`;
      await apiClient.post(url, {
        sfContactId,
        memberProgramUid,
        date,
        retired,
      });
      return true;
    } catch (err: any) {
      const error = this.error(err);
      throw error;
    }
  };

  torchData = async (uid: string, options: any): Promise<any> => {
    try {
      const url = `/membership/accounts/${uid}/nukeallthethings`;
      const response = await apiClient.post(url, options);
      const data = this.response(response);
      return data;
    } catch (err: any) {
      const error = this.error(err);
      throw error;
    }
  };

  changeGroup = async (uid: string, groupUId: string): Promise<MembershipAccount> => {
    try {
      const url = `/membership/accounts/${uid}/groupchange`;
      const response = await apiClient.put(url, {
        groupUId: groupUId,
      });
      const data = this.response(response);
      const account = new MembershipAccount(data);
      return account;
    } catch (err: any) {
      const error = this.error(err);
      throw error;
    }
  };

  getBillingMembers = async (uid: string): Promise<BillingMember[]> => {
    try {
      const url = `/membership/accounts/${uid}/billing-members`;
      const response = await apiClient.get(url);
      return this.response(response);
    } catch (err: any) {
      const error = this.error(err);
      throw error;
    }
  };

  createBillingRecords = async (uid: string): Promise<any> => {
    try {
      const url = `/membership/accounts/${uid}/billing-account`;
      const response = await apiClient.post(url);
      const data = this.response(response);
      return data;
    } catch (err: any) {
      const error = this.error(err);
      throw error;
    }
  };

  getDeathOfMemberWorkflowInstanceInProgress = async (
    uid: string,
  ): Promise<WorkflowInstance | null> => {
    try {
      const url = `/membership/workflows/death`;
      const response = await apiClient.get(url, {
        params: {
          membershipAccount: uid,
          status: 'in-progress',
        },
      });
      const workflowInstances: WorkflowInstance[] = this.response(response);
      if (workflowInstances.length > 0) {
        return workflowInstances[0];
      } else {
        return null;
      }
    } catch (err: any) {
      const error = this.error(err);
      throw error;
    }
  };
}

export default MembershipAccounts.getInstance();
