import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Thunk } from '..';
import CustomError from '../../lib/customError';
import User from '../../models/user';
import apiClient from '../../lib/api';
import { hideToastMessage, showToastMessage } from './toastMessage';

interface UserState {
  user: User | null;
  loading: boolean;
  saved: boolean;
  error: CustomError | null;
}

interface UserSuccessPayload {
  user: User;
}

interface UserErrorPayload {
  error: CustomError;
}

export const initialState = {
  user: null,
  loading: false,
  saved: false,
  error: null,
} as UserState;

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    getUserRequest(state) {
      state.user = null;
      state.error = null;
      state.loading = true;
      state.saved = false;
    },
    getUserSuccess(state, action: PayloadAction<UserSuccessPayload>) {
      state.user = action.payload.user;
      state.error = null;
      state.loading = false;
      state.saved = false;
    },
    getUserError(state, action: PayloadAction<UserErrorPayload>) {
      state.user = null;
      state.error = action.payload.error;
      state.loading = false;
      state.saved = false;
    },
    verifyUserRequest(state) {
      state.user = null;
      state.error = null;
      state.loading = true;
      state.saved = false;
    },
    verifyUserSuccess(state, action: PayloadAction<UserSuccessPayload>) {
      state.user = action.payload.user;
      state.error = null;
      state.loading = false;
      state.saved = false;
    },
    verifyUserError(state, action: PayloadAction<UserErrorPayload>) {
      state.user = null;
      state.error = action.payload.error;
      state.loading = false;
      state.saved = false;
    },
    activateUserRequest(state) {
      state.error = null;
      state.loading = true;
      state.saved = false;
    },
    activateUserSuccess(state, action: PayloadAction<UserSuccessPayload>) {
      state.user = action.payload.user;
      state.error = null;
      state.loading = false;
      state.saved = true;
    },
    activateUserError(state, action: PayloadAction<UserErrorPayload>) {
      state.error = action.payload.error;
      state.loading = false;
      state.saved = false;
    },
    deactivateUserRequest(state) {
      state.error = null;
      state.loading = true;
      state.saved = false;
    },
    deactivateUserSuccess(state, action: PayloadAction<UserSuccessPayload>) {
      state.user = action.payload.user;
      state.error = null;
      state.loading = false;
      state.saved = true;
    },
    deactivateUserError(state, action: PayloadAction<UserErrorPayload>) {
      state.error = action.payload.error;
      state.loading = false;
      state.saved = false;
    },
    clearUser(state) {
      state.user = null;
      state.error = null;
      state.loading = false;
      state.saved = false;
    },
    updateUserRequest(state) {
      state.error = null;
      state.loading = true;
      state.saved = false;
    },
    updateUserSuccess(state, action: PayloadAction<UserSuccessPayload>) {
      state.user = action.payload.user;
      state.error = null;
      state.loading = false;
      state.saved = true;
    },
    updateUserError(state, action: PayloadAction<UserErrorPayload>) {
      state.error = action.payload.error;
      state.loading = false;
      state.saved = false;
    },
  },
});

export const getUser = (uid: string): Thunk => async dispatch => {
  try {
    dispatch(hideToastMessage());
    dispatch(getUserRequest());
    const user = await apiClient.users.get(uid);
    dispatch(getUserSuccess({ user }));
  } catch (err: any) {
    dispatch(getUserError({ error: err }));
    dispatch(showToastMessage({ message: err.message, type: 'error' }));
  }
};

export const verifyUser = (email: string): Thunk => async dispatch => {
  try {
    dispatch(hideToastMessage());
    dispatch(verifyUserRequest());
    const user = await apiClient.users.verify(email);
    dispatch(verifyUserSuccess({ user }));
  } catch (err: any) {
    dispatch(verifyUserError({ error: err }));
    dispatch(showToastMessage({ message: err.message, type: 'error' }));
  }
};

export const activateUser = (email: string): Thunk => async dispatch => {
  try {
    dispatch(hideToastMessage());
    dispatch(activateUserRequest());
    const user = await apiClient.users.activate(email);
    dispatch(activateUserSuccess({ user }));
    dispatch(showToastMessage({ message: 'User activated', type: 'success', duration: 3000 }));
  } catch (err: any) {
    dispatch(activateUserError({ error: err }));
    dispatch(showToastMessage({ message: err.message, type: 'error' }));
  }
};

export const deactivateUser = (email: string): Thunk => async dispatch => {
  try {
    dispatch(hideToastMessage());
    dispatch(deactivateUserRequest());
    const user = await apiClient.users.deactivate(email);
    dispatch(deactivateUserSuccess({ user }));
    dispatch(showToastMessage({ message: 'User deactivated', type: 'success' }));
  } catch (err: any) {
    dispatch(deactivateUserError({ error: err }));
    dispatch(showToastMessage({ message: err.message, type: 'error' }));
  }
};

export const updateUser = (userData: User): Thunk => async dispatch => {
  try {
    dispatch(hideToastMessage());
    dispatch(updateUserRequest());
    const user = await apiClient.users.update(userData);
    dispatch(updateUserSuccess({ user }));
    dispatch(
      showToastMessage({
        message: 'The user has been saved',
        type: 'success',
        duration: 3000,
      }),
    );
  } catch (err: any) {
    dispatch(updateUserError({ error: err }));
    dispatch(showToastMessage({ message: err.message, type: 'error' }));
  }
};

/* Actions & Reducer */
/* prettier-ignore */
export const { 
  getUserRequest, getUserSuccess, getUserError,
  verifyUserRequest, verifyUserSuccess, verifyUserError,
  activateUserRequest, activateUserSuccess, activateUserError,
  deactivateUserRequest, deactivateUserSuccess, deactivateUserError,
  updateUserRequest, updateUserSuccess, updateUserError,
  clearUser
} = userSlice.actions;

export default userSlice.reducer;
