import { LifeEventName } from './constants';
import MembershipAccount from '../../../../../models/membershipAccount';

export abstract class BaseEvent {
  name: LifeEventName;
  membershipAccount: MembershipAccount;

  constructor(name: LifeEventName, membershipAccount: MembershipAccount) {
    this.name = name;
    this.membershipAccount = membershipAccount;
  }
}
