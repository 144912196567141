import React from 'react';
import clsx from 'clsx';
import { Card, CardContent, makeStyles, Theme, Typography, Box } from '@material-ui/core';
import NumberFormat from 'react-number-format';
import { PricingProgramLevel, Product, ProgramOption } from '../../../../models/eligibleProgram';
import Spacer from '../../../Spacer';

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    minWidth: 450,
  },
  pricing: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  priceContainer: {
    fontWeight: 700,
    opacity: 0.8,
    display: 'flex',
    flexDirection: 'row',
  },
  leftPriceLabel: {
    minWidth: '40px',
  },
  rightPriceLabel: {
    minWidth: '50px',
  },
  alignRight: { textAlign: 'right', justifyContent: 'flex-end' },
  optionItems: { margin: 0, listStyle: 'none', padding: 0 },
}));

interface ProgramLevelDetailsProps {
  programLevel: PricingProgramLevel;
}

const ProgramLevelDetails = (props: ProgramLevelDetailsProps) => {
  const classes = useStyles();
  const { programLevel } = props;

  return (
    <Card>
      <CardContent>
        <div className={classes.title}>
          {/* Program Level Name */}
          <Typography variant="h4">
            <strong>{programLevel.name}</strong>
          </Typography>

          {/* Program Cost */}
          <Typography variant="h4">
            <NumberFormat
              value={programLevel.totalAmount}
              displayType={'text'}
              thousandSeparator={true}
              prefix={'$'}
            />
            /mo
          </Typography>
        </div>

        <div className={classes.pricing}>
          <Box>
            <Box className={classes.priceContainer}>
              <div className={classes.leftPriceLabel}>AHP: </div>
              <div>
                <NumberFormat
                  value={programLevel.ahpAmount}
                  displayType={'text'}
                  thousandSeparator={true}
                  prefix={'$'}
                />
              </div>
            </Box>
            {programLevel.mcrAmount && (
              <Box className={classes.priceContainer}>
                <div className={classes.leftPriceLabel}>MCR: </div>
                <div>
                  <NumberFormat
                    value={programLevel.mcrAmount}
                    displayType={'text'}
                    thousandSeparator={true}
                    prefix={'$'}
                  />
                </div>
              </Box>
            )}
          </Box>
          <Box>
            {programLevel.products.map((product: Product, index: number) => (
              <Box key={index} className={clsx([classes.priceContainer, classes.alignRight])}>
                <div>{product.name}: </div>
                <div className={classes.rightPriceLabel}>
                  {product.amount !== null && (
                    <NumberFormat
                      value={product.amount < 0 ? product.amount * -1 : product.amount}
                      displayType={'text'}
                      thousandSeparator={true}
                      prefix={'$'}
                    />
                  )}
                  {product.amount === null && <span>'n/a'</span>}
                </div>
              </Box>
            ))}
          </Box>
        </div>

        {programLevel.options && programLevel.options.length > 0 && (
          <>
            <Spacer size={20} />

            <div>
              <Typography variant="h6">Options</Typography>
              <ul className={classes.optionItems}>
                {programLevel.options.map((option: ProgramOption, index: number) => (
                  <li key={index}>{option.description}</li>
                ))}
              </ul>
            </div>
          </>
        )}
      </CardContent>
    </Card>
  );
};

export default ProgramLevelDetails;
