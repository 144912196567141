import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment-timezone';
import { useFormik } from 'formik';
import { Button } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { useMutation, useQueryClient } from 'react-query';
import { map } from 'lodash';
import Dialog from '../../Dialog';
import MembershipWithdrawalForm from './MembershipWithdrawalForm';
import apiClient from '../../../lib/api';
import { showToastMessage } from '../../../store/slices/toastMessage';
import { formatDate, isDateWithRange, MAX_CCM_PROGRAM_DATE } from '../../../lib/util';
import { getStartDateFromCurrentProgramEnrollment } from '../../../lib/util';
import MembershipAccount from '../../../models/membershipAccount';
import Member from '../../../models/member';
import ButtonProgressIndicator from '../../ButtonProgressIndicator';

interface WithdrawalDialogProps {
  membershipAccount: MembershipAccount;
  open: boolean;
  handleCloseDialog: Function;
  selectedMember: Member | null;
}

interface WithdrawMemberPayload {
  members: { [key: string]: Member };
  membershipAccount: string;
  endDate: string;
}

enum DialogTitles {
  WITHDRAW = 'Withdraw Members',
  CONFIRM = 'Withdraw Members: Confirmation',
}

const WithdrawalDialog: React.FC<WithdrawalDialogProps> = ({
  selectedMember,
  membershipAccount,
  handleCloseDialog,
  open,
}) => {
  const [currentTab, setCurrentTab] = useState<number>(0);
  const [withdrawalModalTitle, setWithdrawalModalTitle] = useState<DialogTitles>(
    DialogTitles.WITHDRAW,
  );
  const [footer, setFooter] = useState<any>();
  const [selectedMembers, setSelectedMembers] = useState<{ [key: string]: Member }>({});
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const withdrawMembers = useMutation((values: WithdrawMemberPayload) => {
    const members = Object.values(values.members).map((member: Member) => member.uid);
    const endDate = formatDate(values.endDate);
    const payload = { ...values, members, endDate };

    return apiClient.members.withdraw(payload);
  });

  const getSelectedMembers = useCallback((member: Member) => {
    const selected: { [key: string]: Member } = {};

    selected[member.uid] = member;
    return selected;
  }, []);

  const formik = useFormik<WithdrawMemberPayload>({
    initialValues: {
      members: {},
      membershipAccount: membershipAccount.uid,
      endDate: moment().endOf('month').format('YYYY-MM-DD'),
    },
    onSubmit: values => {
      handleSubmit();
    },
  });

  const closeDialog = () => {
    formik.setFieldValue('members', {});
    setCurrentTab(0);
    handleDateChange(moment().endOf('month').format('YYYY-MM-DD'));
    setSelectedMembers({});
    handleCloseDialog();
  };

  useEffect(() => {
    if (open && selectedMember) {
      const temp = getSelectedMembers(selectedMember);
      setSelectedMembers(temp);
      formik.setFieldValue('members', temp);
      handleDateChange(moment().endOf('month').format('YYYY-MM-DD'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMember, getSelectedMembers, open]);

  const handleSubmit = () => {
    withdrawMembers.mutate(formik.values, {
      onSuccess: () => {
        closeDialog();
        queryClient.invalidateQueries(['membershipAccount', membershipAccount.uid]);
        dispatch(
          showToastMessage({
            message: 'The withdrawal was successful',
            type: 'success',
          }),
        );
      },
      onError: (error: any) => {
        closeDialog();
        dispatch(
          showToastMessage({
            message: `Could not complete the withdrawal ${
              error.message ? `. ${error.message}` : ''
            }`,
            type: 'error',
          }),
        );
      },
    });
  };

  // Selects an account member, if the account owner is selected, all are
  const handleMemberSelected = (member: Member, selected: boolean) => {
    const temp = formik.values.members;
    if (selected) {
      temp[member.uid] = member;
    } else {
      delete temp[member.uid];
    }
    formik.setFieldValue('members', temp);
    setSelectedMembers(formik.values.members);
  };

  const renderButtons = () => {
    const nextButton = (
      <Button
        key="nextButton"
        color="primary"
        variant="contained"
        style={{ marginRight: '15px' }}
        disabled={
          Object.values(selectedMembers).length < 1 ||
          !isDateWithRange(
            formik.values.endDate,
            getEarliestWithdrawalEndDate(selectedMembers),
            MAX_CCM_PROGRAM_DATE,
          )
        }
        onClick={() => {
          setCurrentTab(currentTab + 1);
          setWithdrawalModalTitle(DialogTitles.CONFIRM);
        }}
      >
        Continue
      </Button>
    );
    const submitButton = (
      <Button
        key="submitButton"
        color="primary"
        variant="contained"
        style={{ marginRight: '15px' }}
        disabled={withdrawMembers.isLoading}
        onClick={handleSubmit}
      >
        {withdrawMembers.isLoading ? <ButtonProgressIndicator /> : 'Confirm'}
      </Button>
    );
    const cancelButton = (
      <Button
        key="cancelButton"
        color="default"
        variant="contained"
        disabled={withdrawMembers.isLoading}
        onClick={() => closeDialog()}
      >
        Cancel
      </Button>
    );
    if (currentTab === 0) {
      return [nextButton, cancelButton];
    } else {
      return [submitButton, cancelButton];
    }
  };

  useEffect(() => {
    setFooter('');
  }, [membershipAccount]);

  const handleTabClick = (tabIndex: number) => {
    tabIndex > 0
      ? setWithdrawalModalTitle(DialogTitles.CONFIRM)
      : setWithdrawalModalTitle(DialogTitles.WITHDRAW);
    setCurrentTab(tabIndex);
  };

  const handleDateChange = (date: string | null) =>
    formik.setFieldValue('endDate', formatDate(date || ''));

  /**
   * Get the earliest date to withdraw members
   *  The earliest they can be withdraw is the latest start date of all the active program enrollments from all the members selected to be withdraw
   */
  const getEarliestWithdrawalEndDate = useCallback(
    selectedMembers => {
      const members = map(selectedMembers, member => member);
      const selectedMemberProgramEnrollments = membershipAccount.programEnrollments!.filter(pe =>
        members.some(m => {
          if (typeof pe.member === 'string') {
            return (
              pe.member === m.uid &&
              ((pe.ended && moment(pe.ended).isAfter(new Date())) || pe.ended === null)
            );
          }
          return (
            (pe.member as Member).uid === m.uid &&
            ((pe.ended && moment(pe.ended).isAfter(new Date())) || pe.ended === null)
          );
        }),
      );
      const earliestWithdrawalEndDate = getStartDateFromCurrentProgramEnrollment(
        selectedMemberProgramEnrollments,
      );
      return earliestWithdrawalEndDate;
    },
    [membershipAccount.programEnrollments],
  );

  return (
    <Dialog open={open} title={withdrawalModalTitle} buttons={renderButtons()} footer={footer}>
      <MembershipWithdrawalForm
        membershipAccount={membershipAccount}
        selectedMembers={selectedMembers}
        currentTab={currentTab}
        onTabClick={handleTabClick}
        onMemberSelected={handleMemberSelected}
        onDateSelected={handleDateChange}
        endDate={formik.values.endDate}
        earliestWithdrawalDate={getEarliestWithdrawalEndDate(selectedMembers)}
      />
    </Dialog>
  );
};

export default WithdrawalDialog;
