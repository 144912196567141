import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';

import { ProgramMember } from '.';
import Spacer from '../../../../Spacer';
import MemberCard from './MemberCard';
import DialogErrors from '../../../../DialogErrors';

interface EnrollmentsProps {
  programMembers: ProgramMember[];
  minEnrollmentDate: string;
  maxEnrollmentDate: string;
  errors?: string[];
  changeEnrollmentDate(startEnd: string, id: string, dt: Date | null): void;
  changeButtonState(button: string, enabled: boolean, visible: boolean): void;
  validateInput(): void;
}

const Enrollments = ({
  programMembers,
  minEnrollmentDate,
  maxEnrollmentDate,
  errors,
  changeEnrollmentDate,
  changeButtonState,
  validateInput,
}: EnrollmentsProps) => {
  const handleDateChange = (startEnd: string, id: string, dt: Date | null) => {
    // check for valid date value & range
    changeEnrollmentDate(startEnd, id, dt);

    if (moment(dt).isValid() || dt === null) {
      validateInput();
    }
  };

  const renderProgramMembers = () => {
    programMembers.sort((a: ProgramMember, b: ProgramMember) => {
      if (a.dependentNumber === 'TBD' && b.dependentNumber !== 'TBD') return 1;
      if (a.dependentNumber !== 'TBD' && b.dependentNumber === 'TBD') return -1;

      return parseInt(a.dependentNumber) < parseInt(b.dependentNumber) ? -1 : 1;
    });

    return (
      <>
        <Typography variant="subtitle2">
          <strong>Update enrollment start and end dates for each member.</strong>
        </Typography>

        <Spacer size={10} />

        <DialogErrors errors={errors} />

        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell style={{ fontWeight: 900, padding: '8px' }}>Member</TableCell>
              <TableCell style={{ fontWeight: 900, padding: '8px' }} width="210px">
                Effective From
              </TableCell>
              <TableCell style={{ fontWeight: 900, padding: '8px' }} width="210px">
                To
              </TableCell>
            </TableRow>
          </TableHead>
        </Table>

        <Spacer size={10} />

        <Box style={{ flexGrow: 1, overflow: 'auto', maxHeight: '480px' }}>
          <Table size="small">
            <TableBody>
              {programMembers.map(m => (
                <TableRow key={m.id}>
                  <TableCell style={{ border: 0, padding: '8px' }}>
                    <MemberCard programMember={m} showRetired={true} />
                  </TableCell>
                  <TableCell style={{ border: 0, padding: '8px' }} width="210px">
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        autoOk
                        disableToolbar
                        variant="inline"
                        inputVariant="outlined"
                        minDate={moment(minEnrollmentDate)}
                        maxDate={moment(maxEnrollmentDate)}
                        invalidDateMessage=""
                        minDateMessage=""
                        maxDateMessage=""
                        format="MM/dd/yyyy"
                        value={m.enrollment.startDate}
                        onChange={date => handleDateChange('start', m.id, date)}
                        onBlur={validateInput}
                      />
                    </MuiPickersUtilsProvider>
                  </TableCell>
                  <TableCell style={{ border: 0, padding: '8px' }} width="210px">
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        autoOk
                        disableToolbar
                        variant="inline"
                        inputVariant="outlined"
                        invalidDateMessage=""
                        minDateMessage=""
                        maxDateMessage=""
                        format="MM/dd/yyyy"
                        value={m.enrollment.endDate}
                        onChange={date => handleDateChange('end', m.id, date)}
                        onBlur={validateInput}
                      />
                    </MuiPickersUtilsProvider>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </>
    );
  };

  return <>{renderProgramMembers()}</>;
};

export default Enrollments;
