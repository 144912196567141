import { Card, CardContent, Grid, Typography } from '@material-ui/core';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { MedicalBillReimbursementStatus } from '../medical-bill-reimbursement.constants';
import MedicalBillReimbursementStatusSelect from './MedicalBillReimbursementStatusSelect';
import SalesforceCaseLaunchButton from './SalesforceCaseLaunchButton';

interface MedicalBillReimbursementHeaderProps {
  submissionDate: string;
  status: MedicalBillReimbursementStatus;
  referenceNumber: string | undefined;
  adminCaseId: string | undefined;
  accountName: string;
  membershipAccount: string;
  submitterName: string;
  isEditMode: boolean;
  onStatusChange: (status: MedicalBillReimbursementStatus) => void;
}

const MedicalBillReimbursementHeader = ({
  submissionDate,
  status,
  referenceNumber,
  adminCaseId,
  accountName,
  membershipAccount,
  submitterName,
  isEditMode,
  onStatusChange,
}: MedicalBillReimbursementHeaderProps) => {
  return (
    <>
      <Card>
        <CardContent>
          {/* Row 1 */}
          <Grid container spacing={2}>
            <Grid item lg={4} xs={6}>
              <Typography variant="h6">Requested</Typography>
              <Typography variant="body1">
                {moment(submissionDate).format('MMMM D, YYYY')}
              </Typography>
            </Grid>

            <Grid item lg={4} xs={6}>
              <Typography variant="h6">Membership Account</Typography>
              <Typography variant="body1">
                <Link to={`/membership/accounts/${membershipAccount}`}>{accountName}</Link>
              </Typography>
            </Grid>

            <Grid item lg={4} xs={6}>
              <Typography variant="h6">Status</Typography>
              <MedicalBillReimbursementStatusSelect
                status={status}
                onChange={onStatusChange}
                disabled={!isEditMode}
              />
            </Grid>
          </Grid>

          {/* Row 2 */}
          <Grid container spacing={2}>
            <Grid item lg={4} xs={6}>
              <Typography variant="h6">Reference #</Typography>
              <Typography variant="body1">
                {referenceNumber ? (
                  <>
                    {referenceNumber}
                    <SalesforceCaseLaunchButton adminCaseId={adminCaseId} />
                  </>
                ) : (
                  'N/A'
                )}
              </Typography>
            </Grid>

            <Grid item lg={4} xs={6}>
              <Typography variant="h6">Submitted By</Typography>
              <Typography variant="body1">{submitterName}</Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};

export default MedicalBillReimbursementHeader;
