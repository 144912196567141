import { AddAppSetting, AppSetting } from '../../models/appSettings';
import { APIClientBase } from './apiClientBase';
import apiClient from './client';

class AppSettings extends APIClientBase {
  private static _instance: AppSettings;

  static getInstance(): AppSettings {
    if (AppSettings._instance === undefined) {
      AppSettings._instance = new AppSettings();
    }
    return AppSettings._instance;
  }

  getAppSettings = async (): Promise<AppSetting[]> => {
    try {
      const url = '/membership/appSettings';
      const response = await apiClient.get(url);
      const data = this.response(response);
      return data;
    } catch (err: any) {
      const error = this.error(err);
      throw error;
    }
  };

  createAppSettings = async (body: AddAppSetting): Promise<void> => {
    try {
      const url = `/membership/appSettings`;
      await apiClient.post(url, body);
    } catch (err: any) {
      const error = this.error(err);
      throw error;
    }
  };

  updateAppSettings = async (uid: string, body: Partial<AppSetting>): Promise<void> => {
    try {
      const url = `/membership/appSettings/${uid}`;
      await apiClient.patch(url, body);
    } catch (err: any) {
      const error = this.error(err);
      throw error;
    }
  };

  deleteAppSettings = async (uid: string): Promise<void> => {
    try {
      const url = `/membership/appSettings/${uid}`;
      await apiClient.delete(url);
    } catch (err: any) {
      const error = this.error(err);
      throw error;
    }
  };
}

export default AppSettings.getInstance();
