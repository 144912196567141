import apiClient from './client';
import { APIClientBase } from './apiClientBase';
import WorkflowInstance from '../../models/workflowInstance';

export interface StartDeathWorkflowPayload {
  membershipAccountUId: string;
  transitioningMembers: string[];
  deceasedMembers: string[];
  remainingMembers: string[];
}

class Workflows extends APIClientBase {
  private static _instance: Workflows;

  static getInstance(): Workflows {
    if (Workflows._instance === undefined) {
      Workflows._instance = new Workflows();
    }
    return Workflows._instance;
  }

  startDeathWorkflow = async (payload: StartDeathWorkflowPayload): Promise<WorkflowInstance> => {
    try {
      const url = `/membership/workflows/death`;
      const response = await apiClient.post(url, payload);
      return response.data;
    } catch (err) {
      throw this.error(err);
    }
  };
}

export default Workflows.getInstance();
