import React, { useMemo, useEffect, useState } from 'react';
import {
  FormControl,
  FormLabel,
  InputLabel,
  Select,
  MenuItem,
  Button,
  RadioGroup,
  Radio,
  FormControlLabel,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns'; // choose your lib
import moment from 'moment';
import { ParsableDate } from '@material-ui/pickers/constants/prop-types';

import apiClient from '../../../../../lib/api';
import { showToastMessage } from '../../../../../store/slices/toastMessage';
import MembershipAccount from '../../../../../models/membershipAccount';
import { ProgramLevel } from '../../../../../models/programLevel';
import {
  isDateWithRange,
  isEligibleForSeniorProgram,
  MAX_CCM_PROGRAM_DATE,
  MIN_CCM_PROGRAM_DATE,
} from '../../../../../lib/util';
import ButtonProgressIndicator from '../../../../ButtonProgressIndicator';
import { isSeniorProgram } from '../../../../../models/memberProgram';

const useStyles = makeStyles(theme => ({
  programSelect: {
    marginTop: '25px',
  },
  fieldWrapper: {
    marginTop: '25px',
  },
  modalButtonWrapper: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: '50px',
    justifyContent: 'flex-end',
  },
  modalButtons: {
    display: 'flex',
    width: '50%',
    '& button:first-child': {
      marginRight: 16,
    },
  },
}));

interface ProgramCreationFormProps {
  programLevels: ProgramLevel[];
  membershipAccount: MembershipAccount;
  onClose: () => void;
}

interface CreateMemberProgramForm {
  programLevelId: number | string;
  programOptionId: number | string;
  startDate: ParsableDate;
}

const ProgramCreationForm: React.FC<ProgramCreationFormProps> = ({
  programLevels,
  membershipAccount,
  onClose,
}) => {
  // dispatch to call redux
  const dispatch = useDispatch();

  // queryClient to access react-query
  const queryClient = useQueryClient();

  // styles
  const styles = useStyles();

  let defaultStartDate = moment().format('YYYY-MM-01');
  const [displayedProgramLevels, setDisplayedProgramLevels] =
    useState<ProgramLevel[]>(programLevels);
  const [startDate, setStartDate] = useState<string>(defaultStartDate);

  // Form
  const createMemberProgramForm = useFormik<CreateMemberProgramForm>({
    initialValues: {
      programLevelId: '',
      programOptionId: '',
      startDate: moment().startOf('month'),
    },
    onSubmit: async values => {
      createMemberProgram.mutate(
        {
          membershipAccountUid: membershipAccount.uid,
          programLevelId: values.programLevelId as number,
          programOptionId: values.programOptionId as number,
          startDate: moment(values.startDate).format('YYYY-MM-DD'),
        },
        {
          onSuccess: () => {
            onClose();
            // get newest data from the membership account
            queryClient.invalidateQueries(['membershipAccount', membershipAccount.uid]);
            dispatch(
              showToastMessage({
                message: 'The program was created',
                type: 'success',
              }),
            );
          },
          onError: () => {
            onClose();
            dispatch(
              showToastMessage({
                message: 'Member program could not be created',
                type: 'error',
              }),
            );
          },
        },
      );
    },
  });

  // api call to create member program
  const createMemberProgram = useMutation(
    ({
      membershipAccountUid,
      programLevelId,
      programOptionId,
      startDate,
    }: {
      membershipAccountUid: string;
      programLevelId: number;
      programOptionId: number;
      startDate: string;
    }) =>
      apiClient.membershipPrograms.createMemberProgram(
        membershipAccountUid,
        programLevelId,
        [programOptionId],
        startDate,
      ),
  );

  // handles the selection of the program options
  const handleSelectOption = (e: React.ChangeEvent<HTMLInputElement>) => {
    const optionId = parseInt(e.target.value, 10);
    createMemberProgramForm.setFieldValue('programOptionId', optionId);
  };

  // handles the selection of the program level
  const handleSelectProgramLevel = (e: any) => {
    const levelId = parseInt(e.target.value);
    createMemberProgramForm.setFieldValue('programLevelId', levelId);
    createMemberProgramForm.setFieldValue('programOptionId', '');

    // auto select if there is only one option
    const programLevel = programLevels.find(pl => pl.id === levelId);
    if (programLevel && programLevel.options) {
      if (programLevel.options.length === 1) {
        createMemberProgramForm.setFieldValue('programOptionId', programLevel.options[0].id);
      } else if (programLevel.options.length > 1) {
        // if there is the APLL option, select that by default
        const applOption = programLevel.options.find(o => o.slug === 'APLL');
        if (applOption) {
          createMemberProgramForm.setFieldValue('programOptionId', applOption.id);
        }
      }
    }
  };

  const isButtonDisabled = () => {
    return (
      !createMemberProgramForm.values.programLevelId ||
      (programLevelOptions.length > 0 && !createMemberProgramForm.values.programOptionId) ||
      createMemberProgram.isLoading ||
      !isDateWithRange(
        createMemberProgramForm.values.startDate as Date,
        MIN_CCM_PROGRAM_DATE,
        MAX_CCM_PROGRAM_DATE,
      )
    );
  };

  const handleStartDateChange = (startDate: Date | null) => {
    if (!moment(startDate).isValid()) {
      return;
    }
    createMemberProgramForm.setFieldValue('startDate', startDate);
    setStartDate(moment(startDate).format('YYYY-MM-DD'));
  };

  // get the options of the program level selected
  const programLevelOptions = useMemo(() => {
    const programLevelIdSelected = createMemberProgramForm.values.programLevelId;
    if (programLevelIdSelected) {
      const programLevelSelected = programLevels.find(pl => pl.id === programLevelIdSelected);
      return programLevelSelected && programLevelSelected.options
        ? programLevelSelected.options
        : [];
    }
    return [];
  }, [programLevels, createMemberProgramForm.values.programLevelId]);

  // set the list of programLevels if membership account is not eligible for senior program
  useEffect(() => {
    if (!isEligibleForSeniorProgram(membershipAccount, startDate)) {
      const displayedProgramLevels = programLevels.filter(
        programLevel => !isSeniorProgram(programLevel.name) && programLevel,
      );
      setDisplayedProgramLevels(displayedProgramLevels);
    }
    else
    {
      setDisplayedProgramLevels(programLevels);
    }
  }, [membershipAccount, programLevels, startDate]);

  return (
    <form>
      <FormControl variant="outlined" fullWidth className={styles.programSelect}>
        <InputLabel id="programLevelId-label">Program</InputLabel>
        <Select
          id="programLevelId"
          name="programLevelId"
          label="Program"
          labelId="programLevelId-label"
          value={createMemberProgramForm.values.programLevelId}
          onChange={handleSelectProgramLevel}
        >
          {displayedProgramLevels.map(pl => (
            <MenuItem value={pl.id} key={pl.name}>
              {pl.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {Boolean(programLevelOptions.length) && (
        <FormControl component="fieldset" fullWidth className={styles.fieldWrapper}>
          <FormLabel component="legend">Program Options</FormLabel>
          <RadioGroup
            aria-label="programOption"
            name="programOptionId"
            value={createMemberProgramForm.values.programOptionId}
            onChange={handleSelectOption}
          >
            {programLevelOptions.map(plo => (
              <FormControlLabel
                key={plo.id}
                value={plo.id}
                control={<Radio color="primary" />}
                label={plo.description}
              />
            ))}
          </RadioGroup>
        </FormControl>
      )}
      <FormControl component="fieldset" className={styles.fieldWrapper}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            fullWidth
            id="startDate"
            name="startDate"
            label="Start Date"
            format="MM/dd/yyyy"
            value={createMemberProgramForm.values.startDate}
            onChange={handleStartDateChange}
            minDate={moment(MIN_CCM_PROGRAM_DATE)}
            maxDate={moment(MAX_CCM_PROGRAM_DATE)}
            minDateMessage={`The start date cannot be before ${moment(MIN_CCM_PROGRAM_DATE).format(
              'LL',
            )}`}
            maxDateMessage={`The start date cannot be after ${moment(MAX_CCM_PROGRAM_DATE).format(
              'LL',
            )}`}
            inputVariant="outlined"
            KeyboardButtonProps={{
              'aria-label': 'Add start date',
            }}
          />
        </MuiPickersUtilsProvider>
      </FormControl>
      <div className={styles.modalButtonWrapper}>
        <div className={styles.modalButtons}>
          <Button
            color="primary"
            variant="contained"
            size="large"
            onClick={createMemberProgramForm.submitForm}
            fullWidth
            disabled={isButtonDisabled()}
          >
            {createMemberProgram.isLoading ? <ButtonProgressIndicator /> : 'Add'}
          </Button>

          <Button
            color="default"
            variant="contained"
            size="large"
            onClick={onClose}
            fullWidth
            disabled={createMemberProgram.isLoading}
          >
            Cancel
          </Button>
        </div>
      </div>
    </form>
  );
};

export default ProgramCreationForm;
