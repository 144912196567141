import DateFnsUtils from '@date-io/date-fns';
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Alert, Autocomplete } from '@material-ui/lab';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { useFormik } from 'formik';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';
import ButtonProgressIndicator from '../../../components/ButtonProgressIndicator';
import Dialog from '../../../components/Dialog';
import printRequestJobTypes from '../../../lib/printRequests';
import MemberProgram, {
  MemberProgramStatus,
  isMediShareValue,
} from '../../../models/memberProgram';
import MembershipAccount from '../../../models/membershipAccount';
import { showToastMessage } from '../../../store/slices/toastMessage';
import printRequestsApi from '../api/print-requests.api.adapter';
import { PrintRequestStatus } from '../print-request.constants';
import { PrintRequest } from '../types/print-request';

const useStyles = makeStyles(theme => ({
  printRequestForm: {
    width: 860,
  },
  formExtWrapper: {
    marginTop: theme.spacing(1),
  },
  errorsContainer: {
    paddingTop: 0,
  },
  formControl: {
    width: '100%',
  },
}));

const printRequestQuantities = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
const printRequestFormSchema = yup.object({
  jobType: yup.string().trim().min(1).required('Job Type is Required'),
});

const showFormErrors = (printRequestForm: any) => {
  const errors = [];
  if (printRequestForm.touched.jobType && Boolean(printRequestForm.errors.jobType)) {
    errors.push(<div key="jobType">{printRequestForm.errors.jobType}</div>);
  }
  if (printRequestForm.touched.quantity && Boolean(printRequestForm.errors.quantity)) {
    errors.push(<div key="quantity">{printRequestForm.errors.quantity}</div>);
  }
  return errors;
};

interface PrintRequestProps {
  printRequests: PrintRequest[];
  membershipAccount: MembershipAccount | undefined;
  onDialogClose: Function;
  open: boolean;
}

interface PrintRequestModalProps {
  programs: MemberProgram[] | undefined;
  printRequests: PrintRequest[];
  membershipAccount: MembershipAccount | undefined;
  onClose: () => void;
  onSubmit: any;
  open: boolean;
}

interface PrintRequestForm {
  memberProgramUId: string;
  membershipAccountUId: string;
  jobType: string;
  quantity: number;
  sendAt: string;
}

const PrintRequestModal = (props: PrintRequestModalProps) => {
  const { programs, printRequests, membershipAccount, onClose, onSubmit, open } = props;
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const styles = useStyles();
  const [inputValue, setInputValue] = useState('');
  const [selectedProgram, setSelectedProgram] = useState<MemberProgram | undefined>(undefined);
  const [selectedJobTypeName, setSelectedJobTypeName] = useState('');
  const [selectedJobTypeValue, setSelectedJobTypeValue] = useState('');
  const [pendingPrintRequestExist, setPendingPrintRequestExist] = useState(false);

  useEffect(() => {
    let doesPendingPrintRequestExist = false;

    const memberProgramUId = selectedProgram?.uid;
    const jobType = selectedJobTypeValue;

    if (printRequests.length > 0 && memberProgramUId && jobType) {
      const pendingPrintRequest = printRequests.find(
        (printRequest: PrintRequest) =>
          printRequest.status === PrintRequestStatus.PENDING &&
          printRequest.memberProgramUId === memberProgramUId &&
          printRequest.jobType === jobType,
      );

      if (pendingPrintRequest) {
        doesPendingPrintRequestExist = true;
      }
    }

    setPendingPrintRequestExist(doesPendingPrintRequestExist);
  }, [printRequests, selectedProgram, selectedJobTypeValue]);

  const handleChange = (memberProgram: string) => {
    setInputValue(memberProgram);
  };

  const handleProgramChange = (_: any, value: any) => {
    setSelectedProgram(value!);
  };

  const handleJobTypeChange = (event: any, value: any) => {
    let jobTypeName = '';
    let jobTypeValue = '';

    if (typeof value.props.value === 'string') {
      printRequestJobTypes.forEach(option => {
        if (option.value === value.props.value) {
          jobTypeName = option.name;
          jobTypeValue = option.value;
        }
      });
    }

    setSelectedJobTypeName(jobTypeName);
    setSelectedJobTypeValue(jobTypeValue);

    printRequestForm.handleChange(event);
    // printRequestForm.handleChange(value);
  };

  const printRequest = useMutation(
    (values: PrintRequestForm) =>
      printRequestsApi.create(
        values.memberProgramUId,
        values.jobType,
        values.quantity,
        values.sendAt,
      ),
    {
      onSuccess: () => {
        // get newest data from the membership account
        queryClient.invalidateQueries([
          'membershipAccount',
          membershipAccount ? membershipAccount.uid : '',
        ]);
        queryClient.invalidateQueries(['printRequests']);
        dispatch(
          showToastMessage({
            message: 'The print request has been created',
            type: 'success',
          }),
        );
        handleModalClose();
      },
      onError: () => {
        dispatch(
          showToastMessage({
            message: `The print request could not be created`,
            type: 'error',
          }),
        );
        handleModalClose();
      },
    },
  );

  const handleModalClose = () => {
    onClose();
  };

  const handleSubmit = () => {
    if (selectedProgram)
      printRequest.mutate({
        membershipAccountUId: membershipAccount ? membershipAccount.uid : '',
        memberProgramUId: selectedProgram.uid,
        jobType: printRequestForm.values.jobType,
        quantity: +printRequestForm.values.quantity,
        sendAt: printRequestForm.values.sendAt,
      });
  };

  const renderButtons = () => {
    const cancelButton = (
      <Box component="span" display="flex" key="cancel" justifyContent="space-around">
        <Button color="default" size="large" key="cancel" onClick={handleModalClose} fullWidth>
          Cancel
        </Button>
      </Box>
    );
    const submitButton = (
      <Box component="span" display="flex" key="submit" justifyContent="space-around">
        <Button
          color="primary"
          variant="contained"
          size="large"
          onClick={printRequestForm.submitForm}
          key="add"
          fullWidth
          disabled={!selectedProgram || printRequest.isLoading}
        >
          {printRequest.isLoading ? <ButtonProgressIndicator /> : 'SUBMIT'}
        </Button>
      </Box>
    );
    return [cancelButton, submitButton];
  };

  const printRequestForm = useFormik({
    initialValues: {
      jobType: '',
      quantity: 1,
      sendAt: moment().format('YYYY-MM-DD'),
    },
    validationSchema: printRequestFormSchema,
    onSubmit: async values => {
      handleSubmit();
    },
  });

  const handleDateChange = (date: Date | null) => {
    printRequestForm.setFieldValue('sendAt', date);
  };

  const form = () => {
    return (
      <>
        <Typography variant="body2">
          Choose a member program, a job type, a quantity and click submit.
        </Typography>
        <Grid container spacing={5} className={styles.formExtWrapper}>
          <Grid item xs={12}>
            <FormControl variant="outlined" fullWidth>
              <Autocomplete
                id="memberProgramUId"
                options={
                  programs?.filter(
                    (mp: MemberProgram) =>
                      (mp.status === MemberProgramStatus.Active ||
                        mp.status === MemberProgramStatus.Pending) &&
                      !isMediShareValue(mp.programLevel.name),
                  ) || []
                }
                getOptionLabel={option =>
                  `${option.programLevel.name} - Started on ${moment(option.started).format(
                    'MMMM D, YYYY',
                  )}`
                }
                renderInput={params => (
                  <TextField
                    {...params}
                    label={<Typography variant="h6">Member Program</Typography>}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>{params.InputProps.endAdornment}</React.Fragment>
                      ),
                    }}
                  />
                )}
                filterOptions={options => options}
                getOptionSelected={(option, value) => option.uid === value.uid}
                onChange={handleProgramChange}
                onInputChange={(_, value) => handleChange(value)}
                inputValue={inputValue}
              />
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl variant="outlined" className={styles.formControl}>
              <InputLabel id="jobType-label">Job Type</InputLabel>
              <Select
                id="jobType"
                name="jobType"
                label="jobType"
                labelId="jobType-label"
                value={printRequestForm.values.jobType}
                onChange={handleJobTypeChange}
                error={printRequestForm.touched.jobType && Boolean(printRequestForm.errors.jobType)}
              >
                {printRequestJobTypes.map((prjt, index) => {
                  return (
                    <MenuItem value={prjt.value} key={index}>
                      {prjt.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl variant="outlined" className={styles.formControl}>
              <InputLabel id="quantity-label">Quantity</InputLabel>
              <Select
                id="quantity"
                name="quantity"
                label="quantity"
                value={printRequestForm.values.quantity}
                onChange={printRequestForm.handleChange}
                error={
                  printRequestForm.touched.quantity && Boolean(printRequestForm.errors.quantity)
                }
              >
                {printRequestQuantities.map(val => (
                  <MenuItem key={val} value={val}>
                    {val}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                disableToolbar
                value={moment(printRequestForm.values.sendAt)}
                inputVariant="outlined"
                onChange={handleDateChange}
                format="MM/dd/yyyy"
                label="Send At"
                disabled={true}
                className={styles.formControl}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={12} className={styles.errorsContainer}>
            <FormHelperText error component="div">
              {showFormErrors(printRequestForm)}
            </FormHelperText>
          </Grid>
          <Grid item xs={12}>
            {pendingPrintRequestExist && (
              <Alert severity="warning">
                There is a pending {selectedJobTypeName} print request for this member program and
                it will be cancelled
              </Alert>
            )}
          </Grid>
        </Grid>
      </>
    );
  };

  return (
    <Dialog title="Create a Print Request" open={open} buttons={renderButtons()}>
      <form onSubmit={onSubmit} className={styles.printRequestForm}>
        {form()}
      </form>
    </Dialog>
  );
};

const CreatePrintRequest: React.FC<PrintRequestProps> = ({
  printRequests,
  membershipAccount,
  open,
  onDialogClose,
}) => {
  const [printRequestModalOpen, setPrintRequestModalOpen] = useState(false);

  const onClose = () => {
    setPrintRequestModalOpen(false);
    onDialogClose();
  };

  useEffect(() => {
    setPrintRequestModalOpen(open);
  }, [open]);

  return (
    <PrintRequestModal
      programs={membershipAccount ? membershipAccount.memberPrograms : []}
      printRequests={printRequests}
      membershipAccount={membershipAccount}
      onClose={onClose}
      onSubmit={onClose}
      open={printRequestModalOpen}
    />
  );
};

export default CreatePrintRequest;
