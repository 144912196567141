import { Dispatch } from 'react';
import { useMutation, useQuery, QueryClient } from 'react-query';
import { showToastMessage } from '../../../store/slices/toastMessage';
import { ProviderNetworksApiPort } from '../api/provider-networks.api.port';
import { ProviderNetworkAssignment } from '../types/provider-network-assignment';

/**
 * @param providerNetworkApi the api adapter implementation to use
 * @returns the hooks
 */
export const hookFactory = (
  providerNetworkApi: ProviderNetworksApiPort,
  queryClient: QueryClient,
  dispatch: Dispatch<any>,
) => {
  return {
    useProviderNetworkAssignmentsQuery: (variables: { uid: string }) => {
      return useQuery<ProviderNetworkAssignment[], [string, string]>(
        ['providerNetworkAssignmentsRequest', variables.uid],
        async () => {
          return await providerNetworkApi.get(variables.uid);
        },
      );
    },
    useAssignProviderNetworkMutation: (variables: { uid: string }) => {
      return useMutation(
        async () => {
          await providerNetworkApi.assign(variables.uid);
        },
        {
          onSuccess: () => {
            // Invalidate the query to trigger a reload of the get method
            queryClient.invalidateQueries('providerNetworkAssignmentsRequest');
          },
          onError: (error: any) => {
            dispatch(
              showToastMessage({
                message: `Failed to assign a Provider Network${
                  error.message ? `. ${error.message}` : ''
                }`,
                type: 'error',
              }),
            );
          },
        },
      );
    },
  };
};
