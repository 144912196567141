import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  makeStyles,
  Theme,
  Box,
  Typography,
  Card,
  CardContent,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import CancelIcon from '@material-ui/icons/Cancel';
import { useFormik } from 'formik';

import StatusBadge from '../StatusBadge';
import User from '../../models/user';
import { verifyUser } from '../../store/slices/user';
import { RootState } from '../../store';

const useStyles = makeStyles((theme: Theme) => ({
  buttonContainer: {
    marginTop: '16px',
  },
  activeDirectoryContainer: {
    display: 'flex',
    marginTop: 10,
    alignItems: 'center',
    '& > span': {
      marginLeft: 5,
    },
  },
  authUserCardContent: {
    backgroundColor: theme.backgroundColors.body,
  },
}));

const renderAuthUser = (authUser: User | null, styles?: any) => {
  if (authUser && authUser.authId) {
    let message = '';
    if (!authUser.isActiveDirectoryUser) {
      message =
        'This user is not an Active Directory user. Only Active Directory users can be registered.';
    } else if (authUser.status !== 'active') {
      message = 'This user is inactive and cannot be registered';
    }
    return (
      <Card>
        <CardContent className={styles.authUserCardContent}>
          <Typography variant="h6">User Found</Typography>
          <Typography variant="h5">
            {authUser.firstName} {authUser.lastName}
            <StatusBadge
              marginLeft={2}
              variant={authUser.status === 'active' ? 'success' : 'default'}
              label={authUser.status}
            />
          </Typography>
          <Box>{authUser.email}</Box>
          <Box>
            {authUser.isActiveDirectoryUser ? (
              <div className={styles.activeDirectoryContainer}>
                <VerifiedUserIcon className="text-success" />
                <span className="text-success">Active Directory User</span>
              </div>
            ) : (
              <div className={styles.activeDirectoryContainer}>
                <CancelIcon className="text-danger" />
                <span className="text-danger">Not in Active Directory</span>
              </div>
            )}
          </Box>
          {message.length !== 0 && (
            <Box marginTop={2}>
              <span className="text-warn">
                <em>{message}</em>
              </span>
            </Box>
          )}
        </CardContent>
      </Card>
    );
  }

  return null;
};

const userFormSchema = yup.object({
  email: yup.string().email('Invalid email address').required('Email is required'),
});

const UserForm = (props: any) => {
  const { disabled, onSubmit, onCancel } = props;
  const styles = useStyles();
  const dispatch = useDispatch();
  const [activateDisabled, setActivateDisabled] = useState(true);
  const { user } = useSelector((state: RootState) => state.user);

  const activateUser = () => {
    onSubmit(user);
  };

  const userForm = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: userFormSchema,
    onSubmit: values => {
      dispatch(verifyUser(values.email));
    },
  });

  useEffect(() => {
    setActivateDisabled(
      disabled || !user?.authId || !user?.isActiveDirectoryUser || user?.status !== 'active',
    );
  }, [disabled, user]);

  return (
    <form noValidate onSubmit={userForm.handleSubmit}>
      <FormControl variant="outlined" fullWidth margin="normal">
        <InputLabel htmlFor="email">Email</InputLabel>
        <OutlinedInput
          id="email"
          name="email"
          type="email"
          fullWidth
          value={userForm.values.email}
          onChange={userForm.handleChange}
          error={userForm.touched.email && Boolean(userForm.errors.email)}
          endAdornment={
            <InputAdornment position="end">
              <IconButton onClick={userForm.submitForm} edge="end">
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          }
          labelWidth={70}
        />
        <FormHelperText id="email" className="text-danger">
          {userForm.touched.email && userForm.errors.email}
        </FormHelperText>
      </FormControl>

      {/* If a user is found it will display here */}
      {renderAuthUser(user, styles)}

      <Grid container spacing={1} direction="row" className={styles.buttonContainer}>
        <Grid item xs={6}>
          <Button
            color="primary"
            variant="contained"
            size="medium"
            disabled={activateDisabled}
            onClick={activateUser}
            fullWidth
          >
            Activate
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            color="default"
            variant="contained"
            size="medium"
            disabled={disabled}
            onClick={onCancel}
            fullWidth
          >
            Cancel
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default UserForm;
