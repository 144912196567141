import { Theme, createStyles, makeStyles } from '@material-ui/core';
import { FC } from 'react';
import MembershipAccount from '../../../models/membershipAccount';
import { PrintRequest } from '../types/print-request';
import PrintRequestFooter from './PrintRequestFooter';
import PrintRequestHeader from './PrintRequestHeader';
import PrintRequestEmpty from './print-request-state/PrintRequestEmpty';
import PrintRequestError from './print-request-state/PrintRequestError';
import PrintRequestLoading from './print-request-state/PrintRequestLoading';
import PrintRequestTable from './print-request-table/PrintRequestTable';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    printRequest: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(2),
    },
  }),
);

interface PrintRequestProps {
  printRequests: PrintRequest[];
  membershipAccount: MembershipAccount | undefined;
  page: number;
  pageSize: number;
  isLoading: boolean;
  error: string | null;
  onCancelRequest: (printRequestUId: string) => void;
  handlePageChange: (_: any, pageIndex: number) => void;
}

const PrintRequestTemplate: FC<PrintRequestProps> = ({
  printRequests,
  membershipAccount,
  page,
  pageSize,
  isLoading,
  error,
  onCancelRequest,
  handlePageChange,
}) => {
  const styles = useStyles();

  if (isLoading) {
    return <PrintRequestLoading />;
  }

  const renderTemplate = () => {
    if (error) {
      return <PrintRequestError text={error} />;
    }

    if (printRequests.length === 0) {
      return <PrintRequestEmpty />;
    }

    return (
      <>
        <PrintRequestTable printRequests={printRequests} onCancelRequest={onCancelRequest} />
        <PrintRequestFooter
          page={page}
          pageSize={pageSize}
          printRequestLength={printRequests.length}
          handlePageChange={handlePageChange}
        />
      </>
    );
  };

  return (
    <div className={styles.printRequest}>
      <PrintRequestHeader printRequests={printRequests} membershipAccount={membershipAccount} />
      {renderTemplate()}
    </div>
  );
};

export default PrintRequestTemplate;
