import React from 'react';
import Member from '../../../models/member';
import { Box, createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import MemberCard from "./MemberCard";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		membersContainer: {
			display: 'flex',
			gap: '10px',
			flexWrap: 'wrap',
			'& .MuiCardContent-root': {
				minWidth: '300px'
			}
		},
		title: {
			fontSize: '18px',
			letterSpacing: .15,
			margin: 0,
			fontWeight: 400,
			lineHeight: '21px',
			textTransform: 'none'
		},
		header: {
			display: 'flex',
			justifyContent: 'start',
			marginBottom: '10px',
		}
	}),
);

interface MemberListProps {
	members: Member[],
	title?: string
}

export const MemberList: React.FC<MemberListProps> = ({members, title}) => {
	const styles = useStyles();

	return (
		<>
			{title && <Box className={styles.header}>
          <Typography variant="h6" className={styles.title}>{title}</Typography>
      </Box>
			}
			<Box className={styles.membersContainer}>
				{members.map((member: Member, index: number) => (
					<MemberCard key={index} member={member} selectable={false} summary={true}/>
				))}
			</Box>
		</>
	);

};
