import Spacer from '../../components/Spacer';
import React from 'react';
import Page from '../../components/Page';
import { BusinessLinesTable } from "./BusinessLinesTable";


export const BusinessLines = () => (<Page
  breadcrumbs={[
    {href: '/', label: 'Home'},
    {href: '#', label: 'System Administration'},
    {href: '#', label: 'Program Catalog'},
    {href: '/system-admin/program-catalog/business-lines', label: 'Business Lines'},
  ]}
>
  <Spacer size={15}/><BusinessLinesTable/>
</Page>);