export enum BenefitsManagementTransactionStatus {
  Completed = 'completed',
  Pending = 'pending',
  Processing = 'processing',
  Cancelled = 'cancelled',
}

export enum BenefitsManagementEDIFilesStatus {
  Pending = 'pending',
  Processed = 'processed',
  Sent = 'sent',
  SendFailed = 'send_failed',
  SendDisabled = 'send_disabled',
  Deleted = 'deleted',
}
