import apiClient from './client';
import { APIClientBase } from './apiClientBase';
import BusinessLine from "../../models/businessLine";

export interface createBusinessLinePayload {
  name: string;
  description: string;
}

export interface updateBusinessLinePayload {
  uid: string;
  name: string;
  description: string;
}

class ProgramCatalog extends APIClientBase {
  private static _instance: ProgramCatalog;

  static getInstance(): ProgramCatalog {
    if (ProgramCatalog._instance === undefined) {
      ProgramCatalog._instance = new ProgramCatalog();
    }
    return ProgramCatalog._instance;
  }

  getBusinessLines = async (query: any): Promise<any> => {
    try {
      const queryString = this.toQueryString(query);
      const url = `/membership/program-catalog/business-lines${
        queryString ? `?${queryString}` : ''
      }`;
      const response = await apiClient.get(url);

      return this.response(response);
    } catch (err) {
      const error = this.error(err);
      throw error;
    }
  };

  createBusinessLine = async (payload: createBusinessLinePayload): Promise<BusinessLine> => {
    try {
      const url = `/membership/program-catalog/business-lines`;
      const response = await apiClient.post(url, payload);
      return new BusinessLine(response);
    } catch (err) {
      const error = this.error(err);
      throw error;
    }
  };

  updateBusinessLine = async (payload: updateBusinessLinePayload): Promise<BusinessLine> => {
    try {
      const url = `/membership/program-catalog/business-lines/${payload.uid}`;
      const response = await apiClient.patch(url, payload);
      return new BusinessLine(response);
    } catch (err) {
      const error = this.error(err);
      throw error;
    }
  };

  deleteBusinessLine = async (uid: string) => {
    try {
      const url = `/membership/program-catalog/business-lines/${uid}`;
      await apiClient.delete(url);
    } catch (err) {
      const error = this.error(err);
      throw error;
    }
  };

}

export default ProgramCatalog.getInstance();
