import React from 'react';
import {
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  Paper,
} from '@material-ui/core';

import { AppSetting } from '../../models/appSettings';
import AppSettingsRow from './AppSettingsRow';

interface AppSettingsTableProps {
  appSettings: AppSetting[];
}

export const AppSettingsTable: React.FC<AppSettingsTableProps> = ({ appSettings }) => {
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell width={300}>Setting Name / Setting Value</TableCell>
            <TableCell width={200}></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {appSettings.map(appSetting => (
            <AppSettingsRow appSetting={appSetting} key={appSetting.uid} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default AppSettingsTable;
