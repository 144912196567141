import React from 'react';
import { Box, Card, CardContent, makeStyles, Typography } from '@material-ui/core';

const groupCardStyles = makeStyles({
  titleBox: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.25)',
    marginBottom: '16px'
  },
  title: {
    fontSize: 18,
  },
});

const getStatus = (rawStatus: string) => rawStatus.split("")[0].toUpperCase() + rawStatus.split("").slice(1).join("");

export default function GroupCard(props: any) {
  const classes = groupCardStyles();
  const { group } = props;
  const groupStatus = !!group.status ? getStatus(group.status) : ""; 
  return (
    <Card>
      <CardContent>
        <Box className={classes.titleBox}>
          <Typography className={classes.title}>{group.groupName}</Typography>
        </Box>
        <Typography>Underwriter: {group.underwriter}</Typography>
        <Typography>Underwriter Group: {group.underwriterGroup}</Typography>
        <Typography>Status: {groupStatus}</Typography>
      </CardContent>
    </Card>
  );
};
