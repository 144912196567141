import { Card, CardContent, Grid, Typography } from '@material-ui/core';
import moment from 'moment';

interface MedicalBillReimbursementDetailsProps {
  patientName: string;
  dateOfServiceStart: string;
  dateOfServiceEnd?: string;
  hospitalVisit: boolean;
  conditionsTreated: string;
  servicesPerformed: string;
  totalAmountBilled: number;
}

const MedicalBillReimbursementDetails = ({
  patientName,
  dateOfServiceStart,
  dateOfServiceEnd,
  hospitalVisit,
  conditionsTreated,
  servicesPerformed,
  totalAmountBilled,
}: MedicalBillReimbursementDetailsProps) => {
  return (
    <Card>
      <CardContent>
        {/* Top Row */}
        <Grid container spacing={2}>
          <Grid item lg={4} xs={6}>
            <Typography variant="h6">Patient</Typography>
            <Typography variant="body1">{patientName}</Typography>
          </Grid>

          <Grid item lg={4} xs={6}>
            <Typography variant="h6">Date(s) of Service</Typography>
            <Typography variant="body1">
              {moment(dateOfServiceStart).format('MM/DD/YYYY')}
              {dateOfServiceEnd && ` - ${moment(dateOfServiceEnd).format('MM/DD/YYYY')}`}
            </Typography>
          </Grid>

          <Grid item lg={4} xs={6}>
            <Typography variant="h6">Hospital Visit</Typography>
            <Typography variant="body1">{hospitalVisit ? 'Yes' : 'No'}</Typography>
          </Grid>
        </Grid>

        {/* Bottom row */}
        <Grid container spacing={2}>
          <Grid item lg={4} xs={6}>
            <Typography variant="h6">Conditions Treated</Typography>
            <Typography variant="body1">{conditionsTreated || 'N/A'}</Typography>
          </Grid>

          <Grid item lg={4} xs={6}>
            <Typography variant="h6">Services Performed</Typography>
            <Typography variant="body1">{servicesPerformed}</Typography>
          </Grid>

          <Grid item lg={4} xs={6}>
            <Typography variant="h6">Total Billed</Typography>
            <Typography variant="body1">${Number(totalAmountBilled).toFixed(2)}</Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default MedicalBillReimbursementDetails;
