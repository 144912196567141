import {
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Card,
  CardContent,
  Grid,
} from '@material-ui/core';
import FileIcon from '@material-ui/icons/InsertDriveFile';
import { useMemo } from 'react';
import { extractFileNameFromPath, truncateFileName } from '../../../lib/util';

interface Attachment {
  fileName: string;
}

export interface MedicalBillReimbursementAttachmentsProps {
  files: Attachment[];
  onClick: (fileId: string) => void;
}

const MedicalBillReimbursementAttachments = ({
  files,
  onClick,
}: MedicalBillReimbursementAttachmentsProps) => {
  const parsedFiles = useMemo(() => {
    return files.map(file => {
      const fileName = extractFileNameFromPath(file.fileName);
      const truncatedFileName = truncateFileName(fileName, 30);

      return {
        fileName,
        truncatedFileName,
      };
    });
  }, [files]);

  return (
    <Card>
      <CardContent>
        <Typography variant="h6">Attachments</Typography>
        <List>
          <Grid container spacing={2}>
            {parsedFiles.map(file => {
              return (
                <Grid key={file.fileName} item lg={4} xs={6}>
                  <ListItem
                    button
                    onClick={() => onClick(file.fileName)}
                    aria-label={file.fileName}
                    title={file.fileName}
                  >
                    <ListItemIcon>
                      <FileIcon />
                    </ListItemIcon>
                    <ListItemText primary={file.truncatedFileName} />
                  </ListItem>
                </Grid>
              );
            })}
          </Grid>
        </List>
      </CardContent>
    </Card>
  );
};

export default MedicalBillReimbursementAttachments;
