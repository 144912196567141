import React, { useState } from 'react';
import moment from 'moment';
import {
  createStyles,
  FormControl,
  Grid,
  makeStyles,
  Tab,
  Tabs,
  Theme,
  Typography,
  Box,
} from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { Alert } from '@material-ui/lab';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

import EnrolledProgram from './EnrolledProgram';
import EligibleProgram from '../../../../models/eligibleProgram';
import EligibleProgramView from './EligibleProgram';
import ProgramConfirmationView from './ProgramChangeConfirmation';
import Spacer from '../../../Spacer';
import Member from '../../../../models/member';
import Person from '../../../../models/person';
import MemberProgram from '../../../../models/memberProgram';
import {
  MAX_CCM_PROGRAM_DATE,
  MIN_CCM_PROGRAM_DATE,
  sortEligiblePrograms,
} from '../../../../lib/util';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
      minWidth: 800,
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    tabs: {
      borderRight: `1px solid ${theme.palette.divider}`,
    },
    dialogActions: {
      display: 'flex',
      alignItems: 'flex-end',
      justifyContent: 'flex-end',
    },
    rightMargin: {
      marginRight: '10px',
    },
    programWrapper: {
      height: 460,
      overflowY: 'auto',
    },
    programContainer: {
      padding: 10,
    },
  }),
);

export interface DialogTitleProps {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const renderEligibleProgramSkeleton = (count = 1) => {
  const skeletons = [];
  for (let i = 0; i < count; i++) {
    skeletons.push(
      <div key={i} style={{ display: 'flex', flexDirection: 'row', padding: 20 }}>
        <div style={{ marginRight: 10 }}>
          <Skeleton animation="wave" variant="circle" width={40} height={40} />
        </div>
        <div style={{ flexGrow: 1 }}>
          <Skeleton animation="wave" height={10} width="100%" style={{ marginBottom: 6 }} />
          <Skeleton animation="wave" height={10} width="100%" style={{ marginBottom: 6 }} />
          <Skeleton animation="wave" height={10} width="60%" />
        </div>
      </div>,
    );
  }
  return skeletons.map(s => s);
};

interface ProgramChangeProps {
  memberProgram: MemberProgram;
  members: Member[];
  startDate: string;
  remainingMembers: number;
  currentTab: number;
  eligiblePrograms?: EligibleProgram[];
  isFetching: boolean;
  onTabClick: Function;
  onChangeStartDate: Function;
  onSelectPrograms: Function;
  handleError: Function;
  hasError: boolean;
  isStartDateValid: Function;
}
const ProgramChange = (props: ProgramChangeProps) => {
  const {
    isFetching,
    memberProgram,
    members,
    remainingMembers,
    currentTab,
    onTabClick,
    onChangeStartDate,
    startDate,
    eligiblePrograms,
    onSelectPrograms,
    handleError,
    hasError,
    isStartDateValid,
  } = props;
  const classes = useStyles();
  const [selectedEligiblePrograms, setSelectedEligiblePrograms] = useState<EligibleProgram[]>([]);
  const [tempDate, setTempDate] = useState(startDate);

  const handleTabChange = (event: React.ChangeEvent<{}>, tabIndex: number) => {
    onTabClick(tabIndex);
  };

  const handleProgramLevelSelected = ({
    program,
    programLevel,
    eligibleMembers,
    isSelected,
  }: any) => {
    const selectedEligibleMembers = eligibleMembers.map((member: Member) => member.uid);
    const eligibleProgram: EligibleProgram = {
      name: program.name,
      programLevels: [programLevel],
      eligibleMembers: program.eligibleMembers,
    };

    // if this is a new selection, add it to the collection; if it has been
    // unselected, remove it from the collection
    let updatedSelectedEligiblePrograms = selectedEligiblePrograms.slice(0);
    if (isSelected) {
      const existingProgram = updatedSelectedEligiblePrograms.find(program => {
        return (
          selectedEligibleMembers.every((m: string) => program.eligibleMembers.includes(m)) &&
          program.eligibleMembers.every((m: string) => selectedEligibleMembers.includes(m)) &&
          program.programLevels[0].id === programLevel.id
        );
      });

      // if the program exists, update it
      if (existingProgram) {
        existingProgram.name = eligibleProgram.name;
        existingProgram.programLevels = eligibleProgram.programLevels;
        existingProgram.eligibleMembers = eligibleProgram.eligibleMembers;
      } else {
        updatedSelectedEligiblePrograms.push(eligibleProgram);
      }
    } else {
      updatedSelectedEligiblePrograms = updatedSelectedEligiblePrograms.filter(program => {
        return !(
          selectedEligibleMembers.every((m: string) => program.eligibleMembers.includes(m)) &&
          program.eligibleMembers.every((m: string) => selectedEligibleMembers.includes(m)) &&
          program.programLevels[0].id === programLevel.id
        );
      });
    }

    setSelectedEligiblePrograms(updatedSelectedEligiblePrograms);
    onSelectPrograms(updatedSelectedEligiblePrograms);
  };

  const eligibleProgramCount = () => {
    let programCount = 0;
    if (!eligiblePrograms || eligiblePrograms.length === 0) {
      return 0;
    }

    for (let i = 0; i < eligiblePrograms.length; i++) {
      programCount += eligiblePrograms[i].programLevels
        ? eligiblePrograms[i].programLevels.length
        : 0;
    }

    return programCount;
  };

  const seniorAssistEligible = () => {
    const saEarlyEligibleDOB = moment().subtract(65, 'years').add(3, 'months');
    return (members as Member[]).some((member: Member) => {
      return moment((member.person as Person).dob).isSameOrBefore(saEarlyEligibleDOB);
    });
  };

  const handleChangeStartDate = (newStartDate: Date | null) => {
    setTempDate(moment(newStartDate).format('YYYY-MM-DD'));
    handleError(!isStartDateValid(newStartDate));
    if (
      isStartDateValid(newStartDate) &&
      moment(newStartDate).format('YYYY-MM-DD') !== moment(startDate).format('YYYY-MM-DD')
    ) {
      setSelectedEligiblePrograms([]);
      onSelectPrograms([]);
      onChangeStartDate(moment(newStartDate).format('YYYY-MM-DD')); // expected format YYYY-MM-DD
    }
  };

  return (
    <div
      style={{
        flexGrow: 1,
        display: 'flex',
        height: seniorAssistEligible() ? 620 : 560,
        width: 1100,
      }}
    >
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={currentTab}
        onChange={handleTabChange}
        aria-label="Switch Program tabs"
        className={classes.tabs}
        indicatorColor="primary"
      >
        <Tab label="Choose Programs" id="vertical-tab-0" />
        <Tab label="Confirmation" id="vertical-tab-1" disabled={remainingMembers !== 0} />
      </Tabs>

      {/* Membership programs tab */}
      <div
        role="tabpanel"
        hidden={currentTab !== 0}
        id="vertical-tabpanel-0"
        style={{ flex: 1, paddingLeft: 20 }}
      >
        {/* show an alert if there is a Medi-Share enrolled member that is eligible for Senior Assist */}
        {seniorAssistEligible() && !['SA 1250'].includes(memberProgram.programLevel.name) && (
          <Box paddingTop={1}>
            <Alert severity="warning" variant="outlined">
              One or more members currently enrolled in Medi-Share are eligible for Senior Assist
            </Alert>
            <Spacer size={10} />
          </Box>
        )}

        <Typography variant="subtitle1">
          Choose a start date and the new programs for enrollment.
        </Typography>

        <Spacer size={20} />

        <Grid container spacing={4}>
          {/* Current program, new start date, selected programs (left column) */}
          <Grid item xs={6}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                maxHeight: '492px',
              }}
            >
              {/* Currently enrolled program */}
              <div style={{ flexGrow: 0, flexShrink: 0 }}>
                <Typography variant="h6">Enrolled Program</Typography>
                {memberProgram && (
                  <EnrolledProgram memberProgram={memberProgram} members={members} />
                )}

                <Spacer size={30} />
              </div>

              {/* New Start Date */}
              <div style={{ flexGrow: 0, flexShrink: 0 }}>
                <Typography variant="h6" style={{ marginBottom: 10 }}>
                  Start Date
                </Typography>
                <FormControl variant="outlined" margin="none" fullWidth>
                  {/* Min date: '1/1/1993', Max Date: 'one year from today's date */}
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      id="startDate"
                      name="startDate"
                      disabled={isFetching}
                      inputVariant="outlined"
                      format="MM/dd/yyyy"
                      value={moment(tempDate)}
                      minDate={moment(MIN_CCM_PROGRAM_DATE)}
                      maxDate={moment(MAX_CCM_PROGRAM_DATE)}
                      minDateMessage={`The start date cannot be before ${moment(
                        MIN_CCM_PROGRAM_DATE,
                      ).format('LL')}`}
                      maxDateMessage={`The start date cannot be after ${moment(
                        MAX_CCM_PROGRAM_DATE,
                      ).format('LL')}`}
                      onChange={date => handleChangeStartDate(date)}
                      KeyboardButtonProps={{
                        'aria-label': 'change start date',
                      }}
                      onError={e => handleError(e ? true : false)}
                    />
                  </MuiPickersUtilsProvider>
                </FormControl>
                <Spacer size={30} />
              </div>

              {/* Selected Programs */}
              <div style={{ flexGrow: 1 }}>
                {selectedEligiblePrograms && (
                  <>
                    <Typography variant="h6">New Program(s)</Typography>
                    {selectedEligiblePrograms.length === 0 && (
                      <div>You have not selected any programs</div>
                    )}
                    <div style={{ height: '200px', overflow: 'auto' }}>
                      {selectedEligiblePrograms.map((program: EligibleProgram, index: number) => (
                        <EligibleProgramView
                          key={index}
                          program={program}
                          enrolledProgram={memberProgram}
                          onSelectProgramLevel={handleProgramLevelSelected}
                          members={members}
                          isSelected={true}
                          readOnly={hasError}
                        />
                      ))}
                    </div>
                  </>
                )}
              </div>
            </div>
          </Grid>

          {/* Eligible programs (right column) */}
          <Grid item xs={6}>
            {/* Eligible Programs */}
            <Typography variant="h6">Eligible Programs</Typography>
            <div className={classes.programWrapper}>
              {isFetching && renderEligibleProgramSkeleton(5)}
              {!isFetching && eligibleProgramCount() === 0 && (
                <div>There are no eligible programs to display</div>
              )}
              {!isFetching &&
                eligiblePrograms &&
                eligiblePrograms
                  .sort(sortEligiblePrograms)
                  .filter(
                    (program: EligibleProgram, index: number) => program.programLevels.length > 0,
                  )
                  .map((program: EligibleProgram, index: number) => (
                    <div key={index} className={classes.programContainer}>
                      <Typography variant="h6">
                        {program.name === 'SA'
                          ? 'Senior Assist'
                          : program.name === 'MS65'
                          ? 'Medi-Share 65+'
                          : program.name === 'MS3'
                          ? 'Medi-Share'
                          : program.name === 'MV1'
                          ? 'Medi-Share Value'
                          : program.name === 'CS1'
                          ? 'Co-Share'
                          : ''}
                      </Typography>
                      <EligibleProgramView
                        key={index}
                        program={program}
                        enrolledProgram={memberProgram}
                        onSelectProgramLevel={handleProgramLevelSelected}
                        members={members}
                        renderCurrentProgram={false}
                        readOnly={hasError}
                      />
                    </div>
                  ))}
            </div>
          </Grid>
        </Grid>
      </div>

      {/* Confirmation tab */}
      <div
        role="tabpanel"
        hidden={currentTab !== 1}
        id="vertical-tabpanel-0"
        style={{ flex: 1, paddingLeft: 20 }}
      >
        <ProgramConfirmationView
          memberProgram={memberProgram}
          members={members}
          selectedEligiblePrograms={selectedEligiblePrograms}
          handleProgramLevelSelected={handleProgramLevelSelected}
          startDate={startDate}
        />
      </div>
    </div>
  );
};

export default ProgramChange;
