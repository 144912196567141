import { useState } from 'react';
import { Grid, Typography, Button } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { useQueryClient } from 'react-query';
import { capitalize } from 'lodash';
import { Address, ADDRESS_TYPE, ADDRESS_STATUS } from '../../../models/address';
import AddressForm from '../../address/AddressForm';
import { AddressLabel } from '../../address/AddressLabel';
import CustomModal from '../../Modal';
import { showToastMessage } from '../../../store/slices/toastMessage';

interface MemberAddressProps {
  addresses: Address[];
  personUid: string;
  membershipAccountUid: string;
}

export const MemberAddress: React.FC<MemberAddressProps> = ({
  addresses,
  personUid,
  membershipAccountUid,
}) => {
  const [formAddress, setFormAddress] = useState<Address | null | undefined>(undefined);
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const mailingAddress = addresses.find(
    a => a.addressType === ADDRESS_TYPE.MAILING && a.status === ADDRESS_STATUS.ACTIVE,
  );
  const billingAddress = addresses.find(
    a => a.addressType === ADDRESS_TYPE.BILLING && a.status === ADDRESS_STATUS.ACTIVE,
  );

  const formModalTitle = `${formAddress?.address1 ? 'Update' : 'Add'} ${
    formAddress?.addressType ? capitalize(formAddress.addressType) : ''
  } Address`;

  const onAddressSaved = () => {
    setFormAddress(undefined);
    dispatch(showToastMessage({ message: 'Address saved', type: 'success' }));
    queryClient.invalidateQueries(['membershipAccount', membershipAccountUid]);
  };

  const openModalAddress = (address: Address | null | undefined, addressType: string) => {
    if (address) {
      setFormAddress(address);
    } else {
      const newAddress: Address = {
        addressType,
        address1: '',
        address2: '',
        city: '',
        state: '',
        postalCode: '',
        status: ADDRESS_STATUS.ACTIVE,
      };
      setFormAddress(newAddress);
    }
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Typography variant="h6">Billing Address</Typography>
            <AddressLabel address={billingAddress} />
            {false && (
              <Button
                color="primary"
                variant="contained"
                size="small"
                style={{ width: '35%' }}
                onClick={() => openModalAddress(billingAddress, ADDRESS_TYPE.BILLING)}
              >
                {billingAddress ? 'Edit' : 'Add'}
              </Button>
            )}
          </div>
        </Grid>
        <Grid item xs={12} md={6}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Typography variant="h6">Mailing Address</Typography>
            <AddressLabel address={mailingAddress} />
            {false && (
              <Button
                color="primary"
                variant="contained"
                size="small"
                style={{ width: '35%' }}
                onClick={() => openModalAddress(mailingAddress, ADDRESS_TYPE.MAILING)}
              >
                {mailingAddress ? 'Edit' : 'Add'}
              </Button>
            )}
          </div>
        </Grid>
      </Grid>
      <CustomModal open={Boolean(formAddress)} title={formModalTitle} style={{ width: 800 }}>
        <AddressForm
          address={formAddress}
          addressAssociationForeignKey={{ person: personUid }}
          onCancel={() => setFormAddress(undefined)}
          onAddressSaved={onAddressSaved}
        />
      </CustomModal>
    </>
  );
};

export default MemberAddress;
