import { FC } from 'react';
import { Typography } from '@material-ui/core';
import { MailingAddress } from '../../types/print-request';
import { hyphenString } from '../../../../lib/util';

interface PrintRequestDetailsAddressProps {
  header: string;
  address: MailingAddress;
}

const PrintRequestDetailsAddress: FC<PrintRequestDetailsAddressProps> = ({ header, address }) => {
  const { streetAddress, secondaryAddress, city, state, postalCode } = address;
  const line1 = `${streetAddress}${secondaryAddress ? `, ${secondaryAddress}` : ''}`;
  const line2 = `${city}, ${state} ${postalCode}`;
  return (
    <>
      <Typography variant="h6" data-testid={hyphenString(header)}>
        {header.toUpperCase()}
      </Typography>
      <Typography variant="body1">{line1}</Typography>
      <Typography variant="body1">{line2}</Typography>
    </>
  );
};

export default PrintRequestDetailsAddress;
