import React from 'react';
import {
	Card,
	CardContent,
	Avatar,
	Checkbox,
	CardActionArea,
	Tooltip,
	makeStyles,
	createStyles,
} from '@material-ui/core';
import LockIcon from '@material-ui/icons/Lock';
import Member, { AlloyJourneyStatus, Role as MembershipRole } from "../../../models/member";
import Person from "../../../models/person";
import ProgramEnrollment from "../../../models/programEnrollment";
import MemberNameAndAge from "./MemberNameAndAge";
import StatusBadge, { getStatusVariant } from "../../StatusBadge";
import { removeUnderscoresFromString } from "../../../lib/util";
import { getSanitizedKYCStatus } from "../memberInfo/MemberCard";
import MemberRole from "./MemberRole";
import Spacer from "../../Spacer";

const useStyles = makeStyles((theme) =>
	createStyles({
		root: {
			backgroundColor: theme.backgroundColors.body,
			minHeight: '76px',
			flexShrink: 0,
			opacity: 0.7,
			transition: 'all 0.2s ease-in-out',
			'&:hover': {
				opacity: 1,
			},
		},
		content: {
			cursor: 'pointer',
			display: 'flex',
			justifyContent: 'space-between',
			gap: '5px'
		},
		disabled: {
			opacity: .5,
		},
		cursorPointer: {
			cursor: 'pointer'
		},
		checkbox: {
			width: '24px',
			height: '24px',
		},
		kycStatus: {
			marginLeft: '5px',
		},
		nameWrapper: {
			display: 'flex',
			flexDirection: 'column'
		},
		archivedIcon: {
			position: 'absolute',
			top: '3px',
			right: '-1px'
		}
	}),
);

interface MemberCardProps {
	member: Member,
	selectable: boolean,
	selected?: boolean,
	onMemberSelected?: (member: Member, selected: boolean) => void,
	disabled?: boolean,
	handleMemberOpen?: (member: Member) => void,
	programEnrollments?: ProgramEnrollment[],
	KYCStatus?: string,
	summary?: boolean
}

const MemberCard: React.FC<MemberCardProps> = ({
																								 member,
																								 selectable = false,
																								 selected = false,
																								 onMemberSelected,
																								 disabled = false,
																								 handleMemberOpen,
																								 programEnrollments,
																								 KYCStatus,
																								 summary = false
																							 }) => {

	const styles = useStyles();

	const handleSelection = () => {
		if (!disabled && onMemberSelected) {
			onMemberSelected(member, !selected);
		}
	};

	const onMemberOpen = () => {
		if (handleMemberOpen) {
			handleMemberOpen(member);
		}
	}

	return (
		<Card className={styles.root}>
			{selectable ? (
				<CardActionArea disabled={disabled} onClick={handleSelection}>
					<CardContent className={`${styles.content} ${(disabled ? styles.disabled : '')}`}>
						<Checkbox className={styles.checkbox} color="primary" checked={selected} disabled={disabled}
											onChange={handleSelection}/>
						<MemberNameAndAge person={member.person as Person}/>
						<MemberRole member={member} programEnrollments={programEnrollments}/>
						{member.archived && (
							<Tooltip title="Archived">
								<LockIcon/>
							</Tooltip>
						)}
					</CardContent>
				</CardActionArea>
			) : (
				<CardContent className={`${styles.content} ${styles.cursorPointer}`} onClick={onMemberOpen}>
					{!summary &&
              <Avatar>{(member.person as Person).firstName[0]}{(member.person as Person).lastName[0]}</Avatar>}
					<div className={styles.nameWrapper}>
						<MemberNameAndAge person={member.person as Person}/>
						{!summary && <div>
                <Spacer size={3}/>
                <StatusBadge
                    variant={getStatusVariant(member.status)}
                    label={removeUnderscoresFromString(member.status)}
                />
							{member.retired && <>&nbsp;
                  <StatusBadge label="Retired" variant="info"/>
              </>}
							{KYCStatus && KYCStatus !== '' && member.role === MembershipRole.Admin && (
								<>&nbsp;
									<StatusBadge
										variant="kyc"
										label={getSanitizedKYCStatus(
											(KYCStatus.toLowerCase() as unknown) as AlloyJourneyStatus,
										)}
									/>
								</>
							)}
            </div>
						}
					</div>
					<MemberRole member={member} programEnrollments={programEnrollments}/>
					{member.archived && (
						<Tooltip title="Archived">
							<LockIcon/>
						</Tooltip>
					)}
				</CardContent>
			)}
		</Card>
	);
};

export default MemberCard;