interface ErrorParameters {
  message: string;
  data?: any;
  status?: number;
  type?: string;
}

class CustomError {
  _customError?: boolean = true;

  message = '';
  data?: any | null;
  status = 0;
  type = 'application error';

  constructor({ message, data, status = 0, type = 'application error' }: ErrorParameters) {
    this._customError = true;
    this.message = message;
    this.data = data;
    this.status = status;
    this.type = type;
  }

  toString() {
    return this.message;
  }

  responseError(error: any) {
    let errorMessage = '';
    if (error.response.data) {
      errorMessage = error.response.data.message || error.message || 'Unknown error';
    } else {
      errorMessage = error.message || error.response.statusText || 'Unknown error';
    }

    this.message = errorMessage;
    this.data = error.response.data;
    this.status = error.response.status;
    this.type = 'response';
  }

  static isCustomError(err: CustomError | any): err is CustomError {
    return (err as CustomError)._customError !== undefined;
  }
}

/**
 * Formats an error received into a consistent format
 * @param error the error received
 */
export const handleError = (error: CustomError | any) => {
  if (CustomError.isCustomError(error)) {
    return error;
  }

  let customError = new CustomError({ message: 'Unknown error' });

  if (error.response) {
    customError.responseError(error);
  } else {
    customError.message = error.message || 'Error: ' + error.toString();
  }

  return customError;
};

export default CustomError;
