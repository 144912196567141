import { AxiosInstance } from 'axios';
import { APIClientBase } from '../../../lib/api/apiClientBase';
import client from '../../../lib/api/client';
import { MedicalBillReimbursementSalesforceCaseGetResponseDto } from '../types/medical-bill-reimbursement-salesforce-case-get.response.dto';
import { MedicalBillReimbursementUpdateRequestDto } from '../types/medical-bill-reimbursement-update.request.dto';
import { MedicalBillReimbursementWithDetailsResponseDto } from '../types/medical-bill-reimbursement-with-details.response.dto';
import {
  MedicalBillReimbursementApiPort,
  SearchMedicalBillReimbursementsQueryParams,
} from './medical-bill-reimbursements.api.port';

export class MedicalBillReimbursementApi
  extends APIClientBase
  implements MedicalBillReimbursementApiPort {
  private readonly client: AxiosInstance = client;

  get = async (uid: string): Promise<MedicalBillReimbursementWithDetailsResponseDto> => {
    try {
      const response = await this.client.get(`/membership/medical-bill-reimbursements/${uid}`);
      return response.data;
    } catch (err) {
      const error = this.error(err);
      throw error;
    }
  };

  query = async (
    query: SearchMedicalBillReimbursementsQueryParams,
  ): Promise<MedicalBillReimbursementWithDetailsResponseDto[]> => {
    try {
      const queryString = this.toQueryString(query);
      const url = `/membership/medical-bill-reimbursements${queryString ? `?${queryString}` : ''}`;
      const response = await this.client.get<MedicalBillReimbursementWithDetailsResponseDto[]>(url);
      return this.response(response);
    } catch (err) {
      const error = this.error(err);
      throw error;
    }
  };

  listFiles = async (uid: string): Promise<{ fileName: string }[]> => {
    try {
      const response = await this.client.get(
        `/membership/medical-bill-reimbursements/${uid}/files`,
      );
      return response.data;
    } catch (err) {
      const error = this.error(err);
      throw error;
    }
  };

  downloadFile = async (uid: string, fileId: string): Promise<string> => {
    try {
      // Make a request to the API to download the file as a blob
      const response = await this.client.get(
        `/membership/medical-bill-reimbursements/${uid}/files/${encodeURIComponent(fileId)}`,
        {
          responseType: 'blob',
        },
      );

      // Create an object-url from the blob
      const blobUrl = URL.createObjectURL(new Blob([response.data]));

      return blobUrl;
    } catch (err) {
      const error = this.error(err);
      throw error;
    }
  };

  update = async (uid: string, data: MedicalBillReimbursementUpdateRequestDto): Promise<void> => {
    await this.client.patch(`/membership/medical-bill-reimbursements/${uid}`, data);
  };

  getAdminCase = async (
    uid: string,
  ): Promise<MedicalBillReimbursementSalesforceCaseGetResponseDto> => {
    try {
      const response = await this.client.get(
        `/membership/medical-bill-reimbursements/${uid}/admin-case`,
      );
      return response.data;
    } catch (err) {
      const error = this.error(err);
      throw error;
    }
  };

  closeAdminCase = async (uid: string): Promise<void> => {
    await this.client.patch(`/membership/medical-bill-reimbursements/${uid}/admin-case/close`);
  };
}

export default new MedicalBillReimbursementApi();
