import {
  Box,
  Button,
  ButtonGroup,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { BusinessOutlined } from '@material-ui/icons';
import { useFormik } from 'formik';
import React, { useRef, useState } from 'react';
import * as yup from 'yup';
import { OrganizationData } from '../../lib/api/organizations';
import { ImageUtil } from '../../lib/imageUtil';
import Organization from '../../models/organization';
import ConfirmationDialog from '../ConfirmationDialog';
import Spacer from '../Spacer';
import OrganizationLogo from './OrganizationLogo';

const useStyles = makeStyles(() => ({
  buttonContainer: {
    marginTop: '16px',
  },
  logo: {
    maxWidth: '100%',
    maxHeight: '200px',
    borderRadius: '8px',
    objectFit: 'contain',
    objectPosition: 'center',
  },
  logoBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flex: '1',
    marginRight: '10px',
    minHeight: '100px',
    padding: '10px',
    background: '#fff',
    borderRadius: '8px',
    border: '1px solid black',
  },
  logoPlaceholder: {
    color: '#9699a4',
    height: '50px',
    width: '50px',
  },
  logoInput: {
    visibility: 'hidden',
    width: 0,
    height: 0,
  },
}));

const organizationFormSchema = yup.object({
  organizationName: yup
    .string()
    .trim()
    .max(150, 'Organization names cannot be longer than 150 characters.')
    .required('Organization Name is required'),
  status: yup.string().required('Status is required'),
});

interface Props {
  organization?: Organization;
  disabled: boolean;
  onSubmit(data: OrganizationData): void;
  cancel(): void;
}

interface OrgFormImageProps {
  localImgUrl: string | null;
  organization: Organization | undefined;
  didDeleteLogo: boolean;
}

const OrgFormImage: React.FC<OrgFormImageProps> = props => {
  const styles = useStyles();
  if (props.localImgUrl) {
    return <img className={styles.logo} src={props.localImgUrl} alt="Logo" />;
  }
  if (props.didDeleteLogo || !props.organization) {
    return <BusinessOutlined className={styles.logoPlaceholder} />;
  }

  return (
    <OrganizationLogo
      organization={props.organization}
      logoStyles={styles.logo}
      logoPlaceholderStyles={styles.logoPlaceholder}
    />
  );
};

const OrganizationForm: React.FC<Props> = props => {
  const { organization, disabled, onSubmit } = props;
  const styles = useStyles();
  const [file, setFile] = useState<File | null>(null);
  const [localImgUrl, setLocalImgUrl] = useState<string | null>(null);
  const [fileError, setFileError] = useState<string | null>(null);
  const [deleteLogoDialogOpen, setDeleteLogoDialogOpen] = useState<boolean>(false);
  const [didDeleteLogo, setDidDeleteLogo] = useState<boolean>(false);
  const logoInput = useRef<HTMLInputElement | null>(null);

  let saveButtonLabel = organization ? 'Update' : 'Create';
  let organizationData = organization ? organization : new Organization();

  const organizationForm = useFormik({
    initialValues: {
      uid: organizationData.uid || '',
      organizationName: organizationData.organizationName || '',
      status: organizationData.status || 'active',
    },
    validationSchema: organizationFormSchema,
    onSubmit: values => {
      const data: OrganizationData = { ...values };
      if (file) {
        data.logoImage = file;
      }
      if (organization && didDeleteLogo) {
        data.logoUrlToDelete = organization.settings.logoUrl;
      }
      onSubmit(data);
    },
  });

  const handleLogoSelect = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target!.files![0];
    if (!file) {
      return;
    }
    await validateLogo(file);
    setFile(file);
    const imgUrl = URL.createObjectURL(file);
    setLocalImgUrl(imgUrl);
  };

  const validateLogo = async (file: File) => {
    let error: string | null = null;
    const extIndex = file.name.lastIndexOf('.');
    const extension = file.name.substr(extIndex + 1);
    if (!['jpg', 'jpeg', 'png'].includes(extension)) {
      error = `Logo must be a JPG or PNG but the selected file has the extension ${extension}`;
    } else {
      const base64 = await ImageUtil.toBase64(file);
      const dimensions = await ImageUtil.getImageDimensions(base64);
      if (dimensions.width > 300 || dimensions.height > 300) {
        error = `Logo height and width cannot be bigger than 300px but the image is ${dimensions.width}x${dimensions.height}`;
      } else if (file.size / 1024 / 1024 > 1) {
        error = `Logo cannot be larger than 1MB`;
      }
    }
    setFileError(error);
  };

  const imgUrl = localImgUrl || (!didDeleteLogo ? organization?.settings.logoUrl : null);

  return (
    <form noValidate onSubmit={organizationForm.handleSubmit} style={{ width: 400 }}>
      <TextField
        id="organizationName"
        name="organizationName"
        label="Organization Name"
        margin="normal"
        fullWidth
        variant="outlined"
        autoComplete="none"
        disabled={disabled}
        value={organizationForm.values.organizationName}
        onChange={organizationForm.handleChange}
        error={
          organizationForm.touched.organizationName &&
          Boolean(organizationForm.errors.organizationName)
        }
        helperText={
          organizationForm.touched.organizationName && organizationForm.errors.organizationName
        }
        inputProps={{
          maxLength: 150,
        }}
      ></TextField>

      <FormControl variant="outlined" fullWidth margin="normal">
        <InputLabel id="status-label">Status</InputLabel>
        <Select
          id="status"
          name="status"
          label="Status"
          labelId="status-label"
          disabled={disabled}
          value={organizationForm.values.status}
          onChange={organizationForm.handleChange}
        >
          <MenuItem value="active">Active</MenuItem>
          <MenuItem value="pending">Pending</MenuItem>
          <MenuItem value="suspended">Suspended</MenuItem>
          <MenuItem value="inactive">Inactive</MenuItem>
        </Select>
      </FormControl>

      <input
        id="logoImage"
        name="logoImage"
        ref={logoInput}
        className={styles.logoInput}
        type={'file'}
        onChange={handleLogoSelect}
        accept={'.jpg,.jpeg,.png'}
      />

      <Spacer size={10} />
      <Typography variant="caption">Logo</Typography>
      <Box display="flex" alignItems="flex-start">
        <Box className={styles.logoBox}>
          <OrgFormImage
            localImgUrl={localImgUrl}
            organization={organization}
            didDeleteLogo={didDeleteLogo}
          />
        </Box>
        <ButtonGroup orientation="vertical" color="primary" variant="contained">
          <Button onClick={() => logoInput.current?.click()}>
            {!imgUrl || didDeleteLogo ? 'Upload' : 'Replace'}
          </Button>
          {imgUrl && (
            <Button
              className="button-contained-dangerous"
              onClick={() => setDeleteLogoDialogOpen(true)}
            >
              Delete
            </Button>
          )}
        </ButtonGroup>
      </Box>
      {fileError && (
        <Typography color="error" variant="caption">
          {fileError}
        </Typography>
      )}
      <Spacer size={16} />

      <Divider />

      <Grid container spacing={1} direction="row" className={styles.buttonContainer}>
        <Grid item xs={6}>
          <Button
            color="primary"
            variant="contained"
            size="large"
            disabled={disabled || !!fileError}
            onClick={organizationForm.submitForm}
            fullWidth
          >
            {saveButtonLabel}
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            color="default"
            variant="contained"
            size="large"
            disabled={disabled}
            onClick={() => props.cancel()}
            fullWidth
          >
            Cancel
          </Button>
        </Grid>
      </Grid>

      <ConfirmationDialog
        title="Delete Logo"
        message="Are you sure you want to delete the logo?"
        dangerous={true}
        open={deleteLogoDialogOpen}
        onConfirm={() => {
          setDeleteLogoDialogOpen(false);
          setDidDeleteLogo(true);
          setFile(null);
          setLocalImgUrl(null);
        }}
        onCancel={() => setDeleteLogoDialogOpen(false)}
      />
    </form>
  );
};

export default OrganizationForm;
