import React from 'react';
import { TableCell, TableRow, Typography, FormControlLabel, Checkbox } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

import { AppSetting } from '../../models/appSettings';
import apiClient from '../../lib/api';
import { showToastMessage } from '../../store/slices/toastMessage';
import AppSettingsDeleteButton from './AppSettingsDeleteButton';
import AppSettingsUpdateButton from './AppSettingsUpdateButton';

interface AppSettingsRowProps {
  appSetting: AppSetting;
}

const useStyles = makeStyles({
  valueWrapper: {
    fontWeight: 600,
    border: '1px solid',
    width: 'fit-content',
    padding: '10px',
    borderRadius: '5px',
    marginTop: '10px',
  },
  iconsWrapper: {
    display: 'inline-block',
    '& .delete-icon': {
      marginLeft: '20px',
      color: '#f44336',
    },
  },
});

const AppSettingsRow: React.FC<AppSettingsRowProps> = ({ appSetting }) => {
  const styles = useStyles();
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const updateAppSettingPrivate = useMutation(
    () => apiClient.appSettings.updateAppSettings(appSetting.uid, { private: !appSetting.private }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('appSettings');
      },
      onError: (error: any) => {
        dispatch(
          showToastMessage({
            message: error.message,
            type: 'error',
          }),
        );
      },
    },
  );

  return (
    <>
      <TableRow>
        <TableCell>
          <Typography variant="body1">{appSetting.settingName}</Typography>
          <div className={styles.valueWrapper}>
            <code>{appSetting.settingValue}</code>
          </div>
        </TableCell>
        <TableCell>
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                checked={appSetting.private}
                onChange={() => updateAppSettingPrivate.mutate()}
              />
            }
            label="Private"
          />
          <div className={styles.iconsWrapper}>
            <AppSettingsUpdateButton appSetting={appSetting} />
            <AppSettingsDeleteButton appSetting={appSetting} />
          </div>
        </TableCell>
      </TableRow>
    </>
  );
};

export default AppSettingsRow;
