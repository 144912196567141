import { Alert } from '@material-ui/lab';

const PrintRequestEmpty = () => {
  return (
    <Alert color="info" severity="info" variant="outlined" data-testid="print-request-empty">
      No printing requests found for this account
    </Alert>
  );
};

export default PrintRequestEmpty;
