import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Thunk } from '..';
import apiClient from '../../lib/api';
import Group from '../../models/group';
import CustomError from '../../lib/customError';
import { hideToastMessage, showToastMessage } from './toastMessage';

interface GroupState {
  group: Group | null;
  loading: boolean;
  saved: boolean;
  error: CustomError | null;
}

interface GroupSuccessPayload {
  group: Group;
}

interface GroupErrorPayload {
  error: CustomError;
}

export const initialState = {
  loading: false,
  saved: false,
} as GroupState;

const groupSlice = createSlice({
  name: 'group',
  initialState,
  reducers: {
    resetGroup(state) {
      state.group = null;
      state.error = null;
      state.loading = false;
      state.saved = false;
    },
    saveGroupRequest(state) {
      state.group = null;
      state.error = null;
      state.loading = true;
      state.saved = false;
    },
    saveGroupSuccess(state, action: PayloadAction<GroupSuccessPayload>) {
      state.group = new Group(action.payload.group);
      state.error = null;
      state.loading = false;
      state.saved = true;
    },
    saveGroupError(state, action: PayloadAction<GroupErrorPayload>) {
      state.error = action.payload.error;
      state.loading = false;
      state.saved = false;
    },
    getGroupRequest(state) {
      state.error = null;
      state.loading = true;
    },
    getGroupSuccess(state, action: PayloadAction<GroupSuccessPayload>) {
      state.group = new Group(action.payload.group);
      state.error = null;
      state.loading = false;
    },
    getGroupError(state, action: PayloadAction<GroupErrorPayload>) {
      state.error = action.payload.error;
      state.loading = false;
    },
    deleteGroupRequest(state) {
      state.error = null;
      state.loading = true;
      state.saved = false;
    },
    deleteGroupSuccess(state) {
      state.error = null;
      state.loading = false;
      state.saved = true;
      state.group = new Group({ ...state.group, status: 'deleted' });
    },
    deleteGroupError(state, action: PayloadAction<GroupErrorPayload>) {
      state.error = action.payload.error;
      state.loading = false;
      state.saved = false;
    },
  },
});

/* Thunks */
interface GroupData {
  uid: string;
  groupName: string;
  status: string;
  default: boolean;
}

export const saveGroup = (data: GroupData): Thunk => async dispatch => {
  try {
    dispatch(hideToastMessage());
    dispatch(saveGroupRequest());

    const group = await apiClient.groups.save(data);
    dispatch(saveGroupSuccess({ group }));
    dispatch(
      showToastMessage({
        message: 'The group has been saved',
        type: 'success',
        duration: 3000,
      }),
    );
  } catch (err: any) {
    dispatch(saveGroupError({ error: err }));
    dispatch(showToastMessage({ message: err.message, type: 'error' }));
  }
};

export const getGroup = (uid: string): Thunk => async dispatch => {
  try {
    dispatch(getGroupRequest());
    const group: Group = await apiClient.groups.get(uid);
    dispatch(getGroupSuccess({ group }));
  } catch (err: any) {
    dispatch(getGroupError({ error: err }));
    dispatch(showToastMessage({ message: err.message, type: 'error' }));
  }
};

export const deleteGroup = (uid: string): Thunk => async dispatch => {
  try {
    dispatch(deleteGroupRequest());
    await apiClient.groups.delete(uid);
    dispatch(deleteGroupSuccess());
    dispatch(
      showToastMessage({
        message: 'The group has been deleted',
        type: 'success',
        duration: 3000,
      }),
    );
  } catch (err: any) {
    dispatch(deleteGroupError({ error: err }));
    dispatch(showToastMessage({ message: err.message, type: 'error' }));
  }
};

/* Actions & Reducer */
/* prettier-ignore */
export const {
  resetGroup, saveGroupRequest, saveGroupSuccess, saveGroupError,
  getGroupRequest, getGroupSuccess, getGroupError,
  deleteGroupRequest, deleteGroupSuccess, deleteGroupError,
} = groupSlice.actions;

export default groupSlice.reducer;
