import React, { useEffect } from 'react';
import { Typography, Modal, Paper, makeStyles, Box, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useDispatch } from 'react-redux';

import { updateSessionActivity } from '../store/slices/session';

const useStyles = makeStyles(theme => ({
  modal: {
    position: 'absolute',
    outline: 'none',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  titleWrapper: {
    display: 'flex',
    margin: '1rem',
    justifyContent: 'space-between',
  },
  title: {
    '& > h2': {
      margin: 0,
    },
    padding: 20,
  },
  body: {
    padding: 20,
    paddingTop: 0,
  },
}));

const CustomModal = ({ title, style, children, onClose, ...rest }: any) => {
  const styles = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!rest.preventUserActivity) {
      dispatch(updateSessionActivity());
    }
  }, [dispatch, rest.open, rest.preventUserActivity]);

  return (
    <Modal {...rest}>
      <Paper className={styles.modal} style={{ ...style }}>
        {title && (
          <div className={styles.titleWrapper}>
            <Box className={styles.title}>
              <Typography variant="h2">{title}</Typography>
            </Box>
            {onClose && (
              <IconButton title="cancel" onClick={onClose}>
                <CloseIcon />
              </IconButton>
            )}
          </div>
        )}
        <Box className={styles.body}>{children}</Box>
      </Paper>
    </Modal>
  );
};

export default CustomModal;
