import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from 'lodash';

import { RootState } from '../../store';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  TableSortLabel,
  Paper,
  makeStyles,
  Box,
  Typography,
  Button,
} from '@material-ui/core';
import Page, { Breadcrumb } from '../../components/Page';
import SearchInput from '../../components/SearchInput';
import Spacer from '../../components/Spacer';
import Group from '../../models/group';
import Organization from '../../models/organization';
import StatusBadge, { getStatusVariant } from '../../components/StatusBadge';
import { getGroups } from '../../store/slices/groups';
import GroupForm from '../../components/groups/GroupForm';
import { Action, Subject } from '@ccm-innovation/auth-membership-service';
import { useSession } from '../../context/session';

type SortDir = 'asc' | 'desc';

const useStyles = makeStyles({
  row: {
    cursor: 'pointer',
    '& > *': {
      borderBottom: 'unset',
    },
  },
  emptyRow: {
    cursor: 'default',
  },
  emptyRowCell: {
    textAlign: 'center',
  },
});

const Row = (props: { group: Group }) => {
  const { group } = props;
  const styles = useStyles();
  const history = useHistory();

  const handleGroupRowClick = (uid: string) => {
    history.push(`/membership/groups/${uid}`);
  };

  return (
    <React.Fragment>
      <TableRow className={styles.row} hover onClick={() => handleGroupRowClick(group.uid)}>
        <TableCell>
          {group.underwriter}:{group.underwriterGroup}
        </TableCell>
        <TableCell>{group.groupName}</TableCell>
        <TableCell>{(group.organization as Organization).organizationName}</TableCell>
        <TableCell>
          <StatusBadge variant={getStatusVariant(group.status)} label={group.status} />
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

const renderPaginationLabel = (
  from: number,
  to: number,
  pageSize: number,
  rowCount: number,
): string => {
  if (rowCount > pageSize) {
    return `${from} - ${to}`;
  } else {
    return `${from} - ${from + rowCount - 1}`;
  }
};

const renderCount = (rowsReturned: number, rowsPerPage: number, pageIndex: number): number => {
  return rowsReturned > rowsPerPage ? -1 : rowsPerPage * pageIndex + rowsReturned;
};

const GroupsView = (props: any) => {
  const breadcrumbs: Breadcrumb[] = [
    { href: '/', label: 'Home' },
    { href: '#', label: 'Membership' },
    { label: 'Groups' },
  ];
  const groupsState = useSelector((state: RootState) => state.groups);
  const [filteredGroups, setFilteredGroups] = useState(groupsState.groups);
  const [orderBy, setOrderBy] = useState('groupName');
  const [sort, setSort] = useState<SortDir>('asc');
  const [searchQuery, setSearchQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize] = useState(25);
  const urlParams = new URLSearchParams(window.location.search);
  const organization = urlParams.get('organization') || '';
  const dispatch = useDispatch();
  const styles = useStyles();
  const { user } = useSession();

  const getGroupResults = (
    query: string,
    page: number,
    sort: string = 'asc',
    orderBy: string = 'groupName',
  ) => {
    dispatch(
      getGroups({
        organization,
        nameOrNumber: query,
        expand: 'organization',
        pageSize,
        page,
        orderBy,
        sort,
        plus1: true,
      }),
    );
  };

  const handleGroupSearch = debounce((val: string) => {
    setSearchQuery(val);
    getGroupResults(val, 1, sort, orderBy);
  }, 500);

  const handlePageChange = (e: any, page: number) => {
    getGroupResults(searchQuery, page + 1, sort, orderBy);
  };

  const handleSort = (e: any, orderBy: string, sort: SortDir) => {
    setOrderBy(orderBy);
    setSort(sort);
    getGroupResults(searchQuery, 1, sort, orderBy);
  };

  useLayoutEffect(() => {
    // get the latest version of the groups from the API
    dispatch(
      getGroups({
        organization,
        expand: 'organization',
        pageSize,
        page: 1,
        plus1: true,
        orderBy: 'groupName',
        sort: 'asc',
      }),
    );
  }, [dispatch, organization, pageSize]);

  useEffect(() => {
    if (!groupsState.loading) {
      setCurrentPage((groupsState.page && groupsState.page - 1) || 0);
      setFilteredGroups(groupsState.groups);
    }
  }, [groupsState, setFilteredGroups]);

  const [modalOpen, setModalOpen] = useState(false);

  return (
    <Page breadcrumbs={breadcrumbs}>
      <Box display="flex" justifyContent="space-between" alignItems="flex-start">
        <Typography variant="h2">Groups</Typography>
        {user && user.can(Action.Create, Subject.Group) && (
          <Button color="primary" variant="contained" onClick={() => setModalOpen(true)}>
            Add Group
          </Button>
        )}
      </Box>
      <SearchInput placeholder="Search by group name or number" onChange={handleGroupSearch} />

      <Spacer size={30} />

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell width={160}>Group #</TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'groupName'}
                  direction={orderBy === 'groupName' ? sort : 'asc'}
                  onClick={e => handleSort(e, 'groupName', sort === 'asc' ? 'desc' : 'asc')}
                >
                  Group Name
                </TableSortLabel>
              </TableCell>
              <TableCell width={350}>
                <TableSortLabel
                  active={orderBy === 'organizationId'}
                  direction={orderBy === 'organizationId' ? sort : 'asc'}
                  onClick={e => handleSort(e, 'organizationId', sort === 'asc' ? 'desc' : 'asc')}
                >
                  Organization
                </TableSortLabel>
              </TableCell>
              <TableCell width={180}>
                <TableSortLabel
                  active={orderBy === 'status'}
                  direction={orderBy === 'status' ? sort : 'asc'}
                  onClick={e => handleSort(e, 'status', sort === 'asc' ? 'desc' : 'asc')}
                >
                  Status
                </TableSortLabel>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!filteredGroups.length && (
              <TableRow className={`${styles.row} ${styles.emptyRow}`}>
                <TableCell colSpan={5} className={styles.emptyRowCell}>
                  No groups found
                </TableCell>
              </TableRow>
            )}
            {filteredGroups.map(
              (group: Group, index: number) =>
                (index < filteredGroups.length - 1 || filteredGroups.length <= pageSize) && (
                  <Row key={group.uid} group={group} />
                ),
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {filteredGroups.length > 0 && (
        <TablePagination
          rowsPerPageOptions={[pageSize]}
          component="div"
          count={renderCount(filteredGroups.length, pageSize, currentPage)}
          labelDisplayedRows={({ from, to, count }) =>
            renderPaginationLabel(from, to, pageSize, filteredGroups.length)
          }
          rowsPerPage={pageSize}
          page={currentPage}
          onChangePage={handlePageChange}
        />
      )}

      <GroupForm close={() => setModalOpen(false)} group={null} open={modalOpen} />
    </Page>
  );
};

export default GroupsView;
