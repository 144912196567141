import React, { useCallback, useEffect, useState } from 'react';
import { Button, FormControl, Typography, TextField, debounce } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import MembershipAccount from '../../../../models/membershipAccount';
import apiClient from '../../../../lib/api';
import { showToastMessage } from '../../../../store/slices/toastMessage';
import Dialog from '../../../Dialog';
import Group from '../../../../models/group';
import { Autocomplete } from '@material-ui/lab';
import ButtonProgressIndicator from '../../../ButtonProgressIndicator';

const useStyles = makeStyles(theme => ({
  dialogContainer: {
    flexGrow: 1,
    display: 'flex',
  },
  changeGroupForm: {
    height: 250,
    width: 475,
  },
  inputErrorText: {
    marginTop: '0',
    '& .MuiFormHelperText-root': {
      marginLeft: '0',
    },
  },
  muiAlert: {
    textAlign: 'center',
    '& > .MuiAlert-icon': {
      alignItems: 'center',
    },
  },
  bodyText: {
    marginBottom: '32px',
  },
}));

interface ChangeGroupProps {
  membershipAccount: MembershipAccount;
  onDialogClose: Function;
  open: boolean;
}

enum Steps {
  Form = 'form',
  Confirmation = 'Confirmation',
}

interface ChangeGroupModalProps {
  groups: Group[] | undefined;
  groupUId: string;
  isFetched: boolean;
  isFetching: boolean;
  membershipAccount: MembershipAccount;
  onClose: () => void;
  onchange: (event: any) => void;
  open: boolean;
}

interface ChangeGroupForm {
  groupUId: string;
  membershipAccountUId: string;
}

const ChangeGroupModal: React.FC<ChangeGroupModalProps> = ({
  groups,
  // hasData,
  isFetching,
  membershipAccount,
  onClose,
  onchange,
  open,
}) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const styles = useStyles();
  const [inputValue, setInputValue] = useState('');
  const [selectedGroup, setSelectedGroup] = useState<Group>();
  const [dialog, setDialog] = useState<Steps>(Steps.Form);

  /* eslint-disable */
  const debouncedSearch = useCallback(
    debounce((searchQuery: string) => onchange(searchQuery), 500),
    [],
  );

  const handleChange = (query: string) => {
    setSelectedGroup(undefined);
    setInputValue(query);
    debouncedSearch(query);
  };

  const changeGroup = useMutation(
    (values: ChangeGroupForm) =>
      apiClient.membershipAccounts.changeGroup(values.membershipAccountUId, values.groupUId),
    {
      onSuccess: () => {
        // get newest data from the membership account
        queryClient.invalidateQueries(['membershipAccount', membershipAccount.uid]);
        dispatch(
          showToastMessage({
            message: 'The assigned group has been changed',
            type: 'success',
          }),
        );
        handleModalClose();
      },
      onError: () => {
        dispatch(
          showToastMessage({
            message: `The assigned group could not be changed`,
            type: 'error',
          }),
        );
        handleModalClose();
      },
    },
  );

  const handleModalClose = () => {
    onClose();
    setTimeout(() => {
      setSelectedGroup(undefined);
      setDialog(Steps.Form);
    }, 750);
  };

  const renderButtons = () => {
    const continueButton = (
      <Button
        color="primary"
        variant="contained"
        size="large"
        onClick={
          dialog === Steps.Confirmation && selectedGroup
            ? () =>
                changeGroup.mutate({
                  membershipAccountUId: membershipAccount.uid,
                  groupUId: selectedGroup.uid,
                })
            : () => setDialog(Steps.Confirmation)
        }
        key="add"
        fullWidth
        disabled={isFetching || !selectedGroup || changeGroup.isLoading}
      >
        {changeGroup.isLoading ? (
          <ButtonProgressIndicator />
        ) : dialog === Steps.Confirmation ? (
          'Confirm'
        ) : (
          'Continue'
        )}
      </Button>
    );
    const cancelButton = (
      <Button
        color="default"
        variant="contained"
        size="large"
        key="cancel"
        onClick={handleModalClose}
        fullWidth
      >
        Cancel
      </Button>
    );
    return [continueButton, cancelButton];
  };

  const form = () => {
    return (
      <>
        <Typography variant="body2" className={styles.bodyText}>
          Choose a new group and click <strong>Continue</strong>. You will be prompted to confirm
          this change in the next step.
        </Typography>
        <Typography variant="h6">Current Group</Typography>
        <Typography variant="body2" className={styles.bodyText}>
          {membershipAccount.group!.groupName} - {membershipAccount.group!.underwriter}
          {membershipAccount.group!.underwriterGroup}
        </Typography>
        <FormControl variant="outlined" fullWidth margin="normal" className={styles.inputErrorText}>
          <Autocomplete
            id="groupUId"
            options={groups || []}
            getOptionLabel={option =>
              `${option.groupName} - ${option.underwriter}${option.underwriterGroup}`
            }
            renderInput={params => (
              <TextField
                {...params}
                label={<Typography variant="h6">New Group</Typography>}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {isFetching ? <ButtonProgressIndicator size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
              />
            )}
            filterOptions={options => options}
            // check for strict equality
            getOptionSelected={(option, value) => option.uid === value.uid}
            onChange={(_, value) => {
              if (typeof value === 'string') {
                const group = groups!.find(g => g.uid === value);
                setSelectedGroup(group);
              } else {
                setSelectedGroup(value!);
              }
            }}
            onInputChange={(_, value) => handleChange(value)}
            inputValue={inputValue}
          />
        </FormControl>
      </>
    );
  };

  const confirmation = () => {
    return (
      <>
        <Typography variant="body2" className={styles.bodyText}>
          This change will take effect immediately. Click <strong>Confirm</strong> to submit this
          group change.
        </Typography>
        <Typography variant="h6">Current Group</Typography>
        <Typography variant="body2" className={styles.bodyText}>
          {membershipAccount.group!.groupName} - {membershipAccount.group!.underwriter}
          {membershipAccount.group!.underwriterGroup}
        </Typography>
        <Typography variant="h6">New Group</Typography>
        <Typography variant="body2" className={styles.bodyText}>
          {selectedGroup!.groupName} - {selectedGroup!.underwriter}
          {selectedGroup!.underwriterGroup}
        </Typography>
      </>
    );
  };

  return (
    <Dialog title="Change Group" open={open} buttons={renderButtons()}>
      <div className={styles.dialogContainer}>
        <form className={styles.changeGroupForm}>
          {dialog === Steps.Confirmation && selectedGroup ? confirmation() : form()}
        </form>
      </div>
    </Dialog>
  );
};

const ChangeGroup: React.FC<ChangeGroupProps> = ({ membershipAccount, open, onDialogClose }) => {
  const [changeGroupModalOpen, setChangeGroupModalOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');

  const { data, isFetching, isFetched, refetch } = useQuery(
    ['groups', searchQuery],
    async () => {
      const groups = await apiClient.groups.list({
        nameOrNumber: searchQuery,
        organization: membershipAccount.organization,
        status: 'active',
      });
      return groups?.filter(g => g.uid !== membershipAccount.group!.uid);
    },
    { refetchOnWindowFocus: false, enabled: false },
  );

  const handleGroupSearch = (val: string) => setSearchQuery(val);

  const onClose = () => {
    onDialogClose();
    setChangeGroupModalOpen(false);
  };

  useEffect(() => {
    setChangeGroupModalOpen(open);
    if (open) {
      refetch();
    }
  }, [open, searchQuery, refetch]);

  return (
    <ChangeGroupModal
      groups={data}
      groupUId={membershipAccount!.group!.uid}
      isFetched={isFetched}
      isFetching={isFetching}
      onchange={query => {
        handleGroupSearch(query);
      }}
      membershipAccount={membershipAccount}
      onClose={onClose}
      open={changeGroupModalOpen}
    />
  );
};

export default ChangeGroup;
