import React, { useState } from 'react';
import { Button, Checkbox, FormControlLabel, TextField } from '@material-ui/core';
import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { makeStyles } from '@material-ui/core/styles';

import apiClient from '../../lib/api';
import { showToastMessage } from '../../store/slices/toastMessage';
import CustomDialog from '../Dialog';
import { AddAppSetting } from '../../models/appSettings';

const useStyles = makeStyles({
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '500px',
    '& .MuiTextField-root': {
      marginTop: '15px',
    },
  },
  inputErrorText: {
    '& .MuiFormHelperText-root': {
      marginLeft: '0',
    },
  },
  buttonsWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '15px',
    '& button:first-child': {
      marginRight: '15px',
    },
  },
});

const createFormSchema = yup.object({
  settingName: yup.string().trim().required('Setting name is required'),
  settingValue: yup.string().trim().required('Setting name is required'),
  private: yup.boolean().required('Private is required'),
});

const AppSettingsAddButton: React.FC<{}> = () => {
  const styles = useStyles();
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const [openDialog, setOpenDialog] = useState(false);

  const addForm = useFormik({
    initialValues: {
      settingName: '',
      settingValue: '',
      private: true,
    },
    validationSchema: createFormSchema,
    onSubmit: async values => {
      addAppSetting.mutate(values);
    },
  });

  const addAppSetting = useMutation(
    (values: AddAppSetting) => apiClient.appSettings.createAppSettings(values),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries('appSettings');
        handleCloseDialog();
      },
      onError: (error: any) => {
        dispatch(
          showToastMessage({
            message: error.message,
            type: 'error',
          }),
        );
      },
    },
  );

  const handleCloseDialog = () => {
    addForm.resetForm();
    setOpenDialog(false);
  };

  return (
    <>
      <Button color="primary" variant="contained" onClick={() => setOpenDialog(true)}>
        Add Application Setting
      </Button>

      <CustomDialog open={openDialog} maxWidth="lg" title="Add Application Setting">
        <form className={styles.contentWrapper} onSubmit={addForm.handleSubmit}>
          <TextField
            className={styles.inputErrorText}
            id="settingName"
            name="settingName"
            label="Setting Name"
            margin="normal"
            fullWidth
            variant="outlined"
            autoComplete="none"
            value={addForm.values.settingName}
            onChange={addForm.handleChange}
            onBlur={addForm.handleBlur}
            disabled={addAppSetting.isLoading}
            error={addForm.touched.settingName && Boolean(addForm.errors.settingName)}
            helperText={addForm.touched.settingName && addForm.errors.settingName}
            inputProps={{
              maxLength: 200,
            }}
          />
          <TextField
            className={styles.inputErrorText}
            id="settingValue"
            name="settingValue"
            label="Setting Value"
            margin="normal"
            fullWidth
            variant="outlined"
            autoComplete="none"
            value={addForm.values.settingValue}
            onChange={addForm.handleChange}
            onBlur={addForm.handleBlur}
            disabled={addAppSetting.isLoading}
            error={addForm.touched.settingValue && Boolean(addForm.errors.settingValue)}
            helperText={addForm.touched.settingValue && addForm.errors.settingValue}
            inputProps={{
              maxLength: 200,
            }}
          />
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                checked={addForm.values.private}
                onChange={() => addForm.setFieldValue('private', !addForm.values.private)}
              />
            }
            label="Private"
          />
          <div className={styles.buttonsWrapper}>
            <Button
              variant="contained"
              color="primary"
              disabled={addAppSetting.isLoading}
              onClick={addForm.submitForm}
              key="addAppSettingButton"
            >
              Add
            </Button>
            <Button
              variant="contained"
              color="default"
              onClick={handleCloseDialog}
              key="cancelAddAppSettingButton"
            >
              Cancel
            </Button>
          </div>
        </form>
      </CustomDialog>
    </>
  );
};

export default AppSettingsAddButton;
