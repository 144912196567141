import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ToastMessageState {
  message: string;
  type: string;
  duration: number | null;
  showing: boolean;
}

interface ToastMessagePayload {
  message: string;
  type: string;
  duration?: number;
}

export const initialState = {
  message: '',
  type: '',
  showing: false,
} as ToastMessageState;

const toastMessageSlice = createSlice({
  name: 'toastMessage',
  initialState,
  reducers: {
    showToastMessage(state, action: PayloadAction<ToastMessagePayload>) {
      state.message = action.payload.message;
      state.type = action.payload.type;
      state.duration =
        action.payload.duration !== undefined
          ? action.payload.duration
          : action.payload.type === 'error'
          ? null
          : 3000;
      state.showing = true;
    },
    hideToastMessage(state) {
      state.showing = false;
      state.duration = null;
    },
  },
});

export const { showToastMessage, hideToastMessage } = toastMessageSlice.actions;
export default toastMessageSlice.reducer;
