import React from 'react';

import Page from '../../components/Page';
import Spacer from '../../components/Spacer';
import EDIFilesTable from '../../components/pages/benefits-management/edi-files/EDIFilesTable';

export const EDIFiles: React.FC<{}> = () => {
  return (
    <Page
      breadcrumbs={[
        { href: '/', label: 'Home' },
        { href: '#', label: 'System Administration' },
        { href: '#', label: 'Benefits Management' },
        { href: '/system-admin/benefits-management/edifiles', label: 'EDI Files' },
      ]}
    >
      <Spacer size={15} />
      <EDIFilesTable />
    </Page>
  );
};

export default EDIFiles;
