import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { useDispatch } from 'react-redux';
import { IconButton } from '@material-ui/core';

import apiClient from '../../../../lib/api';
import { hideToastMessage } from '../../../../store/slices/toastMessage';

interface DownloadEDIFileButtonProps {
  uid: string;
}

const DownloadEDIButton = ({ uid }: DownloadEDIFileButtonProps) => {
  const dispatch = useDispatch();

  const downloadEDIFile = async () => {
    dispatch(hideToastMessage());
    await apiClient.benefitsManagement.downloadEDIFile(uid);
  };

  return (
    <IconButton
      title="download"
      aria-label="download"
      color="inherit"
      size="small"
      onClick={downloadEDIFile}
    >
      <CloudDownloadIcon />
    </IconButton>
  );
};

export default DownloadEDIButton;
