import { Skeleton } from '@material-ui/lab';

const PrintRequestLoading = () => {
  return (
    <div data-testid="print-request-loading">
      <Skeleton variant="rect" height={60} style={{ marginBottom: 20 }} />
      <Skeleton variant="rect" height={250} />
    </div>
  );
};

export default PrintRequestLoading;
