export enum ProviderNetworkAssignmentStatus {
  Active = 'active',
  Inactive = 'inactive',
  Pending = 'pending',
}

export enum ProviderNetworkCodes {
  FH = 'FH',
  PHCS = 'PHCS',
  NONE = 'NONE',
  UNKNOWN = 'UNKNOWN'
}
