import User from './user';

export enum SubjectType {
  Organization = 'organization',
  Group = 'group',
  MembershipAccount = 'membership_account',
  MemberProgram = 'member_program',
  MembershipAddress = 'membership_address',
  Member = 'member',
  ProgramEnrollment = 'program_enrollment',
  Person = 'person',
  User = 'user',
}

export interface AuditEvent {
  uid: string;
  category: string;
  action: string;
  result: string;
  description: string;
  actor: User | null;
  subjectId: number | null;
  subjectType: SubjectType | null;
  systemEvent: boolean;
  metadata: any;
  createdAt: Date;
}
