import { Action, Roles, Subject } from '@ccm-innovation/auth-membership-service';
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Toolbar,
  Typography,
} from '@material-ui/core';
import { createStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import {
  AccountTreeOutlined,
  AssessmentOutlined,
  BusinessOutlined,
  ChevronLeft,
  ChevronRight,
  DashboardOutlined,
  Description,
  Error,
  GroupWorkOutlined,
  Payment,
  PeopleAltOutlined,
  Policy,
  Tune,
  WorkOutlined,
} from '@material-ui/icons';
import clsx from 'clsx';
import { Link } from 'react-router-dom';

import { useSession } from '../context/session';

const drawerWidth = 260;

const useStyles = makeStyles(theme =>
  createStyles({
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
    },
    drawerContainer: {
      overflow: 'auto',
    },
    drawerOpen: {
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      width: theme.spacing(7) + 1,
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9) + 1,
      },
    },
    toolbar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    menuListContainer: {
      padding: '1rem 0.5rem',
    },
    menuTitle: {
      marginLeft: '8px',
    },
    menuItem: {
      borderRadius: '4px',
    },
    userContainer: {
      padding: '1rem',
      textAlign: 'center',
    },
    avatarContainer: {
      display: 'flex',
      justifyContent: 'center',
    },
    avatar: {
      width: theme.spacing(7),
      height: theme.spacing(7),
    },
    userDetailsContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignContent: 'center',
      paddingTop: '1rem',
    },
  }),
);

const MiniDrawer = (props: any) => {
  const styles = useStyles();
  const theme = useTheme();
  const { user } = useSession();
  const { isDrawerOpen, onToggleDrawer } = props;

  const isClipped = true;

  const handleDrawerClose = () => {
    onToggleDrawer(false);
  };
  return (
    <Drawer
      variant="permanent"
      className={clsx(styles.drawer, {
        [styles.drawerOpen]: isDrawerOpen,
        [styles.drawerClose]: !isDrawerOpen,
      })}
      classes={{
        paper: clsx({
          [styles.drawerOpen]: isDrawerOpen,
          [styles.drawerClose]: !isDrawerOpen,
        }),
      }}
    >
      {!isClipped && (
        <div className={styles.toolbar}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronRight /> : <ChevronLeft />}
          </IconButton>
        </div>
      )}
      {isClipped && <Toolbar />}
      <div className={styles.drawerContainer}>
        <Divider />
        <Box className={styles.userContainer}>
          <Box className={styles.avatarContainer}>
            <Link to="#">
              <Avatar className={styles.avatar} />
            </Link>
          </Box>
          <Box className={styles.userDetailsContainer}>
            <Typography color="textSecondary">{user?.fullName}</Typography>
            <a href="mailto:">{user?.email}</a>
          </Box>
        </Box>
        <Divider />
        <Box className={styles.menuListContainer}>
          {/* Home */}
          <List>
            {/* Dashboard */}
            <Link to="/dashboard">
              <ListItem className={styles.menuItem} button key="Dashboard">
                <ListItemIcon>
                  <DashboardOutlined />
                </ListItemIcon>
                <ListItemText primary="Dashboard" />
              </ListItem>
            </Link>
          </List>

          {user &&
            (user.can(Action.View, Subject.Organization) ||
              user.can(Action.View, Subject.Group) ||
              user.can(Action.View, Subject.MembershipAccount)) && (
              <List
                subheader={
                  <ListSubheader
                    className={styles.menuTitle}
                    component="div"
                    disableSticky={true}
                    disableGutters={true}
                  >
                    Membership
                  </ListSubheader>
                }
              >
                {/* Organizations */}
                {user.can(Action.View, Subject.Organization) && (
                  <Link to="/membership/organizations">
                    <ListItem className={styles.menuItem} button key="Organzations">
                      <ListItemIcon>
                        <BusinessOutlined />
                      </ListItemIcon>
                      <ListItemText primary="Organizations" />
                    </ListItem>
                  </Link>
                )}

                {/* Groups */}
                {user.can(Action.View, Subject.Group) && (
                  <Link to="/membership/groups">
                    <ListItem className={styles.menuItem} button key="Groups">
                      <ListItemIcon>
                        <GroupWorkOutlined />
                      </ListItemIcon>
                      <ListItemText primary="Groups" />
                    </ListItem>
                  </Link>
                )}

                {/* Membership Accounts */}
                {user.can(Action.View, Subject.MembershipAccount) && (
                  <Link to="/membership/accounts">
                    <ListItem className={styles.menuItem} button key="Membership Accounts">
                      <ListItemIcon>
                        <AccountTreeOutlined />
                      </ListItemIcon>
                      <ListItemText primary="Membership Accounts" />
                    </ListItem>
                  </Link>
                )}
              </List>
            )}

          {user && user.hasRole(Roles.SystemAdmin) && (
            <>
              {/* System Administration */}
              <List
                subheader={
                  <ListSubheader
                    className={styles.menuTitle}
                    component="div"
                    disableSticky={true}
                    disableGutters={true}
                  >
                    System Administration
                  </ListSubheader>
                }
              >
                {/* Users */}
                <Link to="/system-admin/users">
                  <ListItem className={styles.menuItem} button key="Users">
                    <ListItemIcon>
                      <PeopleAltOutlined />
                    </ListItemIcon>
                    <ListItemText primary="User Management" />
                  </ListItem>
                </Link>
                {/* Audits */}
                <Link to="/system-admin/audit">
                  <ListItem className={styles.menuItem} button key="AuditEvents">
                    <ListItemIcon>
                      <AssessmentOutlined />
                    </ListItemIcon>
                    <ListItemText primary="Audit Events" />
                  </ListItem>
                </Link>
                {/* Application Settings */}
                <Link to="/system-admin/app-settings">
                  <ListItem className={styles.menuItem} button key="AppSettings">
                    <ListItemIcon>
                      <Tune />
                    </ListItemIcon>
                    <ListItemText primary="Application Settings" />
                  </ListItem>
                </Link>
              </List>

              {/* Benefits Management */}
              <List
                subheader={
                  <ListSubheader
                    className={styles.menuTitle}
                    component="div"
                    disableSticky={true}
                    disableGutters={true}
                  >
                    Benefits Management
                  </ListSubheader>
                }
              >
                {/* Benefits Management - Transactions */}
                <Link to="/system-admin/benefits-management/transactions">
                  <ListItem className={styles.menuItem} button key="BenefitsManagementTransactions">
                    <ListItemIcon>
                      <Policy />
                    </ListItemIcon>
                    <ListItemText primary="Transactions" />
                  </ListItem>
                </Link>
                {/* Benefits Management - EDI Files */}
                <Link to="/system-admin/benefits-management/ediFiles">
                  <ListItem className={styles.menuItem} button key="BenefitsManagementEDIFiles">
                    <ListItemIcon>
                      <Description />
                    </ListItemIcon>
                    <ListItemText primary="EDI Files" />
                  </ListItem>
                </Link>
              </List>
            </>
          )}

          {user && (user.hasRole(Roles.SystemAdmin) || user.hasRole(Roles.NeedsProcessingUser)) && (
            <>
              {/*Needs Processing */}
              <List
                subheader={
                  <ListSubheader
                    className={styles.menuTitle}
                    component="div"
                    disableSticky={true}
                    disableGutters={true}
                  >
                    Needs Processing
                  </ListSubheader>
                }
              >
                <Link to="/medical-bill-reimbursements">
                  <ListItem className={styles.menuItem} button key="NeedsProcessing">
                    <ListItemIcon>
                      <Payment />
                    </ListItemIcon>
                    <ListItemText primary="Reimbursement Requests" />
                  </ListItem>
                </Link>
              </List>
            </>
          )}

          {user && user.hasRole(Roles.SystemAdmin) && (
            <>
              {/*Program Catalog*/}
              <List
                subheader={
                  <ListSubheader
                    className={styles.menuTitle}
                    component="div"
                    disableSticky={true}
                    disableGutters={true}
                  >
                    Program Catalog
                  </ListSubheader>
                }
              >
                <Link to="/system-admin/program-catalog">
                  <ListItem className={styles.menuItem} button key="ProgramCatalog">
                    <ListItemIcon>
                      <WorkOutlined />
                    </ListItemIcon>
                    <ListItemText primary="Business Lines" />
                  </ListItem>
                </Link>
              </List>

              {/* Logs */}
              <Link to="/system-admin/exception-logs">
                <ListItem className={styles.menuItem} button key="ExceptionLogs">
                  <ListItemIcon>
                    <Error />
                  </ListItemIcon>
                  <ListItemText primary="Exception Logs" />
                </ListItem>
              </Link>
            </>
          )}
        </Box>
      </div>
    </Drawer>
  );
};
export default MiniDrawer;
