import React, { useEffect, useState } from 'react';

import MembershipAccount from '../../../models/membershipAccount';
import Member from '../../../models/member';
import WithdrawalDialog from './WithdrawalDialog';

interface MembershipWithdrawalProps {
  membershipAccount: MembershipAccount;
  closeMemberWithdrawalDialog: Function;
  onDialogClose: Function;
  open?: boolean;
  selectedMember: Member | null;
  setSelectedMember: Function;
}

const MembershipWithdrawal: React.FC<MembershipWithdrawalProps> = ({
  membershipAccount,
  open,
  onDialogClose,
  closeMemberWithdrawalDialog,
  selectedMember,
  setSelectedMember,
}) => {
  const [withdrawalModalOpen, setWithdrawalModalOpen] = useState(false);

  const onModalClose = () => {
    setSelectedMember(null);
    onDialogClose();
    closeMemberWithdrawalDialog();
  };

  useEffect(() => {
    if (open !== undefined) {
      setWithdrawalModalOpen(open);
    }
  }, [open]);

  return (
    <WithdrawalDialog
      membershipAccount={membershipAccount}
      handleCloseDialog={onModalClose}
      open={withdrawalModalOpen}
      selectedMember={selectedMember}
    />
  );
};

export default MembershipWithdrawal;
