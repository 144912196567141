import {
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Theme,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import { AddCircleOutline } from '@material-ui/icons';
import React from 'react';

interface MedicalBillReimbursementMedicalBillNumberInputProps {
  value: string;
  onChange: (medicalBillNumber: string) => void;
  onBlur: () => void;
  onSubmit: () => void;
  errorText: string | undefined;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textField: {
      width: '60ch',
      marginBottom: theme.spacing(1),
    },
  }),
);

const MedicalBillReimbursementMedicalBillNumberInput = ({
  value,
  onChange,
  onBlur,
  onSubmit,
  errorText,
}: MedicalBillReimbursementMedicalBillNumberInputProps) => {
  const inputRef = React.useRef<HTMLInputElement>();

  const handleKeyDown = (key: string) => {
    switch (key) {
      case 'Enter':
        onSubmit();
        break;
      case 'Escape':
        onChange('');
        break;
    }
  };

  const handleSubmit = () => {
    onSubmit();
    inputRef?.current?.focus();
  };

  const styles = useStyles();

  return (
    <FormControl className={styles.textField} variant="outlined">
      <InputLabel htmlFor="newMedicalBillNumber">Enter New Medical Bill Number</InputLabel>
      <OutlinedInput
        id="newMedicalBillNumber"
        name="newMedicalBillNumber"
        type="text"
        value={value}
        onChange={event => onChange(event.target.value)}
        onKeyDown={event => handleKeyDown(event.key)}
        onBlur={onBlur}
        inputRef={inputRef}
        error={!!errorText}
        inputProps={{
          'aria-label': 'Input number',
        }}
        endAdornment={
          <InputAdornment position="end">
            <IconButton aria-label="add" onClick={handleSubmit} edge="end">
              <AddCircleOutline />
            </IconButton>
          </InputAdornment>
        }
        labelWidth={230}
      />
      {!!errorText && (
        <FormHelperText error id="newMedicalBillNumber-error">
          {errorText}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default MedicalBillReimbursementMedicalBillNumberInput;
