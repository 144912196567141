import React, { useEffect, useState } from 'react';
import { makeStyles, ThemeProvider } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { useLocation } from 'react-router-dom';

import Alert from './components/Alert';
import Layout from './components/Layout';
import ClientStorage, { itemTypes } from './lib/clientStorage';
import { LightTheme, DarkTheme } from './theme';
import { RootState } from './store';
import { showToastMessage, hideToastMessage } from './store/slices/toastMessage';
import { updateSessionActivity, logout } from './store/slices/session';

import './styles/globals.css';

/* Routes */
import Routes from './Routes';

const initialDarkMode = ClientStorage.retrieve(itemTypes.DARK_MODE);
let lastActive: Date | null;

const getSessionTimeout = (): number =>
  (process.env.REACT_APP_SESSION_TIMEOUT && parseInt(process.env.REACT_APP_SESSION_TIMEOUT, 10)) ||
  15;

const maxInactivitySeconds = 60 * getSessionTimeout();

const useStyles = makeStyles({
  '@global': {
    '.button-contained-dangerous': {
      backgroundColor: '#f44336',
      color: '#ffffff',
    },
  },
});

function App(): React.ReactElement {
  useStyles();
  const dispatch = useDispatch();
  const location = useLocation();
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [isLoginView, setIsLoginView] = useState(false);
  const [appBarVisible, setAppBarVisible] = useState(false);
  const [leftMenuVisible, setLeftMenuVisible] = useState(false);
  const [currentTheme, setCurrentTheme] = useState(initialDarkMode ? DarkTheme : LightTheme);
  const [lastLocationPath, setLastLocationPath] = useState(location.pathname);
  const session = useSelector((state: RootState) => state.session);
  const toastMessage = useSelector((state: RootState) => state.toastMessage);

  const toggleDarkMode = () => {
    const darkMode = !isDarkMode;
    ClientStorage.store(itemTypes.DARK_MODE, darkMode);

    const theme = darkMode ? DarkTheme : LightTheme;
    setCurrentTheme(theme);
    setIsDarkMode(darkMode);
  };

  const setToastHidden = () => {
    dispatch(hideToastMessage());
  };

  useEffect(() => {
    const darkMode = ClientStorage.retrieve(itemTypes.DARK_MODE);
    setIsDarkMode(darkMode);

    const theme = darkMode ? DarkTheme : LightTheme;
    setCurrentTheme(theme);
  }, []);

  useEffect(() => {
    setAppBarVisible(!!session.user);
    setLeftMenuVisible(!!session.user);
    setIsLoginView(!session.user);
  }, [session.user]);

  // user activity check
  useEffect(() => {
    const currentPath = location.pathname;

    if (currentPath !== lastLocationPath) {
      // location has changed so update activity
      setLastLocationPath(currentPath);
      dispatch(updateSessionActivity());
    } else if (moment(session.lastActive).isSame(moment(lastActive))) {
      // this state change was not due to updating the last activity date/time
      // so dispatch an update
      dispatch(updateSessionActivity());
    } else {
      // set local last active date / time for next render
      lastActive = session.lastActive;
    }
  }, [session, dispatch, location, lastLocationPath]);

  // activity monitor
  useEffect(() => {
    const activityTimer = setInterval(() => {
      const currentDateTime = new Date();
      if (
        session.user &&
        moment(currentDateTime).diff(moment(lastActive), 'seconds') >= maxInactivitySeconds
      ) {
        ClientStorage.store(itemTypes.REDIRECT_URL, location.pathname, true);
        // kill session & force logout
        dispatch(logout());
        dispatch(
          showToastMessage({ message: 'You have been logged out due to inactivity', type: 'info' }),
        );
      }
    }, 1000);

    return () => {
      clearInterval(activityTimer);
    };
  }, [session.user, dispatch, location]);

  return (
    <ThemeProvider theme={currentTheme}>
      <Layout
        isDarkMode={isDarkMode}
        showAppBar={appBarVisible}
        showLeftMenu={leftMenuVisible}
        toggleDarkMode={toggleDarkMode}
        isLoginView={isLoginView}
      >
        <Alert
          open={toastMessage?.showing}
          duration={toastMessage?.duration}
          type={toastMessage?.type}
          onClose={setToastHidden}
        >
          {toastMessage?.message}
        </Alert>
        <Routes />
      </Layout>
    </ThemeProvider>
  );
}

export default App;
