import { combineReducers, configureStore, Action } from '@reduxjs/toolkit';
import { ThunkAction } from 'redux-thunk';

import apiClient from '../lib/api';
import ClientStorage, { itemTypes } from '../lib/clientStorage';
import User from '../models/user';

/* Reducers */
/* prettier-ignore */
import session, { initialState as sessionInitialState } from './slices/session';
import organization, { initialState as organizationInitialState } from './slices/organization';
import organizations, { initialState as organizationsInitialState } from './slices/organizations';
import group, { initialState as groupInitialState } from './slices/group';
import groups, { initialState as groupsInitialState } from './slices/groups';
import user, { initialState as userInitialState } from './slices/user';
import users, { initialState as usersInitialState } from './slices/users';
import toastMessage, { initialState as toastMessageInitialState } from './slices/toastMessage';
import membershipAccounts, { initialState as membershipAccountsInitialState } from './slices/membershipAccounts';
import membershipAccount, { initialState as membershipAccountInitialState } from './slices/membershipAccount';

const reducer = combineReducers({
  session,
  organization,
  organizations,
  group,
  groups,
  user,
  users,
  toastMessage,
  membershipAccounts,
  membershipAccount,
});

const initialState: RootState = {
  session: sessionInitialState,
  organization: organizationInitialState,
  organizations: organizationsInitialState,
  group: groupInitialState,
  groups: groupsInitialState,
  user: userInitialState,
  users: usersInitialState,
  toastMessage: toastMessageInitialState,
  membershipAccounts: membershipAccountsInitialState,
  membershipAccount: membershipAccountInitialState,
};

const getStoredState = (): RootState => {
  const storedUser = ClientStorage.retrieve(itemTypes.USER, true);
  const storedAccessToken = ClientStorage.retrieve(itemTypes.ACCESS_TOKEN, true);
  if (storedUser && storedAccessToken) {
    const user = new User(storedUser);
    return {
      ...initialState,
      session: {
        user,
        accessToken: storedAccessToken,
        lastActive: new Date(),
        loading: false,
        error: null,
      },
    };
  }

  return initialState;
};

const preloadedState = getStoredState();

const getStore = () => {
  const store = configureStore({
    reducer,
    preloadedState,
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({
        thunk: {
          extraArgument: { api: apiClient, ClientStorage },
        },
        serializableCheck: false,
      }),
  });

  return store;
};

const store = getStore();

export type RootState = ReturnType<typeof reducer>;
export type Dispatch = typeof store.dispatch;
export type Thunk = ThunkAction<void, RootState, unknown, Action<string>>;
export default store;
