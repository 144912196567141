import providerNetworksApi from '../api/provider-networks.api.adapter';
import { useDispatch } from 'react-redux';
import { useQueryClient } from 'react-query';
import { hookFactory } from '../hooks/provider-network.hooks';
import { useEffect, useState } from 'react';
import ProviderNetworkAssignmentsTemplate from '../components/ProviderNetworkAssignmentsTemplate';
import { ProviderNetworkAssignment } from '../types/provider-network-assignment';

interface ProviderNetworkAssignmentProps {
  memberProgramUId: string;
}

const ProviderNetworkAssignmentsView = ({ memberProgramUId }: ProviderNetworkAssignmentProps) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const { useProviderNetworkAssignmentsQuery, useAssignProviderNetworkMutation } = hookFactory(
    providerNetworksApi,
    queryClient,
    dispatch,
  );

  const [data, setData] = useState<ProviderNetworkAssignment[] | []>([]);

  // Hooks
  const dataQuery = useProviderNetworkAssignmentsQuery({ uid: memberProgramUId });

  const networkAssigner = useAssignProviderNetworkMutation({ uid: memberProgramUId });

  useEffect(() => {
    if (dataQuery.data) {
      setData(dataQuery.data);
    }
  }, [dataQuery.data]);

  return (
    <ProviderNetworkAssignmentsTemplate
      providerNetworkAssignments={data}
      loading={dataQuery.isLoading || networkAssigner.isLoading}
      assignProviderNetworks={networkAssigner.mutate}
    />
  );
};

export default ProviderNetworkAssignmentsView;
