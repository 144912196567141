import { Role as MemberRole } from '../models/member';
import { Role as ProgramEnrollmentRole } from '../models/programEnrollment';

export const memberRoleFromContactRole = (role: string): MemberRole => {
  switch (role) {
    case 'head_of_household':
    case 'Head of Household':
      return MemberRole.AccountOwner;
    case 'spouse':
    case 'Spouse':
      return MemberRole.Admin;
    default:
      return MemberRole.Member;
  }
};

export const enrollmentRoleFromContactRole = (role: string): ProgramEnrollmentRole | null => {
  switch (role) {
    case 'head_of_household':
    case 'Head of Household':
      return ProgramEnrollmentRole.HeadOfHousehold;
    case 'spouse':
    case 'Spouse':
      return ProgramEnrollmentRole.Spouse;
    case 'child':
    case 'Child':
      return ProgramEnrollmentRole.Child;
    case 'dependent_exception':
    case 'Dependent-Exception':
      return ProgramEnrollmentRole.DependentException;
    default:
      return null;
  }
};
