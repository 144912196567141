import { AxiosInstance } from 'axios';
import { APIClientBase } from '../../../lib/api/apiClientBase';
import client from '../../../lib/api/client';
import { PrintRequest } from '../types/print-request';
import { PrintRequestsApiPort } from './print-requests.api.port';

export class PrintRequestsApi extends APIClientBase implements PrintRequestsApiPort {
  private readonly client: AxiosInstance = client;

  list = async (
    membershipAccountUId: string,
    page: number,
    pageSize: number,
  ): Promise<PrintRequest[]> => {
    try {
      const response = await this.client.get(`/membership/print-requests`, {
        params: { membershipAccountUId, page, pageSize },
      });
      return response.data;
    } catch (err) {
      const error = this.error(err);
      throw error;
    }
  };

  create = async (
    memberProgramUId: string,
    jobType: string,
    quantity: number,
    sendAt: string,
  ): Promise<any> => {
    try {
      const response = await this.client.post('/membership/print-requests', {
        memberProgramUId,
        jobType,
        quantity,
        sendAt,
      });
      return response.data;
    } catch (err: any) {
      const error = this.error(err);
      throw error;
    }
  };

  cancelPrintRequest = async (printRequestUId: string): Promise<void> => {
    await this.client.delete(`/membership/print-requests/${printRequestUId}`);
  };
}

export default new PrintRequestsApi();
