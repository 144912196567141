import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  createStyles,
  makeStyles,
  TextField,
  Typography,
  useTheme,
} from '@material-ui/core';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';

import { authenticateUser } from '../store/slices/session';
import { RootState } from '../store';
import ClientStorage, { itemTypes } from '../lib/clientStorage';

const useStyles = makeStyles(theme =>
  createStyles({
    loginContainer: {
      maxWidth: '600px',
      paddingTop: '80px',
      paddingBottom: '80px',
    },
    logoContainer: {
      maxWidth: '400px',
      margin: 'auto',
      marginBottom: '32px',
      '& img': {
        width: '100%',
      },
    },
    buttonContainer: {
      marginTop: '16px',
    },
  }),
);

const loginFormSchema = yup.object({
  email: yup.string().email('Invalid email address').required('Email is required'),
  password: yup
    .string()
    .min(8, 'Passwords must be at least 8 characters')
    .required('Password is required'),
});

const ccmLogos = {
  white: '/ccm-logo-white.png',
  color: '/ccm-logo.png',
};

const LoginView = (props: any) => {
  const styles = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const theme = useTheme();
  const [formDisabled, setFormDisabled] = useState(false);
  const session = useSelector((state: RootState) => state.session);

  const loginForm = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: loginFormSchema,
    onSubmit: values => {
      dispatch(authenticateUser({ username: values.email, password: values.password }));
    },
  });

  useEffect(() => {
    setFormDisabled(session.loading);
  }, [session]);

  // redirect if user is already logged in
  useEffect(() => {
    if (session.user) {
      const redirectUrl = ClientStorage.retrieve(itemTypes.REDIRECT_URL, true) || '/';
      ClientStorage.clear(itemTypes.REDIRECT_URL, true);
      history.push(redirectUrl);
    }
  }, [session.user, history]);

  if (session.user) {
    return null;
  }

  return (
    <>
      <Container className={styles.loginContainer}>
        <Box className={styles.logoContainer}>
          <img
            src={theme.palette.type === 'dark' ? ccmLogos.white : ccmLogos.color}
            alt="CCM logo"
          />
        </Box>
        <Card>
          <CardContent>
            <Box>
              <Typography variant="h2" color="textPrimary" gutterBottom>
                Member Admin
              </Typography>
              <Typography variant="body2" color="textSecondary" gutterBottom>
                Enter your email address and password to sign in
              </Typography>
            </Box>

            <Box>
              <form noValidate onSubmit={loginForm.handleSubmit}>
                <TextField
                  id="email"
                  name="email"
                  label="Email"
                  margin="normal"
                  fullWidth
                  variant="outlined"
                  autoComplete="none"
                  disabled={formDisabled}
                  value={loginForm.values.email}
                  onChange={loginForm.handleChange}
                  error={loginForm.touched.email && Boolean(loginForm.errors.email)}
                  helperText={loginForm.touched.email && loginForm.errors.email}
                  inputProps={{ maxLength: 100 }}
                ></TextField>

                <TextField
                  id="password"
                  name="password"
                  label="Password"
                  margin="normal"
                  fullWidth
                  variant="outlined"
                  type="password"
                  autoComplete="none"
                  disabled={formDisabled}
                  value={loginForm.values.password}
                  onChange={loginForm.handleChange}
                  error={loginForm.touched.password && Boolean(loginForm.errors.password)}
                  helperText={loginForm.touched.password && loginForm.errors.password}
                  inputProps={{ maxLength: 100 }}
                ></TextField>

                <Box className={styles.buttonContainer}>
                  <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    fullWidth
                    size="large"
                    disabled={formDisabled}
                  >
                    Sign In
                  </Button>
                </Box>
              </form>
            </Box>
          </CardContent>
        </Card>
      </Container>
    </>
  );
};

export default LoginView;
