import React from 'react';

import { useQueryClient, useMutation, useQuery } from 'react-query';
import { useDispatch } from 'react-redux';

import { showToastMessage } from '../../../../store/slices/toastMessage';

import apiClient from '../../../../lib/api';
import Person from '../../../../models/person';
import MembershipAccount from '../../../../models/membershipAccount';
import Dialog from '../../../Dialog';
import { CircularProgress, makeStyles, createStyles, Theme, Button, Box } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { CheckCircle, RemoveCircleOutline, HelpOutline } from '@material-ui/icons';
import StatusBadge from '../../../StatusBadge';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    memberList: {
      listStyleType: 'none',
      padding: 0,
    },
    memberListItem: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'start',
      flexWrap: 'wrap',
      marginBottom: theme.spacing(1),
    },
    progressWrapper: {
      margin: theme.spacing(1),
      position: 'relative',
    },
    progress: {
      color: 'white',
    },
    success: {
      color: theme.textColors.success,
    },
    error: {
      color: theme.textColors.error,
    },
    loader: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
  }),
);
interface CreateBillingRecordsProps {
  membershipAccount: MembershipAccount;
  onDialogClose: Function;
  open: boolean;
}

const MemberStatusListItem = ({
  contactUID,
  dependentNumber,
  name,
  status,
}: {
  contactUID: string;
  dependentNumber: string;
  name: string;
  status: string;
}) => {
  const styles = useStyles();
  return (
    <li className={styles.memberListItem} key={contactUID}>
      {status === 'Ok' && <CheckCircle className={styles.success} />}
      {status === 'Missing' && <RemoveCircleOutline className={styles.error} />}
      {(status === 'Missing from Membership' || status === 'Mismatched Dep. #') && (
        <HelpOutline className={styles.error} />
      )}
      &nbsp;
      <div>
        <strong>{name}</strong>
        <br />
        <StatusBadge label={`ContactUID: ${contactUID}`} />
        &nbsp;
        <StatusBadge label={`Dep. #: ${dependentNumber}`} />
      </div>
      {status === 'Ok' ? (
        <StatusBadge label="Present" variant="success" style={{ marginLeft: 'auto' }} />
      ) : (
        <StatusBadge label={status} variant="danger" style={{ marginLeft: 'auto' }} />
      )}
    </li>
  );
};

const CreateBillingRecords: React.FC<CreateBillingRecordsProps> = ({
  membershipAccount,
  open,
  onDialogClose,
}) => {
  const styles = useStyles();

  const dispatch = useDispatch();

  const queryClient = useQueryClient();

  const getBillingMembers = useQuery(
    ['billingMembers', membershipAccount.uid],
    async () => {
      return await apiClient.membershipAccounts.getBillingMembers(membershipAccount.uid);
    },
    { refetchOnWindowFocus: false, enabled: !!open },
  );

  const createBillingRecords = useMutation(
    (values: { membershipAccountUId: string }) =>
      apiClient.membershipAccounts.createBillingRecords(values.membershipAccountUId),
    {
      onSuccess: () => {
        // get newest data from the membership account
        queryClient.invalidateQueries(['membershipAccount', membershipAccount.uid]);
        onDialogClose();
        dispatch(
          showToastMessage({
            message: 'A new billing account was created',
            type: 'success',
          }),
        );
      },
      onError: () => {
        onDialogClose();
        dispatch(
          showToastMessage({
            message: `An error occurred while creating the billing account.`,
            type: 'error',
          }),
        );
      },
    },
  );

  // Find the members that are not in the billing account, but present in the membership account
  const membersMissingFromBilling = React.useMemo(() => {
    return (membershipAccount.members || []).filter(
      member =>
        !getBillingMembers.data?.find(
          m => String(m.ContactUID) === (member.person as Person).sfContactUId,
        ),
    );
  }, [membershipAccount.members, getBillingMembers.data]);

  // Find members missing from the membership account, but are in the billing account, include duplicates
  const billingMembersMissingFromMembership = React.useMemo(() => {
    const billingMembers = getBillingMembers.data || [];
    const membershipMembers = [...(membershipAccount.members || [])];
    // Loop through each billing member
    return billingMembers.filter(billingMember => {
      // Find a matching membership member with the same contactUid
      const membershipMemberIndex = membershipMembers.findIndex(
        membershipMember =>
          (membershipMember.person as Person).sfContactUId === String(billingMember.ContactUID),
      );
      // If a match is not found, add the billing member to the list of missing members
      if (membershipMemberIndex === -1) return true;
      // If a match is found, no longer consider that membership member for future matches
      membershipMembers.splice(membershipMemberIndex, 1);
      return false;
    });
  }, [getBillingMembers.data, membershipAccount.members]);

  // Create a list of members along with their status
  const membersWithStatus = React.useMemo(() => {
    const billingMembers = getBillingMembers.data || [];
    const members = (membershipAccount.members || []).map(member => {
      // Create a member object with a default status of 'Ok'
      const mappedMember = {
        name: `${(member.person as Person)?.firstName} ${(member.person as Person)?.lastName}`,
        contactUID: (member.person as Person).sfContactUId || '',
        dependentNumber: member.dependentNumber,
        status: 'Ok',
      };
      // Find a matching billing member by sfContactUId and dependentNumber
      const billingMember = billingMembers.find(
        billingMember =>
          String(billingMember.ContactUID) === (member.person as Person).sfContactUId &&
          billingMember.DependentNbr === member.dependentNumber,
      );
      // If a match is not found, set the status to 'Missing'
      if (!billingMember) {
        mappedMember.status = 'Missing';
      }
      // If a match is found, but the billing member is missing a dependent number, set the status to 'Missing Dep. #'
      if (billingMember && billingMember.DependentNbr !== member.dependentNumber) {
        mappedMember.status = 'Mismatched Dep. #';
      }
      return mappedMember;
    });
    // Loop through each billing member, and add any that are not in the membership account
    billingMembersMissingFromMembership.forEach(billingMember => {
      members.push({
        name: `${billingMember.FName} ${billingMember.LName}`,
        contactUID: String(billingMember.ContactUID),
        dependentNumber: billingMember.DependentNbr,
        status: 'Missing from Membership',
      });
    });
    return members;
  }, [membershipAccount.members, getBillingMembers.data, billingMembersMissingFromMembership]);

  // Determine if it is possible to create a billing records
  const shouldCreateBillingRecords = React.useMemo(() => {
    // A utility function to determine if there are gaps in a number sequence
    const hasGaps = (sequence: number[]) =>
      Math.max(...sequence) - Math.min(...sequence) + 1 !== sequence.length;

    // Determine if there are any gaps in the dependent numbers
    const hasGapsIndependentNumbers = hasGaps(
      (membershipAccount.members || []).map(m => Number(m.dependentNumber)),
    );

    // Calculate the number of members with mismatched dependent numbers
    const membersWithDifferentDependentNumber = (membershipAccount.members || []).filter(member => {
      const billingMember = getBillingMembers.data?.find(
        billingMember => billingMember.ContactUID === (member.person as Person).sfContactUId,
      );
      return billingMember && billingMember.DependentNbr !== member.dependentNumber;
    });

    // If there are no members on the membership account, then we can't create a billing account
    if (membershipAccount.members?.length === 0) return false;
    // If there are members missing from membership, but present in the billing account, then we can't create a billing account
    if (billingMembersMissingFromMembership.length > 0) return false;
    // If dependent numbers are not sequential, then we can't create a billing account
    if (hasGapsIndependentNumbers) return false;
    // If there are any mismatches between the membership account and the billing account, on member dependent number, then we can't create a billing account
    if (membersWithDifferentDependentNumber.length > 0) return false;
    // If there are members missing from the billing account, but present in the membership account, then we can create a billing account
    if (membersMissingFromBilling.length === 0) return false;

    return true;
  }, [
    billingMembersMissingFromMembership.length,
    getBillingMembers.data,
    membersMissingFromBilling.length,
    membershipAccount.members,
  ]);

  // Determine if the create billing records button should be disabled
  const createButtonDisabled = React.useMemo(() => {
    // If we have already started to create billing records, disable the button
    if (createBillingRecords.isLoading) return true;
    // If the query is loading, disable the button
    if (getBillingMembers.isLoading) return true;
    // If the query has an error, disable the button
    if (getBillingMembers.isError) return true;

    return !shouldCreateBillingRecords;
  }, [
    createBillingRecords.isLoading,
    getBillingMembers.isLoading,
    getBillingMembers.isError,
    shouldCreateBillingRecords,
  ]);

  const buttons = [
    <Button
      key="create"
      variant="contained"
      onClick={() => createBillingRecords.mutate({ membershipAccountUId: membershipAccount.uid })}
      color="primary"
      autoFocus
      disabled={createButtonDisabled}
    >
      Create Missing Records
    </Button>,

    <Button
      key="cancel"
      onClick={() => onDialogClose()}
      color="default"
      disabled={createBillingRecords.isLoading}
    >
      Cancel
    </Button>,
  ];

  return (
    <>
      <Dialog
        open={open || getBillingMembers.isFetching || createBillingRecords.isLoading}
        title="Confirm Billing Record Creation"
        maxWidth="sm"
        onClose={onDialogClose}
        buttons={buttons}
      >
        {getBillingMembers.isError && (
          <div>
            <p></p>
            <Alert severity="error">
              <AlertTitle>Failure Fetching Billing Records</AlertTitle>
              An error occurred while retrieving billing records. Please try again later.
            </Alert>
          </div>
        )}
        {getBillingMembers.isSuccess && getBillingMembers.data.length > 0 && (
          <>
            {!shouldCreateBillingRecords && billingMembersMissingFromMembership.length > 0 && (
              <Alert severity="warning">
                <AlertTitle>Record Mismatch</AlertTitle>
                Billing records cannot be reconciled automatically. <br />
                Any missing billing records must be created manually.
              </Alert>
            )}

            <ul className={styles.memberList}>
              {membersWithStatus?.map(member => (
                <MemberStatusListItem {...member} />
              ))}
            </ul>
          </>
        )}
        {getBillingMembers.isSuccess && getBillingMembers.data.length === 0 && (
          <>
            <Alert severity="info">
              <AlertTitle>Billing Records Not Found</AlertTitle>
              <p>Missing records can be created. Proceed?</p>
            </Alert>
            <ul className={styles.memberList}>
              {membersWithStatus?.map(member => (
                <MemberStatusListItem {...member} />
              ))}
            </ul>
          </>
        )}
        {getBillingMembers.isFetching ||
          (createBillingRecords.isLoading && (
            <Box
              className={styles.loader}
              display="flex"
              paddingY={10}
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              height="100%"
            >
              <CircularProgress />
              Please wait
            </Box>
          ))}
      </Dialog>
    </>
  );
};

export default CreateBillingRecords;
