import { AxiosRequestConfig } from 'axios';
import Organization from '../../models/organization';
import { APIClientBase } from './apiClientBase';
import apiClient from './client';

export interface OrganizationData {
  default?: boolean;
  uid: string;
  organizationName: string;
  status: string;
  /** Will be present if a logo is being uploaded */
  logoImage?: File;
  /** The URL of the logo that should be deleted */
  logoUrlToDelete?: string;
}

interface OrganizationDeleteLogoData {
  uid: string;
  logoUrl: string;
}

class Organizations extends APIClientBase {
  private static _instance: Organizations;

  static getInstance(): Organizations {
    if (Organizations._instance === undefined) {
      Organizations._instance = new Organizations();
    }
    return Organizations._instance;
  }

  save = async (data: OrganizationData): Promise<Organization> => {
    try {
      const isNew = !data.uid;

      if (!isNew && data.logoUrlToDelete) {
        await this.deleteLogo({
          uid: data.uid,
          logoUrl: data.logoUrlToDelete!,
        });
      }

      const url = !isNew ? `/membership/organizations/${data.uid}` : '/membership/organizations';

      const formData = new FormData();
      if (data.organizationName) {
        formData.append('organizationName', data.organizationName);
      }
      if (data.status) {
        formData.append('status', data.status);
      }
      if (data.logoImage) {
        formData.append('logoImage', data.logoImage);
      }
      if (data.default) {
        formData.append('default', JSON.stringify(data.default));
      }

      const config: AxiosRequestConfig = {
        headers: {
          'content-type': 'multipart/form-data',
        },
      };
      const response = data.uid
        ? await apiClient.put(url, formData, config)
        : await apiClient.post(url, formData, config);
      const res = this.response(response);
      const organization = new Organization(res);
      return organization;
    } catch (err: any) {
      const error = this.error(err);
      throw error;
    }
  };

  deleteLogo = async (data: OrganizationDeleteLogoData): Promise<Organization> => {
    try {
      const url = `/membership/organizations/${data.uid}/deleteLogo`;
      const response = await apiClient.patch(url, {
        logoUrl: data.logoUrl,
      });
      const res = this.response(response);
      const updatedOrganization = new Organization(res);
      return updatedOrganization;
    } catch (err: any) {
      const error = this.error(err);
      throw error;
    }
  };

  get = async (uid: string): Promise<Organization> => {
    try {
      const response = await apiClient.get(`/membership/organizations/${uid}`);
      const data = this.response(response);
      const organization = new Organization(data);
      return organization;
    } catch (err: any) {
      const error = this.error(err);
      throw error;
    }
  };

  list = async (query: any = {}): Promise<Organization[]> => {
    const queryString = this.toQueryString(query);
    try {
      const response = await apiClient.get(`/membership/organizations?${queryString}`);
      const data = this.response(response);
      const organizations: Organization[] = [];
      for (let i = 0; i < data.length; i++) {
        organizations.push(data[i]);
      }
      return organizations;
    } catch (err: any) {
      const error = this.error(err);
      throw error;
    }
  };

  delete = async (uid: string): Promise<boolean> => {
    try {
      await apiClient.delete(`/membership/organizations/${uid}`);
      return true;
    } catch (err: any) {
      const error = this.error(err);
      throw error;
    }
  };
}

export default Organizations.getInstance();
