import SendRoundedIcon from '@material-ui/icons/SendRounded';
import { useDispatch } from 'react-redux';
import { IconButton } from '@material-ui/core';

import apiClient from '../../../../lib/api';
import ConfirmationDialog from '../../../ConfirmationDialog';
import { hideToastMessage, showToastMessage } from '../../../../store/slices/toastMessage';
import { useState } from 'react';

interface SendEDIFileButtonProps {
  uid: string;
}

const SendEDIButton = ({ uid }: SendEDIFileButtonProps) => {
  const [overrideConfirmationDialogOpen, setOverrideConfirmationDialogOpen] = useState(false);
  const [sendFileLoading, setSendFileLoading] = useState(false);
  const dispatch = useDispatch();

  const sendEDIFile = async (overrideSendDisabled = false) => {
    dispatch(hideToastMessage());
    setSendFileLoading(true);

    try {
      const response = await apiClient.benefitsManagement.sendEDIFile(uid, {
        overrideSendDisabled,
      });

      dispatch(showToastMessage({ message: response.message, type: 'success', duration: 5000 }));
      setOverrideConfirmationDialogOpen(false);
      setSendFileLoading(false);
    } catch (err: any) {
      if (err.data && err.data.code && err.data.code === 'SEND_DISABLED') {
        // this means that the request was handled properly but sending EDI via FTP is disabled
        // so provide an option to override
        setOverrideConfirmationDialogOpen(true);
      } else {
        dispatch(showToastMessage({ message: err.message, type: 'error' }));
      }
      setSendFileLoading(false);
    }
  };

  return (
    <>
      <IconButton
        title="send"
        aria-label="send"
        color="inherit"
        size="small"
        onClick={() => sendEDIFile()}
      >
        <SendRoundedIcon />
      </IconButton>
      <ConfirmationDialog
        open={overrideConfirmationDialogOpen}
        loading={sendFileLoading}
        title="Send EDI File"
        message="Sending EDI files to Eldorado is disabled. Override and send the file anyway?"
        confirmText="Yes"
        onConfirm={() => {
          sendEDIFile(true);
        }}
        cancelText="No"
        onCancel={() => setOverrideConfirmationDialogOpen(false)}
      />
    </>
  );
};

export default SendEDIButton;
