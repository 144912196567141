import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory, Redirect } from 'react-router-dom';
import { Box, Button, createStyles, makeStyles, Theme } from '@material-ui/core';
import { Action, Subject } from '@ccm-innovation/auth-membership-service';

import { RootState } from '../../store';
import {
  getOrganization,
  saveOrganization,
  deleteOrganization,
} from '../../store/slices/organization';
import Organization from '../../models/organization';
import Page, { Breadcrumb } from '../../components/Page';
import StatusBadge, { getStatusVariant } from '../../components/StatusBadge';
import Modal from '../../components/Modal';
import OrganizationForm from '../../components/organizations/OrganizationForm';
import DeleteButton from '../../components/DeleteButton';
import { OrganizationData } from '../../lib/api/organizations';
import OrganizationLogo from '../../components/organizations/OrganizationLogo';
import { DefaultOrganizationModal } from '../../components/organizations/OrganizationDefaultModal';
import { useSession } from '../../context/session';

interface Params {
  uid: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    pageTitle: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    logoNameBox: {
      display: 'flex',
      flexDirection: 'row',
    },
    logo: {
      maxHeight: '100%',
      maxWidth: '100%',
      borderRadius: '8px',
      objectFit: 'contain',
      objectPosition: 'center',
    },
    logoBox: {
      height: '175px',
      width: '175px',
      overflow: 'hidden',
      borderRadius: '50%',
      padding: '10px',
      background: '#fff',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    logoPlaceholder: {
      color: '#9699a4',
      height: '6em',
      width: '6em',
    },
  }),
);

const OrganizationView: React.FC = () => {
  const params: Params = useParams();
  const { user } = useSession();
  const dispatch = useDispatch();
  const styles = useStyles();
  const history = useHistory();
  const uid = params.uid;
  const organizationState = useSelector((state: RootState) => state.organization);
  const [organization, setOrganization] = useState(new Organization());
  const [modalOpen, setModalOpen] = useState(false);
  const [defaultModalOpen, setDefaultModalOpen] = useState(false);
  const [formDisabled, setFormDisabled] = useState(false);
  const breadcrumbs: Breadcrumb[] = [
    { href: '/', label: 'Home' },
    { href: '#', label: 'Membership' },
    { href: '/membership/organizations', label: 'Organizations' },
    { label: organization.organizationName || 'Not Found' },
  ];

  const onSaveOrganization = (data: OrganizationData) => {
    setFormDisabled(true);
    dispatch(saveOrganization(data));
  };

  const handleDelete = (organizationUId: string) => {
    setFormDisabled(true);
    dispatch(deleteOrganization(organizationUId));
  };

  useLayoutEffect(() => {
    dispatch(getOrganization(uid));
  }, [dispatch, uid]);

  useEffect(() => {
    if (organizationState.saved) {
      setModalOpen(false);
      setOrganization(organizationState.organization!);
      if (organizationState.organization?.status === 'deleted') {
        history.push('/membership/organizations');
      }
    }
    if (!organizationState.loading) {
      setFormDisabled(false);
    }
    if (organizationState.organization) {
      setOrganization(organizationState.organization!);
    }
  }, [organizationState, history]);

  // check permissions
  if (user && user.cannot(Action.View, Subject.Organization)) {
    return <Redirect to={{ pathname: '/not-found' }} />;
  }

  return (
    <Page
      title={
        <Box className={styles.pageTitle}>
          <Box className={styles.logoNameBox}>
            <Box className={styles.logoBox}>
              <OrganizationLogo
                organization={organization}
                logoStyles={styles.logo}
                logoPlaceholderStyles={styles.logoPlaceholder}
              />
            </Box>
            <div>
              {organization.organizationName}
              <StatusBadge
                label={organization.status}
                variant={organization.status && getStatusVariant(organization.status)}
                marginLeft={2}
              />
              {organization.default ? (
                <StatusBadge label={'Default'} variant={'info'} marginLeft={2} />
              ) : (
                user &&
                user.can(Action.Update, Subject.Organization) && (
                  <StatusBadge
                    asButton
                    label={'Set as Default'}
                    marginLeft={2}
                    onClick={() => {
                      setDefaultModalOpen(true);
                    }}
                  />
                )
              )}
            </div>
          </Box>
          <div>
            {user && user.can(Action.Update, Subject.Organization) && (
              <Button color="primary" variant="contained" onClick={() => setModalOpen(true)}>
                Update Organization
              </Button>
            )}
            {user && user.can(Action.Destroy, Subject.Organization) && (
              <DeleteButton
                buttonText="Delete Organization"
                model="organization"
                handleDelete={() => handleDelete(organization?.uid)}
              />
            )}
          </div>
        </Box>
      }
      breadcrumbs={breadcrumbs}
    >
      <Modal open={modalOpen} title="Update Organization">
        <OrganizationForm
          onSubmit={onSaveOrganization}
          cancel={() => setModalOpen(false)}
          disabled={formDisabled}
          organization={organization}
        />
      </Modal>
      <DefaultOrganizationModal
        open={defaultModalOpen}
        organization={organization}
        showModal={setDefaultModalOpen}
      />
    </Page>
  );
};

export default OrganizationView;
