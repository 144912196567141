import { useState } from 'react';
import { Typography, Box, IconButton, Tooltip } from '@material-ui/core';
import { Refresh } from '@material-ui/icons';
import { useQuery, useQueryClient } from 'react-query';

import PageTable from './PageTable';

export interface PaginationColumnProps {
  width?: number | string;
  name: string;
  as?: string;
  format?: (data: any) => any;
}

interface PaginationTableProps {
  title: string;
  columns: PaginationColumnProps[];
  queryKeys: any[];
  queryFunction: (queryParams?: any) => any;
  formatDetailRowContent?: (data?: any) => any;
  buttons?: React.ReactElement;
  pageSize?: number;
  showDetailRow?: boolean;
  noDataMessage?: string;
  onRowClick?: Function;
}

/**
 * Props:
 * title - the title of the table; can also include components like buttons
 * columns - an object to set the header title, get the data, and format the data
 * queryKeys - the keys to use when fetching the data using useQuery
 * queryFunction - the method to get the data (apiClient)
 * pageSize - the number of rows in the table per page
 */
const PaginationTable: React.FC<PaginationTableProps> = ({
  title,
  columns,
  buttons,
  queryKeys,
  queryFunction,
  pageSize = 25,
  showDetailRow = false,
  formatDetailRowContent,
  noDataMessage,
  onRowClick
}) => {
  const [queryParams, setQueryParams] = useState<any>({
    page: 1,
    pageSize,
    plus1: true,
  });
  const queryClient = useQueryClient();

  const dataFetch = useQuery([...queryKeys, queryParams], () => queryFunction(queryParams), {
    keepPreviousData: true,
  });

  const data: any[] | undefined = dataFetch.data;
  const handlePaging = ({ page, pageSize }: any) => {
    const newQueryParams = {
      ...queryParams,
    };
    if (page) {
      newQueryParams.page = page;
    }
    if (pageSize) {
      newQueryParams.pageSize = pageSize;
    }
    setQueryParams(newQueryParams);
  };

  const handleDataRefresh = () => {
    queryClient.invalidateQueries([...queryKeys, queryParams]);
  };

  return (
    <section>
      {/* Title */}
      <Box display="flex" justifyContent="space-between" alignItems="flex-start" marginBottom={1}>
        <Box display="flex">
          <Box marginRight={2}>
            <Typography variant="h3" style={{ margin: 0 }}>
              {title}
            </Typography>
          </Box>

          <Tooltip title={'refresh'}>
            <IconButton color="default" size="small" onClick={handleDataRefresh}>
              <Refresh />
            </IconButton>
          </Tooltip>
        </Box>
        {buttons}
      </Box>

      <PageTable
        columns={columns}
        data={data}
        showDetailRow={showDetailRow}
        onPage={handlePaging}
        currentPage={queryParams.page || 1}
        pageSize={pageSize}
        formatDetailRowContent={formatDetailRowContent}
        noDataMessage={noDataMessage}
        onRowClick={onRowClick}
      />
    </section>
  );
};

export default PaginationTable;
