import { useState } from 'react';
import { Typography, Box, IconButton, Tooltip } from '@material-ui/core';
import { FilterList, Refresh } from '@material-ui/icons';
import { useQuery, useQueryClient } from 'react-query';

import apiClient from '../../../../lib/api';
import { AuditEventsQueryParams } from '../../../..//lib/api/membershipAccounts';
import AuditEventsTable from './AuditEventsTable';
import { AuditEvent } from '../../../../models/auditEvent';
import AuditEventsFilter from './AuditEventsFilter';

const AuditEvents = (props: any) => {
  const { membershipAccount } = props;
  const [queryParams, setQueryParams] = useState<AuditEventsQueryParams>({
    page: 1,
    pageSize: 15,
    plus1: true,
  });
  const [showFilter, setShowFilter] = useState(false);
  const queryClient = useQueryClient();

  const auditEventsFetch = useQuery(
    ['auditEvents', membershipAccount, queryParams],
    () => apiClient.membershipAccounts.getAuditEvents(membershipAccount, queryParams),
    { keepPreviousData: false, cacheTime: 0, enabled: true },
  );

  const auditEvents: AuditEvent[] | undefined = auditEventsFetch.data;
  const handlePaging = ({ page, pageSize }: AuditEventsQueryParams) => {
    const newQueryParams = {
      ...queryParams,
    };
    if (page) {
      newQueryParams.page = page;
    }
    if (pageSize) {
      newQueryParams.pageSize = pageSize;
    }
    setQueryParams(newQueryParams);
  };

  const handleRefreshEvents = () => {
    queryClient.invalidateQueries(['auditEvents', membershipAccount, queryParams]);
  };

  const filterChange = (newQueryParams: AuditEventsQueryParams) => {
    setQueryParams(newQueryParams);
  };

  return (
    <section>
      {/* Title */}
      <div style={{ display: 'flex' }}>
        <Box marginRight={2}>
          <Typography variant="h3">Audit Events</Typography>
        </Box>
        <Box>
          <Tooltip title={'filter list'}>
            <IconButton color="default" size="small" onClick={() => setShowFilter(!showFilter)}>
              <FilterList />
            </IconButton>
          </Tooltip>
        </Box>
        <Box>
          <Tooltip title={'refresh events'}>
            <IconButton color="default" size="small" onClick={handleRefreshEvents}>
              <Refresh />
            </IconButton>
          </Tooltip>
        </Box>
      </div>

      {/* Audit Events Filter */}
      {showFilter && <AuditEventsFilter queryParams={queryParams} onFilterChange={filterChange} />}

      {/* Audit Events Table */}
      <AuditEventsTable
        auditEvents={auditEvents}
        onPage={handlePaging}
        pageSize={queryParams.pageSize}
        currentPage={queryParams.page || 1}
      />
    </section>
  );
};

export default AuditEvents;
