import React, { useEffect, useState } from 'react';
import { Typography, Grid, Button, Theme, createStyles } from '@material-ui/core';
import { Timeline } from '@material-ui/lab';
import AccountEvent from '../../../../models/accountEvent';
import { TimelineEvent } from './TimelineEvent';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    toggleButton: {
      color: theme.textColors.error,
      borderColor: theme.textColors.error,
    },
  }),
);

interface AccountTimelineProps {
  events: AccountEvent[];
}

const AccountTimeline: React.FC<AccountTimelineProps> = ({ events }) => {
  const MAX_DISPLAYED_EVENTS = 15;
  const [displayedEvents, setDisplayedEvents] = useState<AccountEvent[]>([]);
  const styles = useStyles();

  const hasMoreThanMaxEvents = () => {
    return events.length && events.length > MAX_DISPLAYED_EVENTS;
  };

  const toggleDisplayedEvents = () => {
    if (displayedEvents.length > MAX_DISPLAYED_EVENTS) {
      setDisplayedEvents(events.slice(0, MAX_DISPLAYED_EVENTS));
    } else {
      setDisplayedEvents(events);
    }
  };

  useEffect(() => {
    if (events.length && events.length > MAX_DISPLAYED_EVENTS) {
      setDisplayedEvents(events.slice(0, MAX_DISPLAYED_EVENTS));
    } else {
      setDisplayedEvents(events);
    }
  }, [events]);

  return (
    <Grid container>
      <Grid container item>
        <Grid item xs={6}>
          <Typography variant="h3">Recent Activity</Typography>
        </Grid>
        <Grid container item xs={6} justify="flex-end">
          {hasMoreThanMaxEvents() && (
            <Button
              variant="outlined"
              className={styles.toggleButton}
              onClick={toggleDisplayedEvents}
            >
              {displayedEvents.length === events.length ? 'View Less' : 'View All'}
            </Button>
          )}
        </Grid>
      </Grid>
      <Grid container item>
        <Timeline align="left">
          {displayedEvents.map((evt: AccountEvent, index: number) => (
            <TimelineEvent
              event={evt}
              key={index}
              displayConnector={index + 1 < displayedEvents.length}
              collapsed
            />
          ))}
        </Timeline>
      </Grid>
    </Grid>
  );
};

export default AccountTimeline;
