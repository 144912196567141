import { Dispatch } from 'react';
import { QueryClient, useMutation, useQuery } from 'react-query';
import { showToastMessage } from '../../../store/slices/toastMessage';
import { PrintRequestsApiPort } from '../api/print-requests.api.port';
import { PrintRequest } from '../types/print-request';

export interface QueryParams {
  membershipAccountUId: string;
  page: number;
  pageSize: number;
}

export const hookFactory = (
  printRequestsApi: PrintRequestsApiPort,
  queryClient: QueryClient,
  dispatch: Dispatch<any>,
) => {
  let pendingCancellationUIds: string[] = [];

  return {
    useListPrintRequestsQuery: (variables: { queryParams: QueryParams }) => {
      return useQuery<PrintRequest[]>(['printRequests', { ...variables.queryParams }], async () => {
        let printRequests = await printRequestsApi.list(
          variables.queryParams.membershipAccountUId,
          variables.queryParams.page,
          variables.queryParams.pageSize,
        );

        if (printRequests && printRequests.length > 0) {
          printRequests = printRequests.map(printRequest => ({
            ...printRequest,
            isCancelling: pendingCancellationUIds.includes(printRequest.uid),
          }));
        }

        return printRequests;
      });
    },

    useCancelPrintRequestMutation: () => {
      return useMutation(
        async (variables: { printRequestUId: string; queryParams: QueryParams }) => {
          if (!pendingCancellationUIds.includes(variables.printRequestUId)) {
            pendingCancellationUIds.push(variables.printRequestUId);
          }

          await printRequestsApi.cancelPrintRequest(variables.printRequestUId);
        },
        {
          onSuccess: (data, variables, context: any) => {
            pendingCancellationUIds = pendingCancellationUIds.filter(
              uid => uid !== variables.printRequestUId,
            );

            if (pendingCancellationUIds.length === 0) {
              queryClient.invalidateQueries(['printRequests', { ...variables.queryParams }]);

              dispatch(
                showToastMessage({
                  message: 'The Print Request has been cancelled',
                  type: 'success',
                }),
              );
            }
          },
          onError: (error: any, variables, context: any) => {
            pendingCancellationUIds = pendingCancellationUIds.filter(
              uid => uid !== variables.printRequestUId,
            );

            if (pendingCancellationUIds.length === 0) {
              queryClient.invalidateQueries(['printRequests', { ...variables.queryParams }]);

              dispatch(
                showToastMessage({
                  message: `The Print Request could not be cancelled${
                    error.message ? `. ${error.message}` : ''
                  }`,
                  type: 'error',
                }),
              );
            }
          },
        },
      );
    },
  };
};
