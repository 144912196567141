import { Theme, makeStyles, createStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    userDetailsContainer: {
      display: 'flex',
      justifyContent: 'flex-start',
      marginTop: 20,
    },
    avatarContainer: {
      height: 200,
      width: 200,
      margin: '0 40px 0 20px',
    },
    avatar: {
      height: '100%',
      width: '100%',
    },
    userInfoContainer: {
      height: 200,
      minWidth: 450,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    userNameStatus: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: 16,
      '& > h3': {
        marginBottom: 0,
      },
    },
    userEmail: {
      color: theme.palette.grey[500],
    },
    activateButtonContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    permissionsContainer: {
      display: 'flex',
      justifyContent: 'start',
      flexWrap: 'wrap',
    },
    permissionsCard: {
      width: 300,
      margin: '0 20px 20px 0',
    },
  }),
);
