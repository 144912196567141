import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import moment from 'moment';
import { FC, useState } from 'react';
import { PrintRequest } from '../../types/print-request';
import PrintRequestDetails from '../print-request-details/PrintRequestDetails';
import PrintRequestTableRowCancel from './PrintRequestTableRowCancel';
import PrintRequestTableRowStatus from './PrintRequestTableRowStatus';

interface PrintRequestRowProps {
  printRequest: PrintRequest;
  onCancelRequest: (printRequestUId: string) => void;
}

const PrintRequestTableRow: FC<PrintRequestRowProps> = ({ printRequest, onCancelRequest }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <TableRow>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? (
              <KeyboardArrowUpIcon data-testid={`up-${printRequest.uid}`} />
            ) : (
              <KeyboardArrowDownIcon data-testid={`down-${printRequest.uid}`} />
            )}
          </IconButton>
        </TableCell>
        <TableCell>{moment(printRequest.createdAt).format('MM/DD/YYYY')}</TableCell>
        <TableCell>{moment(printRequest.sendAt).format('MM/DD/YYYY')}</TableCell>
        <TableCell>{printRequest.data.jobType}</TableCell>
        <TableCell>{printRequest.data.programLevel}</TableCell>
        <TableCell>
          <PrintRequestTableRowStatus status={printRequest.status} />
        </TableCell>
        <TableCell>
          <PrintRequestTableRowCancel
            uid={printRequest.uid}
            status={printRequest.status}
            isCancelling={printRequest.isCancelling}
            onCancelRequest={onCancelRequest}
          />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1} data-testid={`details-${printRequest.uid}`}>
              <PrintRequestDetails details={printRequest.data} />
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default PrintRequestTableRow;
