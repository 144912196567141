import { ProviderNetworkAssignment } from '../types/provider-network-assignment';
import {
  TimelineConnector,
  TimelineContent, TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator
} from '@material-ui/lab';
import { Grid, Typography } from '@material-ui/core';
import moment from 'moment/moment';
import StatusBadge, { getStatusVariant } from '../../../components/StatusBadge';
import { removeUnderscoresFromString, toCCMLocalDate } from '../../../lib/util';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

interface ProviderNetworkAssignmentTimelineItemProps {
  providerNetworkAssignment: ProviderNetworkAssignment;
  displayConnector: boolean;
}

const useStyles = makeStyles(theme => ({
  primaryIcon: {
    backgroundColor: theme.palette.primary.main,
    fontSize: 'small',
    color: theme.palette.primary.contrastText,
    fontWeight: 600,
    width: '2.5rem',
    height: '2.5rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const ProviderNetworkAssignmentTimelineItem = ({
  providerNetworkAssignment,
  displayConnector,
}: ProviderNetworkAssignmentTimelineItemProps) => {
  const styles = useStyles();

  const endDateLabel =
    !providerNetworkAssignment.endDate &&
    moment(providerNetworkAssignment.startDate).isAfter(moment())
      ? ''
      : '- Present';

  return (
    <TimelineItem>
      <TimelineSeparator>
        <TimelineDot className={styles.primaryIcon}>
          {providerNetworkAssignment.providerNetwork.code}
        </TimelineDot>
        {displayConnector && <TimelineConnector />}
      </TimelineSeparator>
      <TimelineOppositeContent style={{ display: 'none' }}></TimelineOppositeContent>
      <TimelineContent>
        <Grid container>
          <Grid item xs={8}>
            <Typography variant="subtitle2">
              {moment(toCCMLocalDate(providerNetworkAssignment.startDate)).format('MMMM D, YYYY')}{' '}
              {providerNetworkAssignment.endDate
                ? ' - '.concat(moment(toCCMLocalDate(providerNetworkAssignment.endDate)).format('MMMM D, YYYY'))
                : endDateLabel}
            </Typography>
            <Typography variant="subtitle1">
              {providerNetworkAssignment.providerNetwork.name} (
              {providerNetworkAssignment.providerNetwork.code})
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <StatusBadge
              color="success"
              variant={getStatusVariant(providerNetworkAssignment.status)}
              label={removeUnderscoresFromString(providerNetworkAssignment.status)}
            />
          </Grid>
        </Grid>
      </TimelineContent>
    </TimelineItem>
  );
};

export default ProviderNetworkAssignmentTimelineItem;
