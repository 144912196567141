import { Address } from './address';
import moment from 'moment';
/**
 * Group
 */
export default class Group {
  uid = '';
  organization: any = {};
  groupName = '';
  groupNumber = '';
  underwriter = '';
  underwriterGroup = '';
  settings: any = {};
  status = '';
  default: false;
  addresses: Address[] = [];
  address1: string;
  address2: string;
  city: string;
  state: string;
  postalCode: string;
  countryCode: string;
  started: Date | null;
  ended: Date | null;

  constructor(data: any = {}) {
    this.uid = data.uid;
    this.organization = data.organization;
    this.groupName = data.groupName;
    this.groupNumber = data.groupNumber;
    this.underwriter = data.underwriter;
    this.underwriterGroup = data.underwriterGroup;
    this.settings = data.settings;
    this.status = data.status;
    this.default = data.default;
    this.addresses = data.addresses;
    this.countryCode = 'US';

    const mailingAddress = (data.addresses as Address[])?.find(
      (a: Address) => a.addressType === 'mailing',
    );
    this.address1 = mailingAddress?.address1 || '';
    this.address2 = mailingAddress?.address2 || '';
    this.city = mailingAddress?.city || '';
    this.state = mailingAddress?.state || '';
    this.postalCode = mailingAddress?.postalCode || '';

    this.started = data.started ? moment(data.started, 'YYYY-MM-DD').toDate() : moment().toDate();
    this.ended = data.ended ? moment(data.ended, 'YYYY-MM-DD').toDate() : null;
  }
}
