import React, { useEffect, useState } from 'react';
import Snackbar, { SnackbarOrigin } from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    muiAlert: {
      textAlign: 'center',
      '& > .MuiAlert-icon': {
        alignItems: 'center',
      },
    },
  }),
);
const getAnchorOrigin = (placement?: string): SnackbarOrigin => {
  switch (placement) {
    case 'top-left':
      return {
        vertical: 'top',
        horizontal: 'left',
      };
    case 'top-center':
      return {
        vertical: 'top',
        horizontal: 'center',
      };
    case 'top-right':
      return {
        vertical: 'top',
        horizontal: 'right',
      };
    case 'bottom-left':
      return {
        vertical: 'bottom',
        horizontal: 'left',
      };
    case 'bottom-center':
      return {
        vertical: 'bottom',
        horizontal: 'center',
      };
    case 'bottom-right':
      return {
        vertical: 'bottom',
        horizontal: 'right',
      };
    default:
      return {
        vertical: 'top',
        horizontal: 'center',
      };
  }
};

const formatContent = (children: any) => {
  if (typeof children === 'string') {
    return <div dangerouslySetInnerHTML={{ __html: children.replace(/\r/, '<br>') }} />;
  }

  return children;
};

const Alert = (props: any) => {
  const styles = useStyles();
  const [open, setOpen] = useState(false);
  const anchorOrigin = getAnchorOrigin(props.placement);

  useEffect(() => {
    setOpen(props.open);
  }, [props.open]);

  const handleClose = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
    if (props.onClose) {
      props.onClose();
    }
  };

  const closeButton = (
    <IconButton aria-label="close" color="inherit" size="small" onClick={handleClose}>
      <CloseIcon />
    </IconButton>
  );

  return (
    <Snackbar
      open={open}
      anchorOrigin={anchorOrigin}
      autoHideDuration={props.duration}
      onClose={handleClose}
    >
      <MuiAlert
        className={styles.muiAlert}
        elevation={props.elevation}
        variant={props.variant || 'filled'}
        severity={
          props.type && ['error', 'info', 'success', 'warning'].includes(props.type)
            ? props.type
            : 'info'
        }
        action={closeButton}
      >
        {formatContent(props.children)}
      </MuiAlert>
    </Snackbar>
  );
};

export default Alert;
