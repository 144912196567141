import React, { useMemo } from 'react';
import {
  Button,
  Card,
  CardContent,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Theme,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useFormik } from 'formik';

import { CancellationType, DataValues } from './MembershipCancellation';
import Person from '../../../models/person';
import MemberProgram, {
  CancellationReasonType,
  getCancellationReasonHumanReadable,
} from '../../../models/memberProgram';
import { CancelProgram } from '../../../models/membershipAccount';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';
import ProgramEnrollment from '../../../models/programEnrollment';
import {
  getLatestStartDateFromProgramEnrollments,
  isDateWithRange,
  MAX_CCM_PROGRAM_DATE,
} from '../../../lib/util';

const useStyles = makeStyles((theme: Theme) => ({
  formBody: {
    display: 'flex',
    flexDirection: 'column',
    height: 400,
    '& h3': {
      marginTop: 0,
      fontSize: '1.5rem',
    },
    '& .MuiGrid-container': {
      overflow: 'auto',
    },
  },
  formCancelTypeReasonWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  formProgramsWrapper: {
    '& .MuiPaper-root': {
      padding: '1rem',
      marginBottom: '1rem',
    },
    '& .MuiFormControl-root': {
      width: '100%',
    },
    '& .MuiFormGroup-root': {
      height: 300,
      overflow: 'auto',
      flexWrap: 'inherit',
    },
    '& .MuiFormControlLabel-label': {
      fontSize: '1.25rem',
    },
  },
  formCancellationReasonField: {
    marginTop: '3rem',
  },
  formButtonWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-end',
    '& > :first-child': {
      marginRight: '2rem',
    },
    marginTop: '1rem',
  },
  endDate: {
    marginTop: '3rem',
  },
  cardContent: {
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '&:last-child': {
      paddingBottom: '16px',
    },
    backgroundColor: theme.backgroundColors.body,
  },
  currentProgramWrapper: {
    marginLeft: '12px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  cardWrapper: {
    opacity: 0.8,
    transition: 'all 0.2s ease-in-out',
    '&:hover': {
      opacity: 1,
    },
    borderRadius: 10,
    marginBottom: '10px',
  },
}));

interface MembershipCancellationFormProps {
  activeMemberEnrolledPrograms: CancelProgram[];
  onClose: () => void;
  onContinue: (values: DataValues) => void;
  memberPrograms: MemberProgram[];
  cancellationData: DataValues | null;
  setMemberPrograms: Function;
  programEnrollments: ProgramEnrollment[];
}

const MembershipCancellationForm: React.FC<MembershipCancellationFormProps> = ({
  activeMemberEnrolledPrograms,
  onClose,
  onContinue,
  setMemberPrograms,
  cancellationData,
  memberPrograms,
  programEnrollments,
}) => {
  const styles = useStyles();

  const formik = useFormik<DataValues>({
    initialValues: {
      cancellationType:
        cancellationData && cancellationData.cancellationType
          ? cancellationData.cancellationType
          : memberPrograms.length > 0
          ? CancellationType.MemberProgram
          : null,
      cancelProgram: memberPrograms.map((mp: MemberProgram) => ({
        memberProgram: mp,
        eligibleMembers: mp?.members || [],
      })),
      cancellationReason:
        cancellationData && cancellationData.cancellationReason
          ? cancellationData.cancellationReason
          : CancellationReasonType.NonPayment,
      endDate:
        cancellationData && cancellationData.endDate
          ? cancellationData.endDate
          : programEnrollments.length
          ? getLatestStartDateFromProgramEnrollments(
              programEnrollments.filter(pe => pe.ended === null),
              memberPrograms.length > 0 ? memberPrograms.map(m => m.uid) : [],
            )
          : moment().format('YYYY-MM-DD'),
    },
    onSubmit: values => {
      // if cancelling the membership account, set the `cancelProgram` to all the active Programs
      if (values.cancellationType === CancellationType.MembershipAccount) {
        values.cancelProgram = activeMemberEnrolledPrograms;
      }
      onContinue(values);
    },
  });

  /**
   * Handler to change the state of the checkboxes for the programs selection
   */
  const onCheckProgram = (checked: boolean, value: CancelProgram) => {
    const values = formik.values.cancelProgram;
    if (checked) {
      values.push(value);
      setMemberPrograms([...memberPrograms, value.memberProgram]);
    } else {
      const uid = value.memberProgram.uid;
      values.splice(
        values.findIndex(e => e.memberProgram.uid === uid),
        1,
      );
      setMemberPrograms(memberPrograms.filter(mp => mp.uid !== uid));
    }
    formik.setFieldValue('cancelProgram', values);
  };

  const handleDateChange = (date: Date | null) => {
    formik.setFieldValue('endDate', date);
  };

  /**
   * Get the earliest date to cancel a program or an account
   * If cancelling the account, from all the program enrollments, get the date from the program enrollment who is the newest and is active
   * If cancelling programs, from the program enrollments of the programs selected, get the date from the program enrollment who is the newest and is active
   */
  const earliestCancellationEndDate = useMemo(() => {
    return getLatestStartDateFromProgramEnrollments(
      programEnrollments.filter(pe => pe.ended === null),
      formik.values.cancellationType === CancellationType.MemberProgram
        ? memberPrograms.map(m => m.uid)
        : [],
    );
  }, [programEnrollments, memberPrograms, formik.values.cancellationType]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className={styles.formBody}>
        <Grid container spacing={5}>
          <Grid item xs={12} md={6} className={styles.formCancelTypeReasonWrapper}>
            <FormControl>
              <Typography variant="h6">Cancel</Typography>
              <RadioGroup
                aria-label="cancellationType"
                name="cancellationType"
                value={formik.values.cancellationType}
                onChange={formik.handleChange}
              >
                <FormControlLabel
                  value={CancellationType.MembershipAccount}
                  control={<Radio color="primary" />}
                  label="Membership Account"
                />
                <FormControlLabel
                  value={CancellationType.MemberProgram}
                  control={<Radio color="primary" />}
                  label="Member Program(s)"
                />
              </RadioGroup>
            </FormControl>
            <FormControl variant="outlined" className={styles.formCancellationReasonField}>
              <InputLabel id="cancellationReasonLabel">Reason</InputLabel>
              <Select
                labelId="cancellationReasonLabel"
                id="cancellationReason"
                name="cancellationReason"
                value={formik.values.cancellationReason}
                onChange={formik.handleChange}
                label="Reason"
              >
                <MenuItem value={CancellationReasonType.NonPayment}>
                  {getCancellationReasonHumanReadable(CancellationReasonType.NonPayment)}
                </MenuItem>
                <MenuItem value={CancellationReasonType.MemberRequested}>
                  {getCancellationReasonHumanReadable(CancellationReasonType.MemberRequested)}
                </MenuItem>
                <MenuItem value={CancellationReasonType.Other}>
                  {getCancellationReasonHumanReadable(CancellationReasonType.Other)}
                </MenuItem>
              </Select>
            </FormControl>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                disableToolbar
                value={moment(formik.values.endDate)}
                inputVariant="outlined"
                onChange={handleDateChange}
                format="MM/dd/yyyy"
                label="End Date"
                className={styles.endDate}
                minDate={moment(earliestCancellationEndDate)}
                maxDate={moment(MAX_CCM_PROGRAM_DATE)}
                minDateMessage={`The start date cannot be before ${moment(
                  earliestCancellationEndDate,
                ).format('LL')}`}
                maxDateMessage={`The start date cannot be after ${moment(
                  MAX_CCM_PROGRAM_DATE,
                ).format('LL')}`}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          {formik.values.cancellationType === CancellationType.MemberProgram && (
            <Grid item xs={12} md={6}>
              <Typography variant="h6">Programs</Typography>
              {activeMemberEnrolledPrograms.map(amep => (
                <Card className={styles.cardWrapper} key={amep.memberProgram.uid}>
                  <CardContent className={styles.cardContent}>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        flexGrow: 1,
                      }}
                    >
                      <div style={{ flexGrow: 0, flexShrink: 0, width: '40px' }}>
                        <Checkbox
                          checked={formik.values.cancelProgram.some(
                            cp =>
                              cp &&
                              cp.memberProgram &&
                              cp.memberProgram.uid === amep.memberProgram.uid,
                          )}
                          color="primary"
                          onChange={e => onCheckProgram(e.target.checked, amep)}
                        />
                      </div>
                      <div style={{ flexGrow: 1 }}>
                        <div className={styles.currentProgramWrapper}>
                          <Typography
                            variant="h5"
                            component="div"
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                            }}
                          >
                            <div>
                              <strong>{amep.memberProgram.programLevel.description}</strong>
                            </div>
                          </Typography>
                          <div>
                            <strong>Eligible Members: </strong>
                            {amep.eligibleMembers.map((em, index) => {
                              const person = em.person as Person;
                              return (
                                <span key={index}>
                                  {index > 0 ? ', ' : ''}
                                  {person.firstName} {person.lastName}
                                </span>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </CardContent>
                </Card>
              ))}
            </Grid>
          )}
        </Grid>
      </div>
      <div className={styles.formButtonWrapper}>
        <Button
          type="submit"
          color="primary"
          variant="contained"
          size="large"
          disabled={
            !isDateWithRange(
              formik.values.endDate,
              earliestCancellationEndDate,
              MAX_CCM_PROGRAM_DATE,
            ) ||
            !formik.values.cancellationType ||
            !formik.values.cancellationReason ||
            (formik.values.cancellationType === CancellationType.MemberProgram &&
              formik.values.cancelProgram.length === 0)
          }
        >
          Continue
        </Button>

        <Button color="default" variant="contained" size="large" onClick={onClose}>
          Cancel
        </Button>
      </div>
    </form>
  );
};

export default MembershipCancellationForm;
