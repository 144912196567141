import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Thunk } from '..';
import CustomError from '../../lib/customError';
import User from '../../models/user';
import apiClient from '../../lib/api';
import { showToastMessage } from './toastMessage';

interface UsersState {
  users: User[];
  loading: boolean;
  error: CustomError | null;
}

interface UsersSuccessPayload {
  users: User[];
}

interface UsersErrorPayload {
  error: CustomError;
}

export const initialState = {
  users: [],
  loading: false,
  error: null,
} as UsersState;

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    getUsersRequest(state) {
      state.error = null;
      state.loading = true;
    },
    getUsersSuccess(state, action: PayloadAction<UsersSuccessPayload>) {
      state.users = action.payload.users;
      state.error = null;
      state.loading = false;
    },
    getUsersError(state, action: PayloadAction<UsersErrorPayload>) {
      state.users = [];
      state.error = action.payload.error;
      state.loading = false;
    },
  },
});

export const getUsers = (query?: string): Thunk => async dispatch => {
  try {
    dispatch(getUsersRequest());
    const usersQuery = query ? { name: query } : undefined;
    const users = await apiClient.users.list(usersQuery);
    dispatch(getUsersSuccess({ users }));
  } catch (err: any) {
    dispatch(getUsersError({ error: err }));
    dispatch(showToastMessage({ message: err.message, type: 'error' }));
  }
};

/* Actions & Reducer */
/* prettier-ignore */
export const { 
  getUsersRequest, getUsersSuccess, getUsersError 
} = usersSlice.actions;

export default usersSlice.reducer;
