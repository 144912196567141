import React from 'react';
import './fire.scss';

const Fire = (props: any) => {
  const particles: any = [];
  for (let i = 0; i < 50; i++) {
    particles.push(<div key={`particle${i}`} className="particle"></div>);
  }
  return <div className="fire">{particles}</div>;
};

export const renderFires = (numFlames: number, blockScreen = true) => {
  const fires: any = [];
  const maxWidth = window.innerWidth;
  const maxHeight = window.innerHeight;

  function randomPosition(min: number, max: number) {
    return Math.random() * (max - min) + min;
  }

  for (let i = 0; i < numFlames; i++) {
    fires.push(
      <div
        key={`fire${i}`}
        style={{
          position: 'absolute',
          top: randomPosition(0, maxHeight - 500),
          left: randomPosition(0, maxWidth - 300),
          zIndex: 1000000,
        }}
      >
        <Fire />
      </div>,
    );
  }

  if (blockScreen) {
    return (
      <div
        style={{
          backgroundColor: 'rgba(0,0,0,0.5)',
          position: 'absolute',
          height: '100vh',
          width: '100vw',
          top: 0,
          left: 0,
          zIndex: 1000000,
        }}
      >
        {fires}
      </div>
    );
  }
  return fires;
};

export default Fire;
