import React, { useState } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Paper, InputBase, Box, IconButton } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: '2px 4px',
      display: 'flex',
      alignItems: 'center',
      minHeight: '48px',
    },
    input: {
      marginLeft: theme.spacing(1),
      flex: 1,
    },
    iconButton: {
      padding: 10,
    },
    divider: {
      height: 28,
      margin: 4,
    },
  }),
);

interface SearchInputProps {
  placeholder: string;
  submitOnEnter?: boolean;
  onSubmit?: Function;
  onChange?: Function;
  withIcon?: boolean;
  disabled?: boolean;
  iconButtons?: any[];
}

const SearchInput = ({
  placeholder,
  submitOnEnter,
  onSubmit,
  onChange,
  withIcon,
  disabled,
  iconButtons,
}: SearchInputProps): JSX.Element => {
  const styles = useStyles();
  const [query, setQuery] = useState('');

  const handleOnSubmit = (e: any) => {
    e.preventDefault();
    if (onSubmit) {
      onSubmit(query);
    }
  };

  const handleQueryChange = (e: any) => {
    const val = e.target.value;
    setQuery(val);

    if (onChange) {
      onChange(val);
    }
  };

  return (
    <Paper component="form" className={styles.root}>
      {!onSubmit && withIcon && (
        <Box className={styles.iconButton}>
          <SearchIcon />
        </Box>
      )}
      <InputBase
        className={styles.input}
        placeholder={placeholder}
        inputProps={{ 'aria-label': placeholder }}
        value={query}
        onKeyPress={e => {
          e.key === 'Enter' && e.preventDefault();
          if (e.key === 'Enter' && submitOnEnter && onSubmit) {
            handleOnSubmit(e);
          }
        }}
        onChange={handleQueryChange}
        disabled={disabled}
      />
      {onSubmit && (
        <IconButton
          type="submit"
          className={styles.iconButton}
          aria-label="search"
          onClick={handleOnSubmit}
        >
          <SearchIcon />
        </IconButton>
      )}
      {iconButtons && iconButtons.map(button => button)}
    </Paper>
  );
};

export default SearchInput;
