import moment from 'moment';
import { Roles } from '@ccm-innovation/auth-membership-service';
import { Button } from '@material-ui/core';

import apiClient from '../../../../lib/api';
import { removeUnderscoresFromString } from '../../../../lib/util';
import PaginationTable from '../../../PaginationTable';
import StatusBadge from '../../../../components/StatusBadge';
import { BenefitsManagementTransactionStatus } from '../../../../models/benefitsManagement';
import { useSession } from '../../../../context/session';
import { useDispatch } from 'react-redux';
import { hideToastMessage, showToastMessage } from '../../../../store/slices/toastMessage';

export const getStatusBadgeVariant = (status: string) => {
  if (status === BenefitsManagementTransactionStatus.Completed) {
    return 'success';
  } else if (status === BenefitsManagementTransactionStatus.Pending) {
    return 'info';
  } else if (status === BenefitsManagementTransactionStatus.Processing) {
    return 'warn';
  } else if (status === BenefitsManagementTransactionStatus.Cancelled) {
    return 'danger';
  }

  return 'default';
};

const TransactionTable = (props: any) => {
  const { user } = useSession();
  const dispatch = useDispatch();

  const processTransactions = async () => {
    dispatch(hideToastMessage());
    try {
      await apiClient.benefitsManagement.processTransactions();
      dispatch(
        showToastMessage({
          message: 'Pending transactions have been processed',
          type: 'success',
          duration: 3000,
        }),
      );
    } catch (err: any) {
      dispatch(
        showToastMessage({
          message: err.message || 'Transaction processing failed',
          type: 'error',
        }),
      );
    }
  };
  return (
    <PaginationTable
      title="Benefits Management Transactions"
      buttons={
        <>
          {user && user.hasRole(Roles.SystemAdmin) && (
            <Button color="primary" variant="contained" onClick={processTransactions}>
              Process Transactions
            </Button>
          )}
        </>
      }
      columns={[
        {
          width: 180,
          name: 'createdAt',
          as: 'Date/Time',
          format: createdAt => moment(createdAt).format('YYYY-MM-DD HH:mm:ss'),
        },
        { width: 180, name: 'type', format: type => removeUnderscoresFromString(type) },
        { width: 150, name: 'action', format: action => removeUnderscoresFromString(action) },
        { name: 'subject' },
        {
          width: 150,
          name: 'status',
          format: status => (
            <StatusBadge
              variant={getStatusBadgeVariant(status)}
              label={removeUnderscoresFromString(status)}
            />
          ),
        },
      ]}
      queryKeys={['benefitsManagementTransactions']}
      queryFunction={queryParams => apiClient.benefitsManagement.getAllTransactions(queryParams)}
      pageSize={25}
      showDetailRow={true}
      formatDetailRowContent={rowData => <pre>{JSON.stringify(rowData.data, null, 2)}</pre>}
    />
  );
};

export default TransactionTable;
