import { useState } from 'react';
import {
  Card,
  CardContent,
  Grid,
  Typography,
  makeStyles,
  createStyles,
  Divider,
  Hidden,
  Tooltip,
  Button,
} from '@material-ui/core';
import LaunchIcon from '@material-ui/icons/Launch';
import { useDispatch } from 'react-redux';
import { useQueryClient } from 'react-query';
import { capitalize } from 'lodash';
import Modal from '../../components/Modal';
import AddressForm from '../address/AddressForm';
import { Address, ADDRESS_STATUS, ADDRESS_TYPE } from '../../models/address';
import Member from '../../models/member';
import Person from '../../models/person';
import MembershipAccount from '../../models/membershipAccount';
import { showToastMessage } from '../../store/slices/toastMessage';
import { AddressLabel } from '../address/AddressLabel';
import { useHistory } from 'react-router';

interface AccountContactInfoProps {
  membershipAccount: MembershipAccount;
  membershipAccountUpdated: any;
}

const useStyles = makeStyles(() =>
  createStyles({
    emptyAddress: {
      paddingTop: '10px',
      paddingBottom: '10px',
    },
    titleWrapper: {
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'space-between',
    },
  }),
);

export default function AccountContactInfo({ membershipAccount }: AccountContactInfoProps) {
  const [formAddress, setFormAddress] = useState<Address | null | undefined>(undefined);
  const history = useHistory();

  const styles = useStyles();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const accountOwner = membershipAccount.members?.find(
    (member: Member) => member.role === 'account_owner',
  )?.person as Person;
  const billingAddress = membershipAccount.addresses?.find(
    (address: Address) =>
      address.addressType === ADDRESS_TYPE.BILLING && address.status === ADDRESS_STATUS.ACTIVE,
  );
  const mailingAddress = membershipAccount.addresses?.find(
    (address: Address) =>
      address.addressType === ADDRESS_TYPE.MAILING && address.status === ADDRESS_STATUS.ACTIVE,
  );
  const homeAddress = membershipAccount.addresses?.find(
    (address: Address) =>
      address.addressType === ADDRESS_TYPE.HOME && address.status === ADDRESS_STATUS.ACTIVE,
  );

  const onAddressSaved = () => {
    setFormAddress(undefined);
    dispatch(showToastMessage({ message: 'Address saved', type: 'success' }));
    queryClient.invalidateQueries(['membershipAccount', membershipAccount.uid]);
  };

  const openModalAddress = (address: Address | null | undefined, addressType: string) => {
    if (address) {
      setFormAddress(address);
    } else {
      const newAddress: Address = {
        addressType,
        address1: '',
        address2: '',
        city: '',
        state: '',
        postalCode: '',
        status: ADDRESS_STATUS.ACTIVE,
      };
      setFormAddress(newAddress);
    }
  };

  const handleGroupClick = (uid: string) => {
    history.push(`/membership/groups/${uid}`);
  };

  const formModalTitle = `${formAddress?.address1 ? 'Update' : 'Add'} ${
    formAddress?.addressType ? capitalize(formAddress.addressType) : ''
  } Address`;

  return (
    <>
      <Card>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item lg={4} xs={12}>
              <div className={styles.titleWrapper}>
                <Typography variant="h6">Billing Address</Typography>
                {false && (
                  <Button
                    color="primary"
                    variant="contained"
                    size="small"
                    onClick={() => openModalAddress(billingAddress, ADDRESS_TYPE.BILLING)}
                  >
                    {billingAddress ? 'Edit' : 'Add'}
                  </Button>
                )}
              </div>
              <AddressLabel emptyAddressStyles={styles.emptyAddress} address={billingAddress} />
            </Grid>
            <Hidden lgUp>
              <Grid item xs={12}>
                <Divider />
              </Grid>
            </Hidden>
            <Grid item lg={4} xs={12}>
              <div className={styles.titleWrapper}>
                <Typography variant="h6">Home Address</Typography>
                {false && (
                  <Button
                    color="primary"
                    variant="contained"
                    size="small"
                    onClick={() => openModalAddress(homeAddress, ADDRESS_TYPE.HOME)}
                  >
                    {homeAddress ? 'Edit' : 'Add'}
                  </Button>
                )}
              </div>
              <AddressLabel emptyAddressStyles={styles.emptyAddress} address={homeAddress} />
            </Grid>
            <Hidden lgUp>
              <Grid item xs={12}>
                <Divider />
              </Grid>
            </Hidden>
            <Grid item lg={4} xs={12}>
              <div className={styles.titleWrapper}>
                <Typography variant="h6">Mailing Address</Typography>
                {false && (
                  <Button
                    color="primary"
                    variant="contained"
                    size="small"
                    onClick={() => openModalAddress(mailingAddress, ADDRESS_TYPE.MAILING)}
                  >
                    {mailingAddress ? 'Edit' : 'Add'}
                  </Button>
                )}
              </div>
              <AddressLabel emptyAddressStyles={styles.emptyAddress} address={mailingAddress} />
            </Grid>

            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item lg={4} xs={12}>
              <Typography variant="h6">Primary Phone</Typography>
              <Typography variant="body1">
                <span>{accountOwner?.primaryPhone || '- none -'}</span>
              </Typography>
            </Grid>
            <Hidden lgUp>
              <Grid item xs={12}>
                <Divider />
              </Grid>
            </Hidden>
            <Grid item lg={8} xs={12}>
              <Typography variant="h6">Primary Email</Typography>
              <Tooltip title={accountOwner?.email || '- none -'}>
                <Typography variant="body1" noWrap>
                  <a href={`mailto:${accountOwner?.email}`}>{accountOwner?.email || '- none -'}</a>
                </Typography>
              </Tooltip>
            </Grid>

            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Hidden lgUp>
              <Grid item xs={12}>
                <Divider />
              </Grid>
            </Hidden>
            <Grid item lg={4} xs={12}>
              <div className={styles.titleWrapper}>
                <Typography variant="h6">
                  Group Name
                  {membershipAccount.group && (
                    <>
                      {' '}
                      <LaunchIcon
                        style={{ fontSize: '0.9rem', marginLeft: 10, cursor: 'pointer' }}
                        onClick={() => handleGroupClick(membershipAccount.group!.uid)}
                      />
                    </>
                  )}
                </Typography>
              </div>
              <Typography variant="body1">
                {membershipAccount.group
                  ? `${membershipAccount.group.groupName} - ${membershipAccount.group.underwriter}${membershipAccount.group.underwriterGroup}`
                  : '- none -'}
              </Typography>
            </Grid>
            {membershipAccount.settings.bankAccounts[0] && (
              <Grid item lg={8} xs={12}>
                <Typography variant="h6">Sharing Account #</Typography>
                <Typography variant="body1">
                  {membershipAccount.settings.bankAccounts[0].accountNumber}
                </Typography>
              </Grid>
            )}
          </Grid>
        </CardContent>
      </Card>

      <Modal open={Boolean(formAddress)} title={formModalTitle} style={{ width: 800 }}>
        <AddressForm
          address={formAddress}
          addressAssociationForeignKey={{ membershipAccount: membershipAccount.uid }}
          onCancel={() => setFormAddress(undefined)}
          onAddressSaved={onAddressSaved}
        />
      </Modal>
    </>
  );
}
