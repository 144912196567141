import { ProgramMember } from '../../components/membership/account/program/update';
import EligibleProgram from '../../models/eligibleProgram';
import MembershipAccount from '../../models/membershipAccount';
import { ProgramLevel } from '../../models/programLevel';
import { ProgramOption } from '../../models/programOption';
import { APIClientBase } from './apiClientBase';
import apiClient from './client';

export interface ProgramChangePayload {
  memberProgram: string;
  selectedPrograms: EligibleProgram[];
  startDate: string;
  allowFail?: boolean;
}

export interface ProgramUpdatePayload {
  uid: string;
  programLevel: ProgramLevel;
  programOptions?: ProgramOption[];
  members: ProgramMember[];
  automate: boolean;
}

class MemberPrograms extends APIClientBase {
  private static _instance: MemberPrograms;

  static getInstance(): MemberPrograms {
    if (MemberPrograms._instance === undefined) {
      MemberPrograms._instance = new MemberPrograms();
    }
    return MemberPrograms._instance;
  }

  changeProgramEnrollments = async (
    programChangePayload: ProgramChangePayload,
  ): Promise<{
    membershipAccount: MembershipAccount;
    errors?: any;
    changeProgramResponse?: any;
  }> => {
    try {
      const url = `/membership/memberPrograms/enrollments`;
      const response = await apiClient.post(url, programChangePayload);
      return this.response(response);
    } catch (err: any) {
      const error = this.error(err);
      throw error;
    }
  };

  prices = async (
    membershipAccountUId: string,
    startDate: string,
    memberProgram?: string,
    selectedPrograms?: EligibleProgram[],
  ): Promise<{ programs: EligibleProgram[]; selectedPrograms: EligibleProgram[] }> => {
    try {
      const response = await apiClient.post(
        `/membership/memberPrograms/prices/${membershipAccountUId}`,
        { startDate, memberProgram, selectedPrograms },
      );
      return this.response(response);
    } catch (err: any) {
      const error = this.error(err);
      throw error;
    }
  };

  cancelProgram = async (
    memberProgramUids: string[],
    reason: string,
    endDate: string,
  ): Promise<boolean> => {
    try {
      await apiClient.patch(`/membership/memberPrograms/cancellation`, {
        uids: memberProgramUids,
        reason,
        endDate,
      });
      return true;
    } catch (err: any) {
      const error = this.error(err);
      throw error;
    }
  };

  reactivateMemberPrograms = async (payload: {
    programEnrollments: string[];
    memberProgram: string;
  }): Promise<boolean> => {
    try {
      await apiClient.post(`/membership/memberPrograms/reactivate`, payload);
      return true;
    } catch (err: any) {
      const error = this.error(err);
      throw error;
    }
  };

  listProgramLevels = async (programNames: string[] = []): Promise<ProgramLevel[]> => {
    const query = programNames.length ? `?programNames=${programNames.join(',')}` : '';
    try {
      const response = await apiClient.get(`/membership/memberPrograms/programLevels${query}`);
      return this.response(response);
    } catch (err: any) {
      const error = this.error(err);
      throw error;
    }
  };

  listProgramAvailabilities = async (programId: number | undefined): Promise<any> => {
    try {
      const response = await apiClient.get(`/program-availabilities/${programId || ''}`);
      return this.response(response);
    } catch (err: any) {
      const error = this.error(err);
      throw error;
    }
  };

  createMemberProgram = async (
    membershipAccountUid: string,
    programLevelId: number,
    programOptionIds: number[],
    startDate: string,
  ): Promise<any> => {
    try {
      const response = await apiClient.post('/membership/memberPrograms', {
        membershipAccountUid,
        programLevelId,
        programOptionIds,
        startDate,
      });
      return this.response(response);
    } catch (err: any) {
      const error = this.error(err);
      throw error;
    }
  };

  updateMemberProgram = async (payload: ProgramUpdatePayload): Promise<any> => {
    try {
      const response = await apiClient.patch(`/membership/memberPrograms/${payload.uid}`, payload);
      return this.response(response);
    } catch (err: any) {
      const error = this.error(err);
      throw error;
    }
  };

  programPrices = async (
    tier: number,
    oldestDOB: string,
    zipCode: string,
    state: string,
    maritalStatus: string,
    timeAt: string = '',
    allPrograms: boolean = true,
    withIds: boolean = true,
  ) => {
    try {
      const response = await apiClient.get(
        `/prices/medishare?tier=${tier}&oldestDateOfBirth=${oldestDOB}&zipCode=${zipCode}&state=${state}&maritalStatus=${maritalStatus}&timeAt=${timeAt}&allPrograms=${allPrograms}&withIds=${withIds}`,
      );
      return this.response(response);
    } catch (err: any) {
      const error = this.error(err);
      throw error;
    }
  };

  deleteProgramEnrollment = async (
    uid: string,
  ): Promise<{
    membershipAccount: MembershipAccount;
    errors?: any;
    changeProgramResponse?: any;
  }> => {
    try {
      const url = `/membership/memberPrograms/enrollment/${uid}`;
      const response = await apiClient.delete(url);
      return this.response(response);
    } catch (err: any) {
      const error = this.error(err);
      throw error;
    }
  };

  deleteProgram = async (
    uid: string,
  ): Promise<{
    membershipAccount: MembershipAccount;
    errors?: any;
    changeProgramResponse?: any;
  }> => {
    try {
      const url = `/membership/memberPrograms/${uid}`;
      const response = await apiClient.delete(url);
      return this.response(response);
    } catch (err: any) {
      const error = this.error(err);
      throw error;
    }
  };
}

export default MemberPrograms.getInstance();
