const printRequestJobTypes = [
  {
    name: 'Welcome Packet',
    value: 'welcome_packet',
  },
  {
    name: 'Membership Cards',
    value: 'membership_cards',
  },
];

export default printRequestJobTypes;
