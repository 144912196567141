import React from 'react';
import { CircularProgress, makeStyles, Theme, createStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    progress: {
      color: theme.textColors.default,
    },
  }),
);

const ButtonProgressIndicator = (props: any) => {
  const styles = useStyles();
  const { size } = props;
  return <CircularProgress size={size || 27} className={styles.progress} />;
};

export default ButtonProgressIndicator;
