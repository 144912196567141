import React from 'react';

import Page from '../../components/Page';
import Spacer from '../../components/Spacer';
import TransactionsTable from '../../components/pages/benefits-management/transactions/TransactionsTable';

export const Transactions: React.FC<{}> = () => {
  return (
    <Page
      breadcrumbs={[
        { href: '/', label: 'Home' },
        { href: '#', label: 'System Administration' },
        { href: '#', label: 'Benefits Management' },
        { href: '/system-admin/benefits-management/transactions', label: 'Transactions' },
      ]}
    >
      <Spacer size={15} />
      <TransactionsTable />
    </Page>
  );
};

export default Transactions;
