import { CircularProgress } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import { grey } from '@material-ui/core/colors';
import CancelIcon from '@material-ui/icons/Cancel';
import { FC } from 'react';
import { PrintRequestStatus } from '../../print-request.constants';

interface PrintRequestTableRowCancelProps {
  uid: string;
  status: string;
  isCancelling: boolean;
  onCancelRequest: (printRequestUId: string) => void;
}

const PrintRequestTableRowCancel: FC<PrintRequestTableRowCancelProps> = ({
  uid,
  status,
  isCancelling,
  onCancelRequest,
}) => {
  const shouldShowCancelButton = status === PrintRequestStatus.PENDING && !isCancelling;
  const shouldShowCancelling = status === PrintRequestStatus.PENDING && isCancelling;

  return (
    <div style={{ width: '20px' }}>
      {shouldShowCancelButton && (
        <Tooltip title="Cancel Request" aria-label="cancel request">
          <CancelIcon
            fontSize="small"
            style={{ color: grey[500], cursor: 'pointer' }}
            onClick={() => onCancelRequest(uid)}
            data-testid={`cancel-${uid}`}
          />
        </Tooltip>
      )}
      {shouldShowCancelling && (
        <Tooltip title="Cancelling Request..." aria-label="cancelling">
          <CircularProgress size={20} />
        </Tooltip>
      )}
    </div>
  );
};

export default PrintRequestTableRowCancel;
