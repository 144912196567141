import apiClient from './client';
import { APIClientBase } from './apiClientBase';
import ExceptionLog from '../../models/exceptionLog';

export enum ExceptionLogNavType {
  first = 'first',
  previous = 'previous',
  next = 'next',
  last = 'last',
}

export interface ExceptionLogQueryParams {
  logLevel?: string;
  pageSize: number;
  nav: ExceptionLogNavType;
  sort?: string;
  createdAt?: string;
  id?: number;
  endDate?: string;
  startDate?: string;
  membershipAccountId?: string;
  sfAccountId?: string;
}

class ExceptionLogs extends APIClientBase {
  private static _instance: ExceptionLogs;

  static getInstance(): ExceptionLogs {
    if (ExceptionLogs._instance === undefined) {
      ExceptionLogs._instance = new ExceptionLogs();
    }
    return ExceptionLogs._instance;
  }

  list = async (query: any): Promise<ExceptionLog[]> => {
    try {
      const queryString = this.toQueryString(query);
      const response = await apiClient.get(`/membership/logs?${queryString}`);
      const data = this.response(response);
      const logs: ExceptionLog[] = [];
      for (let i = 0; i < data.length; i++) {
        logs.push(new ExceptionLog(data[i]));
      }
      return logs;
    } catch (err: any) {
      const error = this.error(err);
      throw error;
    }
  };
}

export default ExceptionLogs.getInstance();
