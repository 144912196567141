import { FC } from 'react';
import { Typography } from '@material-ui/core';
import { hyphenString } from '../../../../lib/util';

interface PrintRequestDetailsTextProps {
  header: string;
  text: string;
}

const PrintRequestDetailsText: FC<PrintRequestDetailsTextProps> = ({ header, text }) => {
  return (
    <>
      <Typography variant="h6" data-testid={hyphenString(header)}>
        {header.toUpperCase()}
      </Typography>
      <Typography variant="body1">{text}</Typography>
    </>
  );
};

export default PrintRequestDetailsText;
