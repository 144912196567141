import Member from './member';
import ProgramEnrollment from './programEnrollment';
import { ProgramLevel } from './programLevel';
import { ProgramOption } from './programOption';

export enum MemberProgramStatus {
  Pending = 'pending',
  Active = 'active',
  OnHold = 'on_hold',
  Inactive = 'inactive',
  Cancelled = 'cancelled',
  Deleted = 'deleted',
}

export interface Fee {
  name: string;
  amount: number;
}

export enum CancellationReasonType {
  NonPayment = 'non_payment',
  MemberRequested = 'member_requested',
  Other = 'other',
}

export const getCancellationReasonHumanReadable = (reason: CancellationReasonType) => {
  switch (reason) {
    case CancellationReasonType.NonPayment:
      return 'non payment';
    case CancellationReasonType.MemberRequested:
      return 'member requested';
    case CancellationReasonType.Other:
      return 'other';
    default:
      return 'none';
  }
};

export interface InvoiceItem {
  occurrence: string;
  id: string;
  description: string;
  createdDT: Date;
  amount: number;
}

export default interface MemberProgram {
  uid: string;
  programLevel: ProgramLevel;
  programOptions?: ProgramOption[];
  basePrice: number;
  pricingFactor: number;
  currentPrice: number;
  nextPriceAdjustment?: number;
  started: string;
  ended?: string;
  status: MemberProgramStatus;
  members?: Member[];
  programEnrollments?: ProgramEnrollment[];
  fms: {
    required: boolean;
    monthlySharePrice: number;
    fees: Fee[];
    invoiceItems: InvoiceItem[];
  };
  cancelledReason?: string;
  isSeniorAssist: boolean;
  isSeniorProgram: boolean;
  archived?: boolean;
}

export const isSeniorProgram = (name: string) => {
  return isSeniorAssist(name) || isMS65(name);
};

export const isMediShareCompleteProgram = (name: string) => {
  return isMS3(name) || isMS2(name);
};

export const isMS65 = (name: string) => {
  const programMatch = ['MS65'].includes(name);
  const programLevelMatch = ['MS65-500'].includes(name);
  return programMatch || programLevelMatch;
};

export const isSeniorAssist = (name: string) => {
  const programMatch = ['SA'].includes(name);
  const programLevelMatch = ['SA 1250'].includes(name);
  return programMatch || programLevelMatch;
};

export const isMediShareValue = (name: string) => {
  const programMatch = ['MV1'].includes(name);
  const programLevelMatch = ['MV1-1K', 'MV1-500'].includes(name);
  return programMatch || programLevelMatch;
};

export const isCoShare = (name: string) => {
  const programMatch = ['CS1'].includes(name);
  const programLevelMatch = ['CS1-3K', 'CS1-6K', 'CS1-9K', 'CS1-12K'].includes(name);
  return programMatch || programLevelMatch;
};

export const isMS2 = (name: string) => {
  const programMatch = ['M2'].includes(name);
  const programLevelMatch = [
    'AHP 1000',
    'AHP 1750',
    'AHP 3000',
    'AHP 4250',
    'AHP 5500',
    'AHP 8000',
    'AHP 10500',
  ].includes(name);
  return programMatch || programLevelMatch;
};

export const isMS3 = (name: string) => {
  const programMatch = ['MS3'].includes(name);
  const programLevelMatch = ['MS3-3K', 'MS3-6K', 'MS3-9K', 'MS3-12K'].includes(name);
  return programMatch || programLevelMatch;
};
