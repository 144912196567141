import { TablePagination } from '@material-ui/core';
import { FC } from 'react';

const renderPaginationLabel = (
  from: number,
  to: number,
  pageSize: number,
  rowCount: number,
): string => {
  if (from === to) {
    return `${from - 1} - ${to - 1}`;
  } else if (rowCount > pageSize) {
    return `${from} - ${to}`;
  } else {
    return `${from} - ${from + rowCount - 1}`;
  }
};

const renderCount = (rowsReturned: number, rowsPerPage: number, pageIndex: number): number => {
  return rowsReturned > rowsPerPage ? -1 : rowsPerPage * pageIndex + rowsReturned + 1;
};

interface PrintRequestFooterProps {
  page: number;
  pageSize: number;
  printRequestLength: number;
  handlePageChange: (_: any, pageIndex: number) => void;
}

const PrintRequestFooter: FC<PrintRequestFooterProps> = ({
  page,
  pageSize,
  printRequestLength,
  handlePageChange,
}) => {
  return (
    <TablePagination
      component="div"
      rowsPerPageOptions={[pageSize]}
      count={renderCount(printRequestLength, pageSize, page - 1)}
      labelDisplayedRows={({ from, to }) =>
        renderPaginationLabel(from, to, pageSize, printRequestLength)
      }
      rowsPerPage={pageSize}
      page={page - 1}
      onChangePage={handlePageChange}
      data-testid="print-request-footer"
    />
  );
};

export default PrintRequestFooter;
