import Spacer from '../../../components/Spacer';
import React from 'react';
import Page from '../../../components/Page';
import MedicalBillReimbursementsTable from '../components/MedicalBillReimbursementsTable';
import { Redirect } from 'react-router-dom';
import { useSession } from '../../../context/session';
import { Action, Subject } from '@ccm-innovation/auth-membership-service';
import { Box, Typography } from '@material-ui/core';

const MedicalBillReimbursements: React.FC = () => {
  const { user } = useSession();

  if (user && user.cannot(Action.View, Subject.ReimbursementRequest)) {
    return <Redirect to={{ pathname: '/not-found' }} />;
  }

  return (
    <Page
      title={
        <Box>
          <Typography variant="h2">Reimbursement Requests</Typography>
        </Box>
      }
      breadcrumbs={[
        { href: '/', label: 'Home' },
        { href: '#', label: 'Needs Processing' },
        { href: '/medical-bill-reimbursements', label: 'Reimbursement Requests' },
      ]}
    >
      <Spacer size={15} />
      <MedicalBillReimbursementsTable />
    </Page>
  );
};

export default MedicalBillReimbursements;
