import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import store from './store';
import * as apiClient from './lib/api/client';
import { SessionProvider } from './context/session';
import { configureGuardedServices } from '@ccm-innovation/auth';
import MembershipService from '@ccm-innovation/auth-membership-service';

apiClient.setStore(store);

configureGuardedServices([MembershipService]);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

/**
 * Note:
 * <React.StrictMode> is commented out due to an issue with using state to control
 * theme (dark/light mode). This is only an issue in development mode. If strict mode
 * is desired, then this can be re-enabled with the caveat that theme switching will not
 * work during development. This is most likely an issue with the Material UI framework.
 */
ReactDOM.render(
  // <React.StrictMode>
  <Provider store={store}>
    <SessionProvider>
      <BrowserRouter>
        <QueryClientProvider client={queryClient}>
          <App />
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </BrowserRouter>
    </SessionProvider>
  </Provider>,
  // </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
