import {
  Box,
  Button,
  Divider,
  Theme,
  Typography,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import ButtonProgressIndicator from '../../../components/ButtonProgressIndicator';
import Page, { Breadcrumb } from '../../../components/Page';
import StatusBadge from '../../../components/StatusBadge';
import { MedicalBillReimbursementStatus } from '../medical-bill-reimbursement.constants';
import { MedicalBillReimbursementWithDetailsResponseDto } from '../types/medical-bill-reimbursement-with-details.response.dto';
import MedicalBillReimbursement from './MedicalBillReimbursement';

export interface MedicalBillReimbursementTemplateProps {
  isDataLoading: boolean;
  shouldShowAdminCloseButton: boolean;
  isSaving: boolean;
  isClosingAdminCase: boolean;
  breadcrumbs: Breadcrumb[];
  isEditMode: boolean;
  isModified: boolean;
  data?: MedicalBillReimbursementWithDetailsResponseDto;
  adminCaseId: string | undefined;
  files?: Array<{ fileName: string }>;
  onCloseAdminCase: () => void;
  onSave: () => void;
  onUnlock: () => void;
  onCancel: () => void;
  onStatusChange: (status: MedicalBillReimbursementStatus) => void;
  onMedicalBillNumbersChange: (medicalBillNumbers: string[]) => void;
  onAttachmentClick: (fileId: string) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    pageTitle: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    firstActionButton: {
      marginTop: theme.spacing(0),
      marginBottom: theme.spacing(0),
      marginLeft: theme.spacing(0),
      marginRight: theme.spacing(1),
    },
    middleActionButton: {
      marginTop: theme.spacing(0),
      marginBottom: theme.spacing(0),
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    lastActionButton: {
      marginTop: theme.spacing(0),
      marginBottom: theme.spacing(0),
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(0),
    },
    spacer: { height: '1em', width: theme.spacing(1) },
    buttonProgress: {
      color: 'white',
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
  }),
);

const MedicalBillReimbursementTemplate = ({
  isDataLoading,
  shouldShowAdminCloseButton,
  isSaving,
  isClosingAdminCase,
  breadcrumbs,
  isEditMode,
  isModified,
  data,
  adminCaseId,
  files,
  onCloseAdminCase,
  onSave,
  onUnlock,
  onCancel,
  onStatusChange,
  onMedicalBillNumbersChange,
  onAttachmentClick,
}: MedicalBillReimbursementTemplateProps) => {
  const styles = useStyles();

  return (
    <Page
      title={
        <Box className={styles.pageTitle}>
          <div style={{ minWidth: 300, display: 'flex' }}>
            <Typography style={{ marginBottom: 0, marginRight: 10 }} variant="h1">
              {isDataLoading ? (
                <Skeleton animation="wave" />
              ) : (
                `Reimbursement Request #${data?.referenceNumber || ''}`
              )}
            </Typography>
            {isEditMode && isModified && (
              <div>
                <StatusBadge color="warning" variant={'warn'} label={'Not Saved'} />
              </div>
            )}
          </div>
          {data && data.status !== MedicalBillReimbursementStatus.Failed && (
            <div style={{ display: 'flex' }}>
              {shouldShowAdminCloseButton && (
                <>
                  <Button
                    className={styles.firstActionButton}
                    color="primary"
                    variant="contained"
                    onClick={onCloseAdminCase}
                    disabled={!adminCaseId}
                  >
                    {isClosingAdminCase ? (
                      <ButtonProgressIndicator size={24} />
                    ) : (
                      'Close Admin Case'
                    )}
                  </Button>
                  <div className={styles.spacer} />
                  <Divider orientation="vertical" flexItem />
                  <div className={styles.spacer} />
                </>
              )}

              {isEditMode ? (
                <Button
                  className={styles.middleActionButton}
                  color="primary"
                  variant="contained"
                  onClick={onSave}
                  disabled={!isModified}
                >
                  {isSaving ? <ButtonProgressIndicator size={24} /> : 'Save'}
                </Button>
              ) : (
                <Button
                  className={styles.middleActionButton}
                  color="primary"
                  variant="contained"
                  onClick={onUnlock}
                >
                  Unlock
                </Button>
              )}
              <Button
                className={styles.lastActionButton}
                color="primary"
                variant="text"
                onClick={onCancel}
                disabled={!isEditMode}
              >
                Cancel
              </Button>
            </div>
          )}
        </Box>
      }
      breadcrumbs={breadcrumbs}
    >
      {data && (
        <MedicalBillReimbursement
          submissionDate={data.createdAt}
          referenceNumber={data.referenceNumber}
          adminCaseId={adminCaseId}
          accountName={data.membershipAccountDetails.accountName || 'N/A'}
          membershipAccount={data.membershipAccount}
          submitterName={`${data.submittedByDetails?.firstName} ${data.submittedByDetails?.lastName}`}
          patientName={`${data.patientDetails?.firstName} ${data.patientDetails?.lastName}`}
          dateOfServiceStart={data.formData.dateOfServiceStart}
          dateOfServiceEnd={data.formData.dateOfServiceEnd}
          servicesPerformed={data.formData.servicesPerformed}
          totalAmountBilled={data.formData.totalBilled}
          hospitalVisit={data.formData.hospitalVisit}
          conditionsTreated={data.formData.conditionsTreated}
          files={files || []}
          status={data.status}
          medicalBillNumbers={data.medicalBillNumbers || []}
          isEditMode={isEditMode}
          onStatusChange={onStatusChange}
          onMedicalBillNumbersChange={onMedicalBillNumbersChange}
          onAttachmentClick={onAttachmentClick}
        />
      )}
    </Page>
  );
};

export default MedicalBillReimbursementTemplate;
