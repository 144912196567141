import React, { useEffect, useLayoutEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  Avatar,
  Typography,
  Tooltip,
  IconButton,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory, Redirect } from 'react-router-dom';
import { startCase } from 'lodash';
import { useQuery, useQueryClient } from 'react-query';
import { FilterList, Refresh } from '@material-ui/icons';
import { ClassNameMap } from '@material-ui/styles';
import { UserWithGroups } from '@ccm-innovation/auth';
import { Action, Subject } from '@ccm-innovation/auth-membership-service';

import Page from '../components/Page';
import StatusBadge from '../components/StatusBadge';
import { RootState } from '../store';
import { deactivateUser, activateUser, getUser } from '../store/slices/user';
import Spacer from '../components/Spacer';
import { useStyles } from './User.styles';
import { useSession } from '../context/session';
import { AuditEventsQueryParams } from '../lib/api/membershipAccounts';
import apiClient from '../lib/api';
import { AuditEvent } from '../models/auditEvent';
import AuditEventsFilter from '../components/membership/account/audit-events/AuditEventsFilter';
import AuditEventsTable from '../components/membership/account/audit-events/AuditEventsTable';

const renderRolesPermissions = (groups: string[] | undefined, styles: ClassNameMap) => {
  const securityEnvironment = process.env.REACT_APP_SECURITY_ENV === 'production' ? 'prod' : 'dev';
  const user = new UserWithGroups(groups || [], securityEnvironment);
  const roles = user.roles.filter(role => role.name === 'membership');

  if (roles.length === 0) {
    return '- none -';
  }
  return (
    <>
      {roles.map(role => {
        if (role.name !== 'membership') {
          return null;
        }

        return (
          <Card className={styles.permissionsCard} key={role.name}>
            <CardContent>
              <Typography variant="h6">{role.name}</Typography>
              {role.roles.map((role, index) => (
                <div key={index}>{startCase(role)}</div>
              ))}
            </CardContent>
          </Card>
        );
      })}
    </>
  );
};

interface Params {
  uid: string;
}

const UserView = (props: any) => {
  const styles = useStyles();
  const params: Params = useParams();
  const history = useHistory();
  const uid = params.uid;
  const [statusButtonLabel, setStatusButtonLabel] = useState('Activate');
  const dispatch = useDispatch();
  const { user } = useSelector((state: RootState) => state.user);
  const [toggleActive, setToggleActive] = useState(true);
  const session = useSession();
  const [queryParams, setQueryParams] = useState<AuditEventsQueryParams>({
    page: 1,
    pageSize: 25,
    plus1: true,
    actorUid: uid,
  });
  const [showFilter, setShowFilter] = useState(false);
  const queryClient = useQueryClient();

  const auditEventsFetch = useQuery(
    [queryParams],
    () => apiClient.auditEvents.getAuditEvents(queryParams),
    { keepPreviousData: true },
  );

  const auditEvents: AuditEvent[] | undefined = auditEventsFetch.data;

  const handlePaging = ({ page, pageSize }: AuditEventsQueryParams) => {
    const newQueryParams = {
      ...queryParams,
    };
    if (page) {
      newQueryParams.page = page;
    }
    if (pageSize) {
      newQueryParams.pageSize = pageSize;
    }
    setQueryParams(newQueryParams);
  };

  const handleRefreshEvents = () => {
    queryClient.invalidateQueries(['auditEvents', queryParams]);
  };

  const filterChange = (newQueryParams: AuditEventsQueryParams) => {
    setQueryParams(newQueryParams);
  };

  const breadcrumbs = [
    { href: '/', label: 'Home' },
    { href: '#', label: 'System Administration' },
    { href: '/system-admin/users', label: 'Users' },
    { label: user ? `${user.firstName} ${user.lastName}` : 'User Details' },
  ];

  const toggleStatus = () => {
    if (user?.status === 'active') {
      dispatch(deactivateUser(user.email));
    } else if (user) {
      dispatch(activateUser(user.email));
    }
  };

  useEffect(() => {
    if (user?.status === 'active') {
      setStatusButtonLabel('Deactivate');
    } else {
      setStatusButtonLabel('Activate');
    }
  }, [user]);

  useLayoutEffect(() => {
    dispatch(getUser(uid));
  }, [uid, dispatch]);

  useEffect(() => {
    const toggleActive = user?.uid !== session.user?.uid;
    setToggleActive(toggleActive);
  }, [session, user, history, dispatch]);

  if (session.user && session.user.cannot(Action.View, Subject.User)) {
    return <Redirect to={{ pathname: '/not-found' }} />;
  }

  return (
    <Page breadcrumbs={breadcrumbs}>
      {/* User Details */}
      <Box className={styles.userDetailsContainer}>
        <Box className={styles.avatarContainer}>
          <Avatar className={styles.avatar} />
        </Box>
        <Card>
          <CardContent className={styles.userInfoContainer}>
            <Box>
              <Box className={styles.userNameStatus}>
                <Typography variant="h3">
                  {user?.firstName} {user?.lastName}
                </Typography>
                <StatusBadge
                  marginLeft={2}
                  variant={user && user.status === 'active' ? 'success' : 'default'}
                  label={user && user.status}
                />
              </Box>
              <Typography variant="h5" className={styles.userEmail}>
                {user?.email}
              </Typography>
            </Box>

            {session.user && session.user.can(Action.Activate, Subject.User) && (
              <Box className={styles.activateButtonContainer}>
                <Button
                  disabled={!toggleActive}
                  color="primary"
                  variant="contained"
                  onClick={() => toggleStatus()}
                >
                  {statusButtonLabel}
                </Button>
              </Box>
            )}
          </CardContent>
        </Card>
      </Box>
      <Spacer size={40} />

      {/* User Roles & Permissions */}
      <section>
        <Box marginRight={2}>
          <Typography variant="h3">Roles &amp; Permissions</Typography>
        </Box>
        <div className={styles.permissionsContainer}>
          {user && renderRolesPermissions(user.groups, styles)}
        </div>
      </section>
      <Spacer size={40} />

      {/* Audit Events */}
      {session.user && session.user.can(Action.View, Subject.AuditEvent) && (
        <section>
          {/* Title */}
          <div style={{ display: 'flex' }}>
            <Box marginRight={2}>
              <Typography variant="h3">Audit Events</Typography>
            </Box>
            <Box>
              <Tooltip title={'filter list'}>
                <IconButton color="default" size="small" onClick={() => setShowFilter(!showFilter)}>
                  <FilterList />
                </IconButton>
              </Tooltip>
            </Box>
            <Box>
              <Tooltip title={'refresh events'}>
                <IconButton color="default" size="small" onClick={handleRefreshEvents}>
                  <Refresh />
                </IconButton>
              </Tooltip>
            </Box>
          </div>

          {/* Audit Events Filter */}
          {showFilter && (
            <AuditEventsFilter queryParams={queryParams} onFilterChange={filterChange} />
          )}

          {/* Audit Events Table */}
          <AuditEventsTable
            auditEvents={auditEvents}
            onPage={handlePaging}
            pageSize={queryParams.pageSize}
            currentPage={queryParams.page || 1}
          />
        </section>
      )}
    </Page>
  );
};

export default UserView;
