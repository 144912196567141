import React, { useState } from 'react';
import clsx from 'clsx';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import {
  Menu as MenuIcon,
  AccountCircle as AccountCircleIcon,
  Brightness3Outlined as Brightness3OutlinedIcon,
  Brightness7Outlined as Brightness7OutlinedIcon,
} from '@material-ui/icons';
import { AppBar, Toolbar, Typography, IconButton, Menu, MenuItem, Box } from '@material-ui/core';
import { useDispatch } from 'react-redux';

import { logout } from '../store/slices/session';

const drawerWidth = 260;

const useStyles = makeStyles(theme =>
  createStyles({
    grow: {
      flexGrow: 1,
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    appBarClipped: {
      zIndex: theme.zIndex.drawer + 1,
    },
    menuButton: {
      marginRight: 36,
    },
    ccmIcon: {
      marginRight: '8px',
      height: 40,
      '& img': {
        height: '100%',
      },
    },
    title: {
      display: 'none',
      [theme.breakpoints.up('sm')]: {
        display: 'block',
      },
      margin: 0,
    },
    hide: {
      display: 'none',
    },
  }),
);

const CustomAppBar = (props: any) => {
  const { isDrawerOpen, onToggleDrawer, isDarkMode, toggleDarkMode } = props;
  const styles = useStyles();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const isMenuOpen = Boolean(anchorEl);
  const isClipped = true;

  const handleDrawerOpen = () => {
    onToggleDrawer(true);
  };

  const appBarClasses = () => {
    return clsx({
      [styles.appBar]: !isClipped,
      [styles.appBarClipped]: isClipped,
      [styles.appBarShift]: !isClipped && isDrawerOpen,
    });
  };

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    dispatch(logout());
    handleMenuClose();
  };

  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      keepMounted
      open={isMenuOpen}
      onClose={handleMenuClose}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <MenuItem onClick={handleLogout}>Logout</MenuItem>
    </Menu>
  );

  const onToggleDarkMode = () => {
    toggleDarkMode();
  };

  return (
    <>
      <AppBar position="fixed" className={appBarClasses()}>
        <Toolbar>
          {/* Open Drawer Button */}
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(styles.menuButton, {
              [styles.hide]: isDrawerOpen,
            })}
          >
            <MenuIcon />
          </IconButton>

          {/* App Bar Logo */}
          <Box className={styles.ccmIcon}>
            <img src="/ccm-icon-white.png" alt="CCM logo" />
          </Box>

          {/* App Bar Title */}
          <Typography className={styles.title} variant="h3" noWrap>
            Member Admin
          </Typography>

          {/* Right Menu & Icons */}
          <div className={styles.grow} />
          <div>
            <IconButton color="inherit" onClick={onToggleDarkMode}>
              {isDarkMode ? <Brightness7OutlinedIcon /> : <Brightness3OutlinedIcon />}
            </IconButton>
            <IconButton edge="end" onClick={handleProfileMenuOpen} color="inherit">
              <AccountCircleIcon />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      {renderMenu}
    </>
  );
};

export default CustomAppBar;
