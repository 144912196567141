import moment from 'moment-timezone';
import ProgramEnrollment from '../models/programEnrollment';
import MemberProgram, { MemberProgramStatus } from '../models/memberProgram';
import EligibleProgram from '../models/eligibleProgram';
import MembershipAccount from '../models/membershipAccount';
import Person from '../models/person';

export const MIN_CCM_PROGRAM_DATE = '1993-01-01';
export const MAX_CCM_PROGRAM_DATE = moment().add(1, 'year').format('YYYY-MM-DD');

/**
 * Creates a Date object relative to the Eastern time zone due to the
 * location of CCM headquarters
 * DEPRECATED - use moment('date string').toDate() instead
 * @param dateTime - received date/time assumed to be in Easter time zone
 * @returns - a date/time with an Eastern time zone
 */
export const toCCMLocalDateTime = (dateTime: string | Date): Date | null => {
  if (dateTime === undefined || !moment(dateTime).isValid()) {
    return null;
  }
  const dt = moment.tz(dateTime, 'America/New_York').toDate();
  return dt;
};

export const formatPhoneNumber = (rawNumber: string | undefined): string => {
  if (!rawNumber) return '';
  //get only the numbers
  let justNumbers = rawNumber.replaceAll(/\D/g, '');
  return justNumbers.length < 10
    ? rawNumber
    : justNumbers.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
};

export const removeUnderscoresFromString = (value: string | undefined): string => {
  if (!value) return '';
  return value.replace(/_/gi, ' ');
};

/**
 * Returns a formatted string e.g. "2021-09-01" for a given JS date object
 * @param date
 */
export const formatDate = (date: Date | string): string => {
  if (date === undefined || !moment(date).isValid()) {
    return '';
  }

  return moment(date).format('YYYY-MM-DD');
};

/**
 *
 * @param dateString a date string like "2021-03-10 00:00:00-05"
 * @param dateFormat a date format, the function defaults to YYYY-MM-DD
 */
export const toCCMLocalDate = (dateString: string, dateFormat: string = 'YYYY-MM-DD') => {
  if (!moment(dateString).isValid()) {
    return null;
  }

  return moment.tz(dateString, 'America/New_York').startOf('day').format(dateFormat);
};

/**
 *  Application date is earliest start date on any active enrollment
 * @param programEnrollments - array of program enrollments
 */
export const getApplicationDate = (programEnrollments: ProgramEnrollment[] = []) => {
  if (
    programEnrollments.length < 1 ||
    !programEnrollments.some((pe: ProgramEnrollment) => pe.ended === null)
  ) {
    return null;
  }

  const activeEnrollments = programEnrollments
    .filter(
      (enrollment: ProgramEnrollment) =>
        !enrollment.ended ||
        (enrollment.ended && moment(enrollment.ended).diff(moment(), 'days') > 0),
    )
    .sort((a: ProgramEnrollment, b: ProgramEnrollment) => {
      if (moment(b.applicationDate).isAfter(moment(a.applicationDate))) return -1;
      if (moment(b.applicationDate).isBefore(moment(a.applicationDate))) return 1;
      return 0;
    });

  return toCCMLocalDate(activeEnrollments[0].applicationDate);
};

/**
 * Sorts member programs by start date in descending order
 * @param a
 * @param b
 */
export const sortMemberPrograms = (a: MemberProgram, b: MemberProgram) => {
  if (moment(b.started).isBefore(moment(a.started))) return -1;
  if (moment(b.started).isAfter(moment(a.started))) return 1;
  return 0;
};

/**
 * Sorts member programs by start date in ascending order or descending order
 * @param a
 * @param b
 */
export const sortProgramEnrollments = (sort = 'ASC') => (
  a: ProgramEnrollment,
  b: ProgramEnrollment,
) => {
  if (moment(a.started).isBefore(moment(b.started))) return sort === 'ASC' ? -1 : 1;
  if (moment(a.started).isAfter(moment(b.started))) return sort === 'ASC' ? 1 : -1;
  return 0;
};

const orderOfEligiblePrograms = ['MS3', 'MV1', 'CS1'];
export const sortEligiblePrograms = (a: EligibleProgram, b: EligibleProgram) => {
  if (orderOfEligiblePrograms.indexOf(a.name) < orderOfEligiblePrograms.indexOf(b.name)) return -1;
  if (orderOfEligiblePrograms.indexOf(a.name) > orderOfEligiblePrograms.indexOf(b.name)) return 1;
  return 0;
};

export const isEligibleForSeniorProgram = (
  membershipAccount: MembershipAccount,
  selectedStartDate: string,
) => {
  let isEligible: boolean = false;
  for (let i = 0; i < membershipAccount.members!.length; i++) {
    if ((membershipAccount.members![i]?.person as Person).dob) {
      let memberDob = moment((membershipAccount.members![i]?.person as Person).dob);
      const earliestEligibleStartDate = memberDob
        .add(64, 'years')
        .add(9, 'months')
        .format('YYYY-MM-DD');
      if (moment(selectedStartDate).isSameOrAfter(earliestEligibleStartDate)) {
        isEligible = true;
      }
    }
  }

  return isEligible;
};

export const getLatestStartDateFromProgramEnrollments = (
  programEnrollments: ProgramEnrollment[],
  memberProgramUids: string[] = [],
) => {
  let allProgramEnrollments = [...programEnrollments];
  if (memberProgramUids.length) {
    allProgramEnrollments = allProgramEnrollments.filter(ape =>
      memberProgramUids.some(
        mpUid =>
          (mpUid === ape.memberProgram || mpUid === (ape.memberProgram as MemberProgram).uid) &&
          ape.ended === null,
      ),
    );
  }

  const earliestCancellationEndDate = allProgramEnrollments.length
    ? allProgramEnrollments.sort(sortProgramEnrollments('ASC'))[0].started
    : MIN_CCM_PROGRAM_DATE;

  return earliestCancellationEndDate;
};

export const getStartDateFromCurrentProgramEnrollment = (
  programEnrollments: ProgramEnrollment[],
) => {
  let earliestWithdrawEndDate = MIN_CCM_PROGRAM_DATE;
  let allProgramEnrollments = [...programEnrollments];
  if (allProgramEnrollments.length === 0) {
    return earliestWithdrawEndDate;
  } else if (allProgramEnrollments.length === 1) {
    earliestWithdrawEndDate = allProgramEnrollments[0].started;
  } else if (allProgramEnrollments.length > 1) {
    const activeProgramEnrollment = allProgramEnrollments.find(
      pe => (pe.memberProgram as MemberProgram).status === MemberProgramStatus.Active,
    );

    if (activeProgramEnrollment) {
      earliestWithdrawEndDate = activeProgramEnrollment.started;
    } else {
      earliestWithdrawEndDate = allProgramEnrollments.length
        ? allProgramEnrollments.sort(sortProgramEnrollments('ASC'))[0].started
        : MIN_CCM_PROGRAM_DATE;
    }
  }

  return earliestWithdrawEndDate;
};

export const isDateWithRange = (
  date: Date | string | null,
  lowBoundDate: Date | string,
  maxBoundDate: Date | string,
) => {
  return (
    moment(date).isValid() &&
    moment(date).isSameOrAfter(lowBoundDate) &&
    moment(date).isSameOrBefore(maxBoundDate)
  );
};

export const isInPast = (endDate: Date | string) => {
  return moment(endDate).isBefore(new Date(), 'day');
};

/**
 * @param blobUrl a string representing the blob url (Object URL)
 * @param fileName the name of the file to be downloaded
 */
export const downloadObjectUrl = (objectUrl: string, fileName: string) => {
  // Create a link element,
  // direct it towards the blob,
  // set the download attribute to indicate the filename,
  // and then 'click' it programmatically
  const link = document.createElement('a');
  link.href = objectUrl;
  link.download = fileName;
  link.click();
  // Revoke the object URL to avoid memory leaks
  URL.revokeObjectURL(objectUrl);
};

/**
 * Returns the file name from a path
 * @param path
 */
export const extractFileNameFromPath = (path: string): string => {
  const fileName = path.split('/').pop();
  return fileName || '';
};

/**
 * Truncates a file name to a given length
 * @param fileName
 * @param maxLength
 * @param ellipsis - defaults to '...'
 * @returns
 */
export const truncateFileName = (fileName: string, maxLength: number, ellipsis = '...'): string => {
  if (fileName.length <= maxLength) {
    return fileName;
  }
  const fileNameWithoutExtension = fileName.split('.').slice(0, -1).join('.');
  const extension = fileName.split('.').pop();
  const truncatedFileNameWithoutExtension = fileNameWithoutExtension.slice(
    0,
    maxLength - ellipsis.length,
  );
  return `${truncatedFileNameWithoutExtension}${ellipsis}${extension}`;
};

/**
 * Replace spaces in a string with hyphens
 * @param str the string
 * @returns the string with hyphens
 */
export const hyphenString = (str: string) => {
  return str.replace(/\s+/g, '-').toLowerCase();
};
