import React from 'react';
import { Button, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ProgramEnrollment from '../../../models/programEnrollment';
import InactiveEnrollment from './InactiveEnrollment';

const useStyles = makeStyles(() => ({
  formButtonWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-end',
    '& > :first-child': {
      marginRight: '2rem',
    },
  },
  memberWrapper: {
    height: 350,
    overflowY: 'auto',
    paddingLeft: 5,
    paddingRight: 5,
    overflowX: 'hidden',
  },
}));

interface ProgramMembersProps {
  allowPartialReactivation: boolean;
  priorEnrollments: ProgramEnrollment[];
  onClose: () => void;
  onContinue: () => void;
  onMemberSelected: Function;
  selectedEnrollments: ProgramEnrollment[];
}

const ProgramMembers: React.FC<ProgramMembersProps> = ({
  priorEnrollments,
  onClose,
  onContinue,
  onMemberSelected,
  selectedEnrollments,
  allowPartialReactivation,
}) => {
  const styles = useStyles();

  return (
    <>
      <div className={styles.memberWrapper}>
        <Grid container spacing={2}>
          {priorEnrollments.map((enrollment: ProgramEnrollment, index: number) => (
            <Grid item lg={6} xs={12} key={index}>
              <InactiveEnrollment
                enrollment={enrollment}
                onMemberSelected={onMemberSelected}
                checked={selectedEnrollments.some(e => e.uid === enrollment.uid)}
                disabled={!allowPartialReactivation}
              />
            </Grid>
          ))}
        </Grid>
      </div>
      <div className={styles.formButtonWrapper}>
        <Button
          type="submit"
          color="primary"
          variant="contained"
          size="large"
          disabled={selectedEnrollments.length < 1}
          onClick={onContinue}
        >
          Continue
        </Button>

        <Button color="default" variant="contained" size="large" onClick={onClose}>
          Cancel
        </Button>
      </div>
    </>
  );
};

export default ProgramMembers;
