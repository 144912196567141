import { FC } from 'react';
import StatusBadge from '../../../../components/StatusBadge';
import { PrintRequestStatus } from '../../print-request.constants';

interface PrintRequestTableRowStatusProps {
  status: string;
}

const getStatusVariant = (status: string) => {
  switch (status.toLowerCase()) {
    case PrintRequestStatus.SENT:
      return 'success';
    case PrintRequestStatus.PENDING:
      return 'warn';
    case PrintRequestStatus.CANCELLED:
      return 'danger';
    default:
      return 'default';
  }
};

const PrintRequestTableRowStatus: FC<PrintRequestTableRowStatusProps> = ({ status }) => {
  return <StatusBadge variant={getStatusVariant(status)} label={status} />;
};

export default PrintRequestTableRowStatus;
