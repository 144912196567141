import { useState, useEffect } from 'react';
import { Box, CircularProgress } from '@material-ui/core';
import { BusinessOutlined } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import Organization from '../../models/organization';
import apiClient from '../../lib/api';

interface OrganizationImageProps {
  organization: Organization;
  logoStyles?: string;
  logoPlaceholderStyles?: string;
}

interface OrganizationImageState {
  loading: boolean;
  src: string | null;
  alt: string;
}

const useStyles = makeStyles(theme => ({
  logo: {
    width: '100%',
    borderRadius: '8px',
    objectFit: 'contain',
    objectPosition: 'center',
  },
  logoPlaceholder: {
    color: '#9699a4',
    height: '6em',
    width: '6em',
  },
}));

const OrganizationLogo: React.FC<OrganizationImageProps> = props => {
  const styles = useStyles();
  const { organization, logoStyles, logoPlaceholderStyles } = props;
  const [orgImage, setOrgImage] = useState<OrganizationImageState>({
    loading: true,
    src: null,
    alt: '',
  });

  useEffect(() => {
    async function getImage(fileNamePath: string) {
      try {
        const image = await apiClient.resources.get(fileNamePath);
        setOrgImage({ loading: false, src: image, alt: `${organization.organizationName} logo` });
      } catch {
        setOrgImage({ loading: false, src: null, alt: 'Empty organization logo' });
      }
    }

    if (organization.settings?.logoUrl) {
      getImage(organization.settings.logoUrl);
    } else {
      setOrgImage({ loading: false, src: null, alt: 'Empty organization logo' });
    }
  }, [organization]);

  // show a loading placeholder if image is needed to be loaded from the api
  if (orgImage.loading) {
    return (
      <Box display="flex" justifyContent="center">
        <CircularProgress />
      </Box>
    );
  }

  if (orgImage.src) {
    return (
      <img
        className={logoStyles || styles.logo}
        src={`data:image/png;base64,${orgImage.src}`}
        alt={orgImage.alt}
      />
    );
  } else {
    return <BusinessOutlined className={logoPlaceholderStyles || styles.logoPlaceholder} />;
  }
};

export default OrganizationLogo;
