import React, { useState } from 'react';
import { Box, Collapse, TableCell, TableRow, Paper } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { makeStyles } from '@material-ui/core/styles';

import { PaginationColumnProps } from '.';

const useStyles = makeStyles({
  row: {
    cursor: 'pointer',
    '& > *': {
      borderBottom: 'unset',
    },
  },
  childRow: {
    cursor: 'pointer',
  },
  emptyRow: {
    cursor: 'default',
  },
  emptyRowCell: {
    textAlign: 'center',
  },
});

interface RowProps {
  columns: PaginationColumnProps[];
  dataRow: any;
  showDetailRow?: boolean;
  formatDetailRowContent?: (data?: any) => any;
  onRowClick?: Function;
}

const PageRow: React.FC<RowProps> = ({
  dataRow,
  columns,
  showDetailRow,
  formatDetailRowContent,
  onRowClick                                     ,
}) => {
  const [open, setOpen] = useState(false);
  const styles = useStyles();

  const detailRowContent = formatDetailRowContent ? formatDetailRowContent(dataRow) : null;

  return (
    <>
      <TableRow className={styles.row} hover onClick={() => { if(onRowClick) onRowClick(dataRow)}}>
        {showDetailRow && (
          <TableCell>
            <IconButton
              size="small"
              onClick={e => {
                e.stopPropagation();
                setOpen(!open);
              }}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
        )}
        {columns.map((column: PaginationColumnProps, index: number) => (
          <TableCell key={index}>
            {column.format ? column.format(dataRow[column.name]) : dataRow[column.name]}
          </TableCell>
        ))}
      </TableRow>
      {showDetailRow && (
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={columns.length + 1}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Paper elevation={3}>
                <Box margin={1} style={{ padding: 20 }}>
                  {detailRowContent ? detailRowContent : <span>No content available</span>}
                </Box>
              </Paper>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};

export default PageRow;
