import React from 'react';
import { Link } from 'react-router-dom';
import { Box, Typography, Breadcrumbs, createStyles, makeStyles, Theme } from '@material-ui/core';
import { NavigateNext } from '@material-ui/icons';

export interface Breadcrumb {
  href?: string;
  label: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    breadcrumbsContainer: {
      marginBottom: 10,
    },
  }),
);

/**
 * PageHeader
 * @param {*} props
 * The PageHeader creates a consistent look for screens that require a title
 * and/or breadcrumb navigation
 */
const PageHeader = (props: any) => {
  const { title, breadcrumbs } = props;
  const styles = useStyles();

  return (
    <>
      {breadcrumbs && (
        <Box className={styles.breadcrumbsContainer}>
          <Breadcrumbs separator={<NavigateNext fontSize="small" />} aria-label="breadcrumb">
            {breadcrumbs.map((breadcrumb: Breadcrumb, index: number) => {
              if (breadcrumb.href) {
                return (
                  <Link to={breadcrumb.href} key={index}>
                    {breadcrumb.label}
                  </Link>
                );
              }

              return (
                <Typography color="textPrimary" key={index}>
                  {breadcrumb.label}
                </Typography>
              );
            })}
          </Breadcrumbs>
        </Box>
      )}
      {title && <Typography variant="h3">{title}</Typography>}
    </>
  );
};

export default PageHeader;
