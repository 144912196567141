import { useState, useEffect } from 'react';
import { Box, Modal, Paper, Backdrop, Typography, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useQuery } from 'react-query';
import apiClient from '../../../../../lib/api';
import { useDispatch } from 'react-redux';

import ProgramCreationForm from './ProgramCreationForm';
import MembershipAccount from '../../../../../models/membershipAccount';
import {
  isCoShare,
  isMediShareValue,
  isMS2,
  isMS3,
  isMS65,
  isSeniorAssist,
  MemberProgramStatus,
} from '../../../../../models/memberProgram';
import { showToastMessage } from '../../../../../store/slices/toastMessage';
import { ProgramLevel } from '../../../../../models/programLevel';
import ButtonProgressIndicator from '../../../../ButtonProgressIndicator';

const useStyles = makeStyles(() => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalBody: {
    width: 600,
    padding: '20px 25px',
  },
}));

interface ProgramCreationProps {
  membershipAccount: MembershipAccount;
}

const ProgramCreation: React.FC<ProgramCreationProps> = ({ membershipAccount }) => {
  // dispatch to call redux
  const dispatch = useDispatch();
  //styles
  const styles = useStyles();
  // state for the modal
  const [openModal, setOpenModal] = useState(false);
  // Program Level Data
  const { data, isFetching, isFetched, refetch } = useQuery(
    ['listProgramLevels', membershipAccount.uid],
    async () => {
      // get program levels
      const programLevelData = await apiClient.membershipPrograms.listProgramLevels();
      const hasActivePrograms = !!membershipAccount.memberPrograms!.some(
        mp => mp.status === MemberProgramStatus.Active,
      );

      let filteredProgramLevels: ProgramLevel[] = [];
      // If the account has no active programs all programs are eligible
      if (hasActivePrograms) {
        // Check if the account has an active non-senior program
        const hasActiveNonSeniorProgram = membershipAccount.memberPrograms!.some(
          mp =>
            mp.status === MemberProgramStatus.Active &&
            !mp.programLevel.name.includes('SA') &&
            !mp.programLevel.name.includes('MS65'),
        );

        // Check if the account as an active senior program
        const hasActiveSeniorProgram = membershipAccount.memberPrograms!.some(
          mp =>
            mp.status === MemberProgramStatus.Active &&
            (mp.programLevel.name.includes('SA') || mp.programLevel.name.includes('MS65')),
        );

        const activeSeniorPrograms = membershipAccount.memberPrograms!.filter(
          p => p.status === MemberProgramStatus.Active && p.isSeniorProgram && p.ended === null,
        );

        if (hasActiveNonSeniorProgram) {
          // Show only senior programs
          filteredProgramLevels = programLevelData.filter(
            (pl: ProgramLevel) => pl.name.includes('SA') || pl.name.includes('MS65'),
          );
        } else if (hasActiveSeniorProgram && activeSeniorPrograms.length > 1) {
          // Exclude senior programs
          filteredProgramLevels = programLevelData.filter(
            (pl: ProgramLevel) => !pl.name.includes('SA') && !pl.name.includes('MS65'),
          );
        } else if (hasActiveSeniorProgram && activeSeniorPrograms.length <= 1) {
          filteredProgramLevels = programLevelData;
        }
      } else {
        // All programs are eligible
        filteredProgramLevels = programLevelData;
      }

      //order the programs because CS1-9K, CS1-12K are at the top of the list since both are new programs
      return [
        ...filteredProgramLevels.filter(f => isMS3(f.name)),
        ...filteredProgramLevels.filter(f => isCoShare(f.name)),
        ...filteredProgramLevels.filter(f => isMS65(f.name)),
        ...filteredProgramLevels.filter(f => isMS2(f.name)),
        ...filteredProgramLevels.filter(f => isSeniorAssist(f.name)),
        ...filteredProgramLevels.filter(f => isMediShareValue(f.name)),
      ];
    },
    { refetchOnWindowFocus: false, enabled: false },
  );

  const onModalOpen = () => {
    const hasActiveNonSeniorProgram = membershipAccount.memberPrograms!.some(
      mp =>
        mp.status === MemberProgramStatus.Active &&
        !mp.programLevel.name.includes('SA') &&
        !mp.programLevel.name.includes('MS65'),
    );

    // Check if the account as an active senior program
    const hasActiveSeniorProgram = membershipAccount.memberPrograms!.some(
      mp =>
        mp.status === MemberProgramStatus.Active &&
        (mp.programLevel.name.includes('SA') || mp.programLevel.name.includes('MS65')),
    );
    if (hasActiveSeniorProgram && hasActiveNonSeniorProgram) {
      dispatch(
        showToastMessage({
          message: 'There are no programs that can be added to this account',
          type: 'warning',
        }),
      );
    } else {
      setOpenModal(true);
      refetch();
    }
  };

  // check if there are any program levels
  // if there are none, show a warning message
  useEffect(() => {
    if (data && !isFetching && isFetched) {
      if (!data.length) {
        dispatch(
          showToastMessage({
            message: 'No available MS3 programs to create for this membership account',
            type: 'warning',
          }),
        );
        setOpenModal(false);
      }
    }
  }, [data, dispatch, isFetched, isFetching]);

  const shouldOpenModal = data && data.length && !isFetching && isFetched && openModal;

  return (
    <>
      {!membershipAccount.archived && (
        <Button color="primary" variant="contained" onClick={onModalOpen} disabled={isFetching}>
          {isFetching ? <ButtonProgressIndicator /> : 'Add Program'}
        </Button>
      )}
      {shouldOpenModal && (
        <Modal
          className={styles.modal}
          open={true}
          onClose={() => setOpenModal(false)}
          closeAfterTransition
          disableBackdropClick
          disableEscapeKeyDown
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Paper className={styles.modalBody}>
            <Box>
              <Typography variant="h2">Add Program</Typography>
            </Box>
            <ProgramCreationForm
              programLevels={data!}
              membershipAccount={membershipAccount}
              onClose={() => setOpenModal(false)}
            />
          </Paper>
        </Modal>
      )}
    </>
  );
};

export default ProgramCreation;
