import moment from 'moment';
/**
 * ExceptionLog
 */
export default class ExceptionLog {
  id: number;
  uid: string;
  logLevel: string;
  description: string;
  metadata: any;
  createdAt: moment.Moment | null;

  constructor(data: any = {}) {
    this.id = parseInt(data.id, 10);
    this.uid = data.uid;
    this.logLevel = data.logLevel;
    this.description = data.description;
    this.metadata = data.metadata;
    this.createdAt = moment(data.createdAt, moment.ISO_8601, true);
  }
}
