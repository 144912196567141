import { MenuItem, TextField } from '@material-ui/core';
import { MedicalBillReimbursementStatus } from '../medical-bill-reimbursement.constants';

export interface MedicalBillReimbursementStatusSelectProps {
  status: MedicalBillReimbursementStatus;
  onChange: (status: MedicalBillReimbursementStatus) => void;
  disabled: boolean;
}

const MedicalBillReimbursementStatusSelect = ({
  status,
  onChange,
  disabled,
}: MedicalBillReimbursementStatusSelectProps) => {
  const statuses = Object.entries(MedicalBillReimbursementStatus).filter(
    ([key, value]) =>
      (status === MedicalBillReimbursementStatus.Failed) ===
      (value === MedicalBillReimbursementStatus.Failed),
  );

  return (
    <TextField
      select={true}
      value={status}
      onChange={event => onChange(event.target.value as MedicalBillReimbursementStatus)}
      variant="outlined"
      size="small"
      hiddenLabel={true}
      inputProps={{
        'aria-label': 'Select status',
      }}
      disabled={disabled}
    >
      {statuses.map(([key, value]) => (
        <MenuItem value={value} key={value} aria-label='Option status'>
          {key}
        </MenuItem>
      ))}
    </TextField>
  );
};

export default MedicalBillReimbursementStatusSelect;
