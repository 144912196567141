import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  TextField,
  Typography,
} from '@material-ui/core';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import * as yup from 'yup';
import ButtonProgressIndicator from '../../../components/ButtonProgressIndicator';
import { importSalesforceAccount } from '../../../store/slices/membershipAccount';

const importFormSchema = yup.object({
  salesforceId: yup.string().trim().required('Salesforce ID is required'),
});

interface Props {
  open: boolean;
  close(): void;
}

const ImportAccountForm: React.FC<Props> = props => {
  const { open, close } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const [importing, setImporting] = useState(false);

  const importForm = useFormik({
    initialValues: {
      salesforceId: '',
    },
    validationSchema: importFormSchema,
    onSubmit: values => {
      importAccount(values.salesforceId);
    },
  });

  const cancel = () => {
    importForm.resetForm();
    close();
  };

  const importAccount = async (salesforceId: string) => {
    try {
      setImporting(true);
      const account: any = await dispatch(importSalesforceAccount(salesforceId));
      importForm.resetForm();
      if (account?.uid) {
        history.push(`/membership/accounts/${account.uid}`);
      }
    } catch (err: any) {
      setImporting(false);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={() => cancel()}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" disableTypography={true}>
        <Typography variant="h2">Import Salesforce Account</Typography>
      </DialogTitle>
      <DialogContent>
        <TextField
          id="salesforceId"
          name="salesforceId"
          label="Salesforce ID"
          margin="normal"
          fullWidth
          variant="outlined"
          autoComplete="none"
          disabled={importing}
          value={importForm.values.salesforceId}
          onChange={importForm.handleChange}
          error={importForm.touched.salesforceId && Boolean(importForm.errors.salesforceId)}
          helperText={importForm.touched.salesforceId && importForm.errors.salesforceId}
        ></TextField>
      </DialogContent>
      <Divider />

      <DialogActions>
        <Button color="default" disabled={importing} onClick={() => cancel()}>
          Cancel
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={!importing ? importForm.submitForm : () => {}}
        >
          {importing ? <ButtonProgressIndicator size={24} /> : 'Import'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ImportAccountForm;
