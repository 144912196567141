import { APIClientBase } from './apiClientBase';

class Jobs extends APIClientBase {
  private static _instance: Jobs;

  static getInstance(): Jobs {
    if (Jobs._instance === undefined) {
      Jobs._instance = new Jobs();
    }
    return Jobs._instance;
  }
}

export default Jobs.getInstance();
