import * as yup from 'yup';
import {
  Button,
  debounce,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import React, { useCallback, useEffect, useState } from 'react';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns'; // choose your lib
import { RootState } from '../../store';
import Group from '../../models/group';
import states from '../../lib/states';
import { Address } from '../../models/address';
import { AddressValidation } from '../address/AddressForm';
import moment from 'moment';
import { showToastMessage } from '../../store/slices/toastMessage';
import { saveGroup } from '../../store/slices/group';
import { useQuery } from 'react-query';
import apiClient from '../../lib/api';
import { Autocomplete } from '@material-ui/lab';
import ButtonProgressIndicator from '../ButtonProgressIndicator';
import Organization from '../../models/organization';
import Dialog from '../Dialog';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  dialogContainer: {
    flexGrow: 1,
    display: 'flex',
  },
  groupForm: {
    width: 800,
  },
}));

const groupFormSchema = yup.object({
  organization: yup.string().trim().min(1).required('Organization is Required'),
  groupName: yup
    .string()
    .trim()
    .max(150, 'Group names cannot be longer than 150 characters.')
    .required('Group Name is required'),
  underwriter: yup
    .string()
    .trim()
    .min(3, 'Underwriter must be at least 3 characters.')
    .max(3, 'Underwriter cannot be longer than 3 characters.')
    .required('Underwriter is required'),
  underwriterGroup: yup
    .string()
    .trim()
    .min(3, 'Underwriter Group must be at least 3 characters.')
    .max(3, 'Underwriter Group cannot be longer than 3 characters.')
    .required('Underwriter Group is required'),
  status: yup.string().required('Status is required'),
  started: yup.string().nullable().required('Date Started is required'),
  ended: yup.date().nullable(),
  address1: yup
    .string()
    .trim()
    .max(200, 'Address Line 1 cannot be longer than 200 characters.')
    .required('Address Line 1 is required'),
  address2: yup.string().trim().max(55, 'Address Line 2 cannot be longer than 55 characters.'),
  city: yup
    .string()
    .trim()
    .max(40, 'City cannot be longer than 40 characters.')
    .required('City is required'),
  state: yup.string().required('State is required'),
  postalCode: yup
    .string()
    .trim()
    .min(5, 'Postal Code must be at least 5 characters.')
    .max(20, 'Postal Code cannot be longer than 20 characters.')
    .required('Postal Code is required'),
});

// this function places all form errors at the top of the form to prevent undesiraable
// UI formatting that results from helper text
const showFormErrors = (groupForm: any) => {
  const errors = [];
  if (groupForm.touched.organization && Boolean(groupForm.errors.organization)) {
    errors.push(<div key="organization">{groupForm.errors.organization}</div>);
  }
  if (groupForm.touched.groupName && Boolean(groupForm.errors.groupName)) {
    errors.push(<div key="groupName">{groupForm.errors.groupName}</div>);
  }
  if (groupForm.touched.underwriter && Boolean(groupForm.errors.underwriter)) {
    errors.push(<div key="underwriter">{groupForm.errors.underwriter}</div>);
  }
  if (groupForm.touched.underwriterGroup && Boolean(groupForm.errors.underwriterGroup)) {
    errors.push(<div key="underwriterGroup">{groupForm.errors.underwriterGroup}</div>);
  }
  if (groupForm.touched.started && Boolean(groupForm.errors.started)) {
    errors.push(<div key="started">{groupForm.errors.started}</div>);
  }
  if (groupForm.touched.ended && Boolean(groupForm.errors.ended)) {
    errors.push(<div key="ended">{groupForm.errors.ended}</div>);
  }
  if (groupForm.touched.status && Boolean(groupForm.errors.status)) {
    errors.push(<div key="status">{groupForm.errors.status}</div>);
  }
  if (groupForm.touched.address1 && Boolean(groupForm.errors.address1)) {
    errors.push(<div key="address1">{groupForm.errors.address1}</div>);
  }
  if (groupForm.touched.city && Boolean(groupForm.errors.city)) {
    errors.push(<div key="city">{groupForm.errors.city}</div>);
  }
  if (groupForm.touched.state && Boolean(groupForm.errors.state)) {
    errors.push(<div key="state">{groupForm.errors.state}</div>);
  }
  if (groupForm.touched.postalCode && Boolean(groupForm.errors.postalCode)) {
    errors.push(<div key="postalCode">{groupForm.errors.postalCode}</div>);
  }
  return errors;
};

interface GroupFormModalProps {
  close: any;
  disabled: boolean;
  group: Group;
  isFetching: boolean;
  onchange: (event: any) => void;
  onSubmit: any;
  open: boolean;
  organizations?: Organization[] | undefined;
}

const GroupFormModal = (props: GroupFormModalProps) => {
  const { close, group, disabled, onSubmit, open, organizations, isFetching, onchange } = props;
  const [openAddressValidation, setOpenAddressValidation] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const styles = useStyles();
  const organization = useSelector((state: RootState) => state.organization.organization);

  /* eslint-disable */
  const debouncedSearch = useCallback(
    debounce((searchQuery: string) => onchange(searchQuery), 500),
    [],
  );

  const handleChange = (query: string) => {
    setInputValue(query);
    debouncedSearch(query);
  };

  const renderButtons = () => {
    const confirmButton = (
      <Button
        color="primary"
        variant="contained"
        size="large"
        onClick={groupForm.submitForm}
        key="add"
        fullWidth
        disabled={isFetching || groupForm.isSubmitting}
      >
        {group ? 'Update' : 'Create'}
      </Button>
    );
    const cancelButton = (
      <Button
        color="default"
        variant="contained"
        size="large"
        key="close"
        onClick={resetForm}
        fullWidth
      >
        Cancel
      </Button>
    );
    return [confirmButton, cancelButton];
  };

  const groupForm = useFormik({
    initialValues: {
      organization: '',
      uid: '',
      groupName: '',
      underwriter: '',
      underwriterGroup: '',
      status: 'active',
      address1: '',
      address2: '',
      city: '',
      state: '',
      postalCode: '',
      countryCode: 'US',
      started: new Date(),
      ended: group?.ended || null,
    },
    validationSchema: groupFormSchema,
    onSubmit: async values => {
      // verify address first
      setOpenAddressValidation(true);
    },
  });

  const resetForm = () => {
    groupForm.resetForm();
    groupForm.values.organization = group && organization ? organization.uid : '';
    close();
  };

  useEffect(() => {
    if (group) {
      groupForm.values.uid = group.uid;
      groupForm.values.groupName = group.groupName;
      groupForm.values.underwriter = group.underwriter;
      groupForm.values.underwriterGroup = group.underwriterGroup;
      groupForm.values.status = group.status;
      groupForm.values.address1 = group.address1;
      groupForm.values.address2 = group.address2;
      groupForm.values.city = group.city;
      groupForm.values.state = group.state;
      groupForm.values.postalCode = group.postalCode;
      groupForm.values.countryCode = group.countryCode;
      groupForm.values.started = group.started || new Date();
      groupForm.values.ended = group.ended || null;
      groupForm.values.organization = organization ? organization.uid : '';
    }
  }, [group, organization]);

  const proceed = (address: Address) => {
    setOpenAddressValidation(false);
    // update address with the verified address or current address
    groupForm.values.address1 = address.address1;
    groupForm.values.address2 = address.address2;
    groupForm.values.city = address.city;
    groupForm.values.state = address.state;
    groupForm.values.postalCode = address.postalCode;
    onSubmit(groupForm.values);
  };

  const form = () => {
    return (
      <>
        <FormHelperText error component="div" style={{ marginBottom: '16px' }}>
          {showFormErrors(groupForm)}
        </FormHelperText>
        <Grid container spacing={2} direction="row">
          {group && (
            <Grid item xs={12}>
              <Typography variant="h6">Organization</Typography>
              <Typography variant="h4">{organization?.organizationName}</Typography>
            </Grid>
          )}
          <Grid item xs={6}>
            <Typography variant="h6">Group Information</Typography>
            <TextField
              id="groupName"
              name="groupName"
              label="Group Name"
              margin="normal"
              fullWidth
              variant="outlined"
              autoComplete="none"
              disabled={disabled}
              value={groupForm.values.groupName}
              onChange={groupForm.handleChange}
              error={groupForm.touched.groupName && Boolean(groupForm.errors.groupName)}
              inputProps={{
                maxLength: 150,
              }}
            ></TextField>
            <Grid container spacing={2} style={{ marginBottom: -16 }}>
              <Grid item xs={6}>
                <TextField
                  id="underwriter"
                  name="underwriter"
                  label="Underwriter"
                  margin="normal"
                  fullWidth
                  variant="outlined"
                  autoComplete="none"
                  disabled={disabled}
                  value={groupForm.values.underwriter}
                  onChange={groupForm.handleChange}
                  error={groupForm.touched.underwriter && Boolean(groupForm.errors.underwriter)}
                  inputProps={{
                    maxLength: 3,
                  }}
                ></TextField>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="underwriterGroup"
                  name="underwriterGroup"
                  label="Underwriter Group"
                  margin="normal"
                  fullWidth
                  variant="outlined"
                  autoComplete="none"
                  disabled={disabled}
                  value={groupForm.values.underwriterGroup}
                  onChange={groupForm.handleChange}
                  error={
                    groupForm.touched.underwriterGroup && Boolean(groupForm.errors.underwriterGroup)
                  }
                  inputProps={{
                    maxLength: 3,
                  }}
                ></TextField>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <FormControl variant="outlined" fullWidth margin="normal">
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      fullWidth
                      disableToolbar
                      id="started"
                      name="started"
                      label="Date started"
                      variant="inline"
                      format="MM/dd/yyyy"
                      value={moment(groupForm.values.started).toDate()}
                      onChange={value => groupForm.setFieldValue('started', value)}
                      inputVariant="outlined"
                      error={groupForm.touched.started && Boolean(groupForm.errors.started)}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl variant="outlined" fullWidth margin="normal">
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      fullWidth
                      disableToolbar
                      id="ended"
                      name="ended"
                      label="Date ended"
                      variant="inline"
                      format="MM/dd/yyyy"
                      value={
                        groupForm.values.ended ? moment(groupForm.values.ended).toDate() : null
                      }
                      onChange={value => groupForm.setFieldValue('ended', value)}
                      inputVariant="outlined"
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </FormControl>
              </Grid>
            </Grid>

            <FormControl variant="outlined" fullWidth margin="normal">
              <InputLabel id="status-label">Status</InputLabel>
              <Select
                id="status"
                name="status"
                label="Status"
                labelId="status-label"
                disabled={disabled}
                value={groupForm.values.status}
                onChange={groupForm.handleChange}
              >
                <MenuItem value="active">Active</MenuItem>
                <MenuItem value="pending">Pending</MenuItem>
                <MenuItem value="suspended">Suspended</MenuItem>
                <MenuItem value="inactive">Inactive</MenuItem>
              </Select>
            </FormControl>
            {!group && (
              <FormControl variant="outlined" fullWidth>
                <Autocomplete
                  id="organization"
                  options={organizations || []}
                  getOptionLabel={option => `${option.organizationName}`}
                  renderInput={params => (
                    <TextField
                      {...params}
                      error={
                        groupForm.touched.organization && Boolean(groupForm.errors.organization)
                      }
                      label={<Typography variant="h6">Organization</Typography>}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {isFetching ? <ButtonProgressIndicator size={20} /> : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                  filterOptions={options => options}
                  // check for strict equality
                  getOptionSelected={(option, value) => option.uid === value.uid}
                  onChange={(_, value) => {
                    if (value) groupForm.values.organization = value.uid;
                  }}
                  onInputChange={(_, value) => handleChange(value)}
                  inputValue={inputValue}
                />
              </FormControl>
            )}
          </Grid>
          <Grid item xs={6}>
            <Typography variant="h6">Mailing Address</Typography>
            <TextField
              id="address1"
              name="address1"
              label="Address1"
              margin="normal"
              fullWidth
              variant="outlined"
              autoComplete="none"
              disabled={disabled}
              value={groupForm.values.address1}
              onChange={groupForm.handleChange}
              error={groupForm.touched.address1 && Boolean(groupForm.errors.address1)}
              inputProps={{
                maxLength: 30,
              }}
            ></TextField>
            <TextField
              id="address2"
              name="address2"
              label="Address2"
              margin="normal"
              fullWidth
              variant="outlined"
              autoComplete="none"
              disabled={disabled}
              value={groupForm.values.address2}
              onChange={groupForm.handleChange}
              error={groupForm.touched.address2 && Boolean(groupForm.errors.address2)}
              inputProps={{
                maxLength: 30,
              }}
            ></TextField>
            <TextField
              id="city"
              name="city"
              label="City"
              margin="normal"
              fullWidth
              variant="outlined"
              autoComplete="none"
              disabled={disabled}
              value={groupForm.values.city}
              onChange={groupForm.handleChange}
              error={groupForm.touched.city && Boolean(groupForm.errors.city)}
              inputProps={{
                maxLength: 30,
              }}
            ></TextField>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <FormControl
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  error={groupForm.touched.state && Boolean(groupForm.errors.state)}
                >
                  <InputLabel id="state-label">State</InputLabel>
                  <Select
                    id="state"
                    name="state"
                    label="State"
                    labelId="state-label"
                    disabled={disabled}
                    value={groupForm.values.state}
                    onChange={groupForm.handleChange}
                    error={groupForm.touched.state && Boolean(groupForm.errors.state)}
                  >
                    {states.map((state, index) => (
                      <MenuItem value={state.code} key={index}>
                        {state.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="postalCode"
                  name="postalCode"
                  label="Postal Code"
                  margin="normal"
                  fullWidth
                  variant="outlined"
                  autoComplete="none"
                  disabled={disabled}
                  value={groupForm.values.postalCode}
                  onChange={groupForm.handleChange}
                  error={groupForm.touched.postalCode && Boolean(groupForm.errors.postalCode)}
                  inputProps={{
                    maxLength: 13,
                  }}
                ></TextField>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  };

  return (
    <Dialog open={open} title={group ? 'Update Group' : 'Create Group'} buttons={renderButtons()}>
      <div className={styles.dialogContainer}></div>
      <form onSubmit={groupForm.handleSubmit} className={styles.groupForm}>
        {form()}
      </form>
      {openAddressValidation && (
        <AddressValidation
          currentAddress={groupForm.values}
          onClose={() => setOpenAddressValidation(false)}
          onProceed={proceed}
        />
      )}
    </Dialog>
  );
};

const GroupForm: React.FC<any> = props => {
  const { close, disabled, group, open } = props;
  const groupState = useSelector((state: RootState) => state.group);
  const [groupSaving, setGroupSaving] = useState(false);
  const history = useHistory();

  const dispatch = useDispatch();

  useEffect(() => {
    if (groupState.saved && groupSaving) {
      close();
      history.push(`/membership/groups/${groupState.group?.uid}`);
    }
  }, [groupState, history, dispatch, groupSaving]);

  const onSaveGroup = (groupData: any) => {
    // check dates
    if (
      groupData.started !== '' &&
      groupData.ended !== '' &&
      moment(groupData.started).isAfter(moment(groupData.ended))
    ) {
      dispatch(
        showToastMessage({
          message: 'Start date must occur before end date',
          type: 'error',
        }),
      );
      return;
    }

    setGroupSaving(true);
    dispatch(saveGroup(groupData));
  };

  const [searchQuery, setSearchQuery] = useState('');

  const { data, isFetching, refetch } = useQuery(
    ['organizations', searchQuery],
    async () => {
      const organizations = await apiClient.organizations.list({
        organizationName: searchQuery,
        status: 'active',
      });
      return organizations;
    },
    { refetchOnWindowFocus: false, enabled: false },
  );

  const handleGroupSearch = (val: string) => setSearchQuery(val);

  useEffect(() => {
    if (open) {
      refetch();
    }
  }, [open, searchQuery, refetch]);

  return (
    <GroupFormModal
      organizations={data}
      isFetching={isFetching}
      close={close}
      disabled={disabled}
      group={group}
      open={open}
      onSubmit={onSaveGroup}
      onchange={query => {
        handleGroupSearch(query);
      }}
    />
  );
};

export default GroupForm;
