import { Paper, Theme, Typography, createStyles, withStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Announcement, PeopleAltTwoTone } from '@material-ui/icons';
import AccountTree from '@material-ui/icons/AccountTree';
import Autorenew from '@material-ui/icons/Autorenew';
import Block from '@material-ui/icons/Block';
import Face from '@material-ui/icons/Face';
import Print from '@material-ui/icons/Print';
import RemoveCircleOutline from '@material-ui/icons/RemoveCircleOutline';
import Transform from '@material-ui/icons/Transform';
import {
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from '@material-ui/lab';
import moment from 'moment';
import React from 'react';
import AccountEvent from '../../../../models/accountEvent';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    spacer: { height: '32px' },
    pageTitle: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    secondaryTail: {
      backgroundColor: theme.palette.secondary.main,
    },
    paper: {
      padding: '6px 16px',
    },
    primaryIcon: {
      backgroundColor: theme.palette.primary.main,
    },
    redIcon: {
      backgroundColor: theme.palette.error.main,
    },
    greenIcon: {
      backgroundColor: theme.palette.success.main,
    },
  }),
);

const CollapsedTimelineItem = withStyles({
  missingOppositeContent: {
    '&:before': {
      display: 'none',
    },
  },
})(TimelineItem);

interface TimelineEventProps {
  event: AccountEvent;
  displayConnector: boolean;
  collapsed?: boolean;
}

interface eventConfig {
  [key: string]: { style: string; label: string; icon: any };
}

export const TimelineEvent: React.FC<TimelineEventProps> = ({
  event,
  displayConnector,
  collapsed,
}) => {
  const styles = useStyles();
  const lifeEvent = {
    style: styles.primaryIcon,
    label: 'Life Event',
    icon: <Announcement className={styles.primaryIcon} />,
  };

  const eventTypeConfig: eventConfig = {
    death: lifeEvent,
    divorce: lifeEvent,
    'account-owner-change': lifeEvent,
    create: {
      style: styles.primaryIcon,
      label: 'New Account',
      icon: <AccountTree className={styles.primaryIcon} />,
    },
    print_request: {
      style: styles.primaryIcon,
      label: 'Print Request',
      icon: <Print className={styles.primaryIcon} />,
    },
    program_change: {
      style: styles.primaryIcon,
      label: 'Account Update',
      icon: <Transform className={styles.primaryIcon} />,
    },
    add_on: {
      style: styles.primaryIcon,
      label: 'Account Update',
      icon: <Face className={styles.primaryIcon} />,
    },
    withdrawal: {
      style: styles.redIcon,
      label: 'Account Update',
      icon: <RemoveCircleOutline className={styles.redIcon} />,
    },
    cancellation: {
      style: styles.redIcon,
      label: 'Account Update',
      icon: <Block className={styles.redIcon} />,
    },
    reactivation: {
      style: styles.greenIcon,
      label: 'Account Update',
      icon: <Autorenew className={styles.greenIcon} />,
    },
    group_change: {
      style: styles.primaryIcon,
      label: 'Account Update',
      icon: <PeopleAltTwoTone className={styles.primaryIcon} />,
    },
    member_change: {
      style: styles.primaryIcon,
      label: 'Member Update',
      icon: <PeopleAltTwoTone className={styles.primaryIcon} />,
    },
  };

  const renderTimelineDot = () => {
    return (
      <TimelineDot className={eventTypeConfig[event.eventType].style}>
        {eventTypeConfig[event.eventType].icon}
      </TimelineDot>
    );
  };

  const TimelineItemComponent = collapsed ? CollapsedTimelineItem : TimelineItem;
  return (
    <TimelineItemComponent>
      {!collapsed && (
        <TimelineOppositeContent>
          <Typography variant="body2" color="textSecondary">
            {moment(event.eventDate).format('MMMM D, YYYY [@] hh:mm a')}
          </Typography>
        </TimelineOppositeContent>
      )}
      <TimelineSeparator>
        {renderTimelineDot()}
        {displayConnector && <TimelineConnector />}
      </TimelineSeparator>
      <TimelineContent>
        {collapsed && (
          <Typography variant="body2" color="textSecondary" style={{ marginBottom: '0.5rem' }}>
            {moment(event.eventDate).format('MMMM D, YYYY [@] hh:mm a')}
          </Typography>
        )}
        <Paper elevation={3} className={styles.paper}>
          <Typography variant="h6" component="h1">
            {eventTypeConfig[event.eventType].label}
          </Typography>
          <Typography> {event.description}</Typography>
        </Paper>
      </TimelineContent>
    </TimelineItemComponent>
  );
};
