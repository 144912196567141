import React from 'react';
import { Typography, Card, CardContent } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import MemberProgram from '../../../models/memberProgram';
import moment from 'moment';
import {removeUnderscoresFromString} from "../../../lib/util";

const useStyles = makeStyles(theme => ({
  label: {
    fontWeight: 'bold',
    marginRight: '3px',
  },
  program: {
    opacity: 0.6,
    transition: 'all 0.2s ease-in-out',
    '&:hover': {
      opacity: 1,
    },
    backgroundColor: theme.backgroundColors.body,
  },
  header: {
    fontWeight: 'bold',
  },
}));

interface InactiveProgramProps {
  program: MemberProgram;
}

const InactiveProgram: React.FC<InactiveProgramProps> = ({ program }) => {
  const styles = useStyles();
  return (
    <>
      <Card>
        <CardContent className={styles.program}>
          <Typography variant="h5" className={styles.header}>{program?.programLevel?.description}</Typography>
          <div>
            <span className={styles.label}>Effective:</span>
            <span>
              {moment(program.started).format('ll')}
              {program.ended && ' - ' + moment(program.ended).format('ll')}
            </span>
          </div>
          <div>
            <span className={styles.label}>Reason:</span>
            <span>{removeUnderscoresFromString(program?.cancelledReason)}</span>
          </div>
        </CardContent>
      </Card>
    </>
  );
};

export default InactiveProgram;
