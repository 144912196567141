import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import ApplicationError from '../../../components/ApplicationError';
import NotFound from '../../../components/NotFound';
import apiClient from '../../../lib/api';
import CustomError from '../../../lib/customError';
import MembershipAccount from '../../../models/membershipAccount';
import printRequestsApi from '../api/print-requests.api.adapter';
import PrintRequestTemplate from '../components/PrintRequestTemplate';
import { QueryParams, hookFactory } from '../hooks/print-requests.hooks';
import { PrintRequest } from '../types/print-request';

const PRINT_REQUESTS_PAGE = 1;
const PRINT_REQUESTS_PER_PAGE = 10;

interface PrintRequestViewProps {
  membershipAccountUId: string;
}

const PrintRequestView: FC<PrintRequestViewProps> = ({ membershipAccountUId }) => {
  const [data, setData] = useState<PrintRequest[] | undefined>(undefined);

  const [page, setPage] = useState(PRINT_REQUESTS_PAGE);

  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const { useListPrintRequestsQuery, useCancelPrintRequestMutation } = hookFactory(
    printRequestsApi,
    queryClient,
    dispatch,
  );

  const queryParams = useMemo<QueryParams>(() => {
    return {
      membershipAccountUId,
      page,
      pageSize: PRINT_REQUESTS_PER_PAGE,
    };
  }, [membershipAccountUId, page]);

  const dataQuery = useListPrintRequestsQuery({
    queryParams,
  });

  useEffect(() => {
    if (dataQuery.isFetched && dataQuery.data) {
      setData(dataQuery.data);
    }
  }, [dataQuery]);

  const accountFetch = useQuery(['membershipAccount', membershipAccountUId], () => {
    return apiClient.membershipAccounts.get(membershipAccountUId, {
      includeMembers: true,
      includeMemberPrograms: true,
      includeProgramEnrollments: true,
      includeAddresses: true,
      includeEvents: true,
      expand: 'group',
    });
  });

  const isLoading = accountFetch.isLoading;
  const accountFetchError = accountFetch.error;
  const membershipAccount: MembershipAccount | undefined = accountFetch.data;

  const cancelRequestMutation = useCancelPrintRequestMutation();

  const handleCancelRequest = useCallback(
    async (printRequestUId: string) => {
      cancelRequestMutation.mutateAsync({
        printRequestUId: printRequestUId,
        queryParams,
      });
    },
    [cancelRequestMutation, queryParams],
  );

  // Show not found or application error if fetch is unsuccessful
  if (accountFetchError && (accountFetchError as CustomError).status === 404) {
    return <NotFound />;
  } else if (accountFetchError) {
    return <ApplicationError />;
  }

  const handlePageChange = (_: any, pageIndex: number) => {
    setPage(pageIndex + 1);
  };

  let error: string | null = dataQuery.error as null;
  if (dataQuery.error !== null) {
    error = (dataQuery.error as any).message as string;
  }

  return (
    <PrintRequestTemplate
      printRequests={data || []}
      membershipAccount={membershipAccount}
      page={page}
      pageSize={PRINT_REQUESTS_PER_PAGE}
      isLoading={dataQuery.isLoading || isLoading}
      error={error}
      onCancelRequest={handleCancelRequest}
      handlePageChange={handlePageChange}
    />
  );
};

export default PrintRequestView;
