import React, { ReactNode, useEffect, useState } from 'react';
import {
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@material-ui/core';
import ExceptionLog from '../../../../models/exceptionLog';
import ExceptionLogRow from './ExceptionLogRow';
import ExceptionLogPageActions from './ExceptionLogPageActions';

const useStyles = makeStyles({
  row: {
    cursor: 'pointer',
    '& > *': {
      borderBottom: 'unset',
    },
  },
  emptyRow: {
    cursor: 'default',
  },
  emptyRowCell: {
    textAlign: 'center',
  },
});

// Return empty ReactNode to override default behavior
const renderPaginationLabel = (): ReactNode => <></>;

interface ExceptionEventsTableProps {
  exceptionLogs?: ExceptionLog[];
  onPage: any;
  currentPage: number;
  pageSize: number;
}

const ExceptionLogsTable = (props: ExceptionEventsTableProps) => {
  const { exceptionLogs, currentPage, onPage } = props;
  const [pageSize, setPageSize] = useState(props.pageSize || 25);
  const [pageIndex, setPageIndex] = useState(currentPage - 1);

  const styles = useStyles();

  const handlePageChange = (e: any, index: number) => {
    onPage({ index });
    setPageIndex(pageIndex);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    onPage({ pageSize: +event.target.value });
  };

  useEffect(() => {
    setPageIndex(currentPage - 1);
  }, [currentPage]);

  useEffect(() => {
    setPageSize(props.pageSize || 25);
  }, [props.pageSize]);

  const renderPaging = () => (
    <TablePagination
      rowsPerPageOptions={[
        { label: '25 Rows', value: 25 },
        { label: '50 Rows', value: 50 },
        { label: '100 Rows', value: 100 },
      ]}
      component="div"
      colSpan={3}
      count={pageSize * (currentPage + 1) + (exceptionLogs?.length || 0)}
      labelDisplayedRows={(): ReactNode => renderPaginationLabel()}
      labelRowsPerPage={<span>Display:</span>}
      rowsPerPage={pageSize}
      page={currentPage}
      SelectProps={{
        inputProps: { 'aria-label': 'rows' },
      }}
      onChangePage={handlePageChange}
      onChangeRowsPerPage={handleChangeRowsPerPage}
      ActionsComponent={ExceptionLogPageActions}
    />
  );

  return (
    <div title="Audit Events">
      {renderPaging()}
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell width={60} />
              <TableCell width={200}>Date/Time</TableCell>
              <TableCell width={100}>Level</TableCell>
              <TableCell>Description</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {exceptionLogs && !exceptionLogs.length && (
              <TableRow className={`${styles.row} ${styles.emptyRow}`}>
                <TableCell colSpan={6} className={styles.emptyRowCell}>
                  No exception logs found
                </TableCell>
              </TableRow>
            )}
            {exceptionLogs &&
              exceptionLogs.map(
                (log: ExceptionLog, index: number) =>
                  (index < exceptionLogs.length - 1 || exceptionLogs.length <= pageSize) && (
                    <ExceptionLogRow key={log.uid} exceptionLog={log} />
                  ),
              )}
          </TableBody>
        </Table>
      </TableContainer>
      {exceptionLogs && exceptionLogs.length > 5 && renderPaging()}
    </div>
  );
};

export default ExceptionLogsTable;
