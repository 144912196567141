import React, { useState } from 'react';
import { Box, Button, Typography } from '@material-ui/core';
import { Redirect } from 'react-router-dom';
import { Action, Subject } from '@ccm-innovation/auth-membership-service';

import Page, { Breadcrumb } from '../../../components/Page';
import ImportAccountForm from './ImportForm';
import MembershipAccountsTable from './MembershipAccountsTable';
import { useSession } from '../../../context/session';

const MembershipAccounts: React.FC = () => {
  const { user } = useSession();
  const [modalOpen, setModalOpen] = useState(false);
  const breadcrumbs: Breadcrumb[] = [
    {
      href: '/',
      label: 'Home',
    },
    {
      href: '#',
      label: 'Membership',
    },
    {
      label: 'Membership Accounts',
    },
  ];

  // check permissions
  if (user && user.cannot(Action.View, Subject.MembershipAccount)) {
    return <Redirect to={{ pathname: '/not-found' }} />;
  }

  return (
    <Page
      title={
        <Box display="flex" justifyContent="space-between" alignItems="flex-start">
          <Typography variant="h2">Membership Accounts</Typography>
          {user && user.can(Action.Create, Subject.MembershipAccount) && (
            <Button color="primary" variant="contained" onClick={() => setModalOpen(true)}>
              Import Salesforce Account
            </Button>
          )}
        </Box>
      }
      breadcrumbs={breadcrumbs}
    >
      <MembershipAccountsTable />

      <ImportAccountForm open={modalOpen} close={() => setModalOpen(false)} />
    </Page>
  );
};

export default MembershipAccounts;
