import { Typography } from '@material-ui/core';
import { Variant } from '@material-ui/core/styles/createTypography';
import { Address } from '../../models/address';

interface AddressLabelProps {
  address: Address | undefined;
  emptyAddressStyles?: any;
  addressStyles?: any;
  variant?: Variant;
}

export const AddressLabel: React.FC<AddressLabelProps> = ({
  address,
  emptyAddressStyles,
  addressStyles,
  variant = 'body1',
}) => {
  if (!address) {
    return (
      <div>
        <Typography variant={variant} component="div">
          <div className={emptyAddressStyles}>{`- none -`}</div>
        </Typography>
      </div>
    );
  }

  const { address1, address2, city, state, postalCode } = address;
  const line1 = `${address1} ${address2 ? `, ${address2}` : ''}`;
  const line2 = `${city}, ${state} ${postalCode}`;

  return (
    <div>
      <Typography variant={variant} className={addressStyles} component="div">
        <div>{line1}</div>
        <div>{line2}</div>
      </Typography>
    </div>
  );
};
