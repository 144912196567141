import MemberProgram from '../../../../../models/memberProgram';
import { createStyles, Grid, makeStyles, Tab, Tabs, Theme, Typography } from '@material-ui/core';
import Spacer from '../../../../Spacer';
import EnrolledProgram from '../EnrolledProgram';
import { Alert } from '@material-ui/lab';
import React from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      display: 'flex',
      height: 560,
      width: 1100,
    },
    tabs: {
      borderRight: `1px solid ${theme.palette.divider}`,
    },

    tabPanel: {
      flex: 1,
      paddingLeft: 20,
    },
  }),
);
interface ProgramDeleteProps {
  memberProgram: MemberProgram;
  onTabClick: Function;
  currentTab: number;
}

const ProgramDelete = (props: ProgramDeleteProps) => {
  const { memberProgram, onTabClick, currentTab } = props;
  const classes = useStyles();

  const handleTabChange = (event: React.ChangeEvent<{}>, tabIndex: number) => {
    onTabClick(tabIndex);
  };

  return (
    <div className={classes.root}>
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={currentTab}
        onChange={handleTabChange}
        aria-label="Switch Program tabs"
        className={classes.tabs}
        indicatorColor="primary"
      >
        <Tab label="Delete Program" id="vertical-tab-0" />
        <Tab label="Confirmation" id="vertical-tab-1" />
      </Tabs>
      <div
        role="tabpanel"
        hidden={currentTab !== 0}
        id="vertical-tabpanel-0"
        className={classes.tabPanel}
      >
        <Typography variant="subtitle1">
          Clicking the next below you will take you to a confirmation screen where you will be asked
          to confirm that you want to permanently delete the program below with all its enrollments.
        </Typography>
        <Spacer size={15} />
        <Grid container>
          <Grid item xs={6}>
            <EnrolledProgram memberProgram={memberProgram} members={memberProgram.members || []} />
          </Grid>
        </Grid>
      </div>
      <div
        role="tabpanel"
        hidden={currentTab !== 1}
        id="vertical-tabpanel-1"
        className={classes.tabPanel}
      >
        <Alert variant="outlined" color="warning" severity="warning">
          These changes must be performed manually in all other systems including NetSuite, Rejoice
          and Eldorado.
        </Alert>
        <Spacer size={25} />
        <Grid container>
          <Grid item xs={6}>
            <EnrolledProgram memberProgram={memberProgram} members={memberProgram.members || []} />
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default ProgramDelete;
