import { useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';

import { Roles } from '@ccm-innovation/auth-membership-service';
import { RootState } from './store';

// Views
import { useSession } from './context/session';
import ClientStorage, { itemTypes } from './lib/clientStorage';
import MedicalBillReimbursementView from './modules/medical-bill-reimbursements/views/MedicalBillReimbursement.view';
import MedicalBillReimbursementsView from './modules/medical-bill-reimbursements/views/MedicalBillReimbursements.view';
import AppSettings from './views/AppSettings';
import AuditEvents from './views/AuditEvents';
import Dashboard from './views/Dashboard';
import ExceptionLogs from './views/ExceptionLogs';
import Login from './views/Login';
import NotFound from './views/NotFound';
import User from './views/User';
import Users from './views/Users';
import EDIFiles from './views/benefits/EDIFiles';
import Transactions from './views/benefits/Transactions';
import Groups from './views/groups';
import Group from './views/groups/Group';
import MembershipAccounts from './views/membership/accounts';
import MembershipAccount from './views/membership/accounts/Account';
import Organization from './views/organization/Organization';
import Organizations from './views/organization/Organizations';
import { BusinessLines } from './views/program-catalog/BusinessLines';

const SecureRoute = ({ children, ...props }: any) => {
  const { user } = useSelector((state: RootState) => state.session);

  if (!user) {
    // when the user logs out manually, the path has a key
    // whereas, if the user is not logged in and tries to go to a page directly, the key is not present
    const hasKey = !!props.location.key;
    if (!hasKey) {
      ClientStorage.store(itemTypes.REDIRECT_URL, props.location.pathname, true);
    }
    return <Redirect to={{ pathname: '/login' }} />;
  }

  return <Route {...props}>{children}</Route>;
};

const SystemAdminRoute = ({ children, ...props }: any) => {
  const { user } = useSession();

  if (!user) {
    return <Redirect to={{ pathname: '/login' }} />;
  }

  if (!user.hasRole(Roles.SystemAdmin)) {
    return <Redirect to={{ pathname: '/notfound' }} />;
  }

  return <Route {...props}>{children}</Route>;
};

const Routes = (props: any) => {
  return (
    <Switch>
      <Route path="/login" component={Login} />
      <SecureRoute exact path="/">
        <Dashboard />
      </SecureRoute>
      <SecureRoute exact path="/dashboard">
        <Dashboard />
      </SecureRoute>
      <SecureRoute exact path="/membership/organizations">
        <Organizations />
      </SecureRoute>
      <SecureRoute exact path="/membership/organizations/:uid">
        <Organization />
      </SecureRoute>
      <SecureRoute exact path="/membership/groups">
        <Groups />
      </SecureRoute>
      <SecureRoute exact path="/membership/groups/:uid">
        <Group />
      </SecureRoute>
      <SecureRoute exact path="/membership/accounts">
        <MembershipAccounts />
      </SecureRoute>
      <SecureRoute path="/membership/accounts/:uid">
        <MembershipAccount />
      </SecureRoute>
      <SecureRoute exact path="/medical-bill-reimbursements">
        <MedicalBillReimbursementsView />
      </SecureRoute>
      <SecureRoute exact path="/medical-bill-reimbursements/:uid">
        <MedicalBillReimbursementView />
      </SecureRoute>

      {/* System admin routes */}
      <SystemAdminRoute exact path="/system-admin/users">
        <Users />
      </SystemAdminRoute>
      <SystemAdminRoute exact path="/system-admin/users/:uid">
        <User />
      </SystemAdminRoute>
      <SystemAdminRoute exact path="/system-admin/audit">
        <AuditEvents />
      </SystemAdminRoute>
      <SystemAdminRoute exact path="/system-admin/app-settings">
        <AppSettings />
      </SystemAdminRoute>
      <SystemAdminRoute exact path="/system-admin/benefits-management/transactions">
        <Transactions />
      </SystemAdminRoute>
      <SystemAdminRoute exact path="/system-admin/benefits-management/edifiles">
        <EDIFiles />
      </SystemAdminRoute>
      <SystemAdminRoute exact path="/system-admin/exception-logs">
        <ExceptionLogs />
      </SystemAdminRoute>
      <SystemAdminRoute exact path="/system-admin/program-catalog">
        <BusinessLines />
      </SystemAdminRoute>

      <Route path="*" component={NotFound} />
    </Switch>
  );
};

export default Routes;
