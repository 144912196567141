import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, Redirect } from 'react-router-dom';
import { debounce } from 'lodash';
import {
  Avatar,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  makeStyles,
} from '@material-ui/core';
import { Action, Subject } from '@ccm-innovation/auth-membership-service';

import Modal from '../components/Modal';
import StatusBadge, { getStatusVariant } from '../components/StatusBadge';
import Page from '../components/Page';
import Spacer from '../components/Spacer';
import SearchInput from '../components/SearchInput';
import User from '../models/user';
import UserForm from '../components/users/UserForm';
import { getUsers } from '../store/slices/users';
import { activateUser, clearUser } from '../store/slices/user';
import { RootState } from '../store';
import { getAvatarColor } from '../lib/avatar';
import { useSession } from '../context/session';

interface UsersViewProps {}

const useStyles = makeStyles({
  row: {
    cursor: 'pointer',
    '& > *': {
      borderBottom: 'unset',
    },
  },
  emptyRow: {
    cursor: 'default',
  },
  emptyRowCell: {
    textAlign: 'center',
  },
});

function Row(props: { user: User; index: number }) {
  const { user, index } = props;
  const styles = useStyles();
  const history = useHistory();

  const handleUserRowClick = (uid: string) => {
    return history.push(`/system-admin/users/${uid}`);
  };

  return (
    <TableRow className={styles.row} hover onClick={() => handleUserRowClick(user.uid)}>
      <TableCell width={30}>
        <Avatar style={{ backgroundColor: getAvatarColor(index), color: '#ffffff' }}>
          {user.firstName.substring(0, 1)}
          {user.lastName.substring(0, 1)}
        </Avatar>
      </TableCell>
      <TableCell>
        {user.firstName} {user.lastName}
      </TableCell>
      <TableCell>{user.email}</TableCell>
      <TableCell>
        <StatusBadge variant={getStatusVariant(user.status)} label={user.status} />
      </TableCell>
    </TableRow>
  );
}

const UsersView = (props: UsersViewProps) => {
  const dispatch = useDispatch();
  const [searchInput, setSearchInput] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const [formDisabled, setFormDisabled] = useState(false);
  const { users } = useSelector((state: RootState) => state.users);
  const userState = useSelector((state: RootState) => state.user);
  const { user } = useSession();
  const styles = useStyles();
  const breadcrumbs = [
    { href: '/', label: 'Home' },
    { href: '#', label: 'System Administration' },
    { label: 'Users' },
  ];

  const handleUserSearch = debounce((val: string) => {
    setSearchInput(val);
  }, 250);

  const onActivateUser = (user: any) => {
    setFormDisabled(true);
    dispatch(activateUser(user.email));
  };

  const onCancelActivateUser = () => {
    setModalOpen(false);
    dispatch(clearUser());
  };

  useEffect(() => {
    dispatch(clearUser());
  }, [dispatch]);

  useEffect(() => {
    if (userState.user && userState.user.uid) {
      // if uid is present, then that means the user was successfully activated
      setModalOpen(false);
      setFormDisabled(false);
      dispatch(getUsers(''));
    }
  }, [dispatch, userState]);

  useEffect(() => {
    dispatch(getUsers(searchInput));
  }, [dispatch, searchInput]);

  if (user && user.cannot(Action.View, Subject.User)) {
    return <Redirect to={{ pathname: '/not-found' }} />;
  }

  return (
    <Page
      title={
        <Box className="pageTitle">
          <div>Users</div>
          <Button color="primary" variant="contained" onClick={() => setModalOpen(true)}>
            Add User
          </Button>
        </Box>
      }
      breadcrumbs={breadcrumbs}
    >
      <Box>
        <SearchInput placeholder="Search for users by name" withIcon onChange={handleUserSearch} />
      </Box>

      <Spacer size={30} />

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell width={72} />
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell width={150}>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((user: User, index: number) => (
              <Row key={user.uid} user={user} index={index} />
            ))}
            {!users.length && (
              <TableRow className={`${styles.row} ${styles.emptyRow}`}>
                <TableCell colSpan={5} className={styles.emptyRowCell}>
                  No records match your search criteria
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Modal
        open={modalOpen}
        style={{ width: 400 }}
        title="Activate User"
        onClose={() => setModalOpen(false)}
        disableBackdropClick
      >
        <UserForm
          onSubmit={onActivateUser}
          onCancel={onCancelActivateUser}
          disabled={formDisabled}
        />
      </Modal>
    </Page>
  );
};

export default UsersView;
