import { Action, Subject } from '@ccm-innovation/auth-membership-service';
import {
  Backdrop,
  Button,
  Card,
  CardContent,
  Fade,
  Grid,
  IconButton,
  Modal,
  Theme,
  Typography,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import moment from 'moment';
import { useEffect, useState } from 'react';

import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { useSession } from '../../../../context/session';
import { isInPast, removeUnderscoresFromString } from '../../../../lib/util';
import { InvoiceItemTypes } from '../../../../models/InvoiceItemTypes';
import Member from '../../../../models/member';
import MemberProgram, { InvoiceItem, MemberProgramStatus } from '../../../../models/memberProgram';
import Person from '../../../../models/person';
import ProgramEnrollment from '../../../../models/programEnrollment';
import { ProgramOption } from '../../../../models/programOption';
import ProviderNetworkAssignmentsView from '../../../../modules/provider-networks/views/ProviderNetworkAssignments.view';
import Spacer from '../../../Spacer';
import StatusBadge, { getStatusVariant } from '../../../StatusBadge';
import MembershipCancellation from '../../cancellation/MembershipCancellation';
import ProgramEnrollments from './ProgramEnrollments';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    modalTitle: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: '2rem',
    },
    modalBody: {
      minWidth: 1200,
      outline: 'none',
      maxWidth: '70%',
      maxHeight: '80%',
      overflow: 'auto',
      padding: '15px',
    },
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
    },
    programStatus: {
      display: 'flex',
      justifyContent: 'flex-end',
      textTransform: 'capitalize',
    },
    fees: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    feesWrapper: {
      marginTop: '10px',
    },
    buttonFMS: {
      display: 'flex',
      alignSelf: 'flex-end',
    },
    label: {
      fontWeight: 'bold',
      marginRight: '3px',
    },
    togglePriorEnrollments: {
      textTransform: 'none',
      fontWeight: 'bold',
    },
    memberWrapper: {
      marginTop: '30px',
    },
    buttonWrapper: {
      marginTop: '20px',
    },
    closeWrapper: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    cancelWrapper: {
      display: 'flex',
      justifyContent: 'flex-start',
    },
    closeButton: {
      marginLeft: '15px',
    },
    cancelProgram: {
      color: theme.palette.type === 'dark' ? '#f44336' : '#ffffff',
      backgroundColor: theme.palette.type === 'dark' ? 'rgba(244, 67, 54, 0.08)' : '#f44336',
    },
    cancelledReason: {
      display: 'flex',
      justifyContent: 'center',
    },
    pendingIcon: {
      marginLeft: '10px',
    },
    deleteProgramButton: {
      color: '#ffffff',
      marginLeft: '15px',
      backgroundColor: '#f44336',
      '&:hover': {
        background: '#B71C1C',
      },
    },
  }),
);

export default function MemberProgramModal({
  memberProgramOpen,
  handleMemberProgramClose,
  selectedMemberProgram,
  showProgramChangeDialog,
  showProgramUpdateDialog,
  membershipAccount,
  showReactivateProgramDialog,
  showDeleteProgramDialog,
  showPrice,
}: any) {
  const classes = useStyles();
  const { user } = useSession();
  const [enrollments, setEnrollments] = useState<ProgramEnrollment[] | []>([]);
  const [priorEnrollments, setPriorEnrollments] = useState<ProgramEnrollment[] | []>([]);
  const [latestPriorEnrollments, setLatestPriorEnrollments] = useState<ProgramEnrollment[] | []>(
    [],
  );
  const [showPriorEnrollments, setShowPriorEnrollments] = useState<boolean>(false);
  const [oneTimeFees, setOneTimeFees] = useState<InvoiceItem[]>([]);
  const [recurringFees, setRecurringFees] = useState<InvoiceItem[]>([]);
  const [showFMS, setShowFMS] = useState(false);
  const [isFutureStartDateProgram, setIsFutureStartDateProgram] = useState(false);

  useEffect(() => {
    if (selectedMemberProgram) {
      let priorEnrollments = membershipAccount?.programEnrollments?.filter(
        (enrollment: ProgramEnrollment) => {
          const { memberProgram }: { memberProgram: string | MemberProgram } = enrollment;

          if (typeof memberProgram === 'string') {
            return (
              memberProgram === selectedMemberProgram?.uid &&
              enrollment.ended &&
              isInPast(enrollment.ended)
            );
          } else {
            return (
              memberProgram &&
              memberProgram.uid === selectedMemberProgram?.uid &&
              enrollment.ended &&
              isInPast(enrollment.ended)
            );
          }
        },
      );

      priorEnrollments.map((enrollment: ProgramEnrollment) => {
        if (typeof enrollment.member === 'string') {
          enrollment.member = membershipAccount.members.find(
            (m: Member) => m.uid === enrollment.member,
          );
        }
        return enrollment;
      });

      const latestPriorEnrollments: ProgramEnrollment[] = Object.values(
        // Takes the priorEnrollments array and reduces it to a unique array of enrollments.
        priorEnrollments.reduce(
          (uniqueEnrollmentsPerPerson: any, enrollment: ProgramEnrollment) => {
            const member = enrollment.member as Member;
            const person = member.person as Person;
            const noEnrollmentForPerson = !uniqueEnrollmentsPerPerson[person?.uid];
            const enrollmentIsNewer =
              enrollment.started > uniqueEnrollmentsPerPerson[person.uid]?.started;
            // If person.uid is unique, add it to unique array, otherwise, check the enrollment.started is dated after (greater than) the unique[person.uid].started,
            // and replace the unique array's enrollment with a new enrollment.
            if (noEnrollmentForPerson || enrollmentIsNewer)
              uniqueEnrollmentsPerPerson[person.uid] = enrollment;
            // return the array:
            return uniqueEnrollmentsPerPerson;
          },
          [],
        ),
      );

      setLatestPriorEnrollments(latestPriorEnrollments);

      setPriorEnrollments(
        // Sorts by end date descending and by dependent number if end date is the same
        priorEnrollments.sort((a: ProgramEnrollment, b: ProgramEnrollment) => {
          if (moment(a.ended).isBefore(moment(b.ended))) return 1;
          if (moment(a.ended).isAfter(moment(b.ended))) return -1;
          if (moment(a.ended).isSame(moment(b.ended))) {
            if ((a.member as Member).dependentNumber > (b.member as Member).dependentNumber)
              return 1;
            if ((a.member as Member).dependentNumber < (b.member as Member).dependentNumber)
              return -1;
          }
          return 0;
        }),
      );

      const enrollments = membershipAccount?.programEnrollments?.filter(
        (enrollment: ProgramEnrollment) => {
          const { memberProgram }: { memberProgram: string | MemberProgram } = enrollment;

          if (typeof memberProgram === 'string') {
            return (
              memberProgram === selectedMemberProgram?.uid &&
              (!enrollment?.ended || (enrollment?.ended && !isInPast(enrollment.ended)))
            );
          } else {
            return (
              memberProgram &&
              memberProgram.uid === selectedMemberProgram?.uid &&
              (!enrollment?.ended || (enrollment?.ended && !isInPast(enrollment.ended)))
            );
          }
        },
      );

      setEnrollments(
        enrollments
          .map((enrollment: ProgramEnrollment) => {
            if (typeof enrollment.member === 'string') {
              enrollment.member = membershipAccount.members.find(
                (m: Member) => m.uid === enrollment.member,
              );
            }
            return enrollment;
          })
          // Sort by dependent number ascending
          .sort((a: ProgramEnrollment, b: ProgramEnrollment) => {
            if ((a.member as Member).dependentNumber > (b.member as Member).dependentNumber)
              return 1;
            if ((a.member as Member).dependentNumber < (b.member as Member).dependentNumber)
              return -1;
            return 0;
          }),
      );
    }

    //clean up when the modal closes
    return () => setShowFMS(false);
  }, [selectedMemberProgram, membershipAccount]);

  useEffect(() => {
    if (
      selectedMemberProgram &&
      selectedMemberProgram.fms &&
      selectedMemberProgram.fms.invoiceItems &&
      selectedMemberProgram.fms.invoiceItems.length
    ) {
      const unsortedItems = selectedMemberProgram.fms.invoiceItems;

      // Monthly share and Admin portions should be listed first
      const monthlyShareItems = unsortedItems.filter(
        (item: InvoiceItem) =>
          item.id === InvoiceItemTypes.MONTHLY_SHARE_PORTION ||
          item.id === InvoiceItemTypes.MONTHLY_ADMIN_PORTION,
      );

      monthlyShareItems.sort((i: InvoiceItem, j: InvoiceItem) => (i.id < j.id ? 1 : -1));

      // List other recurring fees in alphabetical order
      const otherRecurringFees = unsortedItems.filter(
        (item: InvoiceItem) =>
          item.occurrence === 'recurring' &&
          item.id !== InvoiceItemTypes.MONTHLY_SHARE_PORTION &&
          item.id !== InvoiceItemTypes.MONTHLY_ADMIN_PORTION,
      );

      otherRecurringFees.sort((i: InvoiceItem, j: InvoiceItem) => (i.id > j.id ? 1 : -1));

      // Finally list one time fees in alphabetical order
      const oneTimeFees = unsortedItems.filter(
        (item: InvoiceItem) => item.occurrence === 'one-time',
      );
      oneTimeFees.sort((i: InvoiceItem, j: InvoiceItem) => (i.id > j.id ? 1 : -1));

      setRecurringFees(monthlyShareItems.concat(otherRecurringFees));
      setOneTimeFees(oneTimeFees);
    }

    //clean up when the modal closes
    return () => setShowFMS(false);
  }, [selectedMemberProgram]);

  useEffect(() => {
    if (selectedMemberProgram && selectedMemberProgram) {
      return setIsFutureStartDateProgram(
        selectedMemberProgram.started &&
          selectedMemberProgram.status === MemberProgramStatus.Pending &&
          moment(selectedMemberProgram.started).isAfter(moment()),
      );
    }
  }, [selectedMemberProgram]);

  const hasActiveOrPriorEnrollments = enrollments.length > 0 || priorEnrollments.length > 0;
  const modalContent = () => (
    <>
      <div className={classes.modalTitle}>
        <Typography variant="h1">Member Program</Typography>
        <IconButton title="cancel" onClick={handleMemberProgramClose}>
          <CloseIcon />
        </IconButton>
      </div>
      <Grid container>
        <Grid item xs={4} className={classes.wrapper}>
          <Grid container>
            <Grid item xs={12}>
              <div style={{ display: 'flex' }}>
                <Typography variant="h3">
                  {selectedMemberProgram.programLevel.description}
                </Typography>
                <div style={{ marginLeft: '20px' }}>
                  <StatusBadge
                    color="success"
                    variant={getStatusVariant(selectedMemberProgram.status)}
                    label={removeUnderscoresFromString(selectedMemberProgram.status)}
                  />
                </div>
                {selectedMemberProgram.archived && (
                  <div style={{ marginLeft: '4px' }}>
                    <StatusBadge color="warning" variant="warn" label="archived" />
                  </div>
                )}
              </div>
            </Grid>
            <Grid item xs={12}>
              <ProviderNetworkAssignmentsView memberProgramUId={selectedMemberProgram.uid} />
            </Grid>
            <Grid item xs={12}>
              <span className={classes.label}>Effective: </span>
              {moment(selectedMemberProgram.started).format('ll')}
              {selectedMemberProgram.ended &&
                ' - ' + moment(selectedMemberProgram.ended).format('ll')}
            </Grid>
            <Grid item xs={12}>
              <span className={classes.label}>Options: </span>
              {(selectedMemberProgram?.programOptions.length &&
                selectedMemberProgram.programOptions.map((option: ProgramOption, index: number) => (
                  <span key={index}>
                    {index ? ', ' : ''} {option.description}
                  </span>
                ))) ||
                'n/a'}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4} className={classes.cancelledReason}>
          {selectedMemberProgram.cancelledReason && (
            <span>
              <span className={classes.label}>Canceled Reason: </span>
              {removeUnderscoresFromString(selectedMemberProgram.cancelledReason)}
            </span>
          )}
        </Grid>
        <Grid item xs={4} className={classes.wrapper}>
          {showPrice && (
            <Typography variant="h3" className={classes.fees}>
              ${selectedMemberProgram.currentPrice}/mo
            </Typography>
          )}

          {Boolean(
            (recurringFees && recurringFees.length > 0) || (oneTimeFees && oneTimeFees.length > 0),
          ) && (
            <>
              <Button
                variant="contained"
                color="primary"
                size="small"
                className={classes.buttonFMS}
                onClick={() => {
                  setShowFMS(!showFMS);
                }}
              >
                {showFMS ? 'Hide' : 'Show'} FMS
              </Button>

              {showFMS && (
                <div className={classes.feesWrapper}>
                  {recurringFees &&
                    recurringFees.length > 0 &&
                    recurringFees.map((invoiceItem: InvoiceItem, index: number) => (
                      <div className={classes.fees} key={index}>
                        <span className={classes.label}>{invoiceItem.description}: </span> $
                        {invoiceItem.amount.toFixed(2)}
                      </div>
                    ))}

                  {oneTimeFees && oneTimeFees.length > 0 && (
                    <>
                      <Spacer size={10} />
                      <Typography
                        variant="h6"
                        className={classes.fees}
                        style={{ fontWeight: 'bold' }}
                      >
                        One-Time Fees
                      </Typography>
                      {oneTimeFees.map((invoiceItem: InvoiceItem, index: number) => (
                        <div className={classes.fees} key={index}>
                          <span className={classes.label}>{invoiceItem.description}: </span> $
                          {invoiceItem.amount.toFixed(2)}
                        </div>
                      ))}
                    </>
                  )}
                </div>
              )}
            </>
          )}
        </Grid>
        <Grid item xs={12} className={classes.memberWrapper}>
          <Grid container>
            <Grid item xs={3}>
              <Typography variant="h4">Enrolled Members</Typography>
            </Grid>
            <Grid item xs={3}>
              {priorEnrollments.length > 0 && (
                <Button
                  color="primary"
                  className={classes.togglePriorEnrollments}
                  onClick={() => setShowPriorEnrollments(!showPriorEnrollments)}
                >
                  {showPriorEnrollments ? 'hide ' : 'show '} prior enrollments
                </Button>
              )}
            </Grid>
          </Grid>
          {enrollments?.length === 0 && (
            <p>
              <span>There are no currently enrolled members on this program</span>
            </p>
          )}
          <ProgramEnrollments enrollments={enrollments} />
        </Grid>
        <Grid item xs={12} className={classes.memberWrapper} hidden={!showPriorEnrollments}>
          <Grid container>
            <Grid item xs={3}>
              <Typography variant="h4">Prior Enrollments</Typography>
            </Grid>
          </Grid>
          <ProgramEnrollments enrollments={priorEnrollments} />
        </Grid>
        {!selectedMemberProgram.archived && (
          <Grid item xs={12} className={classes.buttonWrapper}>
            <Grid container>
              <Grid item xs={4} className={classes.cancelWrapper}>
                {Boolean(
                  selectedMemberProgram.status === 'active' && hasActiveOrPriorEnrollments,
                ) &&
                  user &&
                  user.can(Action.Cancel, Subject.MemberProgram) && (
                    <MembershipCancellation
                      membershipAccount={membershipAccount}
                      selectedMemberProgram={selectedMemberProgram}
                      onDialogClose={handleMemberProgramClose}
                    />
                  )}
                {(selectedMemberProgram.status === MemberProgramStatus.Cancelled ||
                  selectedMemberProgram.status === MemberProgramStatus.Inactive) &&
                  user &&
                  user.can(Action.Cancel, Subject.MemberProgram) && (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => showReactivateProgramDialog(latestPriorEnrollments)}
                    >
                      Reactivate Program
                    </Button>
                  )}
                {isFutureStartDateProgram && (
                  <Button
                    variant="contained"
                    className={classes.deleteProgramButton}
                    onClick={() => showDeleteProgramDialog()}
                  >
                    Delete Program
                    <DeleteForeverIcon />
                  </Button>
                )}
              </Grid>
              <Grid item xs={8} className={classes.closeWrapper}>
                {Boolean(selectedMemberProgram.status !== MemberProgramStatus.Cancelled) &&
                  user &&
                  user.can(Action.Update, Subject.MemberProgram) && (
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.closeButton}
                      onClick={() => showProgramUpdateDialog()}
                    >
                      Edit Program
                    </Button>
                  )}
                {Boolean(
                  selectedMemberProgram.status !== MemberProgramStatus.Cancelled &&
                    hasActiveOrPriorEnrollments,
                ) &&
                  user &&
                  user.can(Action.Switch, Subject.MemberProgram) && (
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.closeButton}
                      onClick={() => showProgramChangeDialog()}
                    >
                      Switch Program
                    </Button>
                  )}
                <Button
                  color="default"
                  variant="contained"
                  className={classes.closeButton}
                  onClick={handleMemberProgramClose}
                >
                  Close
                </Button>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </>
  );

  return (
    <>
      <Modal
        className={classes.modal}
        open={memberProgramOpen}
        onClose={handleMemberProgramClose}
        closeAfterTransition
        disableBackdropClick
        disableEscapeKeyDown
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={memberProgramOpen}>
          <Card className={classes.modalBody}>
            {selectedMemberProgram && selectedMemberProgram.fms && (
              <CardContent>{modalContent()}</CardContent>
            )}
          </Card>
        </Fade>
      </Modal>
    </>
  );
}
