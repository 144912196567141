import { FC } from 'react';
import moment from 'moment';
import { Divider, Grid, Theme, createStyles, makeStyles } from '@material-ui/core';
import { PrintRequestData } from '../../types/print-request';
import PrintRequestDetailsText from './PrintRequestDetailsText';
import PrintRequestDetailsSingleMember from './PrintRequestDetailsSingleMember';
import PrintRequestDetailsAddress from './PrintRequestDetailsAddress';
import PrintRequestDetailsManyMembers from './PrintRequestDetailsManyMembers';

interface PrintRequestDetailsProps {
  details: PrintRequestData;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      margin: '20px 0',
      padding: '30px',
      backgroundColor: theme.backgroundColors.body,
    },
  }),
);

const PrintRequestDetails: FC<PrintRequestDetailsProps> = ({ details }) => {
  const styles = useStyles();
  return (
    <Grid container direction="column" alignItems="flex-start" className={styles.container}>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <PrintRequestDetailsText header="account number" text={details.accountNumber} />
        </Grid>
        <Grid item xs={3}>
          <PrintRequestDetailsText header="program level" text={details.programLevel} />
        </Grid>
        <Grid item xs={3}>
          <PrintRequestDetailsText header="provider network" text={details.network} />
        </Grid>
        <Grid item xs={3}>
          <PrintRequestDetailsText
            header="dvt effective date"
            text={moment(details.dvtEffectiveDate).format('MM/DD/YYYY')}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Divider style={{ margin: '20px 0' }} />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <PrintRequestDetailsSingleMember header="subscriber" member={details.subscriber} />
        </Grid>
        {details.spouse && (
          <Grid item xs={3}>
            <PrintRequestDetailsSingleMember header="spouse" member={details.spouse} />
          </Grid>
        )}
        <Grid item xs={3}>
          <PrintRequestDetailsText header="mail to" text={details.mailToName} />
        </Grid>
        <Grid item xs={3}>
          <PrintRequestDetailsAddress header="mailing address" address={details.mailingAddress} />
        </Grid>
      </Grid>
      {details.dependents && details.dependents.length > 0 && (
        <>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Divider style={{ margin: '20px 0' }} />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <PrintRequestDetailsManyMembers header="dependents" members={details.dependents} />
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default PrintRequestDetails;
