import apiClient from './client';
import { APIClientBase } from './apiClientBase';
import User from '../../models/user';

class Users extends APIClientBase {
  private static _instance: Users;

  static getInstance(): Users {
    if (Users._instance === undefined) {
      Users._instance = new Users();
    }
    return Users._instance;
  }

  get = async (uid: string): Promise<User> => {
    try {
      const response = await apiClient.get(`/membership/users/${uid}`);
      const data = this.response(response);
      const user = new User(data);
      return user;
    } catch (err: any) {
      const error = this.error(err);
      throw error;
    }
  };

  list = async (query: any = {}): Promise<User[]> => {
    try {
      const queryString = this.toQueryString(query);
      const response = await apiClient.get(`/membership/users?${queryString}`);
      const data = this.response(response);
      const users: User[] = [];
      for (let i = 0; i < data.length; i++) {
        users.push(data[i]);
      }
      return users;
    } catch (err: any) {
      const error = this.error(err);
      throw error;
    }
  };

  verify = async (email: string): Promise<User> => {
    try {
      const response = await apiClient.get(`/membership/users/registration/${email}`);
      const data = this.response(response);
      const user = new User(data);
      user.authId = data.id;
      user.isActiveDirectoryUser = data.isActiveDirectoryUser;
      return user;
    } catch (err: any) {
      const error = this.error(err);
      throw error;
    }
  };

  activate = async (email: string): Promise<User> => {
    try {
      const response = await apiClient.put(`/membership/users/registration/${email}/status?active`);
      const data = this.response(response);
      const user = new User(data);
      return user;
    } catch (err: any) {
      const error = this.error(err);
      throw error;
    }
  };

  deactivate = async (email: string): Promise<User> => {
    try {
      const response = await apiClient.put(
        `/membership/users/registration/${email}/status?inactive`,
      );
      const data = this.response(response);
      const user = new User(data);
      return user;
    } catch (err: any) {
      const error = this.error(err);
      throw error;
    }
  };

  update = async (userData: User): Promise<User> => {
    try {
      const response = await apiClient.put(`/membership/users/${userData.uid}`, userData);
      const data = this.response(response);
      const user = new User(data);
      return user;
    } catch (err: any) {
      const error = this.error(err);
      throw error;
    }
  };
}

export default Users.getInstance();
