import PaginationTable from "../../components/PaginationTable";
import moment from "moment";
import { removeUnderscoresFromString } from "../../lib/util";
import apiClient from "../../lib/api";
import { Roles } from "@ccm-innovation/auth-membership-service";
import {
  Button,
} from "@material-ui/core";
import { useSession } from "../../context/session";
import { AddCircleOutline } from "@material-ui/icons";
import React, { useState } from "react";
import { BusinessLineDialog } from "./BusinessLineDialog";
import BusinessLine from "../../models/businessLine";


export const BusinessLinesTable = () => {

  const {user} = useSession();
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedBusinessLine, setSelectedBusinessLine] = useState<BusinessLine | undefined>(undefined);

  const openBusinessLineModal = () => {
    setModalOpen(true);
  }

  const onRowClick = (businessLine: BusinessLine) => {
    setSelectedBusinessLine(businessLine);
    openBusinessLineModal();
  }

  const closeBusinessLineModal = () => {
    setModalOpen(false);
    setSelectedBusinessLine(undefined);
  }

  return <>
    <PaginationTable
      title="Business Lines"
      buttons={
        <>
          {user && user.hasRole(Roles.SystemAdmin) && (
            <Button color="primary" variant="contained" startIcon={<AddCircleOutline/>}
                    onClick={openBusinessLineModal}>
              New Business Line
            </Button>
          )}
        </>
      }
      columns={[
        {width: 180, name: 'name', format: type => removeUnderscoresFromString(type)},
        {width: 150, name: 'description', format: action => removeUnderscoresFromString(action)},
        {
          width: 180,
          name: 'createdAt',
          as: 'Created',
          format: createdAt => moment(createdAt).format('YYYY-MM-DD HH:mm:ss'),
        },
      ]}
      queryKeys={['businessLines']}
      queryFunction={queryParams => apiClient.programCatalog.getBusinessLines(queryParams)}
      pageSize={10}
      formatDetailRowContent={rowData => <pre>{JSON.stringify(rowData.data, null, 2)}</pre>}
      noDataMessage="There are no configured business lines"
      onRowClick={onRowClick}
    />
    <BusinessLineDialog isOpen={modalOpen} closeDialog={closeBusinessLineModal} businessLine={selectedBusinessLine}/>
  </>
};