export default class BusinessLine {
  uid = '';
  name = '';
  description = '';
  created = '';

  constructor(data: any = {}) {
    this.uid = data.uid;
    this.name = data.name;
    this.description = data.description;
    this.created = data.createdAt;
  }
}