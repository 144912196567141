import React, { useState } from 'react';
import moment from 'moment';
import apiClient from '../lib/api';
import Page from '../components/Page';
import Spacer from '../components/Spacer';
import { useQuery, useQueryClient } from 'react-query';
import { Box, IconButton, Tooltip, Typography } from '@material-ui/core';
import { FilterList, Refresh } from '@material-ui/icons';
import ExceptionLog from '../models/exceptionLog';
import { ExceptionLogQueryParams, ExceptionLogNavType } from '../lib/api/exceptionLogs';
import ExceptionLogFilter from '../components/membership/account/exception-logs/ExceptionLogFilter';
import ExceptionLogsTable from '../components/membership/account/exception-logs/ExceptionLogsTable';

const ExceptionLogs = () => {
  const breadcrumbs = [
    { href: '/', label: 'Home' },
    { href: '#', label: 'System Administration' },
    { href: '/system-admin/exceptionLogs', label: 'Exception Logs' },
  ];

  const [queryParams, setQueryParams] = useState<ExceptionLogQueryParams>({
    pageSize: 25,
    nav: ExceptionLogNavType.first,
  });
  const [showFilter, setShowFilter] = useState(false);
  const queryClient = useQueryClient();

  const validateParams = ({ startDate, endDate }: ExceptionLogQueryParams): boolean =>
    [startDate, endDate].every(date => date === undefined || moment(date).isValid());

  const exceptionLogFetch = useQuery(
    ['exceptionLogs', queryParams],
    () => {
      if (!validateParams(queryParams)) return Promise.resolve([]);
      return apiClient.exceptionLogs.list(queryParams);
    },
    { keepPreviousData: false, cacheTime: 0, enabled: true },
  );

  const exceptionLogs: ExceptionLog[] | undefined = exceptionLogFetch.data;
  const getKeySetParams = (isNext: boolean): Partial<ExceptionLogQueryParams> => {
    if (exceptionLogs) {
      const boundaryLogIndex = isNext ? exceptionLogs.length - 1 : 0;
      const boundaryLog = exceptionLogs[boundaryLogIndex];
      if (boundaryLog) {
        return {
          id: boundaryLog.id,
          createdAt: boundaryLog.createdAt?.toISOString(),
        };
      }
      if (queryParams.id !== undefined) {
        const queryIdOffset = isNext ? 1 : -1;
        return {
          id: queryParams.id + queryIdOffset,
          createdAt: queryParams.createdAt,
        };
      }
    }
    return {};
  };

  const getPageNumber = (nav: ExceptionLogNavType) =>
    Object.values(ExceptionLogNavType).indexOf(nav);

  const handlePaging = ({ pageSize, index }: { pageSize: number; index: number | undefined }) => {
    const newQueryParams = {
      ...queryParams,
    };
    if (index !== undefined) {
      const navType = Object.values(ExceptionLogNavType)[index];
      newQueryParams.nav = navType;
      if (navType === ExceptionLogNavType.next) {
        Object.assign(newQueryParams, getKeySetParams(true));
      } else if (navType === ExceptionLogNavType.previous) {
        Object.assign(newQueryParams, getKeySetParams(false));
        if (newQueryParams.id === 0) {
          newQueryParams.nav = ExceptionLogNavType.last;
        }
      }
    }
    if (pageSize && pageSize !== queryParams.pageSize) {
      newQueryParams.pageSize = pageSize;
      if (exceptionLogs?.length) {
        newQueryParams.nav = ExceptionLogNavType.next;
        Object.assign(newQueryParams, getKeySetParams(false));
        newQueryParams.id = (newQueryParams?.id ?? 0) + 1;
      }
    }
    setQueryParams(newQueryParams);
  };

  const handleRefreshEvents = () => {
    queryClient.invalidateQueries(['exceptionLogs', queryParams]);
  };

  const filterChange = (newQueryParams: ExceptionLogQueryParams) => {
    const { createdAt, id, ...params } = newQueryParams;
    params.nav = ExceptionLogNavType.first;
    setQueryParams(params);
  };

  return (
    <Page breadcrumbs={breadcrumbs}>
      <Spacer size={15} />
      {/* Title */}
      <div style={{ display: 'flex' }}>
        <Box marginRight={2}>
          <Typography variant="h3">Exception Logs</Typography>
        </Box>
        <Box>
          <Tooltip title={'filter list'}>
            <IconButton color="default" size="small" onClick={() => setShowFilter(!showFilter)}>
              <FilterList />
            </IconButton>
          </Tooltip>
        </Box>
        <Box>
          <Tooltip title={'refresh events'}>
            <IconButton color="default" size="small" onClick={handleRefreshEvents}>
              <Refresh />
            </IconButton>
          </Tooltip>
        </Box>
      </div>

      {/* Exception Logs Filter */}
      {showFilter && (
        <ExceptionLogFilter
          queryParams={queryParams}
          onFilterChange={filterChange}
          showAllFilters={true}
        />
      )}

      {/* Exception Logs Table */}
      <ExceptionLogsTable
        exceptionLogs={exceptionLogs}
        onPage={handlePaging}
        pageSize={queryParams.pageSize}
        currentPage={getPageNumber(queryParams.nav)}
      />
    </Page>
  );
};

export default ExceptionLogs;
