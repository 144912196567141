import * as React from 'react';
import { useSelector } from 'react-redux';

import { RootState } from '../../store';
import { SessionState } from '../../store/slices/session';
import SessionUser from './sessionUser';

interface Session {
  user: SessionUser;
  accessToken: string | null;
  lastActive: Date | null;
}

type SessionProviderProps = { children: React.ReactNode };
const SessionContext = React.createContext<Session | undefined>(undefined);

function SessionProvider({ children }: SessionProviderProps) {
  const sessionState = useSelector((state: RootState) => state.session);

  const session = createSession(sessionState);

  return <SessionContext.Provider value={session}>{children}</SessionContext.Provider>;
}

export const createSession = (sessionState: SessionState): Session => {
  let sessionUser: SessionUser | null = null;
  if (sessionState.user) {
    sessionUser = new SessionUser();
    Object.assign(sessionUser, sessionState.user);
    sessionUser.init();
  }

  const session = {
    user: sessionUser,
    accessToken: sessionState.accessToken,
    lastActive: sessionState.lastActive,
  } as Session;

  return session;
};

const useSession = () => {
  const context = React.useContext(SessionContext);
  if (context === undefined) {
    throw new Error('useSession must be used within a SessionProvider');
  }
  return context;
};

export { SessionProvider, useSession };
