import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Thunk } from '..';
import apiClient from '../../lib/api';
import MembershipAccount from '../../models/membershipAccount';
import CustomError from '../../lib/customError';
import { showToastMessage } from './toastMessage';
import { MembershipAccountQueryParams } from '../../lib/api/membershipAccounts';
interface MembershipAccountsState {
  accounts: MembershipAccount[];
  loading: boolean;
  page?: number | null;
  error: CustomError | null;
}

interface AccountsSuccessPayload {
  accounts: MembershipAccount[];
  page?: number | null;
}

interface AccountsErrorPayload {
  error: CustomError;
}

export const initialState = {
  accounts: [],
  loading: false,
  error: null,
} as MembershipAccountsState;

const membershipAccountsSlice = createSlice({
  name: 'membershipAccounts',
  initialState,
  reducers: {
    getAccountsRequest(state) {
      state.error = null;
      state.page = null;
      state.loading = true;
    },
    getAccountsSuccess(state, action: PayloadAction<AccountsSuccessPayload>) {
      state.accounts = action.payload.accounts;
      state.page = action.payload.page;
      state.error = null;
      state.loading = false;
    },
    getAccountsError(state, action: PayloadAction<AccountsErrorPayload>) {
      state.accounts = [];
      state.page = null;
      state.error = action.payload.error;
      state.loading = false;
    },
    clearAccounts(state) {
      state.accounts = [];
      state.page = null;
      state.error = null;
      state.loading = false;
    },
  },
});

/* Thunks */
export const getAccounts = (query: MembershipAccountQueryParams): Thunk => async dispatch => {
  try {
    dispatch(getAccountsRequest());
    const accounts = await apiClient.membershipAccounts.list(query);
    let payload: AccountsSuccessPayload = { accounts };
    if (query && query.page) {
      payload.page = query.page;
    }
    dispatch(getAccountsSuccess(payload));
  } catch (err: any) {
    dispatch(getAccountsError({ error: err }));
    dispatch(showToastMessage({ message: err.message, type: 'error' }));
  }
};

/* Actions & Reducer */
/* prettier-ignore */
export const {
  getAccountsRequest, getAccountsSuccess, getAccountsError, clearAccounts,
} = membershipAccountsSlice.actions;

export default membershipAccountsSlice.reducer;
