import axios, { AxiosInstance } from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';

import * as apiClient from './client';
import { getDecodedAuthenticationResponse } from './authentication';
import ClientStorage, { itemTypes } from '../../lib/clientStorage';
import { handleError } from '../customError';
import { logout } from '../../store/slices/session';

export const createGeneralRequestInterceptor = (client: AxiosInstance) => {
  client.interceptors.request.use(async request => {
    try {
      const accessTokenData = ClientStorage.retrieve(itemTypes.ACCESS_TOKEN, true);
      if (accessTokenData) {
        request.headers['Authorization'] = `Bearer ${accessTokenData.accessToken}`;
      }
    } catch (err: any) {
      const error = handleError(err);
      throw error;
    }
    return request;
  });
};

// RESPONSE INTERCEPTORS
/**
 * Adds a response interceptor for every incomming response
 * @param {AxiosInstance} client - axios instance to which to add the interceptor
 */
export const createGeneralResponseInterceptor = (client: AxiosInstance) => {
  client.interceptors.response.use(
    async response => response,
    async errorResponse => {
      const error = handleError(errorResponse);
      if (error.status === 401) {
        // Since we are getting a 401 Unauthorized response, either the user's token is invalid or
        // the refresh token routine did not succeed so redirect the user to the login screen
        ClientStorage.store(itemTypes.REDIRECT_URL, window.location.pathname, true);
        apiClient.getStore().dispatch(logout());
        throw error;
      } else if (error.status === 403) {
        // This action was forbidden by the API due to a role or permissions conflict. The user will see a message
        // indicating the action was forbidden. This case should only occur if the user was logged in to the
        // application while there permissions were modified.

        // TODO: implement a message and/or logic to update the user's token; possibly returning the user to the
        // login screen to re-authenticate or showing a dialog to enter their password again. This would cause the
        // locally stored access token to re-generate with the new permissions.
        throw error;
      }

      if (error.message === 'Network Error' && error.status === 0) {
        error.message +=
          ': No response received. \rYour device may be offline or our service is having a bad day <span style="white-space:nowrap;overflow:hidden;">¯\\_(ツ)_/¯</span>';
      }

      throw error;
    },
  );
};

/**
 * Adds a retry on a 401 using the refresh token to get a new access token
 * @param {AxiosInstance} client - axios instance to which to add the interceptor
 */
export const createAuthTokenRefreshInterceptor = (client: AxiosInstance, axiosConfig: any) => {
  createAuthRefreshInterceptor(
    client,
    async failedRequest => {
      // Do not refresh the request since we did not receive the refresh indication from
      // the API. This will now be handled by the default response interceptor.
      if (!failedRequest.response.headers['x-auth-refresh']) {
        return Promise.reject(failedRequest);
      }

      // try to get new token using refresh token
      const accessTokenData = ClientStorage.retrieve(itemTypes.ACCESS_TOKEN, true);

      // create a new axios client for the refresh; include withCredentials to send the refresh token cookie
      const requestConfig = { ...axiosConfig, withCredentials: true };
      if (accessTokenData) {
        if (!requestConfig.headers) {
          requestConfig.headers = {};
        }
        requestConfig.headers['Authorization'] = `Bearer ${accessTokenData.accessToken}`;
      }
      const refreshClient = axios.create(requestConfig);

      return refreshClient
        .put(`/membership/authn/tokens`)
        .then(async tokenRefreshResponse => {
          // update access & refresh tokens
          const responseData = tokenRefreshResponse.data;
          const authResponse = getDecodedAuthenticationResponse(responseData);

          const accessTokenData = {
            accessToken: authResponse.accessToken,
            issuedAt: authResponse.accessTokenIssuedAt,
            expiresAt: authResponse.accessTokenExpiresAt,
            refreshTokenId: authResponse.refreshTokenId,
          };

          ClientStorage.store(itemTypes.ACCESS_TOKEN, accessTokenData, true);
          failedRequest.response.config.headers[
            'Authorization'
          ] = `Bearer ${accessTokenData.accessToken}`;
          return Promise.resolve();
        })
        .catch(err => {
          const error = handleError(err);
          error.type = 'token_refresh'; // this is a special type to indicate that a token refresh attempt failed
          return Promise.reject(error);
        });
    },
    { statusCodes: [401] },
  );
};
