import React from 'react';

/**
 * Spacer
 * @param {*} props
 * Spacer accepts a size parameter and outputs a component to add vertical space
 * to the UI
 */

interface Props {
  size: number;
}

const Spacer: React.FC<Props> = (props) => {
  const { size } = props;
  return <div style={{ height: size }} />;
};

export default Spacer;
