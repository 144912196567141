import React from 'react';

import Page, { Breadcrumb } from '../components/Page';

const DashboardView = (props: any) => {
  const breadcrumbs: Breadcrumb[] = [{ href: '/', label: 'Home' }, { label: 'Dashboard' }];

  return <Page title="Dashboard" breadcrumbs={breadcrumbs}></Page>;
};

export default DashboardView;
