import { Roles } from '@ccm-innovation/auth-membership-service';
import { Button, CircularProgress, Grid } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { useEffect, useState } from 'react';
import { useSession } from '../../../context/session';
import { getProviderNetworkAssignmentForDisplay } from '../helpers/provider-network-assignments';
import { ProviderNetworkAssignment } from '../types/provider-network-assignment';
import ProviderNetworkAssignmentDisplay from './ProviderNetworkAssignmentDisplay';
import ProviderNetworkAssignmentHistoryDialog from './ProviderNetworkAssignmentHistory.dialog';

interface ProviderNetworkAssignmentsTemplateProps {
  providerNetworkAssignments?: ProviderNetworkAssignment[];
  loading: boolean;
  assignProviderNetworks: () => void;
}

const useProviderNetworkAssignmentDisplay = (
  providerNetworkAssignments?: ProviderNetworkAssignment[],
) => {
  const [assignmentForDisplay, setAssignmentForDisplay] = useState<
    ProviderNetworkAssignment | undefined
  >(undefined);

  useEffect(() => {
    if (providerNetworkAssignments) {
      const displayAssignment = getProviderNetworkAssignmentForDisplay(providerNetworkAssignments);

      if (displayAssignment) {
        setAssignmentForDisplay(displayAssignment);
      }
    }
  }, [providerNetworkAssignments]);

  return { assignmentForDisplay };
};

export const ProviderNetworkAssignmentsTemplate = ({
  providerNetworkAssignments,
  loading,
  assignProviderNetworks,
}: ProviderNetworkAssignmentsTemplateProps) => {
  const { user } = useSession();
  const [showNetworkAssignmentHistory, setShowNetworkAssignmentHistory] = useState(false);
  const { assignmentForDisplay } = useProviderNetworkAssignmentDisplay(providerNetworkAssignments);

  const handleToggleProviderNetworkAssignmentHistory = () => {
    setShowNetworkAssignmentHistory(!showNetworkAssignmentHistory);
  };

  const handleCloseProviderNetworkAssignmentHistoryDialog = () => {
    setShowNetworkAssignmentHistory(false);
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        {loading ? (
          <Skeleton animation="wave" height={30} />
        ) : (
          <ProviderNetworkAssignmentDisplay providerNetworkAssignment={assignmentForDisplay} />
        )}
        {providerNetworkAssignments && providerNetworkAssignments?.length > 1 && (
          <Button
            type="button"
            variant="contained"
            size="small"
            color="primary"
            style={{ marginLeft: '5px' }}
            onClick={handleToggleProviderNetworkAssignmentHistory}
          >
            History
          </Button>
        )}
        {(providerNetworkAssignments &&
          providerNetworkAssignments?.length === 0 &&
          user &&
          (user.hasRole(Roles.SystemAdmin) ||
            user.hasRole(Roles.AppsAdmin) ||
            user.hasRole(Roles.AppsUser) ||
            user.hasRole(Roles.BillingAdmin)) && (
            <Button
              type="button"
              variant="contained"
              size="small"
              color="primary"
              disabled={loading}
              style={{ marginLeft: '5px' }}
              onClick={assignProviderNetworks}
            >
              Assign Provider Network{' '}
              {loading ? (
                <div
                  style={{
                    position: 'absolute',
                    top: '60%',
                    left: '40%',
                    transform: 'translate(50%, -50%)',
                  }}
                >
                  <CircularProgress size="1.5rem" />
                </div>
              ) : null}
            </Button>
          )) ??
          null}
      </Grid>
      <Grid item xs={12}>
        <ProviderNetworkAssignmentHistoryDialog
          open={showNetworkAssignmentHistory}
          onClose={handleCloseProviderNetworkAssignmentHistoryDialog}
          data={providerNetworkAssignments}
        />
      </Grid>
    </Grid>
  );
};

export default ProviderNetworkAssignmentsTemplate;
