import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '../Dialog';
import Organization from '../../models/organization';
import { saveOrganization } from '../../store/slices/organization';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles(theme => ({
  defaultOrganizationForm: {
    width: 475,
  },
  dialogContainer: {
    flexGrow: 1,
    display: 'flex',
  },
}));

interface DefaultOrganizationModalProps {
  open: boolean;
  organization: Organization;
  showModal: (open: boolean) => void;
}

export const DefaultOrganizationModal: React.FC<DefaultOrganizationModalProps> = ({
  open,
  organization,
  showModal,
}) => {
  const styles = useStyles();
  const dispatch = useDispatch();

  const cancel = () => {
    showModal(false);
  };

  const confirm = () => {
    dispatch(
      saveOrganization({
        default: true,
        uid: organization.uid,
        organizationName: organization.organizationName,
        status: organization.status,
      }),
    );
    showModal(false);
  };

  const renderButtons = () => {
    const yesButton = (
      <Button
        color="primary"
        variant="contained"
        size="large"
        onClick={confirm}
        key="add"
        fullWidth
      >
        Yes
      </Button>
    );
    const noButton = (
      <Button
        color="default"
        variant="contained"
        size="large"
        key="cancel"
        onClick={cancel}
        fullWidth
      >
        No
      </Button>
    );
    return [yesButton, noButton];
  };

  return (
    <Dialog title="Set as Default" open={open} buttons={renderButtons()}>
      <div className={styles.dialogContainer}>
        <form className={styles.defaultOrganizationForm}>
          Are you sure you want to make this the default organization?
        </form>
      </div>
    </Dialog>
  );
};
