import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import moment from 'moment';

import EligibleProgramView from './EligibleProgram';
import EnrolledProgram from './EnrolledProgram';
import EligibleProgram from '../../../../models/eligibleProgram';
import Spacer from '../../../Spacer';
import MemberProgram from '../../../../models/memberProgram';
import Member from '../../../../models/member';

interface ProgramChangeConfirmationProps {
  memberProgram: MemberProgram;
  members: Member[];
  selectedEligiblePrograms: EligibleProgram[];
  startDate: string;
  handleProgramLevelSelected: Function;
}

const ProgramChangeConfirmation = (props: ProgramChangeConfirmationProps) => {
  const {
    memberProgram,
    members,
    selectedEligiblePrograms,
    handleProgramLevelSelected,
    startDate,
  } = props;

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Typography variant="h6">Enrolled Program</Typography>
          {memberProgram && <EnrolledProgram memberProgram={memberProgram} members={members} />}
        </Grid>
      </Grid>

      <Spacer size={30} />

      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Typography variant="h6">Start Date</Typography>
          <Typography variant="h5">{moment(startDate).format('LL')}</Typography>
        </Grid>
      </Grid>

      <Spacer size={20} />

      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Typography variant="h6">New Program(s)</Typography>
          {selectedEligiblePrograms && (
            <div style={{ height: '360px', overflow: 'auto' }}>
              {selectedEligiblePrograms.map((program: EligibleProgram, index: number) => (
                <EligibleProgramView
                  key={index}
                  program={program}
                  enrolledProgram={memberProgram}
                  onSelectProgramLevel={handleProgramLevelSelected}
                  members={members}
                  isSelected={true}
                  readOnly={true}
                />
              ))}
            </div>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default ProgramChangeConfirmation;
