import React from 'react';
import * as yup from 'yup';
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import InputMask from 'react-input-mask';
import { makeStyles } from '@material-ui/core/styles';
import Person from '../../models/person';
import { useFormik, FormikProps } from 'formik';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
  buttonContainer: {
    marginTop: '20px',
  },
  modalTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '2rem',
  },
  closeButton: {
    marginLeft: '15px',
    width: '30%',
    float: 'right',
  },
  updateButton: {
    width: '30%',
    float: 'right',
  },
}));

const accountMemberFormSchema = yup.object({
  prefix: yup.string().trim(),
  firstName: yup.string().trim().required('This field is required'),
  middleName: yup.string().trim(),
  lastName: yup.string().trim().required('This field is required'),
  suffix: yup.string().trim(),
  email: yup.string().email('Invalid email').trim(),
  dob: yup.date(),
  maritalStatus: yup.string().trim(),
  primaryPhone: yup.string().trim(),
  mobilePhone: yup.string().trim(),
  otherPhone: yup.string().trim(),
  gender: yup.string().trim(),
  deceased: yup.string().trim(),
  ssn: yup.string().trim(),
});

const AccountMemberForm = (props: any & FormikProps<Person>) => {
  const { member, toggleModal, handleSubmit } = props;
  const styles = useStyles();

  const getTypedInitialValues: Person = {
    id: '',
    prefix: member.person.prefix || '',
    firstName: member.person.firstName || '',
    middleName: member.person.middleName || '',
    lastName: member.person.lastName || '',
    suffix: member.person.suffix || '',
    email: member.person.email || '',
    dob: member.person.dob ? moment(member.person.dob).toISOString() : '',
    maritalStatus: member.person.maritalStatus || '',
    primaryPhone: member.person.primaryPhone || '',
    mobilePhone: member.person.mobilePhone || '',
    otherPhone: member.person.otherPhone || '',
    gender: member.person.gender || '',
    deceased: member.person.deceased || '',
    ssn: member.person.ssn || '',
    uid: member.person.uid || '',
  };

  const accountMemberForm = useFormik({
    initialValues: getTypedInitialValues,
    validationSchema: accountMemberFormSchema,
    onSubmit: values => {
      const trimRegEx = /\)|\(|\s|-/g;
      const changedValues: any = {};
      values.ssn = values.ssn && values.ssn.replaceAll(trimRegEx, '');
      values.primaryPhone = values.primaryPhone && values.primaryPhone.replaceAll(trimRegEx, '');
      values.mobilePhone = values.mobilePhone && values.mobilePhone.replaceAll(trimRegEx, '');
      values.otherPhone = values.otherPhone && values.otherPhone.replaceAll(trimRegEx, '');

      for (const [key, value] of Object.entries(values)) {
        if (values[key] !== accountMemberForm.initialValues[key]) changedValues[key] = value;
      }
      changedValues.uid = member.person.uid;
      handleSubmit(changedValues);
    },
  });

  return (
    <form noValidate onSubmit={accountMemberForm.handleSubmit}>
      <div className={styles.modalTitle}>
        <Typography variant="h3">Update Member</Typography>
      </div>
      <Grid container spacing={4}>
        <Grid item xs={4}>
          <Typography variant="h6">Member Name</Typography>
          <TextField
            id="prefix"
            name="prefix"
            label="Prefix"
            margin="normal"
            variant="outlined"
            autoComplete="none"
            fullWidth
            value={accountMemberForm.values.prefix}
            onChange={accountMemberForm.handleChange}
            error={accountMemberForm.touched.prefix && Boolean(accountMemberForm.errors.prefix)}
            helperText={accountMemberForm.touched.prefix && accountMemberForm.errors.prefix}
            inputProps={{
              maxLength: 150,
            }}
          />
          <TextField
            id="firstName"
            name="firstName"
            label="First Name"
            margin="normal"
            variant="outlined"
            autoComplete="none"
            fullWidth
            value={accountMemberForm.values.firstName}
            onChange={accountMemberForm.handleChange}
            error={
              accountMemberForm.touched.firstName && Boolean(accountMemberForm.errors.firstName)
            }
            helperText={accountMemberForm.touched.firstName && accountMemberForm.errors.firstName}
            inputProps={{
              maxLength: 150,
            }}
          />
          <TextField
            id="middleName"
            name="middleName"
            label="Middle Name"
            margin="normal"
            variant="outlined"
            autoComplete="none"
            fullWidth
            value={accountMemberForm.values.middleName}
            onChange={accountMemberForm.handleChange}
            error={
              accountMemberForm.touched.middleName && Boolean(accountMemberForm.errors.middleName)
            }
            helperText={accountMemberForm.touched.middleName && accountMemberForm.errors.middleName}
            inputProps={{
              maxLength: 150,
            }}
          />
          <TextField
            id="lastName"
            name="lastName"
            label="Last Name"
            margin="normal"
            variant="outlined"
            autoComplete="none"
            fullWidth
            value={accountMemberForm.values.lastName}
            onChange={accountMemberForm.handleChange}
            error={accountMemberForm.touched.lastName && Boolean(accountMemberForm.errors.lastName)}
            helperText={accountMemberForm.touched.lastName && accountMemberForm.errors.lastName}
            inputProps={{
              maxLength: 150,
            }}
          />
          <TextField
            id="suffix"
            name="suffix"
            label="Suffix"
            margin="normal"
            variant="outlined"
            autoComplete="none"
            fullWidth
            value={accountMemberForm.values.suffix}
            onChange={accountMemberForm.handleChange}
            error={accountMemberForm.touched.suffix && Boolean(accountMemberForm.errors.suffix)}
            helperText={accountMemberForm.touched.suffix && accountMemberForm.errors.suffix}
            inputProps={{
              maxLength: 150,
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <Typography variant="h6">Contact Information</Typography>
          <TextField
            id="email"
            name="email"
            label="Email"
            margin="normal"
            variant="outlined"
            autoComplete="none"
            fullWidth
            type="email"
            value={accountMemberForm.values.email}
            onChange={accountMemberForm.handleChange}
            error={accountMemberForm.touched.email && Boolean(accountMemberForm.errors.email)}
            helperText={accountMemberForm.touched.email && accountMemberForm.errors.email}
          />
          <InputMask
            mask="(999) 999-9999"
            value={accountMemberForm.values.primaryPhone}
            onChange={accountMemberForm.handleChange}
          >
            {() => (
              <TextField
                id="primaryPhone"
                name="primaryPhone"
                label="Primary Phone"
                margin="normal"
                variant="outlined"
                autoComplete="none"
                fullWidth
                value={accountMemberForm.values.primaryPhone}
                error={
                  accountMemberForm.touched.primaryPhone &&
                  Boolean(accountMemberForm.errors.primaryPhone)
                }
                helperText={
                  accountMemberForm.touched.primaryPhone && accountMemberForm.errors.primaryPhone
                }
              />
            )}
          </InputMask>
          <InputMask
            mask="(999) 999-9999"
            value={accountMemberForm.values.mobilePhone}
            onChange={accountMemberForm.handleChange}
          >
            {() => (
              <TextField
                id="mobilePhone"
                name="mobilePhone"
                label="Mobile Phone"
                margin="normal"
                variant="outlined"
                autoComplete="none"
                fullWidth
                value={accountMemberForm.values.mobilePhone}
                error={
                  accountMemberForm.touched.mobilePhone &&
                  Boolean(accountMemberForm.errors.mobilePhone)
                }
                helperText={
                  accountMemberForm.touched.mobilePhone && accountMemberForm.errors.mobilePhone
                }
              />
            )}
          </InputMask>
          <InputMask
            mask="(999) 999-9999"
            value={accountMemberForm.values.otherPhone}
            onChange={accountMemberForm.handleChange}
          >
            {() => (
              <TextField
                id="otherPhone"
                name="otherPhone"
                label="Other Phone"
                margin="normal"
                variant="outlined"
                autoComplete="none"
                fullWidth
                value={accountMemberForm.values.otherPhone}
                error={
                  accountMemberForm.touched.otherPhone &&
                  Boolean(accountMemberForm.errors.otherPhone)
                }
                helperText={
                  accountMemberForm.touched.otherPhone && accountMemberForm.errors.otherPhone
                }
              />
            )}
          </InputMask>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="h6">Misc Information</Typography>
          <FormControl margin="normal" fullWidth>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                id="dob"
                label="Date of Birth"
                inputVariant="outlined"
                format="MM/dd/yyyy"
                value={moment(accountMemberForm.values.dob).toDate()}
                onChange={val => {
                  accountMemberForm.setFieldValue('dob', val);
                }}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
            </MuiPickersUtilsProvider>
          </FormControl>
          <FormControl variant="outlined" margin="normal" fullWidth>
            <InputLabel id="gender-label">Gender</InputLabel>
            <Select
              id="gender"
              name="gender"
              label="gender"
              labelId="gender-label"
              value={accountMemberForm.values.gender}
              onChange={accountMemberForm.handleChange}
            >
              <MenuItem value="male">Male</MenuItem>
              <MenuItem value="female">Female</MenuItem>
            </Select>
          </FormControl>
          <FormControl variant="outlined" margin="normal" fullWidth>
            <InputLabel id="marital-status-label">Marital Status</InputLabel>
            <Select
              id="maritalStatus"
              name="maritalStatus"
              label="maritalStatus"
              labelId="maritalStatus-label"
              value={accountMemberForm.values.maritalStatus}
              onChange={accountMemberForm.handleChange}
            >
              <MenuItem value="single">Single</MenuItem>
              <MenuItem value="married">Married</MenuItem>
              <MenuItem value="divorced">Divorced</MenuItem>
              <MenuItem value="widowed">Widowed</MenuItem>
            </Select>
          </FormControl>
          <InputMask
            mask="999-99-9999"
            value={accountMemberForm.values.ssn}
            onChange={accountMemberForm.handleChange}
          >
            {() => (
              <TextField
                autoComplete="none"
                id="ssn"
                name="ssn"
                label="Social Security Number"
                margin="normal"
                variant="outlined"
                fullWidth
                value={accountMemberForm.values.ssn}
                error={accountMemberForm.touched.ssn && Boolean(accountMemberForm.errors.ssn)}
                helperText={accountMemberForm.touched.ssn && accountMemberForm.errors.ssn}
              />
            )}
          </InputMask>
        </Grid>
      </Grid>

      <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
        <Button
          color="primary"
          variant="contained"
          size="large"
          className={styles.updateButton}
          disabled={
            !(accountMemberForm.isValid && accountMemberForm.dirty) ||
            accountMemberForm.isSubmitting
          }
          onClick={accountMemberForm.submitForm}
          fullWidth
        >
          Update
        </Button>
        <Button
          color="default"
          variant="contained"
          size="large"
          className={styles.closeButton}
          onClick={() => toggleModal(false)}
          fullWidth
          disabled={accountMemberForm.isSubmitting}
        >
          Cancel
        </Button>
      </div>
    </form>
  );
};

export default AccountMemberForm;
