import moment from 'moment';
import { Card, CardContent, Grid, Typography, Divider, Hidden } from '@material-ui/core';
import LaunchIcon from '@material-ui/icons/Launch';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';

export default function MembershipAccountDetails({
  membershipAccount,
  setSnackbarMessage,
  setSnackbarOpen,
}: any) {
  const showSnackbar = (message: string) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const handleCopyID = (idName: string) => {
    switch (idName) {
      case 'sfAccountId':
        if (membershipAccount.sfAccountId) {
          navigator.clipboard.writeText(membershipAccount.sfAccountId);
          showSnackbar('Copied Salesforce Account Id');
        }
        break;
      case 'sfAccountUId':
        if (membershipAccount.sfAccountUId) {
          navigator.clipboard.writeText(membershipAccount.sfAccountUId);
          showSnackbar('Copied Salesforce Account UID');
        }
        break;
      case 'gumd':
        if (membershipAccount.gumd) {
          navigator.clipboard.writeText(membershipAccount.gumd);
          showSnackbar('Copied GUMD');
        }
        break;
      case 'certNumber':
        if (membershipAccount.certNumber) {
          navigator.clipboard.writeText(membershipAccount.certNumber);
          showSnackbar('Copied Cert Number');
        }
        break;
      case 'netsuiteId':
        if (membershipAccount.netsuiteId) {
          navigator.clipboard.writeText(membershipAccount.netsuiteId);
          showSnackbar('Copied NetSuite Id');
        }
        break;
    }
  };

  const openSFAccount = (sfAccountId: string) => {
    const sfAccountUrl = process.env.REACT_APP_SALESFORCE_ACCOUNT_VIEW_URL + `/${sfAccountId}`;
    window.open(sfAccountUrl);
  };

  const renderOtherId = (otherIdName: string, otherIdValue: string, otherIdKey: string) => (
    <Grid item xs={12}>
      <Typography variant="h6">
        {otherIdName}
        {!!otherIdValue && (
          <FileCopyOutlinedIcon
            style={{ fontSize: '0.9rem', marginLeft: 10, cursor: 'pointer' }}
            onClick={() => handleCopyID(otherIdKey)}
          />
        )}
        {otherIdKey === 'sfAccountId' && otherIdValue.indexOf('INVALID') === -1 && (
          <LaunchIcon
            style={{ fontSize: '0.9rem', marginLeft: 10, cursor: 'pointer' }}
            onClick={() => openSFAccount(otherIdValue)}
          />
        )}
      </Typography>
      {((!!otherIdValue && otherIdValue.indexOf('INVALID') === -1) || !otherIdValue) && (
        <Typography variant="body1">{!!otherIdValue ? otherIdValue : '-none-'}</Typography>
      )}
      {otherIdValue && otherIdValue.indexOf('INVALID') !== -1 && (
        <Typography variant="caption">{otherIdValue}</Typography>
      )}
    </Grid>
  );

  return (
    <Card>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item lg={6} xs={12}>
            <Typography variant="h6">Membership #</Typography>
            <Typography variant="body1">{membershipAccount.accountNumber}</Typography>
          </Grid>
          <Hidden lgUp>
            <Grid item xs={12}>
              <Divider />
            </Grid>
          </Hidden>
          <Grid item lg={6} xs={12}>
            <Typography variant="h6">Account Created</Typography>
            <Typography variant="body1">
              {moment(membershipAccount.started).format('MMMM D, YYYY')}
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Divider style={{ margin: '16px 0' }} />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item lg={6} md={12}>
            {/* NetSuite ID */}
            {renderOtherId('NetSuite ID', membershipAccount.netsuiteId, 'netsuiteId')}
          </Grid>
          <Grid item lg={6} md={12}>
            {/* Cert Number */}
            {renderOtherId('Cert #', membershipAccount.certNumber, 'certNumber')}
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Divider style={{ margin: '16px 0' }} />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item lg={6} md={12}>
            {/* Salesforce Id */}
            {renderOtherId('Salesforce Id', membershipAccount.sfAccountId, 'sfAccountId')}
          </Grid>
          <Grid item lg={6} md={12}>
            {/* Salesforce Account UID */}
            {renderOtherId('Account UID', membershipAccount.sfAccountUId, 'sfAccountUId')}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
