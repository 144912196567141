import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Thunk } from '..';
import apiClient from '../../lib/api';
import Group from '../../models/group';
import CustomError from '../../lib/customError';
import { showToastMessage } from './toastMessage';

interface GroupsState {
  groups: Group[];
  loading: boolean;
  page?: number;
  error: CustomError | null;
}

interface GroupsSuccessPayload {
  groups: Group[];
  page?: number;
}

interface GroupsErrorPayload {
  error: CustomError;
}

export const initialState = {
  groups: [],
  loading: false,
  error: null,
} as GroupsState;

const groupsSlice = createSlice({
  name: 'groups',
  initialState,
  reducers: {
    getGroupsRequest(state) {
      state.error = null;
      state.page = undefined;
      state.loading = true;
    },
    getGroupsSuccess(state, action: PayloadAction<GroupsSuccessPayload>) {
      state.groups = action.payload.groups;
      state.page = action.payload.page;
      state.error = null;
      state.loading = false;
    },
    getGroupsError(state, action: PayloadAction<GroupsErrorPayload>) {
      state.groups = [];
      state.page = undefined;
      state.error = action.payload.error;
      state.loading = false;
    },
  },
});

/* Thunks */
export const getGroups = (query?: any): Thunk => async dispatch => {
  try {
    dispatch(getGroupsRequest());
    const groups = await apiClient.groups.list(query);
    let payload: GroupsSuccessPayload = { groups };
    if (query && query.page) {
      payload.page = query.page;
    }
    dispatch(getGroupsSuccess(payload));
  } catch (err: any) {
    dispatch(getGroupsError({ error: err }));
    dispatch(showToastMessage({ message: err.message, type: 'error' }));
  }
};

/* Actions & Reducer */
/* prettier-ignore */
export const {
  getGroupsRequest, getGroupsSuccess, getGroupsError
} = groupsSlice.actions;

export default groupsSlice.reducer;
