import apiClient from './client';
import { APIClientBase } from './apiClientBase';
import MembershipAccount from '../../models/membershipAccount';

interface WithdrawMemberPayload {
  members: string[];
  membershipAccount: string;
  endDate: string;
}

class Members extends APIClientBase {
  private static _instance: Members;

  static getInstance(): Members {
    if (Members._instance === undefined) {
      Members._instance = new Members();
    }
    return Members._instance;
  }

  withdraw = async (
    payload: WithdrawMemberPayload,
  ): Promise<{ membershipAccount: MembershipAccount; errors?: any }> => {
    try {
      const url = `/membership/members/withdraw`;
      const response = await apiClient.post(url, payload);
      return this.response(response);
    } catch (err: any) {
      const error = this.error(err);
      throw error;
    }
  };

  reactivate = async (
    memberUid: string,
  ): Promise<{ membershipAccount: MembershipAccount; errors?: any }> => {
    try {
      const url = `/membership/members/reactivate/${memberUid}`;
      const response = await apiClient.patch(url);
      return this.response(response);
    } catch (err: any) {
      const error = this.error(err);
      throw error;
    }
  };

  linkSalesforceContact = async (
    memberUid: string,
    sfContactId: string
  ): Promise<{ membershipAccount: MembershipAccount; errors?: any }> => {
    try {
      const url = `/membership/members/${memberUid}/link-salesforce-contact`;
      const response = await apiClient.post(url, { sfContactId });
      return this.response(response);
    } catch (err: any) {
      const error = this.error(err);
      throw error;
    }
  };
}

export default Members.getInstance();
