import apiClient from './client';
import { APIClientBase } from './apiClientBase';
import Group from '../../models/group';

class Groups extends APIClientBase {
  private static _instance: Groups;

  static getInstance(): Groups {
    if (Groups._instance === undefined) {
      Groups._instance = new Groups();
    }
    return Groups._instance;
  }

  save = async (groupData: any, query: any = {}): Promise<Group> => {
    try {
      const queryString = this.toQueryString(query);
      const url = groupData.uid
        ? `/membership/groups/${groupData.uid}?${queryString}`
        : `/membership/groups?${queryString}`;
      const response = groupData.uid
        ? await apiClient.put(url, groupData)
        : await apiClient.post(url, groupData);
      const data = this.response(response);
      const group = new Group(data);
      return group;
    } catch (err: any) {
      const error = this.error(err);
      throw error;
    }
  };

  get = async (uid: string, query: any = {}): Promise<Group> => {
    try {
      const queryString = this.toQueryString(query);
      const response = await apiClient.get(`/membership/groups/${uid}?${queryString}`);
      const data = this.response(response);
      const group = new Group(data);
      return group;
    } catch (err: any) {
      const error = this.error(err);
      throw error;
    }
  };

  list = async (query: any): Promise<Group[]> => {
    try {
      const queryString = this.toQueryString(query);
      const response = await apiClient.get(`/membership/groups?${queryString}`);
      const data = this.response(response);
      const groups: Group[] = [];
      for (let i = 0; i < data.length; i++) {
        groups.push(data[i]);
      }
      return groups;
    } catch (err: any) {
      const error = this.error(err);
      throw error;
    }
  };

  delete = async (uid: string): Promise<boolean> => {
    try {
      await apiClient.delete(`/membership/groups/${uid}`);
      return true;
    } catch (err: any) {
      const error = this.error(err);
      throw error;
    }
  };
}

export default Groups.getInstance();
