import { ProviderNetworkAssignment } from '../types/provider-network-assignment';
import React from 'react';

interface ProviderNetworkAssignmentDisplayProps {
  providerNetworkAssignment: ProviderNetworkAssignment | undefined;
}

const ProviderNetworkAssignmentDisplay = ({
                                            providerNetworkAssignment,
                                          }: ProviderNetworkAssignmentDisplayProps) => {
  return (
    <>
      <span style={{ fontWeight: 'bold' }}>Provider Network: </span>
      {providerNetworkAssignment ? (
        <span>
          {providerNetworkAssignment?.providerNetwork?.name} (
          {providerNetworkAssignment?.providerNetwork?.code})
        </span>
      ) : (
        <span>no provider network information</span>
      )}
    </>
  );
};

export default ProviderNetworkAssignmentDisplay;
