import { useEffect, useState, useCallback } from 'react';
import { Button, Tab, Tabs, createStyles, makeStyles, Theme } from '@material-ui/core';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import moment from 'moment';

import Dialog from '../../../../../components/Dialog';
import apiClient from '../../../../../lib/api';
import { ProgramUpdatePayload } from '../../../../../lib/api/memberPrograms';
import { showToastMessage } from '../../../../../store/slices/toastMessage';
import { getApplicationDate } from '../../../../../lib/util';
import ProgramEnrollment, { Role } from '../../../../../models/programEnrollment';
import MemberProgram, {
  isCoShare,
  isMediShareValue,
  isMS2,
  isMS3,
  isSeniorProgram,
} from '../../../../../models/memberProgram';
import MembershipAccount from '../../../../../models/membershipAccount';
import Member from '../../../../../models/member';
import Person from '../../../../../models/person';
import { ProgramLevel } from '../../../../../models/programLevel';
import { ProgramOption } from '../../../../../models/programOption';
import { MIN_CCM_PROGRAM_DATE, MAX_CCM_PROGRAM_DATE } from '../../../../../lib/util';
import { ProgramMember } from '.';
import ProgramAndMembers from './ProgramAndMembers';
import Enrollments from './Enrollments';
import Confirmation from './Confirmation';
import ButtonProgressIndicator from '../../../../ButtonProgressIndicator';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogContent: {
      flexGrow: 1,
      display: 'flex',
      height: 560,
      width: 1100,
    },
    tabs: {
      borderRight: `1px solid ${theme.palette.divider}`,
    },
    tabPanel: {
      flex: 1,
      paddingLeft: 20,
    },
  }),
);

interface ProgramUpdateDialogProps {
  open: boolean;
  membershipAccount: MembershipAccount;
  memberProgram: MemberProgram;
  toggleShowDialog(): void;
  onProgramUpdated(): void;
  switchProgram(): void;
}

const defaultButtonState = {
  next: {
    enabled: true,
    visible: true,
  },
  cancel: {
    enabled: true,
    visible: true,
  },
  submit: {
    enabled: true,
    visible: true,
  },
};
interface ProgramUpdateErrors {
  program: string[];
  enrollments: string[];
}

const defaultProgramErrors: ProgramUpdateErrors = {
  program: [],
  enrollments: [],
};

const ProgramUpdateDialog: React.FC<ProgramUpdateDialogProps> = ({
  open,
  toggleShowDialog,
  membershipAccount,
  memberProgram,
  onProgramUpdated,
  switchProgram,
}) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const styles = useStyles();
  const [programUpdateModalTitle, setProgramUpdateModalTitle] = useState('Edit Program');
  const [currentTab, setCurrentTab] = useState(0);
  const [programMembers, setProgramMembers] = useState<ProgramMember[]>([]);
  const [updatedProgram, setUpdatedProgram] = useState<MemberProgram>(memberProgram);
  const [enrolledMembers, setEnrolledMembers] = useState<Member[]>([]);
  const [eligibleMembers, setEligibleMembers] = useState<Member[]>([]);
  const [programEnrollments, setProgramEnrollments] = useState<ProgramEnrollment[]>([]);
  const [buttonState, setButtonState] = useState(defaultButtonState);
  const [programErrors, setProgramErrors] = useState<ProgramUpdateErrors>(defaultProgramErrors);
  const [automate, setAutomate] = useState(false);
  const [canAddMembers, setCanAddMembers] = useState(false);
  const applicationDate: string =
    getApplicationDate(membershipAccount.programEnrollments) ||
    membershipAccount.started.toString();

  // Program level retrieval
  const { data: eligiblePrograms, isFetching: eligibleProgramsFetching } = useQuery(
    ['listProgramLevels', membershipAccount.uid],
    async () => {
      const programLevels = await apiClient.membershipPrograms.listProgramLevels();

      // filter out program levels not eligible for modification
      const filteredProgramLevels = programLevels.filter(
        programLevel =>
          !isMS2(programLevel.name) && !isSeniorProgram(programLevel.name) && programLevel,
      );
      //order the programs because CS1-9K, CS1-12K are at the top of the list since both are new programs
      return [
        ...filteredProgramLevels.filter(f => isMS3(f.name)),
        ...filteredProgramLevels.filter(f => isCoShare(f.name)),
        ...filteredProgramLevels.filter(f => isMediShareValue(f.name)),
      ];
    },
    { refetchOnWindowFocus: false },
  );

  // Salesforce contacts retrieval
  const { data: sfContacts, isFetching: sfContactsFetching } = useQuery(
    ['admin-addon', membershipAccount.id],
    () =>
      apiClient.membershipAccounts.getSalesforceAccountContacts(membershipAccount.uid, {
        filterImported: true,
      }),
    { refetchOnWindowFocus: false },
  );

  // submits program update
  const updateProgram = useMutation((payload: ProgramUpdatePayload) =>
    apiClient.membershipPrograms.updateMemberProgram(payload),
  );

  const renderButtons = () => {
    const nextButton = (
      <Button
        key="nextButton"
        color="primary"
        variant="contained"
        onClick={() => {
          setCurrentTab(currentTab + 1);
        }}
        disabled={!buttonState.next.enabled || programMembers.length === 0}
      >
        Next
      </Button>
    );

    const submitButton = (
      <Button
        key="submitButton"
        color="primary"
        variant="contained"
        onClick={handleSubmit}
        disabled={!hasChanges() || !buttonState.submit.enabled || updateProgram.isLoading}
      >
        {!updateProgram.isLoading ? 'Submit' : <ButtonProgressIndicator size={24} />}
      </Button>
    );

    const cancelButton = (
      <Button
        key="cancelButton"
        color="default"
        variant="contained"
        onClick={() => toggleShowDialog()}
        disabled={!buttonState.cancel.enabled}
      >
        Cancel
      </Button>
    );

    const buttons = [];
    if (currentTab !== 2 && buttonState.next.visible) {
      buttons.push(nextButton);
    } else if (buttonState.submit.visible) {
      buttons.push(submitButton);
    }

    if (buttonState.cancel.visible) {
      buttons.push(cancelButton);
    }

    return buttons;
  };

  const handleChangeButtonState = (button: string, enabled: boolean, visible: boolean) => {
    switch (button) {
      case 'next':
        setButtonState(currentState => ({ ...currentState, next: { enabled, visible } }));
        break;
      case 'cancel':
        setButtonState(currentState => ({ ...currentState, cancel: { enabled, visible } }));
        break;
      case 'submit':
        setButtonState(currentState => ({ ...currentState, submit: { enabled, visible } }));
        break;
    }
  };

  const handleSubmit = () => {
    const payload: ProgramUpdatePayload = {
      uid: updatedProgram.uid,
      programLevel: updatedProgram.programLevel,
      programOptions: updatedProgram.programOptions,
      members: programMembers,
      automate,
    };

    updateProgram.mutate(payload, {
      onSuccess: data => {
        dispatch(
          showToastMessage({
            message: `Program updated was successfully updated`,
            type: 'success',
          }),
        );
        queryClient.invalidateQueries(['membershipAccount', membershipAccount.uid]);
        toggleShowDialog();
        onProgramUpdated();
      },
      onError: (error: any) => {
        dispatch(
          showToastMessage({
            message: error.message || 'An error occurred when updating program',
            type: 'error',
          }),
        );
      },
    });
  };

  const handleSwitchProgram = () => {
    switchProgram();
  };

  const hasChanges = () => {
    const hasNewMembers = programMembers.some(m => m.canRemove === true);
    const hasNewProgramLevel = updatedProgram.programLevel.id !== memberProgram.programLevel.id;

    const originalProgramOptions =
      (memberProgram.programOptions && memberProgram.programOptions.map(option => option.slug)) ||
      [];
    const newProgramOptions =
      (updatedProgram.programOptions && updatedProgram.programOptions.map(option => option.slug)) ||
      [];
    const hasNewProgramOption =
      !originalProgramOptions.every(option => newProgramOptions.includes(option)) ||
      !newProgramOptions.every(option => originalProgramOptions.includes(option));
    let hasEnrollmentChanges = programMembers.some(m => m.updated);
    return hasNewMembers || hasNewProgramLevel || hasNewProgramOption || hasEnrollmentChanges;
  };

  const handleTabClick = (e: any, tabIndex: number) => {
    setCurrentTab(tabIndex);
  };

  const handleAddProgramMember = (programMember: ProgramMember) => {
    setProgramMembers(currentProgramMembers => [...currentProgramMembers, programMember]);
    handleInputValidation();
  };

  const handleRemoveProgramMember = (id: string) => {
    setProgramMembers(currentProgramMembers => currentProgramMembers.filter(m => m.id !== id));
    handleInputValidation();
  };

  const handleChangeProgramLevel = (programLevel: ProgramLevel | null) => {
    if (programLevel === null || programLevel.id === memberProgram.programLevel.id) {
      setUpdatedProgram(memberProgram);
      return;
    }

    if (programLevel.id !== memberProgram.programLevel.id) {
      let programOptions: ProgramOption[] = [];
      if (programLevel.options && programLevel.options.length > 0) {
        const defaultOption = programLevel.options.find(option => option.slug === 'APLL');
        if (defaultOption) {
          programOptions.push(defaultOption);
        }
      }
      setUpdatedProgram({ ...updatedProgram, programLevel, programOptions });
    }
  };

  const handleChangeProgramOption = (programOption: ProgramOption | null) => {
    if (programOption === null) {
      setUpdatedProgram({ ...updatedProgram, programOptions: memberProgram.programOptions });
      return;
    }

    setUpdatedProgram({ ...updatedProgram, programOptions: [programOption] });
  };

  const handleChangeEnrollmentDate = (startEnd: string, id: string, dt: Date | null) => {
    setProgramMembers(
      programMembers.map(m => {
        if (m.id === id) {
          if (startEnd === 'start') {
            const startDateValue = moment(dt).format('YYYY-MM-DD 00:00:00');
            if (dt === null) {
              m.enrollment.startDate = null;
              m.updated = true;
            } else if (m.enrollment.startDate !== startDateValue) {
              m.enrollment.startDate = startDateValue;
              m.updated = true;
            }
          } else {
            const endDateValue = moment(dt).format('YYYY-MM-DD 23:59:59');
            if (dt === null) {
              m.enrollment.endDate = null;
              m.updated = true;
            } else if (m.enrollment.endDate !== endDateValue) {
              m.enrollment.endDate = endDateValue;
              m.updated = true;
            }
          }

          // The Head of Household can be set to retired only if this is a program with no prior enrollments
          // and the Head of Household is also not enrolled (start date === null). This satisfies a requirement
          // for a death or divorce workflow. To set a head of Household as retired when already enrolled,
          // the user must withdraw that member. To enroll a retired Head of Household, the user must perform
          // an add-on.
          if (
            m.role === Role.HeadOfHousehold &&
            programEnrollments.length === 0 &&
            m.enrollment.startDate === null
          ) {
            m.retired = true;
          } else {
            m.retired = false;
          }
        }

        return m;
      }),
    );
  };

  // input validation
  const handleInputValidation = useCallback(() => {
    // for each program member, make sure the data is valid
    const errors: string[] = [];
    for (const programMember of programMembers) {
      // missing start date
      if (!programMember.enrollment.startDate && !programMember.retired) {
        const msg =
          'Enrollment start dates are required. If a member needs to be removed from this program, use the Withdraw Member feature.';
        if (!errors.includes(msg)) {
          errors.push(msg);
        }
      }

      // invalid start date
      if (
        !programMember.retired &&
        programMember.enrollment.startDate !== null &&
        (!moment(programMember.enrollment.startDate).isValid() ||
          moment(programMember.enrollment.startDate).isBefore(MIN_CCM_PROGRAM_DATE) ||
          moment(programMember.enrollment.startDate).isAfter(MAX_CCM_PROGRAM_DATE))
      ) {
        const msg = `Enrollment start dates must be between ${moment(MIN_CCM_PROGRAM_DATE).format(
          'LL',
        )} and ${moment(MAX_CCM_PROGRAM_DATE).format('LL')}`;
        if (!errors.includes(msg)) {
          errors.push(msg);
        }
      }

      // invalid end date
      if (
        !programMember.retired &&
        programMember.enrollment.endDate &&
        (!moment(programMember.enrollment.endDate).isValid() ||
          moment(programMember.enrollment.endDate).isBefore(MIN_CCM_PROGRAM_DATE) ||
          moment(programMember.enrollment.endDate).isAfter(MAX_CCM_PROGRAM_DATE))
      ) {
        const msg = `Enrollment end dates must be between ${moment(MIN_CCM_PROGRAM_DATE).format(
          'LL',
        )} and ${moment(MAX_CCM_PROGRAM_DATE).format('LL')}`;
        if (!errors.includes(msg)) {
          errors.push(msg);
        }
      }

      // invalid date range
      if (
        moment(programMember.enrollment.startDate).isValid() &&
        moment(programMember.enrollment.endDate).isValid() &&
        moment(programMember.enrollment.startDate).isAfter(programMember.enrollment.endDate)
      ) {
        const msg = 'Enrollment end dates must be on or after start dates';
        if (!errors.includes(msg)) {
          errors.push(msg);
        }
      }
    }

    if (!programMembers.some(m => m.enrollment && m.enrollment.startDate)) {
      // no members have a valid enrollment start date so do not allow user to progress
      errors.push('At least one member must be enrolled with a valid start date');
    }

    if (errors.length > 0) {
      handleChangeButtonState('submit', false, true);
      if (currentTab === 1) {
        handleChangeButtonState('next', false, true);
      }
    } else {
      handleChangeButtonState('submit', true, true);
      handleChangeButtonState('next', true, true);
    }

    setProgramErrors(currentState => {
      return { ...currentState, enrollments: errors };
    });
  }, [programMembers, currentTab]);

  // set initial program members to use in this dialog
  useEffect(() => {
    // set initial program member state
    if (memberProgram && memberProgram.members) {
      const initialProgramMembers: ProgramMember[] = [];
      for (const member of memberProgram.members) {
        const memberEnrollments =
          programEnrollments.filter(p => (p.member as Member).uid === member.uid) || [];
        memberEnrollments.sort((a: ProgramEnrollment, b: ProgramEnrollment) => {
          if (a.ended === null) return -1;
          if (b.ended === null) return 1;
          return moment(a.ended).isAfter(b.ended)
            ? -1
            : moment(a.ended).isBefore(b.ended)
            ? 1
            : moment(a.started).isAfter(b.started)
            ? -1
            : moment(a.started).isBefore(b.started)
            ? 1
            : 0;
        });
        const programEnrollment = memberEnrollments[0];

        const role = (programEnrollment && programEnrollment.role) || 'unknown';
        const startDate =
          programEnrollment && programEnrollment.started
            ? moment(
                (programEnrollment && programEnrollment.started) || memberProgram.started,
              ).format('YYYY-MM-DD 00:00:00')
            : null;
        const endDate = programEnrollment
          ? programEnrollment.ended
            ? moment(programEnrollment.ended).format('YYYY-MM-DD 23:59:59')
            : null
          : memberProgram.ended
          ? moment(memberProgram.ended).format('YYYY-MM-DD 23:59:59')
          : null;
        initialProgramMembers.push({
          id: member.uid,
          member,
          enrollment: {
            uid: (programEnrollment && programEnrollment.uid) || undefined,
            startDate,
            endDate,
            originalStartDate: (programEnrollment && programEnrollment.started) || null,
            originalEndDate: (programEnrollment && programEnrollment.ended) || null,
          },
          name: `${(member.person as Person).firstName} ${(member.person as Person).lastName}`,
          dependentNumber: member.dependentNumber,
          role,
          canRemove: false,
          retired: member.retired ? true : false,
        });
      }
      setProgramMembers(initialProgramMembers.filter(programMember => !programMember.enrollment.endDate || moment(programMember.enrollment.endDate).isAfter(moment())));
    }
  }, [memberProgram, programEnrollments]);

  // set initial enrolled and eligible members
  useEffect(() => {
    if (memberProgram && memberProgram.members) {
      setEnrolledMembers(memberProgram.members);
    }

    if (membershipAccount && membershipAccount.members && memberProgram.members) {
      const members: Member[] = [];
      for (const member of membershipAccount.members) {
        // is member already enrolled in this program?
        if (memberProgram.members.some(m => m.uid === member.uid)) {
          continue;
        }

        const enrollments = membershipAccount.programEnrollments;
        // is member already enrolled in another program?
        if (
          enrollments &&
          enrollments.length > 0 &&
          enrollments.some(e => {
            return (
              ((typeof e.member === 'string' && e.member === member.uid) ||
                (typeof e.member === 'object' && (e.member as Member).uid === member.uid)) &&
              (e.ended === null || moment(e.ended).isAfter(moment(memberProgram.started)))
            );
          })
        ) {
          continue;
        }

        // is the member of age if this is this a Senior Assist program?
        if (
          ['SA 1250', 'MS65-500'].includes(memberProgram.programLevel.name) &&
          !moment((member.person as Person).dob).isSameOrBefore(
            moment(memberProgram.started).subtract(64, 'years').subtract(9, 'months'),
          )
        ) {
          continue;
        }

        // member is not enrolled and eligible for this program
        members.push(member);
      }

      setEligibleMembers(members);
    }
  }, [memberProgram, membershipAccount, programEnrollments]);

  // set the list of program enrollments for this program
  useEffect(() => {
    let enrollments: ProgramEnrollment[] = [];
    if (memberProgram && membershipAccount.programEnrollments) {
      enrollments = membershipAccount.programEnrollments.filter(
        e =>
          (typeof e.memberProgram === 'string' && e.memberProgram === memberProgram.uid) ||
          (typeof e.memberProgram === 'object' &&
            (e.memberProgram as MemberProgram).uid === memberProgram.uid),
      );
      setProgramEnrollments(enrollments);
    }

    // enable automation if this program does not have any existing enrollments past or present.
    if (enrollments.length === 0) {
      setAutomate(true);
      setCanAddMembers(true);
    }
  }, [membershipAccount, memberProgram]);

  // set the dialog title
  useEffect(() => {
    if (currentTab === 0) {
      setProgramUpdateModalTitle('Edit Program: Program & Members');
    } else if (currentTab === 1) {
      setProgramUpdateModalTitle('Edit Program: Enrollments');
    } else if (currentTab === 2) {
      setProgramUpdateModalTitle('Edit Program: Confirmation');
    }
  }, [currentTab]);

  useEffect(() => {
    handleInputValidation();
  }, [handleInputValidation]);

  return (
    <Dialog open={open} title={programUpdateModalTitle} buttons={renderButtons()}>
      <div className={styles.dialogContent}>
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={currentTab}
          onChange={handleTabClick}
          aria-label="Edit Program tabs"
          className={styles.tabs}
          indicatorColor="primary"
        >
          <Tab label="Program &amp; Members" id="vertical-tab-0" />
          <Tab label="Enrollments" id="vertical-tab-1" disabled={programMembers.length === 0} />
          <Tab
            label="Confirmation"
            id="vertical-tab-2"
            disabled={
              programMembers.length === 0 ||
              programErrors.program.length > 0 ||
              programErrors.enrollments.length > 0
            }
          />
        </Tabs>

        {/* Membership program updates tab */}
        <div
          role="tabpanel"
          hidden={currentTab !== 0}
          id="vertical-tabpanel-0"
          style={{ flex: 1, paddingLeft: 20 }}
        >
          <ProgramAndMembers
            isFetching={eligibleProgramsFetching || sfContactsFetching}
            memberProgram={memberProgram}
            eligiblePrograms={eligiblePrograms || []}
            contacts={sfContacts}
            minDate={moment(applicationDate).format('YYYY-MM-DD 00:00:00')}
            addProgramMember={handleAddProgramMember}
            removeProgramMember={handleRemoveProgramMember}
            programMembers={programMembers}
            eligibleMembers={eligibleMembers}
            enrolledMembers={enrolledMembers}
            changeProgramLevel={handleChangeProgramLevel}
            changeProgramOption={handleChangeProgramOption}
            switchProgram={handleSwitchProgram}
            canAddMembers={canAddMembers}
          />
        </div>

        {/* Confirmation tab */}
        <div
          role="tabpanel"
          hidden={currentTab !== 1}
          id="vertical-tabpanel-1"
          style={{ flex: 1, paddingLeft: 20 }}
        >
          <Enrollments
            changeButtonState={handleChangeButtonState}
            changeEnrollmentDate={handleChangeEnrollmentDate}
            errors={programErrors.enrollments}
            programMembers={programMembers}
            minEnrollmentDate={MIN_CCM_PROGRAM_DATE}
            maxEnrollmentDate={MAX_CCM_PROGRAM_DATE}
            validateInput={handleInputValidation}
          />
        </div>

        {/* Confirmation tab */}
        <div
          role="tabpanel"
          hidden={currentTab !== 2}
          id="vertical-tabpanel-2"
          className={styles.tabPanel}
        >
          <Confirmation
            memberProgram={memberProgram}
            updatedProgram={updatedProgram}
            programMembers={programMembers}
            errors={programErrors.enrollments}
            automate={automate}
            hasChanges={hasChanges}
          />
        </div>
      </div>
    </Dialog>
  );
};

export default ProgramUpdateDialog;
