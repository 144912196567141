import React, { useEffect, useState } from 'react';
import { createStyles, Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { Action, Subject } from '@ccm-innovation/auth-membership-service';

import MemberProgram, { MemberProgramStatus } from '../../../../models/memberProgram';
import Member from '../../../../models/member';
import ProgramEnrollment, {
  getProgramEnrollmentsByMemberProgramUId,
} from '../../../../models/programEnrollment';
import MemberProgramView from './MemberProgram';
import ProgramCreation from './creation/ProgramCreation';
import { useSession } from '../../../../context/session';
import { sortMemberPrograms } from '../../../../lib/util';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    section: {
      marginBottom: '20px',
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
    },
  }),
);

const MemberPrograms: React.FC<any> = ({ membershipAccount, handleMemberProgramOpen }) => {
  const styles = useStyles();
  const { user } = useSession();
  const [memberPrograms, setMemberPrograms] = useState<MemberProgram[]>([]);

  useEffect(() => {
    let programs = membershipAccount?.memberPrograms?.filter(
      (program: MemberProgram) => program.status.toLowerCase() !== MemberProgramStatus.Deleted,
    );
    const activePrograms = programs
      .filter((mp: MemberProgram) => mp.status === MemberProgramStatus.Active)
      .sort(sortMemberPrograms);

    const pendingPrograms = programs
      .filter((mp: MemberProgram) => mp.status === MemberProgramStatus.Pending)
      .sort(sortMemberPrograms);

    const onHoldPrograms = programs
      .filter((mp: MemberProgram) => mp.status === MemberProgramStatus.OnHold)
      .sort(sortMemberPrograms);

    const cancelledAndInactivePrograms = programs
      .filter(
        (mp: MemberProgram) =>
          mp.status === MemberProgramStatus.Cancelled || mp.status === MemberProgramStatus.Inactive,
      )
      .sort(sortMemberPrograms);

    programs = [].concat(
      activePrograms,
      pendingPrograms,
      onHoldPrograms,
      cancelledAndInactivePrograms,
    );

    setMemberPrograms(
      programs.map((program: MemberProgram) => {
        const enrollments = membershipAccount?.programEnrollments?.filter(
          (enrollment: ProgramEnrollment) => enrollment.memberProgram === program.uid,
        );

        const enrolledMemberIds = enrollments.map(
          (enrollment: ProgramEnrollment) => enrollment.member as string,
        );
        program.members = membershipAccount?.members?.filter((member: Member) =>
          enrolledMemberIds.includes(member.uid),
        );

        return program;
      }),
    );
  }, [membershipAccount]);

  return (
    <section className={styles.section}>
      <div className={styles.header}>
        <Typography variant="h3">Member Programs</Typography>
        {user && user.can(Action.Create, Subject.MemberProgram) && (
          <ProgramCreation membershipAccount={membershipAccount} />
        )}
      </div>
      <Grid container spacing={2}>
        {memberPrograms.length ? (
          memberPrograms.map((memberProgram: MemberProgram, index: number) => (
            <Grid item lg={3} md={6} xs={12} key={index}>
              <MemberProgramView
                memberProgram={memberProgram}
                enrollments={getProgramEnrollmentsByMemberProgramUId(
                  membershipAccount ? membershipAccount.programEnrollments : [],
                  memberProgram.uid,
                )}
                handleMemberProgramOpen={handleMemberProgramOpen}
              />
            </Grid>
          ))
        ) : (
          <Grid item>
            <Alert variant="filled" severity="info">
              There are no member programs associated with this account
            </Alert>
          </Grid>
        )}
      </Grid>
    </section>
  );
};

export default MemberPrograms;
