import { Button, createStyles, makeStyles, Theme } from '@material-ui/core';
import React, { useState } from 'react';
import ConfirmationDialog from './ConfirmationDialog';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    deleteButton: {
      marginLeft: '5px',
      backgroundColor: theme.palette.error.main,
      color: theme.palette.error.contrastText,
      '&:hover': {
        backgroundColor: theme.palette.error.dark,
      },
    },
  }),
);

interface Props {
  buttonText: string;
  handleDelete(): any;
  model: string;
  noDialog?: boolean;
}

const DeleteButton: React.FC<Props> = props => {
  const styles = useStyles();
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleDialogOpen = () => setDialogOpen(true);
  const handleDialogClose = () => setDialogOpen(false);

  return (
    <>
      <Button
        className={styles.deleteButton}
        variant="contained"
        onClick={props.noDialog ? props.handleDelete : handleDialogOpen}
      >
        {props.buttonText}
      </Button>

      <ConfirmationDialog
        open={dialogOpen}
        dangerous={true}
        title="Confirm Delete"
        message={`Are you sure you want to delete this ${props.model}?`}
        onConfirm={() => {
          props.handleDelete();
          handleDialogClose();
        }}
        onCancel={handleDialogClose}
      />
    </>
  );
};

export default DeleteButton;
