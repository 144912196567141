import React from 'react';
import { ProviderNetworkAssignment } from '../types/provider-network-assignment';
import {
  Backdrop,
  Button,
  Grid,
  IconButton,
  Modal,
  Paper,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import ProviderNetworkAssignmentTimeline from './ProviderNetworkAssignmentTimeline';

interface ProviderNetworkAssignmentHistoryDialogProps {
  data?: ProviderNetworkAssignment[];
  open: boolean;
  onClose: () => void;
}

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalBody: {
    width: 600,
  },
  modalTitle: {
    display: 'flex',
    margin: '1.5rem',
    justifyContent: 'space-between',
    '& > div > h2': {
      paddingTop: 10,
    },
  },
  buttonWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-end',
    margin: '20px',
  },
}));

const ProviderNetworkAssignmentHistoryDialog = ({
  data,
  open,
  onClose,
}: ProviderNetworkAssignmentHistoryDialogProps) => {
  const styles = useStyles();

  const onCloseModal = () => {
    onClose();
  };

  return (
    <Modal
      className={styles.modal}
      open={open}
      onClose={onClose}
      closeAfterTransition
      disableBackdropClick
      disableEscapeKeyDown
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Paper className={styles.modalBody}>
        <div className={styles.modalTitle}>
            <Typography variant="h2">Provider Network History</Typography>
          <IconButton title="cancel" onClick={onCloseModal}>
            <CloseIcon />
          </IconButton>
        </div>
        <ProviderNetworkAssignmentTimeline providerNetworkAssignments={data || []} />
        <Grid container>
          <Grid item xs={12} className={styles.buttonWrapper}>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              size="large"
              onClick={() => onCloseModal()}
            >
              Close
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Modal>
  );
};



export default ProviderNetworkAssignmentHistoryDialog;
