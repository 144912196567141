export enum LifeEventName  {
  Death = 'Death',
  AccountOwnerChange = 'Account Owner Change',
  Divorce = 'Divorce'
}
export interface LifeEventOption {
  value: LifeEventName,
}

export const lifeEventOptions: LifeEventOption[] = [
  { value: LifeEventName.Death },
  { value: LifeEventName.AccountOwnerChange },
  { value: LifeEventName.Divorce },
];
