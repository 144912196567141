import { Typography } from '@material-ui/core';
import { FC } from 'react';

interface PrintRequestDetailsMemberProps {
  name: string;
  dependentNumber: string;
}

const PrintRequestDetailsMember: FC<PrintRequestDetailsMemberProps> = ({
  name,
  dependentNumber,
}) => {
  return (
    <>
      <Typography variant="body1">{name}</Typography>
      <Typography variant="body1">ID: {dependentNumber}</Typography>
    </>
  );
};

export default PrintRequestDetailsMember;
