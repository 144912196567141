import React, { useEffect, useState } from 'react';
import {
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  LinearProgress,
  IconButton,
  Menu,
  MenuItem,
  Fade,
} from '@material-ui/core';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { debounce } from 'lodash';
import { useQuery, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import apiClient from '../../../lib/api';
import AccountRow from '../../../components/membership/AccountRow';
import SearchInput from '../../../components/SearchInput';
import Spacer from '../../../components/Spacer';
import { MembershipAccountQueryParams } from '../../../lib/api/membershipAccounts';
import MembershipAccount from '../../../models/membershipAccount';
import { showToastMessage } from '../../../store/slices/toastMessage';
import CustomError from '../../../lib/customError';
import useGlobalStyles from '../../../styles/globals';

type SortDir = 'asc' | 'desc';

const useStyles = makeStyles({
  row: {
    cursor: 'pointer',
    '& > *': {
      borderBottom: 'unset',
    },
  },
  emptyRow: {
    cursor: 'default',
  },
  emptyRowCell: {
    textAlign: 'center',
  },
});

const renderPaginationLabel = (
  from: number,
  to: number,
  pageSize: number,
  rowCount: number,
): string => {
  if (rowCount > pageSize) {
    return `${from} - ${to}`;
  } else {
    return `${from} - ${from + rowCount - 1}`;
  }
};

const renderCount = (rowsReturned: number, rowsPerPage: number, pageIndex: number): number => {
  return rowsReturned > rowsPerPage ? -1 : rowsPerPage * pageIndex + rowsReturned;
};

interface MembershipAccountsTableProps {
  query?: Partial<MembershipAccountQueryParams>;
  memberAccountCount?: Function;
}

interface LoadingProgress {
  progress: number;
  hidden: boolean;
}

const defaultQueryParams: MembershipAccountQueryParams = {
  page: 1,
  pageSize: 15,
  plus1: true,
  orderBy: 'accountName',
  sort: 'asc',
  searchQuery: '',
  includeMembers: true,
  includeArchived: false,
  includeInactive: true,
};

const MembershipAccountsTable: React.FC<MembershipAccountsTableProps> = props => {
  const history = useHistory();
  const styles = useStyles();
  const globalStyles = useGlobalStyles();
  const dispatch = useDispatch();
  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const [loadingProgress, setLoadingProgress] = useState<LoadingProgress>({
    progress: 0,
    hidden: true,
  });
  const [pagerRange, setPagerRange] = useState({ from: 1, to: defaultQueryParams.pageSize });
  const { memberAccountCount } = props;
  const propsQuery = props.query || {};
  const [queryParams, setQueryParams] = useState<MembershipAccountQueryParams>({
    ...defaultQueryParams,
    ...propsQuery,
  });
  const [visibilityMenuAnchorEl, setVisibilityMenuAnchorEl] = useState<HTMLButtonElement | null>(
    null,
  );
  const visibilityMenuOpen = Boolean(visibilityMenuAnchorEl);

  const queryClient = useQueryClient();
  const { isLoading, isFetching, data: queryResults, error: queryError } = useQuery(
    ['membershipAccounts', queryParams],
    ({ signal }: any) => apiClient.membershipAccounts.search({ query: queryParams, signal }),
    { keepPreviousData: true, cacheTime: 0 },
  );
  const membershipAccounts: MembershipAccount[] | undefined = queryResults;

  useEffect(() => {
    if (memberAccountCount) {
      memberAccountCount(membershipAccounts?.length || 0);
    }
  }, [memberAccountCount, membershipAccounts]);

  useEffect(() => {
    queryClient.invalidateQueries(['membershipAccounts', queryParams]);
  }, [queryClient, queryParams]);

  useEffect(() => {
    if (queryError) {
      dispatch(
        showToastMessage({
          message:
            (queryError as CustomError).message ||
            'An error occured while retrieving membership accounts',
          type: 'error',
        }),
      );
    }
  }, [dispatch, queryError]);

  useEffect(() => {
    if (!isLoading && !isFetching) {
      setPagerRange({
        from: queryParams.page! * queryParams.pageSize! - queryParams.pageSize! + 1,
        to: queryParams.page! * queryParams.pageSize!,
      });
      setLoadingProgress((p: LoadingProgress) => {
        return { ...p, progress: 100 };
      });
      const timer = setTimeout(() => {
        setLoadingProgress((p: LoadingProgress) => {
          return { hidden: true, progress: 0 };
        });
      }, 500);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [isLoading, isFetching, queryParams, setLoadingProgress]);

  useEffect(() => {
    if (isLoading || isFetching) {
      const timer = setInterval(() => {
        setLoadingProgress((p: LoadingProgress) => {
          if (p.progress === 90) {
            return { ...p, progress: 90 };
          }
          const diff = Math.random() * 10;
          return { hidden: false, progress: Math.min(p.progress + diff, 90) };
        });
      }, 250);

      return () => {
        clearInterval(timer);
      };
    }
  }, [isLoading, isFetching]);

  const handlePageChange = (e: any, pageIndex: number) => {
    setCurrentPageIndex(pageIndex);
    setQueryParams({ ...queryParams, page: pageIndex + 1 });
  };

  const handleSort = (e: any, orderBy: string, sort: SortDir) => {
    setQueryParams({ ...queryParams, orderBy, sort, page: 1 });
  };

  const handleQueryChange = debounce((val: string) => {
    const searchString = val.trim();
    if (searchString.length === 0) {
      setQueryParams({ ...queryParams, page: 1, searchQuery: searchString });
    }
  }, 500);

  const handleSubmitMembershipAccountSearch = debounce((val: string) => {
    const searchString = val.trim();
    if (
      searchString !== queryParams.searchQuery &&
      (searchString.length === 0 || searchString.length >= 3)
    ) {
      setQueryParams({ ...queryParams, page: 1, searchQuery: searchString });
    }
  }, 500);

  const handleVisibilityMenuClosed = () => {
    setVisibilityMenuAnchorEl(null);
  };

  return (
    <div title="Membership Accounts">
      <section>
        <SearchInput
          placeholder="Search Membership Accounts"
          onSubmit={handleSubmitMembershipAccountSearch}
          onChange={handleQueryChange}
          submitOnEnter={true}
          iconButtons={[
            <IconButton key="visibility" onClick={e => setVisibilityMenuAnchorEl(e.currentTarget)}>
              <VisibilityOffIcon />
            </IconButton>,
          ]}
        />
        <Menu
          id="visibilityMenu"
          anchorEl={visibilityMenuAnchorEl}
          keepMounted
          open={visibilityMenuOpen}
          onClose={handleVisibilityMenuClosed}
          TransitionComponent={Fade}
        >
          <MenuItem
            onClick={() => {
              setQueryParams((currentQueryParams: MembershipAccountQueryParams) => ({
                ...currentQueryParams,
                includeArchived: !currentQueryParams.includeArchived,
              }));
              handleVisibilityMenuClosed();
            }}
          >
            {queryParams.includeArchived ? 'Exclude' : 'Include'} Archived Accounts
          </MenuItem>
          <MenuItem
            onClick={() => {
              setQueryParams((currentQueryParams: MembershipAccountQueryParams) => ({
                ...currentQueryParams,
                includeInactive: !currentQueryParams.includeInactive,
              }));
              handleVisibilityMenuClosed();
            }}
          >
            {queryParams.includeInactive ? 'Exclude' : 'Include'} Inactive Accounts
          </MenuItem>
        </Menu>
      </section>

      <Spacer size={20} />

      {loadingProgress.hidden && <Spacer size={4} />}
      {!loadingProgress.hidden && (
        <LinearProgress variant="determinate" value={loadingProgress.progress} />
      )}
      <TableContainer component={Paper}>
        <Table className={globalStyles.fixedTable}>
          <TableHead>
            <TableRow>
              <TableCell width={80} />
              <TableCell width={150}>
                <TableSortLabel
                  active={queryParams.orderBy === 'accountNumber'}
                  direction={queryParams.orderBy === 'accountNumber' ? queryParams.sort : 'asc'}
                  onClick={e =>
                    handleSort(e, 'accountNumber', queryParams.sort === 'asc' ? 'desc' : 'asc')
                  }
                  disabled={isLoading || isFetching}
                >
                  Membership #
                </TableSortLabel>
              </TableCell>
              <TableCell className={globalStyles.fullWidthCell}>
                <TableSortLabel
                  active={queryParams.orderBy === 'accountName'}
                  direction={queryParams.orderBy === 'accountName' ? queryParams.sort : 'asc'}
                  onClick={e =>
                    handleSort(e, 'accountName', queryParams.sort === 'asc' ? 'desc' : 'asc')
                  }
                  disabled={isLoading || isFetching}
                >
                  Account Name
                </TableSortLabel>
              </TableCell>
              <TableCell width={140}>
                <TableSortLabel
                  active={queryParams.orderBy === 'certNumber'}
                  direction={queryParams.orderBy === 'certNumber' ? queryParams.sort : 'asc'}
                  onClick={e =>
                    handleSort(e, 'certNumber', queryParams.sort === 'asc' ? 'desc' : 'asc')
                  }
                  disabled={isLoading || isFetching}
                >
                  Cert #
                </TableSortLabel>
              </TableCell>
              <TableCell width={140}>
                <TableSortLabel
                  active={queryParams.orderBy === 'netsuiteId'}
                  direction={queryParams.orderBy === 'netsuiteId' ? queryParams.sort : 'asc'}
                  onClick={e =>
                    handleSort(e, 'netsuiteId', queryParams.sort === 'asc' ? 'desc' : 'asc')
                  }
                  disabled={isLoading || isFetching}
                >
                  NetSuite Id
                </TableSortLabel>
              </TableCell>
              <TableCell width={180}>Account Created</TableCell>
              <TableCell width={120}>
                <TableSortLabel
                  active={queryParams.orderBy === 'status'}
                  direction={queryParams.orderBy === 'status' ? queryParams.sort : 'asc'}
                  onClick={e =>
                    handleSort(e, 'status', queryParams.sort === 'asc' ? 'desc' : 'asc')
                  }
                  disabled={isLoading || isFetching}
                >
                  Status
                </TableSortLabel>
              </TableCell>
            </TableRow>
          </TableHead>
          {isLoading && (
            <TableBody>
              <TableRow className={`${styles.row} ${styles.emptyRow}`}>
                <TableCell colSpan={7} className={styles.emptyRowCell}>
                  Retrieving membership accounts...
                </TableCell>
              </TableRow>
            </TableBody>
          )}
          {!isLoading && (
            <TableBody style={{ opacity: isFetching ? 0.5 : 1 }}>
              {((membershipAccounts && membershipAccounts.length === 0) || queryError) && (
                <TableRow className={`${styles.row} ${styles.emptyRow}`}>
                  <TableCell colSpan={7} className={styles.emptyRowCell}>
                    {queryError
                      ? 'An error occurred while retrieving membership accounts'
                      : 'No accounts found'}
                  </TableCell>
                </TableRow>
              )}

              {membershipAccounts &&
                membershipAccounts.map(
                  (account: any, index: number) =>
                    (index < membershipAccounts.length - 1 ||
                      membershipAccounts.length <= queryParams.pageSize!) && (
                      <AccountRow
                        key={account.uid}
                        account={account}
                        handleAccountRowClick={() =>
                          history.push(`/membership/accounts/${account.uid}`)
                        }
                      />
                    ),
                )}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      {!isLoading && membershipAccounts && membershipAccounts.length > 0 && (
        <TablePagination
          rowsPerPageOptions={[queryParams.pageSize!]}
          component="div"
          count={renderCount(membershipAccounts.length, queryParams.pageSize!, currentPageIndex)}
          labelDisplayedRows={({ from, to }) =>
            (!isLoading &&
              !isFetching &&
              renderPaginationLabel(from, to, queryParams.pageSize!, membershipAccounts.length)) ||
            renderPaginationLabel(
              pagerRange.from,
              pagerRange.to!,
              queryParams.pageSize!,
              membershipAccounts.length,
            )
          }
          rowsPerPage={queryParams.pageSize!}
          page={currentPageIndex}
          onChangePage={handlePageChange}
          backIconButtonProps={{ disabled: isLoading || isFetching || currentPageIndex === 0 }}
          nextIconButtonProps={{
            disabled: isLoading || isFetching || membershipAccounts.length <= queryParams.pageSize!,
          }}
        />
      )}
    </div>
  );
};

export default MembershipAccountsTable;
