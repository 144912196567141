import { ProviderNetworkAssignmentStatus } from '../constants/provider-network.constants';
import { ProviderNetworkAssignment } from '../types/provider-network-assignment';
import moment from 'moment/moment';

/**
 * Sorts provider network assignments by start date in descending order.
 * @param a - The first provider network assignment to compare.
 * @param b - The second provider network assignment to compare.
 * @returns 1 if b's start date is after a's start date, -1 if b's start date is before a's start date, and 0 if they are equal.
 */
export const providerNetworkAssignmentSort = (
  a: ProviderNetworkAssignment,
  b: ProviderNetworkAssignment,
) => {
  if (moment(b.startDate).isAfter(moment(a.startDate))) return 1;
  if (moment(b.startDate).isBefore(moment(a.startDate))) return -1;
  return 0;
};

/**
 * Returns the provider network assignment to display based on its status.
 * @param providerNetworkAssignments - The list of provider network assignments to filter and sort.
 * @returns The active provider network assignment, the latest pending provider network assignment, or the latest inactive provider network assignment. Returns null if there are no assignments.
 */
export const getProviderNetworkAssignmentForDisplay = (
  providerNetworkAssignments: ProviderNetworkAssignment[],
): ProviderNetworkAssignment | null => {
  if (!providerNetworkAssignments || providerNetworkAssignments.length === 0) {
    return null;
  }

  // Filter and sort assignments by status and start date
  const activeAssignments = providerNetworkAssignments
    .filter(pa => pa.status === ProviderNetworkAssignmentStatus.Active)
    .sort(providerNetworkAssignmentSort);

  const pendingAssignments = providerNetworkAssignments
    .filter(pa => pa.status === ProviderNetworkAssignmentStatus.Pending)
    .sort(providerNetworkAssignmentSort);

  const inactiveAssignments = providerNetworkAssignments
    .filter(pa => pa.status === ProviderNetworkAssignmentStatus.Inactive)
    .sort(providerNetworkAssignmentSort);

  let assignmentForDisplay =
    // If there are active assignments,
    // display the latest active assignment (descending order)
    activeAssignments[0] ||
    // If there are no active assignments,
    // display the earliest/next pending assignment (ascending order)
    pendingAssignments[pendingAssignments.length - 1] ||
    // If there are no pending assignments,
    // display the latest inactive assignment (descending order)
    inactiveAssignments[0];

  return assignmentForDisplay;
};
