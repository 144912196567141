import React, { useState, useEffect } from 'react';
import { Button, IconButton, TextField, Typography } from '@material-ui/core';
import { Create as CreateIcon } from '@material-ui/icons';
import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

import apiClient from '../../lib/api';
import { showToastMessage } from '../../store/slices/toastMessage';
import CustomDialog from '../Dialog';
import { AppSetting } from '../../models/appSettings';
import ButtonProgressIndicator from '../ButtonProgressIndicator';

interface AppSettingsUpdateButtonProps {
  appSetting: AppSetting;
}

const useStyles = makeStyles({
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '500px',
    '& .MuiTextField-root': {
      marginTop: '15px',
    },
  },
});

const AppSettingsUpdateButton: React.FC<AppSettingsUpdateButtonProps> = ({ appSetting }) => {
  const styles = useStyles();
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const [openDialog, setOpenDialog] = useState(false);
  const [settingValue, setSettingValue] = useState(appSetting.settingValue);

  const updateAppSettingValue = useMutation(
    () => apiClient.appSettings.updateAppSettings(appSetting.uid, { settingValue }),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries('appSettings');
        setOpenDialog(false);
      },
      onError: (error: any) => {
        dispatch(
          showToastMessage({
            message: error.message,
            type: 'error',
          }),
        );
      },
    },
  );

  // update the app setting value when a new fetch happens
  useEffect(() => {
    setSettingValue(appSetting.settingValue);
  }, [appSetting]);

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSettingValue(appSetting.settingValue);
  };

  return (
    <>
      <IconButton color="primary" size="small" onClick={() => setOpenDialog(true)}>
        <CreateIcon />
      </IconButton>

      <CustomDialog
        open={openDialog}
        maxWidth="lg"
        title="Update Application Setting"
        buttons={[
          <Button
            variant="contained"
            color="primary"
            disabled={
              settingValue === appSetting.settingValue ||
              !settingValue ||
              updateAppSettingValue.isLoading
            }
            onClick={() => updateAppSettingValue.mutate()}
            key="saveAppSettingButton"
          >
            {updateAppSettingValue.isLoading ? <ButtonProgressIndicator /> : 'Save'}
          </Button>,
          <Button
            variant="contained"
            color="default"
            onClick={handleCloseDialog}
            key="cancelSaveAppSettingButton"
          >
            Cancel
          </Button>,
        ]}
      >
        <div className={styles.contentWrapper}>
          <Typography variant="h5">{appSetting.settingName}</Typography>
          <TextField
            id="setting-value"
            label="Setting Value"
            variant="outlined"
            value={settingValue}
            onChange={e => setSettingValue(e.target.value)}
            fullWidth
          />
        </div>
      </CustomDialog>
    </>
  );
};

export default AppSettingsUpdateButton;
