import React from 'react';
import { Alert } from '@material-ui/lab';

interface DialogErrorProps {
  errors?: string[];
  showIcon?: boolean;
  variant?: 'outlined' | 'filled' | 'standard';
}

const DialogErrors = (props: DialogErrorProps) => {
  const { errors, showIcon, variant } = props;

  if (!errors || errors.length === 0) return null;

  return (
    <>
      {errors && errors.length > 0 && (
        <Alert
          color="error"
          severity="error"
          variant={variant || 'outlined'}
          icon={showIcon === false ? false : undefined}
          style={{ marginBottom: '10px' }}
        >
          {errors.map((e: string, index: number) => (
            <div key={index}>{e}</div>
          ))}
        </Alert>
      )}
    </>
  );
};

export default DialogErrors;
