import React from 'react';
import EligibleProgram, { PricingProgramLevel } from '../../../../models/eligibleProgram';
import Member from '../../../../models/member';
import MemberProgram from '../../../../models/memberProgram';
import ProgramLevelCard from './ProgramLevel';

interface EligibleProgramProps {
  program: EligibleProgram;
  enrolledProgram: MemberProgram;
  members: Member[];
  isSelected?: boolean;
  readOnly?: boolean;
  onSelectProgramLevel: Function;
  renderCurrentProgram?: boolean;
}

const EligibleProgramView = (props: EligibleProgramProps) => {
  const {
    program,
    members,
    onSelectProgramLevel,
    isSelected,
    readOnly,
    enrolledProgram,
    renderCurrentProgram = true,
  } = props;
  const { programLevels, eligibleMembers } = program;

  const handleSelectProgramLevel = (
    isSelected: boolean,
    programLevel: any,
    eligibleMembers: Member[],
  ) => {
    onSelectProgramLevel({
      program,
      programLevel,
      eligibleMembers,
      isSelected,
    });
  };

  return (
    <>
      {programLevels.map((programLevel: PricingProgramLevel, index: number) => (
        <ProgramLevelCard
          key={index}
          programLevel={programLevel}
          members={members}
          eligibleMemberIds={eligibleMembers}
          isSelected={isSelected}
          readOnly={readOnly}
          enrolledProgramSwitchOptionSlug={programLevel.options.length > 1 && enrolledProgram.programOptions?.length ? enrolledProgram.programOptions[0].slug : undefined}
          enrolledProgramLevel={programLevel.id === enrolledProgram.programLevel.id}
          onSelectProgramLevel={handleSelectProgramLevel}
          renderCurrentProgram={renderCurrentProgram}
        />
      ))}
    </>
  );
};

export default EligibleProgramView;
