import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography } from "@material-ui/core";
import Spacer from "../../components/Spacer";
import ButtonProgressIndicator from "../../components/ButtonProgressIndicator";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useMutation, useQueryClient } from "react-query";
import { createBusinessLinePayload, updateBusinessLinePayload } from "../../lib/api/programCatalog";
import apiClient from "../../lib/api";
import * as yup from "yup";
import { useFormik } from "formik";
import { showToastMessage } from "../../store/slices/toastMessage";
import BusinessLine from "../../models/businessLine";
import { Delete } from "@material-ui/icons";


export interface BusinessLineDialogProps {
  isOpen: boolean;
  closeDialog: Function;
  businessLine: BusinessLine | undefined;
}

export const BusinessLineDialog = ({isOpen, closeDialog, businessLine}: BusinessLineDialogProps) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const [title, setTitle] = useState('');
  const [, setSelectedBusinessLine] = useState<BusinessLine | null>(null);


  const createBusinessLineFormSchema = yup.object({
    name: yup.string().trim().required('A business line must have a name'),
    description: yup.string().trim().required('A business line must have a description')
  });

  const createBusinessLineForm = useFormik({
    initialValues: {
      name: '',
      description: '',
      uid: ''
    },
    validationSchema: createBusinessLineFormSchema,
    onSubmit: values => {
      createBusinessLine.mutate({name: values.name.trim(), description: values.description.trim()}, {
        onSuccess: () => {
          cancel();
          queryClient.invalidateQueries(['businessLines']);
          dispatch(showToastMessage({
            message: 'The business line was created',
            type: 'success'
          }))
        },
        onError: (error: any) => {
          dispatch(showToastMessage({
            message: `${error && error.message ? error.message : 'The business line could not be created'}`,
            type: 'error'
          }))
        }
      });
    },
  });

  const updateBusinessLineForm = useFormik({
    initialValues: {
      uid: '',
      name: '',
      description: ''
    },
    validationSchema: createBusinessLineFormSchema,
    onSubmit: values => {
      updateBusinessLine.mutate({uid: values.uid, name: values.name, description: values.description}, {
        onSuccess: () => {
          cancel();
          queryClient.invalidateQueries(['businessLines']);
          dispatch(showToastMessage({
            message: 'The business line was updated',
            type: 'success'
          }))
        },
        onError: (error: any) => {
          dispatch(showToastMessage({
            message: `${error && error.message ? error.message : 'The business line could not be updated'}`,
            type: 'error'
          }))
        }
      });
    },
  });

  const deleteBusinessLineForm = useFormik({
    initialValues: {
      uid: ''
    },
    onSubmit: values => {
      deleteBusinessLine.mutate(values.uid, {
        onSuccess: () => {
          cancel();
          queryClient.invalidateQueries(['businessLines']);
          dispatch(showToastMessage({
            message: 'The business line was deleted',
            type: 'success'
          }))
        },
        onError: (error: any) => {
          dispatch(showToastMessage({
            message: `${error && error.message ? error.message : 'The business line could not be deleted'}`,
            type: 'error'
          }))
        }
      });
    },
  });

  /* eslint-disable */
  useEffect(() => {
    setTitle(businessLine ? 'Update Business Line' : 'Create Business Line');

    if (businessLine) {
      setSelectedBusinessLine(businessLine);
      updateBusinessLineForm.values.name = businessLine.name;
      updateBusinessLineForm.values.description = businessLine.description;
      updateBusinessLineForm.values.uid = businessLine.uid;
      deleteBusinessLineForm.values.uid = businessLine.uid;
    }

  }, [businessLine]);

  const createBusinessLine = useMutation(
    ({name, description}: createBusinessLinePayload) =>
      apiClient.programCatalog.createBusinessLine({name, description}),
  );

  const updateBusinessLine = useMutation(
    ({uid, name, description}: updateBusinessLinePayload) =>
      apiClient.programCatalog.updateBusinessLine({uid, name, description}),
  );

  const deleteBusinessLine = useMutation(
    (uid: string) =>
      apiClient.programCatalog.deleteBusinessLine(uid),
  );


  const cancel = () => {
    createBusinessLineForm.resetForm();
    updateBusinessLineForm.resetForm();
    deleteBusinessLineForm.resetForm();
    setSelectedBusinessLine(null);
    closeDialog();
  }

  const renderCreateBusinessLineButtons = () => (
    <>
      <Button color="default" disabled={createBusinessLine.isLoading} onClick={() => cancel()}>
        Cancel
      </Button>
      <Button
        color="primary"
        variant="contained"
        disabled={!createBusinessLineForm.isValid || !createBusinessLineForm.dirty}
        onClick={!createBusinessLine.isLoading ? createBusinessLineForm.submitForm : () => {
        }}
      >
        {createBusinessLine.isLoading ? <ButtonProgressIndicator size={24}/> : 'Create'}
      </Button>
    </>);

  const renderUpdateBusinessLineButtons = () => (<>
    <Button color="default" disabled={updateBusinessLine.isLoading} onClick={() => cancel()}>
      Cancel
    </Button>
    <Button
      color="primary"
      variant="contained"
      disabled={!deleteBusinessLineForm.isValid || deleteBusinessLine.isLoading}
      onClick={!deleteBusinessLine.isLoading ? deleteBusinessLineForm.submitForm : () => {
      }}
      endIcon={<Delete/>}
    >
      {deleteBusinessLine.isLoading ? <ButtonProgressIndicator size={24}/> : 'Delete'}
    </Button>
    <Button
      color="primary"
      variant="contained"
      disabled={!updateBusinessLineForm.isValid || !updateBusinessLineForm.dirty}
      onClick={!updateBusinessLine.isLoading ? updateBusinessLineForm.submitForm : () => {
      }}
    >
      {updateBusinessLine.isLoading ? <ButtonProgressIndicator size={24}/> : 'Update'}
    </Button>
  </>);

  const renderUpdateOrDeleteBusinessLineForm = () => (<>
    <TextField
      id="name"
      name="name"
      label="Name"
      margin="normal"
      fullWidth
      variant="outlined"
      autoComplete="none"
      disabled={updateBusinessLine.isLoading}
      value={updateBusinessLineForm.values.name}
      onChange={updateBusinessLineForm.handleChange}
      error={updateBusinessLineForm.touched.name && Boolean(updateBusinessLineForm.errors.name)}
      helperText={updateBusinessLineForm.touched.name && updateBusinessLineForm.errors.name}
    ></TextField>
    <TextField
      id="description"
      name="description"
      label="Description"
      margin="normal"
      fullWidth
      multiline
      rows={4}
      variant="outlined"
      autoComplete="none"
      disabled={updateBusinessLine.isLoading}
      value={updateBusinessLineForm.values.description}
      onChange={updateBusinessLineForm.handleChange}
      error={updateBusinessLineForm.touched.name && Boolean(updateBusinessLineForm.errors.description)}
      helperText={updateBusinessLineForm.touched.description && updateBusinessLineForm.errors.description}
    ></TextField>
  </>);


  const renderCreateBusinessLineForm = () => (<>
    <TextField
      id="name"
      name="name"
      label="Name"
      margin="normal"
      fullWidth
      variant="outlined"
      autoComplete="none"
      disabled={createBusinessLine.isLoading}
      value={createBusinessLineForm.values.name}
      onChange={createBusinessLineForm.handleChange}
      error={createBusinessLineForm.touched.name && Boolean(createBusinessLineForm.errors.name)}
      helperText={createBusinessLineForm.touched.name && createBusinessLineForm.errors.name}
    ></TextField>
    <TextField
      id="description"
      name="description"
      label="Description"
      margin="normal"
      fullWidth
      multiline
      rows={4}
      variant="outlined"
      autoComplete="none"
      disabled={createBusinessLine.isLoading}
      value={createBusinessLineForm.values.description}
      onChange={createBusinessLineForm.handleChange}
      error={createBusinessLineForm.touched.name && Boolean(createBusinessLineForm.errors.description)}
      helperText={createBusinessLineForm.touched.description && createBusinessLineForm.errors.description}
    ></TextField>
  </>);


  return <Dialog open={isOpen}
                 onClose={() => cancel()}
                 fullWidth
                 maxWidth="sm"
                 aria-labelledby="alert-dialog-title"
                 aria-describedby="alert-dialog-description">
    <DialogTitle id="alert-dialog-title" disableTypography={true}>
      <Typography variant="h2">{title}</Typography>
    </DialogTitle>
    <DialogContent>
      {!businessLine && renderCreateBusinessLineForm()}
      {businessLine && renderUpdateOrDeleteBusinessLineForm()}
    </DialogContent>
    <Spacer size={10}/>
    <DialogActions>
      {!businessLine &&
        renderCreateBusinessLineButtons()
      }
      {businessLine && renderUpdateBusinessLineButtons()
      }
    </DialogActions>
  </Dialog>
}