import { Typography } from '@material-ui/core';
import { FormikProps } from 'formik';
import React, { useEffect, useState } from 'react';
import Member from '../../../../../models/member';
import Spacer from '../../../../Spacer';
import { DeathForm } from './DeathDialog';
import MembershipAccount from "../../../../../models/membershipAccount";
import {MemberSelector} from "../../../member";

export const ContinuingMemberSelector: React.FC<{
  members: Member[];
  form: FormikProps<DeathForm>;
  membershipAccount: MembershipAccount
}> = ({ form, members, membershipAccount }) => {
  const [selectedSurvivingMembers, setSelectedSurvivingMembers] = useState<{
    [key: string]: Member;
  }>({});
  const [eligibleRemainingMembers, setEligibleRemainingMembers] = useState<Member[]>([]);

  useEffect(() => {
    const deceasedMemberUIds = form.values.event!.deceasedMembers;
    setEligibleRemainingMembers(members.filter(member => !deceasedMemberUIds.includes(member.uid)));
    setSelectedSurvivingMembers(form.values.continuingMembers);
  }, [members, form]);

  const onContinuingMemberSelected = (member: Member, selected: boolean) => {
    const temp = form.values.continuingMembers;

    if (selected) {
      temp[member.uid] = member;
      form.values.event!.continuingMembers.push(member.uid);
    } else {
      delete temp[member.uid];
      const index = form.values.event!.continuingMembers.indexOf(member.uid);
      form.values.event!.continuingMembers.splice(index, 1);
    }

    form.setFieldValue('remainingMembers', temp);
    setSelectedSurvivingMembers(form.values.continuingMembers);
  };

  return (
    <>
      <Typography variant="h3">Continuing Members</Typography>
      <Typography variant="h5">Please select all that apply</Typography>
      <Spacer size={20} />
      <MemberSelector
        members={eligibleRemainingMembers}
        selectedMembers={selectedSurvivingMembers}
        membershipAccount={membershipAccount}
        onMemberSelected={onContinuingMemberSelected}
        title="Enrolled Members"
      />
    </>
  );
};
