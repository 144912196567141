import React, { useState } from 'react';
import { Box, Collapse, TableCell, TableRow, Paper, Typography, Tooltip } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { makeStyles } from '@material-ui/core/styles';
import ExceptionLog from '../../../../models/exceptionLog';

const useStyles = makeStyles({
  row: {
    cursor: 'pointer',
    '& > *': {
      borderBottom: 'unset',
    },
  },
  childRow: {
    cursor: 'pointer',
  },
  emptyRow: {
    cursor: 'default',
  },
  emptyRowCell: {
    textAlign: 'center',
  },
});

const renderActor = (actor?: Record<string, unknown> | string) => {
  if (!actor) {
    return '';
  }

  if (typeof actor === 'string') {
    return actor;
  } else if (typeof actor === 'object') {
    let actorVal = '';
    if (actor.firstName) {
      actorVal = actor.firstName as string;
    }
    if (actor.lastName) {
      actorVal += (actorVal.length > 0 ? ' ' : '') + actor.lastName;
    }
    if (actor.email) {
      actorVal += (actorVal.length > 0 ? ' ' : '') + `(${actor.email})`;
    }

    return (
      <Tooltip title={actorVal}>
        <Typography variant="body2" component="div" style={{ maxWidth: '250px' }} noWrap>
          {actorVal}
        </Typography>
      </Tooltip>
    );
  }
};

export default function ExceptionLogRow(props: { exceptionLog: ExceptionLog }) {
  const { exceptionLog } = props;
  const [open, setOpen] = useState(false);
  const styles = useStyles();

  return (
    <React.Fragment>
      <TableRow className={styles.row} hover>
        <TableCell>
          <IconButton
            size="small"
            onClick={e => {
              e.stopPropagation();
              setOpen(!open);
            }}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>{exceptionLog.createdAt?.format('YYYY-MM-DD HH:mm:ss')}</TableCell>
        <TableCell>{exceptionLog.logLevel}</TableCell>
        <TableCell>{renderActor(exceptionLog.description)}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Paper elevation={3}>
              <Box margin={1} style={{ padding: 20 }}>
                {exceptionLog.metadata ? (
                  <pre style={{ whiteSpace: 'pre-wrap' }}>
                    {JSON.stringify(exceptionLog.metadata, null, 2)}
                  </pre>
                ) : (
                  <pre>No metadata available</pre>
                )}
              </Box>
            </Paper>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}
