import { Timeline } from '@material-ui/lab';
import { ProviderNetworkAssignment } from '../types/provider-network-assignment';
import React from 'react';
import ProviderNetworkAssignmentTimelineItem from './ProviderNetworkAssignmentTimelineItem';
import { providerNetworkAssignmentSort } from '../helpers/provider-network-assignments';

interface ProviderNetworkAssignmentTimelineProps {
  providerNetworkAssignments: ProviderNetworkAssignment[];
}

const ProviderNetworkAssignmentTimeline = ({
  providerNetworkAssignments,
}: ProviderNetworkAssignmentTimelineProps) => {
  return (
    <Timeline style={{ maxHeight: 300, overflowY: 'auto' }}>
      {providerNetworkAssignments
        ?.sort(providerNetworkAssignmentSort)
        .map((pna: ProviderNetworkAssignment, index: number) => (
          <ProviderNetworkAssignmentTimelineItem
            key={index}
            providerNetworkAssignment={pna}
            displayConnector={index + 1 < providerNetworkAssignments.length}
          />
        ))}
    </Timeline>
  );
};

export default ProviderNetworkAssignmentTimeline;
