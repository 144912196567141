import React, { useState, useEffect } from 'react';
import {
  Tab,
  Tabs,
  Box,
  Modal,
  Paper,
  Backdrop,
  IconButton,
  Typography,
  Button,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';
import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import MembershipCancellationForm from './MembershipCancellationForm';
import MembershipCancellationConfirm from './MembershipCancellationConfirm';
import apiClient from '../../../lib/api';
import { showToastMessage } from '../../../store/slices/toastMessage';
import MembershipAccount, { CancelProgram } from '../../../models/membershipAccount';
import MemberProgram, { CancellationReasonType } from '../../../models/memberProgram';
import { formatDate } from '../../../lib/util';

export enum CancellationType {
  MembershipAccount = 'membershipAccount',
  MemberProgram = 'memberProgram',
}

export interface DataValues {
  cancellationType: CancellationType | null;
  cancelProgram: CancelProgram[];
  cancellationReason: CancellationReasonType | null;
  endDate: string | null;
}

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalBody: {
    width: 1000,
  },
  modalTitleWrapper: {
    display: 'flex',
    margin: '1.5rem',
    justifyContent: 'space-between',
    '& > div > h2': {
      paddingTop: 10,
    },
  },
  modalBodyTabs: {
    flexGrow: 1,
    display: 'flex',
    height: 500,
  },
  tabNavigation: {
    borderRight: '1px solid #ccc',
  },
  tabBody: {
    width: '100%',
  },
  cancelSelectedProgramButton: {
    color: '#ffffff',
    backgroundColor: '#f44336',
    '&:hover': {
      background: '#B71C1C',
    },
  },
}));

/**
 * TabPanel
 */
interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

const TabPanel = (props: TabPanelProps) => {
  const styles = useStyles();
  const { children, value, index, ...other } = props;
  return (
    <div
      className={styles.tabBody}
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
};

interface MembershipCancellationProps {
  membershipAccount: MembershipAccount;
  selectedMemberProgram?: MemberProgram | null;
  onDialogClose?: any;
  open?: boolean;
}

const MembershipCancellation: React.FC<MembershipCancellationProps> = ({
  membershipAccount,
  selectedMemberProgram,
  onDialogClose,
  open,
}) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const styles = useStyles();
  const [tabIndex, setTabIndex] = useState(0);
  const [cancellationModalOpen, setCancellationModalOpen] = useState(false);
  const [cancellationData, setCancellationData] = useState<DataValues | null>(null);
  const [memberPrograms, setMemberPrograms] = useState<MemberProgram[]>(
    selectedMemberProgram ? [selectedMemberProgram] : [],
  );
  const cancelMembershipAccount = useMutation(
    ({ reason, endDate }: { reason: string; endDate: string }) =>
      apiClient.membershipAccounts.cancelMembershipAccount(
        membershipAccount.uid,
        reason,
        formatDate(endDate),
      ),
  );

  const cancelMemberProgram = useMutation(
    ({
      memberProgramUids,
      reason,
      endDate,
    }: {
      memberProgramUids: string[];
      reason: string;
      endDate: string;
    }) =>
      apiClient.membershipPrograms.cancelProgram(memberProgramUids, reason, formatDate(endDate)),
  );

  const onClose = () => {
    setCancellationModalOpen(false);
    setMemberPrograms([]);
    setTabIndex(0);
    setCancellationData(null);
    if (onDialogClose) {
      onDialogClose();
    }
  };

  const onMoveToConfirm = (data: DataValues) => {
    setCancellationData(data);
    setTabIndex(1);
  };

  const onConfirmCancellation = () => {
    if (!cancellationData) return;

    // Cancelling membership account
    if (cancellationData.cancellationType === CancellationType.MembershipAccount) {
      cancelMembershipAccount.mutate(
        {
          reason: cancellationData!.cancellationReason!,
          endDate: cancellationData.endDate!,
        },
        {
          onSuccess: () => {
            onClose();
            queryClient.invalidateQueries(['membershipAccount', membershipAccount.uid]);
            dispatch(
              showToastMessage({
                message: 'The membership account has been cancelled',
                type: 'success',
              }),
            );
          },
          onError: (error: any) => {
            onClose();
            dispatch(
              showToastMessage({
                message: `Cancellation could not be completed ${
                  error.message ? `. ${error.message}` : ''
                }`,
                type: 'error',
              }),
            );
          },
        },
      );
    } else {
      // Canceling selected member programs
      cancelMemberProgram.mutate(
        {
          reason: cancellationData!.cancellationReason!,
          memberProgramUids: cancellationData.cancelProgram.map(cp => cp.memberProgram.uid),
          endDate: cancellationData.endDate!,
        },
        {
          onSuccess: () => {
            onClose();
            queryClient.invalidateQueries(['membershipAccount', membershipAccount.uid]);
            dispatch(
              showToastMessage({
                message: 'The member programs selected were cancelled',
                type: 'success',
              }),
            );
          },
          onError: (error: any) => {
            onClose();
            dispatch(
              showToastMessage({
                message: `Cancellation could not be completed ${
                  error.message ? `. ${error.message}` : ''
                }`,
                type: 'error',
              }),
            );
          },
        },
      );
    }
  };

  useEffect(() => {
    if (open !== undefined) {
      setCancellationModalOpen(open);
    }
  }, [open]);

  const handleTabClick = (event: React.ChangeEvent<{}>, tabIndex: number) => {
    setTabIndex(tabIndex);
  };

  return (
    <>
      {selectedMemberProgram && (
        <Button
          variant="contained"
          className={styles.cancelSelectedProgramButton}
          onClick={() => setCancellationModalOpen(true)}
          disabled={membershipAccount && membershipAccount.status !== 'active'}
        >
          Cancel Program
        </Button>
      )}
      <Modal
        className={styles.modal}
        open={cancellationModalOpen}
        onClose={() => onClose()}
        closeAfterTransition
        disableBackdropClick
        disableEscapeKeyDown
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Paper className={styles.modalBody}>
          <div className={styles.modalTitleWrapper}>
            <Box>
              <Typography variant="h2">
                {tabIndex === 0 ? 'Cancellation' : 'Cancellation: Confirmation'}
              </Typography>
            </Box>
            <IconButton title="cancel" onClick={() => onClose()}>
              <CloseIcon />
            </IconButton>
          </div>
          <Box>
            <div className={styles.modalBodyTabs}>
              <Tabs
                orientation="vertical"
                value={tabIndex}
                aria-label="Account or Program Cancellation"
                className={styles.tabNavigation}
                indicatorColor="primary"
                onChange={handleTabClick}
              >
                <Tab label="Account or Programs" />
                <Tab label="Confirmation" disabled={tabIndex === 0} />
              </Tabs>
              <TabPanel value={tabIndex} index={0}>
                <MembershipCancellationForm
                  activeMemberEnrolledPrograms={membershipAccount.getAvailableProgramsToCancel()}
                  onClose={onClose}
                  onContinue={onMoveToConfirm}
                  cancellationData={cancellationData}
                  memberPrograms={memberPrograms}
                  setMemberPrograms={setMemberPrograms}
                  programEnrollments={membershipAccount.programEnrollments || []}
                />
              </TabPanel>
              <TabPanel value={tabIndex} index={1}>
                <MembershipCancellationConfirm
                  cancellationData={cancellationData!}
                  onClose={onClose}
                  onConfirm={onConfirmCancellation}
                  isCancelling={cancelMembershipAccount.isLoading}
                  activeMemberEnrolledPrograms={membershipAccount.getAvailableProgramsToCancel()}
                />
              </TabPanel>
            </div>
          </Box>
        </Paper>
      </Modal>
    </>
  );
};

export default MembershipCancellation;
