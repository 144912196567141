import { Role, Permission, UserWithGroups } from '@ccm-innovation/auth';

import User from '../../models/user';

const env =
  process.env.REACT_APP_SEC_ENV === 'production'
    ? 'prod'
    : process.env.REACT_APP_SEC_ENV === 'test'
    ? 'test'
    : 'dev';

class SessionUser extends User {
  private _isValid: boolean = false;
  private _auth: UserWithGroups | null = null;

  get isValid(): boolean {
    return this._isValid;
  }

  set isValid(val: boolean) {
    this._isValid = val;
  }

  init() {
    this._auth = new UserWithGroups(this.groups || [], env);
    this.isValid = true;
  }

  get authRoles() {
    return this._auth?.roles || [];
  }

  can(action: string, subject: string, cb?: Function): boolean {
    if (this._auth && this._auth.can(action, 'membership', subject)) {
      cb && cb();
      return true;
    }

    return false;
  }

  cannot(action: string, subject: string, cb?: Function): boolean {
    const canDoAction = this.can(action, subject);
    if (!canDoAction) {
      cb && cb();
      return true;
    }

    return false;
  }

  hasRole(role: Role): boolean {
    if (this._auth && this._auth.hasRole('membership', role)) {
      return true;
    }
    return false;
  }

  hasPermission(permission: Permission): boolean {
    if (this._auth && this._auth.hasPermission('membership', permission)) {
      return true;
    }

    return false;
  }
}

export default SessionUser;
