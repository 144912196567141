import React, { useEffect, useState } from 'react';
import moment from 'moment';
import {
  FormControl,
  Grid,
  Typography,
  Select,
  MenuItem,
  Button,
  InputLabel,
  Box,
  Link,
  Popover,
  useTheme,
  makeStyles,
  Theme,
} from '@material-ui/core';
import { Alert, Skeleton } from '@material-ui/lab';
import { v4 as uuidv4 } from 'uuid';

import EnrolledProgram from '../EnrolledProgram';
import Spacer from '../../../../Spacer';
import Member from '../../../../../models/member';
import Person from '../../../../../models/person';
import SalesforceContact from '../../../../../models/contact';
import { ProgramMember } from './';
import MemberProgram, { isMS2, isSeniorProgram } from '../../../../../models/memberProgram';
import { ProgramLevel } from '../../../../../models/programLevel';
import { Role as ProgramEnrollmentRole } from '../../../../../models/programEnrollment';
import { enrollmentRoleFromContactRole } from '../../../../../lib/role';
import { ProgramOption } from '../../../../../models/programOption';
import MemberCard from './MemberCard';
import StatusBadge from '../../../../StatusBadge';

const useStyles = makeStyles((theme: Theme) => ({
  addMemberMessage: {
    padding: theme.spacing(2),
    maxWidth: '380px',
  },
}));

interface ProgramAndMembersProps {
  memberProgram: MemberProgram;
  eligiblePrograms: ProgramLevel[];
  isFetching: boolean;
  contacts: SalesforceContact[];
  minDate: string;
  programMembers: ProgramMember[];
  eligibleMembers: Member[];
  enrolledMembers: Member[];
  canAddMembers: boolean;
  addProgramMember(programMember: ProgramMember): void;
  removeProgramMember(id: string): void;
  changeProgramLevel(programLevel: ProgramLevel | null): void;
  changeProgramOption(programOption: ProgramOption | null): void;
  switchProgram(): void;
}

const ProgramAndMembers: React.FC<ProgramAndMembersProps> = ({
  isFetching,
  memberProgram,
  eligiblePrograms,
  contacts,
  programMembers,
  eligibleMembers,
  addProgramMember,
  removeProgramMember,
  changeProgramLevel,
  changeProgramOption,
  switchProgram,
  canAddMembers,
}) => {
  const theme = useTheme();
  const styles = useStyles();
  const [selectedMember, setSelectedMember] = useState('');
  const [selectedProgramLevelId, setSelectedProgramLevelId] = useState(
    memberProgram.programLevel.id,
  );
  const [selectedProgramOptionId, setSelectedProgramOptionId] = useState<string>('');
  const [programOptions, setProgramOptions] = useState<ProgramOption[]>([]);
  const [addMemberMessageElement, setAddMemberMessageElement] = useState<any>(null);
  const [addMemberMessageOpen, setAddMemberMessageOpen] = useState(false);

  const handleSelectProgramLevel = (id: number) => {
    if (id === 0) {
      changeProgramLevel(null);
      setSelectedProgramLevelId(memberProgram.programLevel.id);
      return;
    }

    const programLevel = eligiblePrograms.find(pl => pl.id === id)!;
    setSelectedProgramLevelId(programLevel.id);
    changeProgramLevel(programLevel);
  };

  const handleSelectProgramOption = (id: number) => {
    if (id === 0) {
      changeProgramOption(null);
      if (memberProgram.programOptions && memberProgram.programOptions.length > 0) {
        const currentProgramOption = programOptions.find(
          option => option.slug === memberProgram.programOptions![0].slug,
        );
        setSelectedProgramOptionId(
          (currentProgramOption && currentProgramOption.id)?.toString() || '',
        );
      } else {
        setSelectedProgramOptionId('');
      }
      return;
    }

    const programOption = programOptions.find(option => option.id === id)!;
    setSelectedProgramOptionId(programOption.id.toString());
    changeProgramOption(programOption);
  };

  const handleAddSelectedMember = () => {
    const member = eligibleMembers.find(m => m.uid === selectedMember);
    const contact = contacts && contacts.find(c => c.Id === selectedMember);

    const startDate = moment(memberProgram.started).format('YYYY-MM-DD 00:00:00');
    const endDate = memberProgram.ended
      ? moment(memberProgram.ended).format('YYYY-MM-DD 23:59:59')
      : null;

    // add selected member to program members list
    let role = '';
    if (member) {
      if (member.dependentNumber === '00') {
        role = ProgramEnrollmentRole.HeadOfHousehold;
      } else if (member.programEnrollments && member.programEnrollments.length > 0) {
        role = member.programEnrollments[0].role;
      }

      addProgramMember({
        id: member.uid,
        member,
        enrollment: {
          startDate,
          endDate,
          originalStartDate: null,
          originalEndDate: null,
        },
        name: `${(member.person as Person).firstName} ${(member.person as Person).lastName}`,
        dependentNumber: member.dependentNumber,
        role,
        canRemove: true,
        retired: false,
      });
    } else if (contact) {
      role = enrollmentRoleFromContactRole(contact.Designation_Type__c) || '';

      addProgramMember({
        id: contact.Id,
        contact,
        enrollment: {
          startDate,
          endDate,
          originalStartDate: null,
          originalEndDate: null,
        },
        name: contact.Name,
        dependentNumber: 'TBD',
        role,
        canRemove: true,
        retired: false,
      });
    }

    // reset dropdown
    setSelectedMember('');
  };

  /**
   * Renders a list of members that are or will be enrolled in this program
   * @returns a list of member cards
   */
  const renderMembers = () => {
    const memberBlocks: JSX.Element[] = [];

    programMembers.sort((a: ProgramMember, b: ProgramMember) => {
      if (a.canRemove && !b.canRemove) return -1;
      if (!a.canRemove && b.canRemove) return 1;
      if (a.dependentNumber === 'TBD' && b.dependentNumber !== 'TBD') return 1;
      if (a.dependentNumber !== 'TBD' && b.dependentNumber === 'TBD') return -1;

      return parseInt(a.dependentNumber) < parseInt(b.dependentNumber) ? -1 : 1;
    });

    for (let i = 0; i < programMembers.length; i++) {
      const programMember = programMembers[i];
      memberBlocks.push(
        <MemberCard
          key={programMember.id}
          programMember={programMember}
          removeProgramMember={removeProgramMember}
          editable={true}
        ></MemberCard>,
      );
    }

    return memberBlocks;
  };

  /**
   * Renders a list of eligible members and Salesforce contacts that can be added to this program
   * @returns
   */
  const renderNewMemberOptions = () => {
    const newMemberOptions: Record<string, string>[] = [];
    for (const member of eligibleMembers) {
      // is member already in the program members list?
      if (programMembers.some(m => m.id === member.uid)) {
        continue;
      }
      newMemberOptions.push({
        id: member.uid,
        label: `${(member.person as Person).firstName} ${(member.person as Person).lastName}`,
      });
    }

    if (contacts) {
      for (const contact of contacts) {
        // is contact already in the program members list?
        if (programMembers.some(m => m.id === contact.Id)) {
          continue;
        }

        // if this is SA, make sure contact meets the minimum age
        if (
          isSeniorProgram(memberProgram.programLevel.name) &&
          !moment(contact.Birthdate).isBefore(moment().subtract(64, 'years').subtract(9, 'months'))
        ) {
          continue;
        }
        newMemberOptions.push({ id: contact.Id, label: contact.Name });
      }
    }

    const options = newMemberOptions.map(option => (
      <MenuItem key={option.id} value={option.id}>
        {option.label}
      </MenuItem>
    ));

    return options;
  };

  const renderPrograms = () => {
    const options = eligiblePrograms.map(programLevel => (
      <MenuItem key={programLevel.id} value={programLevel.id}>
        {programLevel.description ? (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span style={{ marginRight: '10px' }}>{programLevel.description}</span>
            <Typography component="span" variant="h6" style={{ margin: 0 }}>
              {programLevel.name}
            </Typography>
          </div>
        ) : (
          programLevel.name
        )}
      </MenuItem>
    ));
    return options;
  };

  const renderProgramOptions = () => {
    return programOptions.map(option => (
      <MenuItem key={uuidv4()} value={option.id}>
        {option.description}
      </MenuItem>
    ));
  };

  const handleAddMemberMessageOpen = (e: any) => {
    setAddMemberMessageElement(e.currentTarget);
    setAddMemberMessageOpen(true);
  };

  const handleAddMemberMessageClose = () => {
    setAddMemberMessageOpen(false);
    setAddMemberMessageElement(null);
  };

  useEffect(() => {
    const programLevel = eligiblePrograms.find(pl => pl.id === selectedProgramLevelId)!;
    setProgramOptions((programLevel && programLevel.options) || []);
  }, [selectedProgramLevelId, eligiblePrograms]);

  useEffect(() => {
    const defaultOption = programOptions.find(option => option.slug === 'APLL');
    if (defaultOption) {
      setSelectedProgramOptionId(defaultOption.id.toString());
    }
  }, [programOptions]);

  return (
    <>
      <Typography variant="subtitle2">
        <strong>
          This feature is primarily used for correcting program details made in error. If you need
          to switch a member's AHP level, use the Switch Program feature instead:{' '}
          <StatusBadge
            label="Switch Program"
            variant="info"
            style={{ cursor: 'pointer' }}
            onClick={() => switchProgram()}
          />
        </strong>
      </Typography>

      <Spacer size={20} />

      <Grid container spacing={4}>
        {/* left column */}
        <Grid item xs={6}>
          {/* Currently enrolled program */}
          <Box>
            <Typography variant="h6">Current Program</Typography>
            {memberProgram && <EnrolledProgram memberProgram={memberProgram} showPrice={false} />}
          </Box>

          <Spacer size={20} />

          {/* New Program */}
          {(isMS2(memberProgram.programLevel.name) ||
            isSeniorProgram(memberProgram.programLevel.name)) && (
            <Typography variant="h6">The current program level cannot be changed</Typography>
          )}
          {!isSeniorProgram(memberProgram.programLevel.name) &&
            !isMS2(memberProgram.programLevel.name) && (
              <Box>
                <Typography variant="h6">New Program</Typography>
                {isFetching ? (
                  <div style={{ flexGrow: 1 }}>
                    <Skeleton
                      animation="wave"
                      height={10}
                      width="100%"
                      style={{ marginBottom: 6 }}
                    />
                    <Skeleton
                      animation="wave"
                      height={10}
                      width="100%"
                      style={{ marginBottom: 6 }}
                    />
                    <Skeleton animation="wave" height={10} width="60%" />
                  </div>
                ) : (
                  <FormControl variant="outlined" margin="none" fullWidth>
                    <Select
                      id="programLevelId"
                      name="programLevelId"
                      disabled={isFetching}
                      value={selectedProgramLevelId}
                      onChange={(e: any) => handleSelectProgramLevel(parseInt(e.target.value))}
                    >
                      {renderPrograms()}
                    </Select>
                  </FormControl>
                )}
              </Box>
            )}
          <Spacer size={20} />

          {/* Program Options */}
          {(isSeniorProgram(memberProgram.programLevel.name) ||
            isMS2(memberProgram.programLevel.name)) &&
            programOptions &&
            programOptions.length > 0 && (
              <Typography variant="h6">The current program options cannot be changed</Typography>
            )}
          {!isSeniorProgram(memberProgram.programLevel.name) &&
            !isMS2(memberProgram.programLevel.name) && (
              <Box>
                {isFetching ? (
                  <div style={{ flexGrow: 1 }}>
                    <Skeleton
                      animation="wave"
                      height={10}
                      width="100%"
                      style={{ marginBottom: 6 }}
                    />
                    <Skeleton
                      animation="wave"
                      height={10}
                      width="100%"
                      style={{ marginBottom: 6 }}
                    />
                    <Skeleton animation="wave" height={10} width="60%" />
                  </div>
                ) : (
                  <>
                    {programOptions.length > 0 && (
                      <>
                        <Typography variant="h6">Program Options</Typography>
                        {programOptions.length === 1 && (
                          <Typography variant="body1">{programOptions[0].description}</Typography>
                        )}
                        {programOptions.length > 1 && (
                          <FormControl variant="outlined" margin="none" fullWidth>
                            <Select
                              id="programOptionId"
                              name="programOptionId"
                              disabled={isFetching}
                              value={selectedProgramOptionId}
                              onChange={(e: any) =>
                                handleSelectProgramOption(parseInt(e.target.value))
                              }
                            >
                              {renderProgramOptions()}
                            </Select>
                          </FormControl>
                        )}
                      </>
                    )}
                  </>
                )}
              </Box>
            )}
        </Grid>

        {/* right column */}
        <Grid item xs={6}>
          {/* Enrolled Members */}
          <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <Typography variant="h6">Enrolled Members</Typography>
            {!canAddMembers && (
              <Link
                href="#"
                style={{ color: theme.textColors.info }}
                onClick={handleAddMemberMessageOpen}
              >
                How do I add new members?
              </Link>
            )}
            <Popover
              open={addMemberMessageOpen}
              anchorEl={addMemberMessageElement}
              onClose={handleAddMemberMessageClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              <Box className={styles.addMemberMessage}>
                <Typography variant="h6">Adding Members</Typography>
                <Typography variant="body2">
                  Since this program already has member enrollments, new members cannot be added
                  using this feature.
                  <br />
                  <br />
                  To add members to this program, you can use the <strong>Admin Add-On</strong>{' '}
                  feature if they have a contact record in Salesforce. They can also be added
                  through a regular <strong>Add-On</strong> opportunity when applying for
                  membership.
                </Typography>
              </Box>
            </Popover>
          </Box>
          <Box
            style={{
              height: '472px',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {/* New Member */}
            {(!isSeniorProgram(memberProgram.programLevel.name) || programMembers.length === 0) &&
              canAddMembers && (
                <>
                  {isFetching ? (
                    <Box>
                      <Skeleton
                        animation="wave"
                        height={10}
                        width="100%"
                        style={{ marginBottom: 6 }}
                      />
                      <Skeleton
                        animation="wave"
                        height={10}
                        width="100%"
                        style={{ marginBottom: 6 }}
                      />
                      <Skeleton animation="wave" height={10} width="60%" />
                    </Box>
                  ) : (
                    <>
                      {((contacts && contacts.length > 0) || eligibleMembers.length > 0) && (
                        <Box>
                          <Spacer size={10} />
                          <div style={{ display: 'flex', alignItems: 'stretch' }}>
                            <FormControl variant="outlined" fullWidth>
                              <InputLabel>Add a new member</InputLabel>
                              <Select
                                label="Add a new member"
                                disabled={isFetching}
                                value={selectedMember}
                                onChange={event => setSelectedMember(event.target.value as string)}
                              >
                                {renderNewMemberOptions()}
                              </Select>
                            </FormControl>
                            <div
                              style={{
                                marginLeft: '15px',
                                display: 'flex',
                                alignItems: 'center',
                                minWidth: '35%',
                              }}
                            >
                              <Button
                                size="large"
                                color="primary"
                                variant="contained"
                                disabled={!selectedMember}
                                onClick={handleAddSelectedMember}
                              >
                                Add
                              </Button>
                            </div>
                          </div>

                          <Spacer size={20} />
                        </Box>
                      )}
                    </>
                  )}
                </>
              )}

            {programMembers.length === 0 && canAddMembers && (
              <Alert color="info" severity="info" variant="outlined">
                Add one or more members to continue
              </Alert>
            )}

            {/* Current Members */}
            <Box style={{ flexGrow: 1, overflow: 'auto' }}>{renderMembers()}</Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default ProgramAndMembers;
