import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import {
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
  makeStyles,
  Popover,
  IconButton,
  Theme,
  Typography,
  useTheme,
} from '@material-ui/core';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Member from '../../../../models/member';
import Person from '../../../../models/person';
import { ProgramOption } from '../../../../models/eligibleProgram';
import ProgramLevelDetails from './ProgramLevelDetails';
import { PricingProgramLevel } from '../../../../models/eligibleProgram';

const useStyles = makeStyles((theme: Theme) => ({
  cardWrapper: {
    opacity: 0.8,
    transition: 'all 0.2s ease-in-out',
    '&:hover': {
      opacity: 1,
    },
    borderRadius: 10,
    marginBottom: '10px',
  },
  currentProgramWrapper: {
    marginLeft: '12px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  btnDetailsWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    marginRight: -20,
  },
  btnDetails: {
    cursor: 'pointer',
  },
  btnPopover: {
    border: '1px solid #ccc',
    padding: 3,
  },
  cardContent: {
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '&:last-child': {
      paddingBottom: '16px',
    },
    backgroundColor: theme.backgroundColors.body,
  },
  selectedCard: {
    border: '1px solid #ccc',
  },
  dpcOption: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginLeft: 50,
  },
}));

interface ProgramLevelProps {
  programLevel: PricingProgramLevel;
  members: Member[];
  eligibleMemberIds: string[];
  isSelected?: boolean;
  readOnly?: boolean;
  enrolledProgramLevel: boolean;
  onSelectProgramLevel: Function;
  renderCurrentProgram?: boolean;
  enrolledProgramSwitchOptionSlug?: string;
}
const ProgramLevel = (props: ProgramLevelProps) => {
  const {
    programLevel,
    members,
    eligibleMemberIds,
    isSelected,
    readOnly,
    enrolledProgramLevel,
    onSelectProgramLevel,
    renderCurrentProgram = true,
    enrolledProgramSwitchOptionSlug,
  } = props;
  const theme = useTheme();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [eligibleMembers, setEligibleMembers] = useState([] as Member[]);
  const open = Boolean(anchorEl);
  const id = open ? 'details-popover' : undefined;
  const options = programLevel.options || [];
  const [programLevelSelected, setProgramLevelSelected] = useState(isSelected);

  const handlePopover = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDPCSelected = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = (event.target as HTMLInputElement).checked;

    if ((programLevel as PricingProgramLevel).options) {
      for (let i = 0; i < (programLevel as PricingProgramLevel).options?.length; i++) {
        if ((programLevel as PricingProgramLevel).options[i].slug === 'DPC') {
          (programLevel as PricingProgramLevel).options[i].selected = checked;
        }
      }
    }

    setProgramLevelSelected(true);
    onSelectProgramLevel(true, programLevel, eligibleMembers);
  };

  const handleSelectProgramLevel = (e: any) => {
    if (readOnly) {
      return;
    }

    const isChecked = !programLevelSelected;
    onSelectProgramLevel(isChecked, programLevel, eligibleMembers);
  };

  const currentlyEnrolled = () => {
    const memberIds = members.map((m: Member) => m.uid);
    return (
      enrolledProgramLevel &&
      eligibleMemberIds.every((m: string) => memberIds.includes(m)) &&
      memberIds.every((m: string) => eligibleMemberIds.includes(m))
    );
  };

  useEffect(() => {
    setEligibleMembers(members.filter((member: Member) => eligibleMemberIds.includes(member.uid)));
  }, [members, eligibleMemberIds]);

  useEffect(() => {
    // For MS3-12K select the option that's not the current one when switching
    if (enrolledProgramSwitchOptionSlug && programLevel.options.length > 1) {
      const selectedOption = programLevel.options.some(o => o.selected === true);
      if (!selectedOption) {
        programLevel.options[
          programLevel.options.findIndex(opt => opt.slug !== enrolledProgramSwitchOptionSlug)
        ].selected = true;
      }
    }
  }, [programLevel, enrolledProgramSwitchOptionSlug]);

  return !renderCurrentProgram &&
    ((programLevel.options.length === 1 && currentlyEnrolled()) ||
      (enrolledProgramLevel && !currentlyEnrolled())) ? (
    <></>
  ) : (
    <Card className={clsx(classes.cardWrapper, isSelected ? classes.selectedCard : '')}>
      <CardContent className={classes.cardContent}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            flexGrow: 1,
          }}
        >
          <div style={{ flexGrow: 0, flexShrink: 0, width: '40px' }}>
            {(!currentlyEnrolled() || (currentlyEnrolled() && programLevel.options.length > 1)) && (
              <Checkbox
                value={programLevel.id}
                checked={isSelected}
                disabled={readOnly}
                onChange={handleSelectProgramLevel}
                color="primary"
              />
            )}
          </div>
          <div style={{ flexGrow: 1 }}>
            <div className={classes.currentProgramWrapper}>
              <Typography
                variant="h5"
                component="div"
                style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}
              >
                <div>
                  <strong>
                    {programLevel.description}{' '}
                    <Typography component="span" variant="h6" style={{ marginLeft: 10 }}>
                      {programLevel.name}
                    </Typography>
                  </strong>
                </div>
                <div>
                  <strong>${programLevel.totalAmount}/mo</strong>
                </div>
              </Typography>
              <div>
                <strong>Eligible Members: </strong>
                {eligibleMembers.map((member: Member, index: number) => {
                  const person = member.person as Person;
                  return (
                    <span key={index}>
                      {index > 0 ? ', ' : ''}
                      {person.firstName} {person.lastName}
                    </span>
                  );
                })}
              </div>
              {currentlyEnrolled() && programLevel.options.length === 1 && (
                <Typography variant="h6" color="error" style={{ marginTop: 4, marginBottom: 4 }}>
                  Currently Enrolled (All Members)
                </Typography>
              )}
              {enrolledProgramLevel && !currentlyEnrolled() && (
                <Typography
                  variant="caption"
                  color="textPrimary"
                  style={{
                    marginTop: 4,
                    marginBottom: 4,
                    fontWeight: 700,
                    color: theme.textColors.info,
                  }}
                >
                  * Same program level as current program
                </Typography>
              )}

              {/*We only want to show this checkbox for MS3-12K (has more than one option) - all other programs submit APLL*/}
              <div>
                {options &&
                  options.map((option: ProgramOption, index: number) => {
                    if (options.length > 1 && currentlyEnrolled()) {
                      if (option.slug === enrolledProgramSwitchOptionSlug!) {
                        return '';
                      } else {
                        return (
                          <Typography key={index} variant="h6" style={{ marginTop: 10 }}>
                            {option.description}
                          </Typography>
                        );
                      }
                    } else if (option.slug === 'DPC') {
                      if (isSelected) {
                        return (
                          <FormControlLabel
                            key={index}
                            control={
                              <Checkbox
                                checked={option.selected || false}
                                disabled={readOnly}
                                onChange={handleDPCSelected}
                                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                checkedIcon={<CheckBoxIcon fontSize="small" />}
                                name="selectOptions"
                                color="primary"
                              />
                            }
                            label={option.description}
                          />
                        );
                      } else {
                        return (
                          <Typography key={index} variant="h6" style={{ marginTop: 10 }}>
                            {option.description} (Optional)
                          </Typography>
                        );
                      }
                    }
                    return '';
                  })}
              </div>
            </div>
          </div>
          <div
            style={{
              alignItems: 'center',
              flexGrow: 0,
              flexShrink: 0,
              width: '40px',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <div className={classes.btnDetailsWrapper}>
              <IconButton onClick={handlePopover} className={classes.btnPopover}>
                <ChevronRightIcon className={classes.btnDetails} fontSize={'small'} />
              </IconButton>
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
              >
                <ProgramLevelDetails programLevel={programLevel} />
              </Popover>
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default ProgramLevel;
