import Group from './group';

/**
 * Organization
 */

export interface OrganizationSettings {
  logoUrl?: string;
}
export default class Organization {
  uid = '';
  default = false;
  organizationName = '';
  settings: OrganizationSettings;
  status = '';
  groups?: Group[];

  constructor(data: any = {}) {
    this.default = data.default;
    this.uid = data.uid;
    this.organizationName = data.organizationName;
    this.settings = data.settings;
    this.status = data.status;
  }

  get orgName() {
    return this.organizationName + '!';
  }
}
