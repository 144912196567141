import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Thunk } from '..';
import { showToastMessage, hideToastMessage } from './toastMessage';
import CustomError from '../../lib/customError';
import User from '../../models/user';
import apiClient from '../../lib/api';
import ClientStorage, { itemTypes } from '../../lib/clientStorage';

export interface SessionState {
  user: User | null;
  accessToken: string | null;
  lastActive: Date | null;
  loading: boolean;
  error: CustomError | null;
}

interface AuthenticationSuccessPayload {
  user: User;
  accessToken: string;
}

interface AuthenticationErrorPayload {
  error: CustomError;
}

export const initialState = {
  user: null,
  loading: false,
} as SessionState;

const sessionSlice = createSlice({
  name: 'session',
  initialState,
  reducers: {
    authenticationRequest(state) {
      state.error = null;
      state.loading = true;
    },
    authenticationSuccess(state, action: PayloadAction<AuthenticationSuccessPayload>) {
      state.user = action.payload.user;
      state.accessToken = action.payload.accessToken;
      state.error = null;
      state.loading = false;
    },
    authenticationError(state, action: PayloadAction<AuthenticationErrorPayload>) {
      state.user = null;
      state.accessToken = null;
      state.error = action.payload.error;
      state.loading = false;
    },
    logoutSuccess(state) {
      state.user = null;
      state.accessToken = null;
      state.loading = false;
    },
    updateSessionActivity(state) {
      state.lastActive = new Date();
    },
  },
});

/* Thunks */
interface Credentials {
  username: string;
  password: string;
}
export const authenticateUser = ({ username, password }: Credentials): Thunk => async dispatch => {
  try {
    dispatch(hideToastMessage());
    dispatch(authenticationRequest());
    const response = await apiClient.authenticate(username, password);

    const user = new User({
      ...response.user,
      id: response.user.userId,
      email: username,
      groups: response.groups,
    });
    ClientStorage.store(itemTypes.USER, user, true);

    const accessTokenData = {
      accessToken: response.accessToken,
      issuedAt: response.accessTokenIssuedAt,
      expiresAt: response.accessTokenExpiresAt,
      refreshTokenId: response.refreshTokenId,
    };

    ClientStorage.store(itemTypes.ACCESS_TOKEN, accessTokenData, true);

    dispatch(authenticationSuccess({ user, accessToken: response.accessToken }));
    dispatch(hideToastMessage());
  } catch (err: any) {
    dispatch(showToastMessage({ message: err.message, type: 'error' }));
    dispatch(authenticationError({ error: err }));
  }
};

export const logout = (): Thunk => async dispatch => {
  ClientStorage.clear(itemTypes.USER, true);
  dispatch(logoutSuccess());
};

/* Actions & Reducer */
/* prettier-ignore */
export const {
  authenticationRequest, authenticationSuccess, authenticationError,
  logoutSuccess, updateSessionActivity
} = sessionSlice.actions;

export default sessionSlice.reducer;
