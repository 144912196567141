import AccountEvent from './accountEvent';
import { Address } from './address';
import Group from './group';
import Member from './member';
import MemberProgram, { MemberProgramStatus } from './memberProgram';
import ProgramEnrollment from './programEnrollment';

export interface CancelProgram {
  memberProgram: MemberProgram;
  eligibleMembers: Member[];
}

export interface ReactivateProgram {
  memberProgram: MemberProgram;
  eligibleMembers: Member[];
}

export interface BankAccount {
  name: string;
  dateOpened: Date;
  customerNumber: string;
  accountNumber: string;
}

export interface Settings {
  bankAccounts: BankAccount[];
  householdMaritalStatus: string;
}

export interface AccountErrors {
  missingMembersPerson: string[];
}

export default class MembershipAccount {
  id = '';
  uid = '';
  organization = '';
  group: Group | undefined;
  accountNumber = '';
  accountName = '';
  accountType = '';
  sfAccountId = '';
  sfAccountUId = '';
  netsuiteId = '';
  certNumber = '';
  gumd = '';
  settings: Settings;
  started: number;
  status = '';
  members?: Member[];
  memberPrograms?: MemberProgram[];
  programEnrollments?: ProgramEnrollment[];
  addresses?: Address[];
  events?: AccountEvent[];
  archived?: boolean;
  errors: AccountErrors | null = null;

  constructor(data: any = {}) {
    this.id = data.id;
    this.uid = data.uid;
    this.organization = data.organization;
    this.group = data.group;
    this.accountNumber = data.accountNumber;
    this.accountName = data.accountName;
    this.accountType = data.accountType;
    this.sfAccountId = data.sfAccountId;
    this.sfAccountUId = data.sfAccountUId;
    this.netsuiteId = data.netsuiteId;
    this.certNumber = data.certNumber;
    this.gumd = data.gumd;
    this.settings = data.settings;
    this.started = data.started;
    this.status = data.status;
    this.members = data.members;
    this.memberPrograms = data.memberPrograms;
    this.programEnrollments = data.programEnrollments;
    this.addresses = data.addresses;
    this.events = data.events;
    this.archived = data.archived;

    this.checkAccountErrors();
    this.filterDuplicateProgramEnrollments();
  }

  getAvailableProgramsToCancel = (): CancelProgram[] => {
    const activeMemberPrograms = this.memberPrograms!.filter(mp => mp.status === 'active');
    const members = this.members!.filter(m => m.status === 'active');
    const programEnrollments = this.programEnrollments!.filter(pe => !Boolean(pe.ended));
    return activeMemberPrograms?.map(amp => {
      const eligibleMembers = members!.filter(m =>
        programEnrollments.some(pe => {
          let result;

          if (typeof pe.member === 'string') result = pe.member === m.uid;
          else result = pe.member.uid === m.uid;

          if (typeof pe.memberProgram === 'string') result = result && pe.memberProgram === amp.uid;
          else result = result && pe.memberProgram?.uid === amp.uid;

          return result;
        }),
      );
      return {
        memberProgram: amp,
        eligibleMembers,
      };
    });
  };

  getInactiveMembers = (uid: string): Member[] => {
    const program = this.memberPrograms!.filter(mp => mp.uid === uid)[0];
    const programEnrollments = this.programEnrollments!.filter(pe => Boolean(pe.ended));
    const members = this.members!.filter(m => m.status === 'inactive');

    return members!.filter(m =>
      programEnrollments.some(pe => pe.member === m.uid && pe.memberProgram === program.uid),
    );
  };

  getFilteredStatusMemberPrograms = (
    filterSA = false,
    filterStatus: MemberProgramStatus[] = [
      MemberProgramStatus.Active,
      MemberProgramStatus.Cancelled,
      MemberProgramStatus.Deleted,
      MemberProgramStatus.Inactive,
      MemberProgramStatus.OnHold,
      MemberProgramStatus.Pending,
    ],
  ) => {
    const filteredMemberPrograms = this.memberPrograms!.filter(mp =>
      filterStatus.some(fs => fs === mp.status),
    );

    if (filterSA) {
      return filteredMemberPrograms.filter(amp => !amp.isSeniorAssist);
    }
    return filteredMemberPrograms;
  };

  getProgramEnrollmentsByMemberUid = (memberUid: string) =>
    this.programEnrollments?.filter(pe => {
      if (typeof pe.member === 'string') return pe.member === memberUid;
      return pe.member.uid === memberUid;
    });

  /**
   * Filter out members who are missing the person data object
   */
  filterOutMemberMissingPerson = () => {
    this.members = this.members?.filter(m => m.person);
    this.programEnrollments = this.programEnrollments?.filter(pe =>
      this.members?.some(m => m.uid === pe.member),
    );
  };

  /**
   * Check any account errors
   */
  checkAccountErrors = () => {
    const missingMembersPerson = this.members?.filter(m => !m.person).map(m => m.uid) || [];
    if (missingMembersPerson.length > 0) {
      this.errors = {
        missingMembersPerson,
      };
      this.filterOutMemberMissingPerson();
    }
  };

  /**
   * Filter out any enrollments of member in a member program that has the same start date with other program enrollments, and it has ended.
   */
  filterDuplicateProgramEnrollments = () => {
    const filteredDuplicatedProgramEnrollments =
      this.programEnrollments?.filter((peA, indexA) => {
        const hasSameStartByMemberAndProgram = this.programEnrollments?.some((peB, indexB) => {
          return (
            // only compare from with the next enrollment in the array
            indexB > indexA &&
            peB.started === peA.started &&
            peB.member === peA.member &&
            peB.memberProgram &&
            peA.memberProgram
          );
        });
        return !(hasSameStartByMemberAndProgram && peA.ended);
      }) ?? [];

    this.programEnrollments = filteredDuplicatedProgramEnrollments;
  };
}
