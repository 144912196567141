import React from 'react';
import { Container, createStyles, makeStyles } from '@material-ui/core';

import Page from '../components/Page';
import NotFound from '../components/NotFound';

const useStyles = makeStyles(theme =>
  createStyles({
    componentContainer: {
      maxWidth: '640px',
      marginTop: '40px',
    },
  }),
);

const NotFoundView = (props: any) => {
  const styles = useStyles();

  return (
    <Page>
      <Container className={styles.componentContainer}>
        <NotFound />
      </Container>
    </Page>
  );
};

export default NotFoundView;
