import React from 'react';
import { Box, Button, createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import Member from '../../../models/member';
import MemberCard from "./MemberCard";
import MembershipAccount from "../../../models/membershipAccount";
import Alert from "@material-ui/lab/Alert";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		membersContainer: {
			display: 'flex',
			flexDirection: 'row',
			flexWrap: 'wrap',
			gap: '16px'
		},
		header: {
			display: 'flex',
			justifyContent: 'start',
			alignItems: 'center',
			marginBottom: '10px',
			gap: '5px',
			'& Button': {
				fontSize: '12px',
				textDecorationLine: 'underline',
				fontWeight: 500,
				lineHeight: '18px',
				textTransform: 'none'
			}
		},
		title: {
			fontSize: '18px',
			letterSpacing: .15,
			margin: 0,
			fontWeight: 400,
			lineHeight: '21px',
			textTransform: 'none'
		}
	}),
);

interface AccountMemberSelectorProps {
	members: Member[];
	selectedMembers: { [key: string]: Member };
	onMemberSelected: (member: Member, selected: boolean) => void;
	membershipAccount: MembershipAccount;
	title?: string;
}

export const MemberSelector: React.FC<AccountMemberSelectorProps> = ({
																																			 members,
																																			 selectedMembers,
																																			 onMemberSelected,
																																			 membershipAccount,
																																			 title = 'Members'
																																		 }) => {
	const styles = useStyles();
	const allSelected = members.length === Object.keys(selectedMembers).length;
	const handleSelectAll = () => {
		if (allSelected) {
			members.forEach((member) => onMemberSelected(member, false)); // Deselect all members
		} else {
			members.forEach((member) => onMemberSelected(member, true)); // Select all members
		}
	};

	return (
		members.length > 0 ? <>
			<Box className={styles.header}>
				<Typography variant="h6" className={styles.title}>{title}</Typography>
				{members.length > 1 &&
            <Button onClick={handleSelectAll} variant="text" color="primary">
							{allSelected ? 'Deselect All' : 'Select All'}
            </Button>
				}
			</Box>
			<Box className={styles.membersContainer}>
				{members.map((member: Member, index: number) => (
					<MemberCard
						key={index}
						member={member}
						onMemberSelected={onMemberSelected}
						selected={Object.keys(selectedMembers).includes(member.uid)}
						programEnrollments={membershipAccount.getProgramEnrollmentsByMemberUid(member.uid) || []}
						selectable={true}/>
				))}
			</Box>
		</> : <Alert severity="warning">There are no members to select from</Alert>
	);
};
