import MemberProgram from '../../../../../models/memberProgram';
import Dialog from '../../../../Dialog';
import { Button } from '@material-ui/core';
import ButtonProgressIndicator from '../../../../ButtonProgressIndicator';
import React, { useState } from 'react';
import ProgramDelete from './ProgramDelete';
import ccmCoreAPI from '../../../../../lib/api';
import { showToastMessage } from '../../../../../store/slices/toastMessage';
import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';

interface ProgramDeleteDialogProps {
  memberProgram: MemberProgram;
  toggleShowDialog: Function;
  handleMemberProgramClose: Function;
  membershipAccountUpdated: Function;
  open: boolean;
}

const ProgramDeleteDialog = (props: ProgramDeleteDialogProps) => {
  const {
    open,
    toggleShowDialog,
    memberProgram,
    handleMemberProgramClose,
    membershipAccountUpdated,
  } = props;
  const [dialogTitle, setDialogTitle] = useState('Delete Program');
  const [currentTab, setCurrentTab] = useState(0);
  const dispatch = useDispatch();

  const handleCloseDialog = () => {
    toggleShowDialog();
    setCurrentTab(0);
    handleMemberProgramClose();
  };

  const handleTabClick = (tabIndex: number) => {
    tabIndex > 0
      ? setDialogTitle('Delete Program: Confirmation')
      : setDialogTitle('Delete Program');
    setCurrentTab(tabIndex);
  };

  // Submits the delete program request
  const deleteProgram = useMutation((memberProgram: string) =>
    ccmCoreAPI.membershipPrograms.deleteProgram(memberProgram),
  );

  const handleSubmit = () => {
    if (memberProgram) {
      deleteProgram.mutate(memberProgram.uid, {
        onSuccess: data => {
          dispatch(showToastMessage({ message: `Member Program deleted`, type: 'success' }));
          membershipAccountUpdated();
          handleCloseDialog();
        },
        onError: (error: any) => {
          console.error(error);
          dispatch(
            showToastMessage({
              message: 'Member Program could not be deleted',
              type: 'error',
            }),
          );
        },
      });
    } else {
      dispatch(
        showToastMessage({
          message: 'No member program to delete',
          type: 'error',
        }),
      );
    }
  };

  const renderButtons = () => {
    const nextButton = (
      <Button
        key="nextButton"
        color="primary"
        variant="contained"
        disabled={!memberProgram}
        onClick={() => {
          setCurrentTab(currentTab + 1);
          setDialogTitle('Delete Program: Confirmation');
        }}
      >
        Next
      </Button>
    );
    const submitButton = (
      <Button
        key="submitButton"
        color="primary"
        variant="contained"
        disabled={deleteProgram.isLoading}
        onClick={handleSubmit}
      >
        {!deleteProgram.isLoading ? 'Submit' : <ButtonProgressIndicator size={24} />}
      </Button>
    );
    const cancelButton = (
      <Button
        key="cancelButton"
        color="default"
        variant="contained"
        disabled={deleteProgram.isLoading}
        onClick={handleCloseDialog}
      >
        Cancel
      </Button>
    );

    if (currentTab === 0) {
      return [nextButton, cancelButton];
    } else {
      return [submitButton, cancelButton];
    }
  };

  return (
    <Dialog open={open} title={dialogTitle} buttons={renderButtons()}>
      <ProgramDelete
        memberProgram={memberProgram}
        onTabClick={handleTabClick}
        currentTab={currentTab}
      />
    </Dialog>
  );
};

export default ProgramDeleteDialog;
