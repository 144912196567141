import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Thunk } from '..';
import apiClient from '../../lib/api';
import Organization from '../../models/organization';
import CustomError from '../../lib/customError';
import { hideToastMessage, showToastMessage } from './toastMessage';
import { OrganizationData } from '../../lib/api/organizations';

interface OrganizationState {
  organization: Organization | null;
  loading: boolean;
  saved: boolean;
  error: CustomError | null;
}

interface OrganizationSuccessPayload {
  organization: Organization;
}

interface OrganizationErrorPayload {
  error: CustomError;
}

export const initialState = {
  loading: false,
  saved: false,
} as OrganizationState;

const organizationSlice = createSlice({
  name: 'organization',
  initialState,
  reducers: {
    resetOrganization(state) {
      state.organization = null;
      state.error = null;
      state.loading = false;
      state.saved = false;
    },
    saveOrganizationRequest(state) {
      state.organization = null;
      state.error = null;
      state.loading = true;
      state.saved = false;
    },
    saveOrganizationSuccess(state, action: PayloadAction<OrganizationSuccessPayload>) {
      state.organization = new Organization(action.payload.organization);
      state.error = null;
      state.loading = false;
      state.saved = true;
    },
    saveOrganizationError(state, action: PayloadAction<OrganizationErrorPayload>) {
      state.error = action.payload.error;
      state.loading = false;
      state.saved = false;
    },
    getOrganizationRequest(state) {
      state.error = null;
      state.loading = true;
    },
    getOrganizationSuccess(state, action: PayloadAction<OrganizationSuccessPayload>) {
      state.organization = new Organization(action.payload.organization);
      state.error = null;
      state.loading = false;
    },
    getOrganizationError(state, action: PayloadAction<OrganizationErrorPayload>) {
      state.error = action.payload.error;
      state.loading = false;
    },
    deleteOrganizationRequest(state) {
      state.error = null;
      state.loading = true;
      state.saved = false;
    },
    deleteOrganizationSuccess(state) {
      state.error = null;
      state.loading = false;
      state.saved = true;
      state.organization = new Organization({ ...state.organization, status: 'deleted' });
    },
    deleteOrganizationError(state, action: PayloadAction<OrganizationErrorPayload>) {
      state.error = action.payload.error;
      state.loading = false;
      state.saved = false;
    },
  },
});

/* Thunks */

export const saveOrganization = (data: OrganizationData): Thunk => async dispatch => {
  try {
    dispatch(hideToastMessage());
    dispatch(saveOrganizationRequest());

    const organization = await apiClient.organizations.save(data);
    dispatch(saveOrganizationSuccess({ organization }));
    dispatch(
      showToastMessage({
        message: 'The organization has been saved',
        type: 'success',
        duration: 3000,
      }),
    );
  } catch (err: any) {
    dispatch(saveOrganizationError({ error: err }));
    dispatch(showToastMessage({ message: err.message, type: 'error' }));
  }
};

export const getOrganization = (uid: string): Thunk => async dispatch => {
  try {
    dispatch(getOrganizationRequest());
    const organization: Organization = await apiClient.organizations.get(uid);
    dispatch(getOrganizationSuccess({ organization }));
  } catch (err: any) {
    dispatch(getOrganizationError({ error: err }));
    dispatch(showToastMessage({ message: err.message, type: 'error' }));
  }
};

export const deleteOrganization = (uid: string): Thunk => async dispatch => {
  try {
    dispatch(deleteOrganizationRequest());
    await apiClient.organizations.delete(uid);
    dispatch(deleteOrganizationSuccess());
    dispatch(
      showToastMessage({
        message: 'The organization has been deleted',
        type: 'success',
        duration: 3000,
      }),
    );
  } catch (err: any) {
    dispatch(deleteOrganizationError({ error: err }));
    dispatch(showToastMessage({ message: err.message, type: 'error' }));
  }
};

/* Actions & Reducer */
/* prettier-ignore */
export const {
  resetOrganization,
  saveOrganizationRequest, saveOrganizationSuccess, saveOrganizationError,
  getOrganizationRequest, getOrganizationSuccess, getOrganizationError,
  deleteOrganizationRequest, deleteOrganizationSuccess, deleteOrganizationError,
} = organizationSlice.actions;

export default organizationSlice.reducer;
