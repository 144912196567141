import React from 'react';
import { Button, Typography, Grid, Card, CardContent } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { CancellationType, DataValues } from './MembershipCancellation';
import Person from '../../../models/person';
import { getCancellationReasonHumanReadable } from '../../../models/memberProgram';
import moment from 'moment';
import ButtonProgressIndicator from '../../ButtonProgressIndicator';
import { CancelProgram } from '../../../models/membershipAccount';

const useStyles = makeStyles(theme => ({
  body: {
    display: 'flex',
    flexDirection: 'column',
    height: 400,
    '& h3': {
      marginTop: 0,
      fontSize: '1.5rem',
    },
  },
  bodyCancelInfo: {
    fontSize: '1rem',
    fontWeight: 600,
  },
  bodyDataWrapper: {
    marginTop: '1rem',
    '& > h6, p': {
      fontSize: '1rem',
    },
  },
  bodyPrograms: {
    padding: '1rem',
    '& h5': {
      fontSize: '1.25rem',
      fontWeight: 600,
    },
  },
  buttonWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-end',
    '& > :first-child': {
      marginRight: '2rem',
    },
  },
  cardWrapper: {
    opacity: 0.8,
    transition: 'all 0.2s ease-in-out',
    '&:hover': {
      opacity: 1,
    },
    borderRadius: 10,
    marginBottom: '10px',
  },
  cardContent: {
    cursor: 'pointer',
    '&:last-child': {
      paddingBottom: '16px',
    },
    backgroundColor: theme.backgroundColors.body,
  },
  rowItem: {
    marginBottom: '3px',
  },
  programName: {
    marginBottom: '0',
    fontWeight: 'bold',
  },
}));

interface MembershipCancellationConfirmProps {
  cancellationData: DataValues;
  isCancelling: boolean;
  onClose: () => void;
  onConfirm: () => void;
  activeMemberEnrolledPrograms: CancelProgram[];
}

const MembershipCancellationConfirm: React.FC<MembershipCancellationConfirmProps> = ({
  cancellationData,
  isCancelling,
  onClose,
  onConfirm,
  activeMemberEnrolledPrograms
}) => {
  const styles = useStyles();
  return (
    <>
      <div className={styles.body}>
        <Typography component="p" className={styles.bodyCancelInfo}>
          {cancellationData.cancellationType === CancellationType.MembershipAccount
            ? 'The membership account, all members and enrollments will be cancelled effective chosen end date.'
            : 'The members and enrollments below will be cancelled effective chosen end date.'}
        </Typography>
        <Grid container spacing={1} className={styles.bodyDataWrapper}>
          <Grid item xs={12} md={6}>
            <Typography variant="h6">Reason</Typography>
            <p>{getCancellationReasonHumanReadable(cancellationData!.cancellationReason!)}</p>
            <Typography variant="h6">End Date</Typography>
            <p>{moment(cancellationData!.endDate!).format('LL')}</p>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="h6">Programs</Typography>
            {cancellationData.cancelProgram.map(cp => (
              <Card className={styles.cardWrapper} key={cp.memberProgram.uid}>
                <CardContent className={styles.cardContent}>
                  <Grid container className={styles.rowItem}>
                    <Grid item xs={12}>
                      <Typography component="span" variant="h5" className={styles.programName}>
                        {cp.memberProgram.programLevel.description}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} className={styles.rowItem}>
                    <div>
                      <strong>Eligible Members: </strong>
                      {
                      activeMemberEnrolledPrograms.map(amep => {
                        if (amep.memberProgram.uid === cp.memberProgram.uid) {
                          return amep.eligibleMembers.map((em, index) => {
                            const person = em.person as Person;
                            return (
                              <span key={index}>
                                {index > 0 ? ', ' : ''}
                                {person.firstName} {person.lastName}
                              </span>
                            )
                          })
                        } else {
                          return null;
                        }
                      })
                    }
                    </div>
                  </Grid>
                </CardContent>
              </Card>
            ))}
          </Grid>
        </Grid>
      </div>
      <div className={styles.buttonWrapper}>
        <Button
          type="submit"
          color="primary"
          variant="contained"
          size="large"
          onClick={onConfirm}
          disabled={isCancelling}
        >
          {isCancelling ? <ButtonProgressIndicator /> : 'Confirm'}
        </Button>
        <Button
          color="default"
          variant="contained"
          size="large"
          onClick={onClose}
          disabled={isCancelling}
        >
          Cancel
        </Button>
      </div>
    </>
  );
};

export default MembershipCancellationConfirm;
