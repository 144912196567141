import { Card, CardContent, Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Organization from '../../models/organization';
import Spacer from '../Spacer';
import OrganizationLogo from './OrganizationLogo';

interface OrgProps {
  organization: Organization;
}

const useStyles = makeStyles(theme => ({
  card: {
    textAlign: 'center',
  },
  logoPlaceholderBox: {
    display: 'flex',
    justifyContent: 'center',
    height: '150px',
    padding: '10px',
    background: '#fff',
    borderRadius: '8px',
  },
}));

const OrganizationCard: React.FC<OrgProps> = props => {
  const styles = useStyles();
  const { organization } = props;

  return (
    <Card className={styles.card} variant="outlined">
      <CardContent>
        <Box className={styles.logoPlaceholderBox}>
          <OrganizationLogo organization={organization} />
        </Box>
        <Spacer size={8} />
        <Typography color="textPrimary" variant="h3">
          {organization.organizationName}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default OrganizationCard;
