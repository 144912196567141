import { Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { updateSessionActivity } from '../store/slices/session';

const CustomDialog = ({ title, buttons, footer, children, ...rest }: any) => {
  const dispatch = useDispatch();
  const scroll = rest.scroll || 'paper';
  const maxWidth = rest.maxWidth || false;

  useEffect(() => {
    if (!rest.preventUserActivity) {
      dispatch(updateSessionActivity());
    }
  }, [dispatch, rest.open, rest.preventUserActivity]);

  return (
    <Dialog scroll={scroll} maxWidth={maxWidth} {...rest}>
      {title && (
        <DialogTitle disableTypography>
          <Typography variant="h3" style={{ margin: 0 }}>
            {title}
          </Typography>
        </DialogTitle>
      )}
      <DialogContent>{children}</DialogContent>
      <footer style={ rest.footerStyle || { display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
        <div style={{ display: 'flex', alignItems: 'center', paddingRight: 20 }}>{footer}</div>
        <div>{buttons && <DialogActions style={{ padding: '16px' }}>{buttons}</DialogActions>}</div>
      </footer>
    </Dialog>
  );
};

export default CustomDialog;
