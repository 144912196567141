import React from 'react';
import {
  Card,
  CardContent,
  createStyles,
  Grid,
  makeStyles,
  Theme,
  Tooltip,
  Typography,
} from '@material-ui/core';
import LockIcon from '@material-ui/icons/Lock';

import Person from '../../../../models/person';
import moment from 'moment';
import StatusBadge, { getStatusVariant } from '../../../StatusBadge';
import MemberProgram from '../../../../models/memberProgram';
import { removeUnderscoresFromString } from '../../../../lib/util';
import ProgramEnrollment from '../../../../models/programEnrollment';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    memberProgram: {
      opacity: 0.6,
      transition: 'all 0.2s ease-in-out',
      '&:hover': {
        opacity: 1,
      },
    },
    memberProgramContent: {
      cursor: 'pointer',
      '&:last-child': {
        paddingBottom: '16px',
      },
    },
    programStatus: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    programName: {
      marginBottom: '0',
      fontWeight: 'bold',
    },
    marginLeft: {
      marginLeft: '3px',
    },
    rowItem: {
      marginBottom: '3px',
    },
    label: {
      fontWeight: 'bold',
    },
    archivedIcon: {
      position: 'absolute',
      marginTop: '-10px',
      marginRight: '-8px',
      top: 0,
      right: 0,
      color: theme.textColors.warn,
      opacity: 0.6,
      zIndex: 1000,
    },
  }),
);

interface MemberProgramViewProps {
  memberProgram: MemberProgram;
  enrollments: ProgramEnrollment[];
  handleMemberProgramOpen: Function;
}

const MemberProgramView: React.FC<MemberProgramViewProps> = ({
  memberProgram,
  enrollments,
  handleMemberProgramOpen,
}) => {
  const styles = useStyles();
  return (
    <div>
      {memberProgram.archived && (
        <div style={{ position: 'relative' }}>
          <Tooltip title="archived">
            <LockIcon className={styles.archivedIcon} />
          </Tooltip>
        </div>
      )}
      <Card className={styles.memberProgram} onClick={() => handleMemberProgramOpen(memberProgram)}>
        <CardContent className={styles.memberProgramContent}>
          <Grid container className={styles.rowItem}>
            <Grid item xs={6}>
              <Typography component="span" variant="h5" className={styles.programName}>
                {memberProgram.programLevel.description}
              </Typography>
            </Grid>
            <Grid item xs={6} className={styles.programStatus}>
              <div>
                <StatusBadge
                  color="success"
                  variant={getStatusVariant(memberProgram.status)}
                  label={removeUnderscoresFromString(memberProgram.status)}
                />
              </div>
            </Grid>
          </Grid>
          <Grid item xs={12} className={styles.rowItem}>
            <span className={styles.label}>Effective: </span>{' '}
            <span className={styles.marginLeft}>
              {moment(memberProgram.started).format('ll')}
              {memberProgram.ended && ' - ' + moment(memberProgram.ended).format('ll')}
            </span>
          </Grid>
          {/* Displaying the current active enrollments for the program */}
          {memberProgram.members && (
            <Grid item xs={12} className={styles.rowItem}>
              <span className={styles.label}>Members: </span>
              {enrollments.some(e => (e.ended && moment(e.ended).isAfter(moment())) || !e.ended)
                ? enrollments
                    .filter(e => (e.ended && moment(e.ended).isAfter(moment())) || !e.ended)
                    .map((enrollment, index) => {
                      const member = memberProgram.members?.find(m =>
                        typeof enrollment.member === 'string'
                          ? m.uid === enrollment.member
                          : m.uid === enrollment.member.uid,
                      );
                      const person = member && (member.person as Person);
                      if (person) {
                        return (
                          <span className={styles.marginLeft} key={person.uid}>
                            {index ? ', ' : ''} {person.firstName} {person.lastName}
                          </span>
                        );
                      }

                      return '';
                    })
                : 'no currently enrolled members'}
            </Grid>
          )}
        </CardContent>
      </Card>
    </div>
  );
};

export default MemberProgramView;
