import { SearchMedicalBillReimbursementsQueryParams } from './api/medical-bill-reimbursements.api.port';

/**
 * The statuses that a medical bill reimbursement can be in
 */
export enum MedicalBillReimbursementStatus {
  Received = 'received',
  Reviewed = 'reviewed',
  Submitted = 'submitted',
  Failed = 'failed',
}

/**
 * The statuses that a medical bill reimbursement salesforce case can be in
 */
export enum MedicalBillReimbursementSalesforceCaseStatus {
  Closed = 'Closed',
}

export const defaultQueryParams: SearchMedicalBillReimbursementsQueryParams = {
  page: 1,
  pageSize: 25,
  plus1: true,
};

export interface LoadingProgress {
  progress: number;
  hidden: boolean;
}
