import { Alert, AlertTitle } from '@material-ui/lab';
import { FormikProps } from 'formik';
import React, { useEffect, useState } from 'react';
import Member, { Role } from '../../../../../models/member';
import Spacer from '../../../../Spacer';
import { DeathForm } from './DeathDialog';
import { MemberList } from "../../../member/MemberList";

export const Confirmation: React.FC<{
	members: Member[];
	form: FormikProps<DeathForm>;
}> = ({members, form}) => {
	const [withdrawingMembers, setWithdrawingMembers] = useState<Member[]>([]);
	const [newShareAccountRequired, setNewShareAccountRequired] = useState<boolean>(false);

	useEffect(() => {
		const deceasedAndContinuingMembers = [
			...form.values.event?.deceasedMembers!,
			...form.values.event?.continuingMembers!,
		];

		const withdrawingMembers = members.filter(
			member => !deceasedAndContinuingMembers.includes(member.uid),
		);

		setWithdrawingMembers(withdrawingMembers);

		const accountOwner = members.find(member => member.role === Role.AccountOwner);
		const spouse = members.find(member => member.role === Role.Admin);

		const isAccountOwnerContinuing =
			!!accountOwner && form.values.event!.continuingMembers.includes(accountOwner.uid);

		if (spouse && !isAccountOwnerContinuing) {
			setNewShareAccountRequired(form.values.event!.continuingMembers.includes(spouse.uid));
		}

		if (isAccountOwnerContinuing) {
			form.values.event!.remainingMembers = form.values.event!.continuingMembers;
			form.values.event!.transitioningMembers = [];
		} else {
			form.values.event!.transitioningMembers = form.values.event!.continuingMembers;
			form.values.event!.remainingMembers = [];
		}
	}, [members, form]);

	return (
		<>
			{newShareAccountRequired && (
				<Alert severity="warning" variant="outlined">
					<AlertTitle>New Share Account Required</AlertTitle>
					Continue to request KYC check for surviving spouse.
				</Alert>
			)}
			{!newShareAccountRequired && (
				<Alert severity="warning" variant="outlined">
					<AlertTitle>New Share Account Not Required</AlertTitle>
					No KYC Required.
				</Alert>
			)}

			{form.values.hasContinuingMembers === 'yes' && (
				<section>
					<Spacer size={15}/>
					<MemberList
						title="Continuing Members"
						members={members.filter(member =>
							form.values.event!.continuingMembers.includes(member.uid),
						)}
					/>
				</section>
			)}

			{withdrawingMembers.length > 0 && (
				<section>
					<Spacer size={15}/>
					<MemberList
						title="Withdrawing Members"
						members={withdrawingMembers}/>
				</section>
			)}
			<section>
				<Spacer size={15}/>
				<MemberList
					title="Deceased Members"
					members={members.filter(member =>
						form.values.event!.deceasedMembers.includes(member.uid),
					)}
				/>
			</section>
		</>
	);
};
