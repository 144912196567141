import React, { useCallback, useEffect, useState } from 'react';
import * as yup from 'yup';
import {
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormHelperText,
  Checkbox,
  FormGroup,
  FormControlLabel,
  ListSubheader,
} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { makeStyles } from '@material-ui/core/styles';
import DateFnsUtils from '@date-io/date-fns';
import { useFormik } from 'formik';
import moment from 'moment';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

import MembershipAccount from '../../../models/membershipAccount';
import MemberProgram, { MemberProgramStatus } from '../../../models/memberProgram';
import apiClient, { ProgramUpdatePayload } from '../../../lib/api';
import { showToastMessage } from '../../../store/slices/toastMessage';
import SalesforceContact from '../../../models/contact';
import Dialog from '../../Dialog';
import { isDateWithRange, MAX_CCM_PROGRAM_DATE, MIN_CCM_PROGRAM_DATE } from '../../../lib/util';
import ButtonProgressIndicator from '../../ButtonProgressIndicator';
import Member, { Role } from '../../../models/member';
import Person from '../../../models/person';
import { memberRoleFromContactRole } from '../../../lib/role';

const getDefaultMemberProgramUId = (memberPrograms: MemberProgram[]) => {
  if (!memberPrograms.length) {
    return '';
  }

  const activeMemberProgram = memberPrograms.find(mp => mp.status === MemberProgramStatus.Active);
  if (activeMemberProgram) {
    return activeMemberProgram.uid;
  }

  return memberPrograms[0].uid;
};

const useStyles = makeStyles(theme => ({
  dialogContainer: {
    flexGrow: 1,
    display: 'flex',
  },
  adminForm: {
    width: 475,
  },
  inputErrorText: {
    '& .MuiFormHelperText-root': {
      marginLeft: '0',
    },
  },
  muiAlert: {
    textAlign: 'center',
    '& > .MuiAlert-icon': {
      alignItems: 'center',
    },
  },
}));

const administrativeAddOnSchema = yup.object({
  retired: yup.boolean(),
  memberId: yup.string().trim().required('Member is required'),
  date: yup
    .string()
    .nullable()
    .when('retired', {
      is: false,
      then: yup.string().trim().required('Date is required'),
    }),
  memberProgramUid: yup
    .string()
    .trim()
    .when('retired', {
      is: false,
      then: yup.string().trim().required('Member Program is required'),
    }),
});

interface AdministrativeAddOnProps {
  membershipAccount: MembershipAccount;
  open: boolean;
  onDialogClose: Function;
}

interface AdministrativeAddOnModalProps {
  hasData: boolean;
  isFetching: boolean;
  isFetched: boolean;
  membershipAccountUid: string;
  memberPrograms: MemberProgram[];
  sfContacts: SalesforceContact[];
  existingMembers?: Member[];
  open: boolean;
  onClose: () => void;
}

interface AdministrativeAddOnForm {
  retired: boolean;
  memberId: string;
  date: string | null;
  memberProgramUid: string;
}

const AdministrativeAddOnModal: React.FC<AdministrativeAddOnModalProps> = ({
  isFetching,
  hasData,
  membershipAccountUid,
  memberPrograms,
  sfContacts,
  existingMembers,
  onClose,
  open,
}) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const styles = useStyles();
  const hoh =
    sfContacts?.find(c => memberRoleFromContactRole(c.Designation_Type__c) === Role.AccountOwner)
      ?.Id || existingMembers?.find(em => em.role === Role.AccountOwner)?.uid;
  const adminAddonForm = useFormik<AdministrativeAddOnForm>({
    initialValues: {
      retired: false,
      memberId: '',
      date: null,
      memberProgramUid: getDefaultMemberProgramUId(memberPrograms),
    },
    validationSchema: administrativeAddOnSchema,
    onSubmit: async values => {
      const member = existingMembers?.find(c => c.uid === values.memberId);

      const programMember = member
        ? [
            {
              id: member.uid,
              enrollment: {
                startDate: values.date,
                endDate: null,
                originalStartDate: null,
                originalEndDate: null,
              },
              name: `${(member.person as Person).firstName} ${(member.person as Person).lastName}`,
              dependentNumber: member.dependentNumber,
              role: member.role,
              canRemove: true,
              retired: false,
            },
          ]
        : [];
      const program = memberPrograms.find(mp => mp.uid === values.memberProgramUid);
      const updateProgramPayload: ProgramUpdatePayload | undefined =
        program && member
          ? {
              uid: values.memberProgramUid,
              programLevel: program.programLevel,
              programOptions: program.programOptions,
              members: programMember,
              automate: true,
            }
          : undefined;

      importContactAddOn.mutate(
        {
          memberProgramUid: values.memberProgramUid,
          memberId: sfContacts.find(d => d.Id === values.memberId)?.Id || '',
          date: moment(values.date!).startOf('day').format('YYYY-MM-DD'),
          updateProgram: updateProgramPayload,
          retired: values.retired,
        },
        {
          onSuccess: () => {
            clear();
            // get newest data from the membership account
            queryClient.invalidateQueries(['membershipAccount', membershipAccountUid]);
            dispatch(
              showToastMessage({
                message: 'Member added successfully',
                type: 'success',
              }),
            );
          },
          onError: (error: any) => {
            clear();
            dispatch(
              showToastMessage({
                message: `Member could not be created. ${error.message}`,
                type: 'error',
              }),
            );
          },
        },
      );
    },
  });

  const importContactAddOn = useMutation(
    ({
      memberProgramUid,
      memberId,
      date,
      updateProgram,
      retired,
    }: {
      memberProgramUid: string;
      memberId: string;
      date: string;
      updateProgram: ProgramUpdatePayload | undefined;
      retired: boolean;
    }) =>
      updateProgram
        ? apiClient.membershipPrograms.updateMemberProgram(updateProgram) // this is an existing member in MAUI
        : apiClient.membershipAccounts.importContactAddOn(
            membershipAccountUid,
            memberProgramUid,
            memberId,
            date,
            retired,
          ), // this is a member from salesforce
  );

  const handleMemberChange = (value: string) => {
    adminAddonForm.setFieldValue('memberId', value);
    if (value !== hoh) {
      adminAddonForm.setFieldValue('retired', false);
    }
  };

  const handleDateChange = (value: Date | null) => {
    if (!value) {
      adminAddonForm.setFieldValue('date', null);
    } else {
      adminAddonForm.setFieldValue('date', value);
    }
  };

  const handleRetiredChange = () => {
    if (!adminAddonForm.values.retired) {
      adminAddonForm.setFieldValue('date', null);
      adminAddonForm.setFieldValue('memberProgramUid', '');
    }
    adminAddonForm.setFieldValue('retired', !adminAddonForm.values.retired);
  };

  // User can only select the current date or 60 days in the past to the current date
  const getEarliestAdminAddonStartDate = useCallback(() => {
    const memberProgramSelected = memberPrograms.find(
      mp => mp.uid === adminAddonForm.values.memberProgramUid,
    );

    return !memberProgramSelected ? MIN_CCM_PROGRAM_DATE : memberProgramSelected.started;
  }, [adminAddonForm.values.memberProgramUid, memberPrograms]);

  const clear = () => {
    adminAddonForm.values.memberProgramUid = getDefaultMemberProgramUId(memberPrograms);
    adminAddonForm.values.date = null;
    adminAddonForm.values.memberId = '';
    adminAddonForm.values.retired = false;
    onClose();
  };

  const renderButtons = () => {
    const addButton = (
      <Button
        color="primary"
        variant="contained"
        size="large"
        onClick={adminAddonForm.submitForm}
        key="add"
        fullWidth
        disabled={
          !adminAddonForm.values.retired &&
          (importContactAddOn.isLoading ||
            isFetching ||
            !adminAddonForm.values.memberId ||
            !isDateWithRange(
              adminAddonForm.values.date,
              getEarliestAdminAddonStartDate(),
              MAX_CCM_PROGRAM_DATE,
            ) ||
            (!hasData && !existingMembers?.length))
        }
      >
        {importContactAddOn.isLoading ? <ButtonProgressIndicator /> : 'Add'}
      </Button>
    );
    const cancelButton = (
      <Button
        color="default"
        variant="contained"
        size="large"
        key="cancel"
        onClick={() => clear()}
        fullWidth
        disabled={importContactAddOn.isLoading}
      >
        Cancel
      </Button>
    );
    return [addButton, cancelButton];
  };
  return (
    <Dialog title="Administrative Add-On" open={open} buttons={renderButtons()}>
      <div className={styles.dialogContainer}>
        <form className={styles.adminForm}>
          {isFetching && (
            <MuiAlert className={styles.muiAlert} variant={'filled'} severity={'info'}>
              Retrieving members...
            </MuiAlert>
          )}
          {!isFetching && !hasData && !existingMembers?.length ? (
            <MuiAlert className={styles.muiAlert} variant={'filled'} severity={'warning'}>
              There are no members that can be added to this account.
            </MuiAlert>
          ) : (
            !isFetching &&
            (hasData || existingMembers!.length) && (
              <>
                <FormControl
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  className={styles.inputErrorText}
                >
                  <InputLabel id="memberId-label">Member</InputLabel>
                  <Select
                    id="memberId"
                    name="memberId"
                    label="Member"
                    labelId="memberId-label"
                    disabled={isFetching || (!hasData && !existingMembers?.length)}
                    value={adminAddonForm.values.memberId}
                    onChange={e => handleMemberChange((e.target.value as string) || '')}
                    error={
                      adminAddonForm.touched.memberId && Boolean(adminAddonForm.errors.memberId)
                    }
                  >
                    {hasData && existingMembers?.length && (
                      <ListSubheader>Salesforce Contacts</ListSubheader>
                    )}
                    {sfContacts?.map(s => (
                      <MenuItem value={s.Id} key={s.Id}>
                        {s.Name}
                      </MenuItem>
                    ))}

                    {hasData && existingMembers?.length && (
                      <ListSubheader>Existing Members</ListSubheader>
                    )}
                    {existingMembers?.map(m => (
                      <MenuItem value={m.uid} key={m.uid}>
                        {`${(m.person as Person).firstName} ${(m.person as Person).lastName}`}
                      </MenuItem>
                    ))}
                  </Select>
                  {adminAddonForm.touched.memberId && Boolean(adminAddonForm.errors.memberId) && (
                    <FormHelperText>{adminAddonForm.errors.memberId}</FormHelperText>
                  )}
                </FormControl>
                {hoh && (
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          value={adminAddonForm.values.retired}
                          checked={adminAddonForm.values.retired}
                          disabled={
                            isFetching ||
                            (!hasData && !existingMembers?.length) ||
                            adminAddonForm.values?.memberId !== hoh ||
                            (adminAddonForm.values?.memberId === hoh &&
                              Boolean(
                                !sfContacts?.find(c => c.Id === adminAddonForm.values?.memberId),
                              ))
                          }
                          onChange={handleRetiredChange}
                          color="primary"
                        />
                      }
                      label="Retired"
                    />
                  </FormGroup>
                )}
                <FormControl variant="outlined" fullWidth margin="normal">
                  <InputLabel id="memberId-label">Program</InputLabel>
                  <Select
                    disabled={
                      isFetching ||
                      (!hasData && !existingMembers?.length) ||
                      adminAddonForm.values.retired
                    }
                    id="memberProgramUid"
                    name="memberProgramUid"
                    label="Program"
                    labelId="memberProgramUid-label"
                    value={adminAddonForm.values.memberProgramUid}
                    onChange={adminAddonForm.handleChange}
                  >
                    {memberPrograms.length ? (
                      memberPrograms.map(s => (
                        <MenuItem value={s.uid} key={s.uid}>
                          {s.programLevel.name}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem value="">No Programs Available</MenuItem>
                    )}
                  </Select>
                </FormControl>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <>
                    <FormControl>
                      <KeyboardDatePicker
                        disabled={
                          isFetching ||
                          (!hasData && !existingMembers?.length) ||
                          adminAddonForm.values.retired
                        }
                        id="date"
                        name="date"
                        label="Effective Date"
                        autoOk
                        disableToolbar
                        variant="inline"
                        format="MM/dd/yyyy"
                        margin="none"
                        value={adminAddonForm.values.date}
                        minDate={moment(getEarliestAdminAddonStartDate())}
                        maxDate={moment(MAX_CCM_PROGRAM_DATE)}
                        minDateMessage={`The start date cannot be before ${moment(
                          getEarliestAdminAddonStartDate(),
                        ).format('LL')}`}
                        maxDateMessage={`The start date cannot be after ${moment(
                          MAX_CCM_PROGRAM_DATE,
                        ).format('LL')}`}
                        onChange={val => {
                          handleDateChange(val);
                        }}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                        error={adminAddonForm.touched.date && Boolean(adminAddonForm.errors.date)}
                      />
                    </FormControl>
                    {adminAddonForm.touched.date && Boolean(adminAddonForm.errors.date) && (
                      <FormHelperText>{adminAddonForm.errors.date}</FormHelperText>
                    )}
                  </>
                </MuiPickersUtilsProvider>
              </>
            )
          )}
        </form>
      </div>
    </Dialog>
  );
};

const AdministrativeAddOn: React.FC<AdministrativeAddOnProps> = ({
  membershipAccount,
  open,
  onDialogClose,
}) => {
  const dispatch = useDispatch();
  const [addonModalOpen, setAddonModalOpen] = useState(false);
  const [hasData, setHasData] = useState(false);
  const { data, isFetching, isFetched, refetch } = useQuery(
    ['admin-addon', membershipAccount.id],
    () =>
      apiClient.membershipAccounts.getSalesforceAccountContacts(membershipAccount.uid, {
        filterImported: true,
      }),
    { refetchOnWindowFocus: false, enabled: false },
  );

  const existingMembers = membershipAccount.members?.filter(
    m =>
      !membershipAccount.programEnrollments?.some(
        pe =>
          pe.member === m.uid && ((pe.ended && moment(pe.ended).isAfter(moment())) || !pe.ended),
      ),
  );

  const onClose = () => {
    onDialogClose();
    setAddonModalOpen(false);
  };

  // check if there are any sfContacts
  useEffect(() => {
    setHasData(((data && !data.length) || !data) && !isFetching && isFetched ? false : true);
  }, [addonModalOpen, data, dispatch, isFetched, isFetching]);

  useEffect(() => {
    setAddonModalOpen(open);
    if (open) {
      refetch();
    }
  }, [open, refetch]);

  return (
    <AdministrativeAddOnModal
      isFetching={isFetching}
      isFetched={isFetched}
      hasData={hasData}
      open={addonModalOpen}
      membershipAccountUid={membershipAccount.uid}
      sfContacts={data}
      existingMembers={existingMembers}
      memberPrograms={membershipAccount.getFilteredStatusMemberPrograms(true, [
        MemberProgramStatus.Active,
        MemberProgramStatus.Pending,
      ])}
      onClose={onClose}
    />
  );
};

export default AdministrativeAddOn;
