import { Dispatch } from 'react';
import { QueryClient, useMutation, useQuery } from 'react-query';
import { downloadObjectUrl } from '../../../lib/util';
import { showToastMessage } from '../../../store/slices/toastMessage';
import { MedicalBillReimbursementApiPort } from '../api/medical-bill-reimbursements.api.port';
import { MedicalBillReimbursementSalesforceCaseStatus } from '../medical-bill-reimbursement.constants';
import { MedicalBillReimbursementSalesforceCaseGetResponseDto } from '../types/medical-bill-reimbursement-salesforce-case-get.response.dto';
import { MedicalBillReimbursementUpdateRequestDto } from '../types/medical-bill-reimbursement-update.request.dto';
import { MedicalBillReimbursementWithDetailsResponseDto } from '../types/medical-bill-reimbursement-with-details.response.dto';

/**
 * This hook factory is used to create the hooks that are used in the reimbursement request views
 *
 * This is done to make it easier to test the views, as we can pass in a mock api adapter
 *
 * @param medicalBillReimbursementApi the api adapter implementation to use
 * @returns the hooks
 */
export const hookFactory = (
  medicalBillReimbursementApi: MedicalBillReimbursementApiPort,
  queryClient: QueryClient,
  dispatch: Dispatch<any>,
) => {
  return {
    useMedicalBillReimbursementQuery: (variables: { uid: string }) => {
      return useQuery<MedicalBillReimbursementWithDetailsResponseDto, [string, string]>(
        ['reimbursementRequest', variables.uid],
        async () => {
          return await medicalBillReimbursementApi.get(variables.uid);
        },
      );
    },

    useMedicalBillReimbursementListFilesQuery: (variables: { uid: string }) => {
      return useQuery<{ fileName: string }[], [string, string]>(
        ['reimbursementRequestListFiles', variables.uid],
        async () => {
          return await medicalBillReimbursementApi.listFiles(variables.uid);
        },
      );
    },

    useUpdateMedicalBillReimbursementMutation: () => {
      return useMutation(
        async (variables: { uid: string; data: MedicalBillReimbursementUpdateRequestDto }) =>
          await medicalBillReimbursementApi.update(variables.uid, variables.data),
        {
          onSuccess: (data, variables, context: any) => {
            queryClient.invalidateQueries(['reimbursementRequest', variables.uid]);

            dispatch(
              showToastMessage({
                message: 'The Medical Bill Reimbursement has been updated',
                type: 'success',
              }),
            );
          },
          onError: (error: any, variables, context: any) => {
            dispatch(
              showToastMessage({
                message: `The Medical Bill Reimbursement could not be updated${
                  error.message ? `. ${error.message}` : ''
                }`,
                type: 'error',
              }),
            );
          },
        },
      );
    },

    useMedicalBillReimbursementAdminCaseQuery: (variables: { uid: string }) => {
      return useQuery<MedicalBillReimbursementSalesforceCaseGetResponseDto, [string, string]>(
        ['reimbursementRequestGetAdminCase', variables.uid],
        async () => {
          return await medicalBillReimbursementApi.getAdminCase(variables.uid);
        },
      );
    },

    useCloseMedicalBillReimbursementAdminCaseMutation: () => {
      return useMutation(
        async (variables: { uid: string }) =>
          await medicalBillReimbursementApi.closeAdminCase(variables.uid),
        {
          onMutate: async variables => {
            const queryKey = ['reimbursementRequestGetAdminCase', variables.uid];

            await queryClient.cancelQueries(queryKey);

            const previousData = queryClient.getQueryData<
              Partial<MedicalBillReimbursementSalesforceCaseGetResponseDto>
            >(queryKey);

            if (previousData) {
              queryClient.setQueryData<
                Partial<MedicalBillReimbursementSalesforceCaseGetResponseDto>
              >(queryKey, {
                ...previousData,
                Status: MedicalBillReimbursementSalesforceCaseStatus.Closed,
              });
            }

            return { previousData };
          },
          onSuccess: (data, variables, context: any) => {
            queryClient.invalidateQueries(['reimbursementRequestGetAdminCase', variables.uid]);

            dispatch(
              showToastMessage({
                message: 'The Salesforce Admin Case has been closed',
                type: 'success',
              }),
            );
          },
          onError: (error: any, variables, context: any) => {
            if (context?.previousData) {
              queryClient.setQueryData<
                Partial<MedicalBillReimbursementSalesforceCaseGetResponseDto>
              >(['reimbursementRequestGetAdminCase', variables.uid], context.previousData);
            }

            dispatch(
              showToastMessage({
                message: `The Salesforce Admin Case could not be closed${
                  error.message ? `. ${error.message}` : ''
                }`,
                type: 'error',
              }),
            );
          },
        },
      );
    },

    // This mutation will return a string (object-url) that can be used to download the file
    useDownloadFileMutation: () => {
      return useMutation(
        async (variables: { uid: string; fileId: string }) =>
          await medicalBillReimbursementApi.downloadFile(variables.uid, variables.fileId),
        {
          onSuccess: (data: string, variables) => {
            downloadObjectUrl(data, variables.fileId);
          },
        },
      );
    },
  };
};
