import axios, { AxiosInstance } from 'axios';

import {
  createGeneralRequestInterceptor,
  createAuthTokenRefreshInterceptor,
  createGeneralResponseInterceptor,
} from './interceptors';

const BASE_URL = process.env.REACT_APP_API_BASE_URL;
const API_KEY = process.env.REACT_APP_CCM_CORE_API_KEY;

const axiosConfig = {
  baseURL: BASE_URL,
  headers: {
    Accept: 'application/json; charset=utf-8',
    'Content-Type': 'application/json',
    'x-ccm-app': 'membership-admin',
    'x-api-key': API_KEY,
  },
};

let _store: any;

class APIClient {
  private static _instance: AxiosInstance;
  private _store: any;

  static getInstance(): AxiosInstance {
    if (APIClient._instance === undefined) {
      APIClient._instance = axios.create(axiosConfig);

      applyInterceptors(APIClient._instance);
    }
    return APIClient._instance;
  }
}

const applyInterceptors = (axiosClient: AxiosInstance) => {
  // Request Interceptors
  createGeneralRequestInterceptor(axiosClient);

  // Response Interceptors
  createAuthTokenRefreshInterceptor(axiosClient, axiosConfig); // this must be applied first
  createGeneralResponseInterceptor(axiosClient);
};

export const setStore = (store: any) => {
  _store = store;
};

export const getStore = (): any => {
  return _store;
};

export const cancelRequest = (signal?: any) => {
  // Create a new CancelToken source for this request
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  // Cancel the request if signal to abort
  signal?.addEventListener('abort', () => {
    source.cancel('Query was cancelled');
  });

  return source.token;
};

export default APIClient.getInstance();
