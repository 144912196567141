import React, { useState } from 'react';
import moment from 'moment';
import { useQuery } from 'react-query';
import { Box, Collapse, TableCell, TableRow, Paper, Typography, Tooltip } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { makeStyles } from '@material-ui/core/styles';

import StatusBadge from '../../../StatusBadge';
import apiClient from '../../../../lib/api';

const useStyles = makeStyles({
  row: {
    cursor: 'pointer',
    '& > *': {
      borderBottom: 'unset',
    },
  },
  childRow: {
    cursor: 'pointer',
  },
  emptyRow: {
    cursor: 'default',
  },
  emptyRowCell: {
    textAlign: 'center',
  },
});

const renderActor = (actor?: Record<string, unknown> | string) => {
  if (!actor) {
    return '';
  }

  if (typeof actor === 'string') {
    return actor;
  } else if (typeof actor === 'object') {
    let actorVal = '';
    if (actor.firstName) {
      actorVal = actor.firstName as string;
    }
    if (actor.lastName) {
      actorVal += (actorVal.length > 0 ? ' ' : '') + actor.lastName;
    }
    if (actor.email) {
      actorVal += (actorVal.length > 0 ? ' ' : '') + `(${actor.email})`;
    }

    return (
      <Tooltip title={actorVal}>
        <Typography variant="body2" component="div" style={{ maxWidth: '250px' }} noWrap>
          {actorVal}
        </Typography>
      </Tooltip>
    );
  }
};

const renderDescription = (description: string) => {
  const MAX_DESCRIPTION_LENGTH = 48;
  if (!description) return '';
  if (description.length > MAX_DESCRIPTION_LENGTH) {
    return (
      <Tooltip title={description}>
        <Typography variant="body2" component="div" style={{ maxWidth: '25vw' }} noWrap>
          {description}
        </Typography>
      </Tooltip>
    );
  }
  return description;
};

export default function AuditEventRow(props: any) {
  const { auditEvent } = props;
  const [open, setOpen] = useState(false);
  const styles = useStyles();
  const [eventUId, setEventUId] = useState();

  const metadataFetch = useQuery(['auditEventMetadata', eventUId], () =>
    eventUId !== undefined ? apiClient.auditEvents.getMetadata(eventUId!) : null,
  );
  const metadata: any | undefined = metadataFetch.data;

  return (
    <React.Fragment>
      <TableRow className={styles.row} hover>
        <TableCell>
          <IconButton
            size="small"
            onClick={e => {
              e.stopPropagation();
              setOpen(!open);
              setEventUId(auditEvent.uid);
            }}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>{moment(auditEvent.createdAt).format('YYYY-MM-DD HH:mm:ss')}</TableCell>
        <TableCell>{auditEvent.action}</TableCell>
        <TableCell>{renderActor(auditEvent.actor)}</TableCell>
        <TableCell>{renderDescription(auditEvent.description)}</TableCell>
        <TableCell>
          <StatusBadge
            variant={
              auditEvent.result === 'success'
                ? 'success'
                : auditEvent.result === 'failed'
                ? 'error'
                : 'default'
            }
            label={auditEvent.result}
          />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Paper elevation={3}>
              <Box margin={1} style={{ padding: 20 }}>
                {metadata && <pre>{JSON.stringify(metadata, null, 2)}</pre>}
              </Box>
            </Paper>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}
