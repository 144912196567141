import React, { ReactElement, useEffect, useState } from 'react';
import {
  Button,
  Divider,
  ListSubheader,
  List,
  ListItem,
  ListItemText,
  DialogContentText,
  CircularProgress,
  Box,
  Grid,
  Card,
  CardContent,
  Typography,
} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';
import { useQuery, useQueryClient, useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import moment from 'moment';

import { showToastMessage } from '../../../store/slices/toastMessage';

import apiClient from '../../../lib/api';
import SalesforceContact from '../../../models/contact';
import Dialog from '../../Dialog';
import StatusBadge from '../../StatusBadge';
import Person from '../../../models/person';
import ConfirmationDialog from '../../ConfirmationDialog';
import Member from '../../../models/member';
import { memberRoleFromContactRole } from '../../../lib/role';
import Alert from '@material-ui/lab/Alert';

const useStyles = makeStyles(theme => ({
  inputErrorText: {
    '& .MuiFormHelperText-root': {
      marginLeft: '0',
    },
  },
  muiAlert: {
    textAlign: 'center',
    '& > .MuiAlert-icon': {
      alignItems: 'center',
    },
  },
  loader: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  }
}));

interface SalesforceContactLinkProps {
  membershipAccountUid: string;
  member: Member | null;
  open: boolean;
  onClose: () => void;
}

interface SalesforceContactLinkDialogProps {
  hasData: boolean;
  isFetching: boolean;
  isFetched: boolean;
  membershipAccountUid: string;
  sfContactId?: string | null;
  sfContactUId?: string | null;
  sfContacts: SalesforceContact[];
  open: boolean;
  onCancel: () => void;
  onSelect?: (contact: SalesforceContact) => void;
}
const sortSalesforceContacts = (a: SalesforceContact, b: SalesforceContact) => {
  const designationSortMap = ['Head of Household', 'Spouse', 'Child']
  if (designationSortMap.indexOf(a.Designation_Type__c) < designationSortMap.indexOf(b.Designation_Type__c)) {
    return -1;
  }
  if (designationSortMap.indexOf(a.Designation_Type__c) > designationSortMap.indexOf(b.Designation_Type__c)) {
    return 1;
  }
  return 0;
};

const SalesforceContactLinkDialog: React.FC<SalesforceContactLinkDialogProps> = ({
  isFetching,
  hasData,
  sfContacts,
  sfContactId,
  sfContactUId,
  onCancel,
  onSelect,
  open,
}) => {
  const styles = useStyles();

  const renderButtons = () => {
    const cancelButton = (
      <Button
        color="default"
        variant="contained"
        size="large"
        key="cancel"
        fullWidth
        onClick={onCancel}
      >
        Cancel
      </Button>
    );
    return [cancelButton];
  };
  return (
    <Dialog title="Link Salesforce Contact to Member" open={open} buttons={renderButtons()} maxWidth="sm">
      <DialogContentText id="alert-dialog-description">
        Please select which Salesforce contact should be linked to this member.
      </DialogContentText>
      <div style={{ position: "relative", minHeight: '185px' }}>
        {!isFetching && !hasData ? (
          <MuiAlert className={styles.muiAlert} variant={'filled'} severity={'warning'}>
            No contacts found for this account
          </MuiAlert>
        ) : (
          hasData && (
            <>
              <List disablePadding subheader={<ListSubheader>Salesforce Contacts by Designation</ListSubheader>}>
              <Divider />
              {sfContacts?.sort(sortSalesforceContacts).map(s => (
                <ListItem
                  button
                  disabled={isFetching}
                  selected={sfContactId === s.Id || sfContactUId === s.ContactUID__c}
                  key={s.Id}
                  onClick={() => onSelect!(s)}
                  >
                  <ListItemText primary={s.Name} secondary={`DOB: ${moment(s.Birthdate).format('MMMM D, YYYY')} | UID: ${s.ContactUID__c}`} />
                  <StatusBadge
                    label={s.Designation_Type__c}
                    variant={'outlined'}
                    color={'primary'}
                  />
                </ListItem>
              ))}
              </List>
            </>
          )
        )}
        {isFetching && (
          <Box className={styles.loader} display="flex" paddingY={10} flexDirection="column" justifyContent="center" alignItems="center" height="100%">
            <CircularProgress />
            Please wait
          </Box>
        )}
      </div>
    </Dialog>
  );
};

const SalesforceContactLink: React.FC<SalesforceContactLinkProps> = ({
  membershipAccountUid,
  member,
  open,
  onClose,
}) => {
  const dispatch = useDispatch();
  const [hasData, setHasData] = useState(false);
  const [selectedContact, setSelectedContact] = useState<SalesforceContact | null>(null);

  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState<string | ReactElement>('');
  const [isProcessing, setIsProcessing] = useState(false);


  const queryClient = useQueryClient();

  const { data, isFetching, isFetched, refetch } = useQuery(
    ['account-contacts', membershipAccountUid],
    () =>
      apiClient.membershipAccounts.getSalesforceAccountContacts(membershipAccountUid, {}),
    { refetchOnWindowFocus: false, enabled: false },
  );

  const linkSalesforceContact = useMutation(({memberUid, sfContactId}: {memberUid: string, sfContactId: string}) =>
    apiClient.members.linkSalesforceContact(memberUid, sfContactId),
  );

  const handleSelect = (contact?: SalesforceContact) => {
    if (contact) {
      setSelectedContact(contact);

      const person = member?.person as Person;
      const personName = `${person.firstName} ${person.lastName}`;
      const contactDOB = moment(contact.Birthdate).format('MM/DD/YYYY');
      const personDOB = moment(person.dob).format('MM/DD/YYYY');
      const contactNameMatches = personName === contact.Name;
      const contactDOBMatches = contactDOB === personDOB
      const contactUIdMatches = contact.ContactUID__c === person.sfContactUId;
      const contactDesignation = memberRoleFromContactRole(contact.Designation_Type__c);
      const contactDesignationMatches = contactDesignation === member?.role;
      const contactGenderMatches = contact.Gender__c?.toLowerCase() === person.gender;

      if (!contactNameMatches || !contactDOBMatches || !contactUIdMatches) {
        setConfirmationMessage(
            <>
              <Typography paragraph>Are you sure you want link this contact to the member?</Typography>
              <Grid
                container
                justify="space-between"
                spacing={2}
              >
                <Grid item xs>
                  <Card variant="outlined">
                    <CardContent>
                      <Typography variant="overline">Before</Typography>
                      <Divider style={{ marginBottom: '1rem' }}/>
                      {!contactUIdMatches && (
                        <>
                          <Typography variant="h6">Contact UId</Typography>
                          <Typography variant="body1" paragraph>{person.sfContactUId || '-'}</Typography>
                        </>
                      )}
                      {!contactNameMatches && (
                        <>
                          <Typography variant="h6">Name</Typography>
                          <Typography variant="body1" paragraph>{personName || '-'}</Typography>
                        </>
                      )}
                      {!contactDOBMatches && (
                        <>
                          <Typography variant="h6">Date of Birth</Typography>
                          <Typography variant="body1" paragraph>{personDOB || '-'}</Typography>
                        </>
                      )}
                      {!contactDesignationMatches && (
                        <>
                          <Typography variant="h6">Designation</Typography>
                          <Typography variant="body1" paragraph>{member?.role || '-'}</Typography>
                        </>
                      )}
                      {!contactGenderMatches && (
                        <>
                          <Typography variant="h6">Gender</Typography>
                          <Typography variant="body1">{person.gender || '-'}</Typography>
                        </>
                      )}
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs>
                  <Card variant="outlined">
                    <CardContent>
                      <Typography variant="overline">After</Typography>
                      <Divider style={{ marginBottom: '1rem' }}/>
                      {!contactUIdMatches && (
                        <>
                          <Typography variant="h6">Contact UId</Typography>
                          <Typography variant="body1" paragraph>{contact.ContactUID__c || '-'}</Typography>
                        </>
                      )}
                      {!contactNameMatches && (
                        <>
                          <Typography variant="h6">Name</Typography>
                          <Typography variant="body1" paragraph>{contact.Name || '-'}</Typography>
                        </>
                      )}
                      {!contactDOBMatches && (
                        <>
                          <Typography variant="h6">Date of Birth</Typography>
                          <Typography variant="body1" paragraph>{contactDOB || '-'}</Typography>
                        </>
                      )}
                      {!contactDesignationMatches && (
                        <>
                          <Typography variant="h6">Designation</Typography>
                          <Typography variant="body1" paragraph>{contactDesignation || '-'}</Typography>
                        </>
                      )}
                      {!contactGenderMatches && (
                        <>
                          <Typography variant="h6">Gender</Typography>
                          <Typography variant="body1">{contact.Gender__c?.toLowerCase() || '-'}</Typography>
                        </>
                      )}
                    </CardContent>
                  </Card>
                </Grid>
                {!contactNameMatches || 
                !contactDOBMatches || 
                !contactUIdMatches || 
                !contactDesignationMatches || 
                !contactGenderMatches ? (
                  <Grid item xs={12}>
                    <Card>
                    <Alert severity="warning" variant="outlined">
                    <Typography variant="body2" paragraph={!contactDesignationMatches}>Member data in Eldorado should match the data in Member Admin before linking to avoid member duplication.</Typography>
                      {!contactDesignationMatches && (
                      <Typography variant="body2" style={{fontWeight: 'bold'}}>A change in designation requires verification in Eldorado.
                      </Typography>
                      )}
                    </Alert> 
                    </Card>
                  </Grid>
                ) : null}
                
              </Grid>
            </>
          );

        setConfirmationDialogOpen(true);
      } else {
        onSalesforceContactLink(contact);
      }
    }
  };

  const onSalesforceContactLink = (contact: SalesforceContact) => {
    setConfirmationDialogOpen(false);
    setIsProcessing(true);

    linkSalesforceContact.mutate({
      memberUid: member?.uid!,
      sfContactId: contact.Id
    }, {
      onSuccess: async () => {
        await queryClient.invalidateQueries(['membershipAccount', membershipAccountUid]);
        setIsProcessing(false);
        if (onClose) onClose();
        dispatch(
          showToastMessage({
            message: 'Salesforce contact was linked successfully',
            type: 'success',
          }),
        );
      },
      onError: (error: any) => {
        setIsProcessing(false);
        if (onClose) onClose();
        dispatch(
          showToastMessage({
            message: error.message,
            type: 'error',
          }),
        );
      },
    });
  };

  const onCancel = () => {
    setConfirmationDialogOpen(false);
    if (onClose) onClose();
  };

  useEffect(() => {
    setHasData(((data && !data.length) || !data) && !isFetching && isFetched ? false : true);
  }, [open, data, dispatch, isFetched, isFetching]);

  useEffect(() => {
    if (open) {
      refetch();
    }
  }, [open, refetch]);

  return (
    <>
      <SalesforceContactLinkDialog
        isFetching={isFetching || isProcessing}
        isFetched={isFetched}
        hasData={hasData}
        open={open}
        membershipAccountUid={membershipAccountUid}
        sfContactId={(member?.person as Person).sfContactId}
        sfContactUId={(member?.person as Person).sfContactUId}
        sfContacts={data}
        onCancel={onCancel}
        onSelect={handleSelect}
      />
      <ConfirmationDialog
        open={confirmationDialogOpen}
        title="Confirm Contact Link"
        message={confirmationMessage}
        onConfirm={() => onSalesforceContactLink(selectedContact!)}
        onCancel={onCancel}
        confirmText="Confirm"
      />
    </>
  );
};

export default SalesforceContactLink;
