import React, { useEffect, useState } from 'react';
import {
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@material-ui/core';
import { capitalize } from 'lodash';

import PageRow from './PageRow';
import { PaginationColumnProps } from '.';

const useStyles = makeStyles({
  row: {
    cursor: 'pointer',
    '& > *': {
      borderBottom: 'unset',
    },
  },
  emptyRow: {
    cursor: 'default',
  },
  emptyRowCell: {
    textAlign: 'center',
  },
});

const renderPaginationLabel = (
  from: number,
  to: number,
  pageSize: number,
  rowCount: number,
): string => {
  if (rowCount > pageSize) {
    return `${from} - ${to}`;
  } else {
    return `${from} - ${from + rowCount - 1}`;
  }
};

const renderCount = (rowsReturned: number, rowsPerPage: number, pageIndex: number): number => {
  return rowsReturned > rowsPerPage ? -1 : rowsPerPage * pageIndex + rowsReturned;
};

interface PageTableProps {
  columns: PaginationColumnProps[];
  data: any;
  showDetailRow?: boolean;
  formatDetailRowContent?: (data?: any) => any;
  onPage: any;
  currentPage: number;
  pageSize: number;
  noDataMessage?: string;
  onRowClick?: Function;
}

const PageTable: React.FC<PageTableProps> = ({
  currentPage,
  onPage,
  columns,
  data,
  pageSize,
  showDetailRow = true,
  formatDetailRowContent,
  noDataMessage,
  onRowClick
}) => {
  const [pageIndex, setPageIndex] = useState(currentPage - 1);
  const styles = useStyles();

  const handlePageChange = (e: any, pageIndex: number) => {
    onPage({ page: pageIndex + 1, pageSize });
    setPageIndex(pageIndex);
  };

  useEffect(() => {
    setPageIndex(currentPage - 1);
  }, [currentPage]);

  const renderPaging = () =>
    data &&
    data.length > 0 && (
      <TablePagination
        rowsPerPageOptions={[pageSize]}
        component="div"
        count={renderCount(data.length, pageSize, pageIndex)}
        labelDisplayedRows={({ from, to }) =>
          renderPaginationLabel(from, to, pageSize, data.length)
        }
        rowsPerPage={pageSize}
        page={pageIndex}
        onChangePage={handlePageChange}
      />
    );

  return (
    <div>
      {renderPaging()}
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              {showDetailRow && <TableCell width={60} />}
              {columns.map((column: PaginationColumnProps, index: number) => (
                <TableCell key={index} width={column.width || ''}>
                  {column.as !== undefined ? column.as : capitalize(column.name)}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data && !data.length && (
              <TableRow className={`${styles.row} ${styles.emptyRow}`}>
                <TableCell colSpan={columns.length} className={styles.emptyRowCell}>
                  { noDataMessage || 'No data found'}
                </TableCell>
              </TableRow>
            )}
            {data &&
              data.map(
                (d: any, index: number) =>
                  (index < data.length - 1 || data.length <= pageSize) && (
                    <PageRow
                      key={index}
                      dataRow={d}
                      columns={columns}
                      showDetailRow={showDetailRow}
                      formatDetailRowContent={formatDetailRowContent}
                      onRowClick={onRowClick}
                    />
                  ),
              )}
          </TableBody>
        </Table>
      </TableContainer>
      {renderPaging()}
    </div>
  );
};

export default PageTable;
