export const ADDRESS_TYPE = {
  MAILING: 'mailing',
  BILLING: 'billing',
  HOME: 'home',
};

export const ADDRESS_STATUS = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
};

export interface Address {
  uid?: string;
  addressType?: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  postalCode: string;
  status: string;
  verified?: boolean;
}

export interface AddressAssociationForeignKey {
  membershipAccount?: string;
  person?: string;
  group?: string;
}

export type CreateAddress = Address & AddressAssociationForeignKey;
