import React from 'react';
import { Box, Grid, Typography } from '@material-ui/core';

import EnrolledProgram from '../EnrolledProgram';
import Spacer from '../../../../Spacer';
import MemberProgram from '../../../../../models/memberProgram';
import { ProgramMember } from '.';
import MemberCard from './MemberCard';
import { Alert } from '@material-ui/lab';
import DialogErrors from '../../../../DialogErrors';

interface ConfirmationProps {
  memberProgram: MemberProgram;
  updatedProgram: MemberProgram;
  programMembers: ProgramMember[];
  errors?: string[];
  automate: boolean;
  hasChanges(): boolean;
}

const Confirmation: React.FC<ConfirmationProps> = ({
  memberProgram,
  updatedProgram,
  programMembers,
  errors,
  automate,
  hasChanges,
}) => {
  const programLevelUpdated = () => {
    const programLevelChanges = updatedProgram.programLevel.id !== memberProgram.programLevel.id;
    const originalProgramOptions =
      (memberProgram.programOptions && memberProgram.programOptions.map(option => option.slug)) ||
      [];
    const newProgramOptions =
      (updatedProgram.programOptions && updatedProgram.programOptions.map(option => option.slug)) ||
      [];
    const programOptionChanges =
      !originalProgramOptions.every(option => newProgramOptions.includes(option)) ||
      !newProgramOptions.every(option => originalProgramOptions.includes(option));
    return programLevelChanges || programOptionChanges;
  };

  return (
    <>
      <Typography variant="subtitle2">
        <strong>Confirm changes and click Submit.</strong>
      </Typography>

      <Spacer size={10} />

      {errors && <DialogErrors errors={errors} />}

      {(!errors || errors.length === 0) && (
        <>
          {automate && hasChanges() && (
            <Alert variant="outlined" color="info" severity="info">
              The changes below will be sent to Rejoice and Eldorado automatically. NetSuite must be
              updated manually.
            </Alert>
          )}
          {!automate && hasChanges() && (
            <Alert variant="outlined" color="warning" severity="warning">
              These changes must be performed manually in all other systems including NetSuite,
              Rejoice and Eldorado.
            </Alert>
          )}
          {!hasChanges() && (
            <Alert variant="outlined" color="warning" severity="warning">
              No changes have been made to this program or its enrollments
            </Alert>
          )}
          <Spacer size={10} />
        </>
      )}
      <Grid container spacing={2}>
        <Grid item xs={6}>
          {/* Current program */}
          <Typography variant="h6">Current Program</Typography>
          {memberProgram && <EnrolledProgram memberProgram={memberProgram} showOptions={true} />}
        </Grid>
        <Grid item xs={6}>
          {/* New program */}
          {programLevelUpdated() && (
            <>
              <Typography variant="h6">New Program</Typography>
              <EnrolledProgram memberProgram={updatedProgram} showOptions={true} />
            </>
          )}
        </Grid>
      </Grid>

      <Spacer size={30} />

      <Typography variant="h6">Members</Typography>
      <Box style={{ display: 'flex', maxHeight: '280px', overflow: 'auto' }}>
        <Grid container spacing={1}>
          {programMembers.map(member => (
            <Grid item xs={6} key={member.id}>
              <MemberCard programMember={member} showEnrollmentDates={true} showRetired={true} />
            </Grid>
          ))}
        </Grid>
      </Box>
    </>
  );
};

export default Confirmation;
