import SearchInput from '../../../components/SearchInput';
import { useEffect, useState } from 'react';
import { debounce } from 'lodash';
import Spacer from '../../../components/Spacer';
import {
  Box,
  createStyles,
  FormControl,
  InputLabel,
  LinearProgress,
  makeStyles,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@material-ui/core';
import apiClient from '../api/medical-bill-reimbursements.api.adapter';
import moment from 'moment/moment';
import { useQuery, useQueryClient } from 'react-query';
import useGlobalStyles from '../../../styles/globals';

import {
  defaultQueryParams,
  MedicalBillReimbursementStatus,
  LoadingProgress,
} from '../medical-bill-reimbursement.constants';

import { MedicalBillReimbursementsTablePagination } from './MedicalBillReimbursementsTablePagination';
import { SearchMedicalBillReimbursementsQueryParams } from '../api/medical-bill-reimbursements.api.port';
import { MedicalBillReimbursementWithDetailsResponseDto } from '../types/medical-bill-reimbursement-with-details.response.dto';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(() =>
  createStyles({
    filterControl: {
      minWidth: 100,
    },
    capitalize: {
      textTransform: 'capitalize',
    },
    row: {
      cursor: 'pointer',
      '& > *': {
        borderBottom: 'unset',
      },
    },
    emptyRow: {
      cursor: 'default',
    },
    emptyRowCell: {
      textAlign: 'center',
    },
  }),
);

export default function MedicalBillReimbursementsTable() {
  const styles = useStyles();
  const history = useHistory();
  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const globalStyles = useGlobalStyles();
  const queryClient = useQueryClient();
  const [pagerRange, setPagerRange] = useState({ from: 1, to: defaultQueryParams.pageSize });

  const [queryParams, setQueryParams] = useState<SearchMedicalBillReimbursementsQueryParams>({
    ...defaultQueryParams,
  });

  const handlePageChange = (e: any, pageIndex: number) => {
    setCurrentPageIndex(pageIndex);
    setQueryParams({ ...queryParams, page: pageIndex + 1 });
  };

  const { isLoading, isFetching, data: queryResults, error: queryError } = useQuery(
    ['medicalBillReimbursements', queryParams],
    () => apiClient.query(queryParams),
    { keepPreviousData: true, cacheTime: 0 },
  );

  const medicalBillReimbursements = queryResults;

  useEffect(() => {
    queryClient.invalidateQueries(['medicalBillReimbursements', queryParams]);
  }, [queryClient, queryParams]);

  const [loadingProgress, setLoadingProgress] = useState<LoadingProgress>({
    progress: 0,
    hidden: true,
  });

  useEffect(() => {
    if (!isLoading && !isFetching) {
      setPagerRange({
        from: queryParams.page! * queryParams.pageSize! - queryParams.pageSize! + 1,
        to: queryParams.page! * queryParams.pageSize!,
      });
      setLoadingProgress((p: LoadingProgress) => {
        return { ...p, progress: 100 };
      });
      const timer = setTimeout(() => {
        setLoadingProgress((p: LoadingProgress) => {
          return { hidden: true, progress: 0 };
        });
      }, 500);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [isLoading, isFetching, queryParams, setLoadingProgress]);

  useEffect(() => {
    if (isLoading || isFetching) {
      const timer = setInterval(() => {
        setLoadingProgress((p: LoadingProgress) => {
          if (p.progress === 90) {
            return { ...p, progress: 90 };
          }
          const diff = Math.random() * 10;
          return { hidden: false, progress: Math.min(p.progress + diff, 90) };
        });
      }, 250);

      return () => {
        clearInterval(timer);
      };
    }
  }, [isLoading, isFetching]);

  const handleReimbursementSearch = debounce((val: string) => {
    const referenceNumber = val.trim();
    if (
      referenceNumber !== queryParams.referenceNumber &&
      (referenceNumber.length === 0 || referenceNumber.length >= 3)
    ) {
      setQueryParams({ ...queryParams, page: 1, referenceNumber });
    }
  }, 500);

  const handleFilterChange = (event: any) => {
    const target = event.target;
    const value: string = target.value;
    const name: string = target.name;
    const newQueryParams = { ...queryParams, page: 1 };
    if (name === 'status') {
      if (value === 'any') {
        delete newQueryParams.status;
      } else {
        newQueryParams.status = value;
      }
    }
    setQueryParams(newQueryParams);
  };

  const handleQueryChange = debounce((val: string) => {
    const referenceNumber = val.trim();
    if (referenceNumber.length === 0) {
      setQueryParams({ ...queryParams, page: 1, referenceNumber });
    }
  }, 500);

  const handleClick = (
    medicalBillReimbursement: MedicalBillReimbursementWithDetailsResponseDto,
  ) => {
    history.push(`/medical-bill-reimbursements/${medicalBillReimbursement.uid}`);
  };

  return (
    <>
      <section>
        <Box display="flex" style={{ width: '100%', justifyContent: 'flex-start' }}>
          <Box style={{ width: '80%' }}>
            <SearchInput
              placeholder="Reference Number"
              onSubmit={handleReimbursementSearch}
              onChange={handleQueryChange}
              submitOnEnter={true}
            />
          </Box>
          <Box display="flex" style={{ width: '20%', justifyContent: 'center' }}>
            <FormControl className={styles.filterControl}>
              <InputLabel id="status">Status</InputLabel>
              <Select
                labelId="status"
                id="status"
                name="status"
                value={queryParams.status || 'any'}
                onChange={handleFilterChange}
              >
                <MenuItem value={'any'}>Any / All</MenuItem>
                {Object.entries(MedicalBillReimbursementStatus).map(([key, value]) => (
                  <MenuItem key={value} value={value} className={styles.capitalize}>
                    {key}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Box>
      </section>

      <Spacer size={20} />

      {loadingProgress.hidden && <Spacer size={4} />}
      {!loadingProgress.hidden && (
        <LinearProgress variant="determinate" value={loadingProgress.progress} />
      )}

      <TableContainer component={Paper}>
        <Table className={globalStyles.fixedTable}>
          <TableHead>
            <TableRow>
              <TableCell width={150}>
                <TableSortLabel>Requested</TableSortLabel>
              </TableCell>
              <TableCell width={150}>
                <TableSortLabel>Reference #</TableSortLabel>
              </TableCell>
              <TableCell width={150}>
                <TableSortLabel>Membership Account</TableSortLabel>
              </TableCell>
              <TableCell width={150}>
                <TableSortLabel>Submitted By</TableSortLabel>
              </TableCell>
              <TableCell width={150}>
                <TableSortLabel>Status</TableSortLabel>
              </TableCell>
            </TableRow>
          </TableHead>
          {isLoading && (
            <TableBody>
              <TableRow className={`${styles.row} ${styles.emptyRow}`}>
                <TableCell colSpan={5} className={styles.emptyRowCell}>
                  Retrieving medical bill reimbursements...
                </TableCell>
              </TableRow>
            </TableBody>
          )}
          {!isLoading && (
            <TableBody style={{ opacity: isFetching ? 0.5 : 1 }}>
              {((medicalBillReimbursements && medicalBillReimbursements.length === 0) ||
                queryError) && (
                <TableRow className={`${styles.row} ${styles.emptyRow}`}>
                  <TableCell colSpan={5} className={styles.emptyRowCell}>
                    {queryError
                      ? 'An error occurred while retrieving medical bill reimbursements'
                      : 'No reimbursement requests found'}
                  </TableCell>
                </TableRow>
              )}

              {medicalBillReimbursements &&
                medicalBillReimbursements.map(
                  (
                    medicalBillReimbursement: MedicalBillReimbursementWithDetailsResponseDto,
                    index: number,
                  ) =>
                    (index < medicalBillReimbursements.length - 1 ||
                      medicalBillReimbursements.length <= queryParams.pageSize!) && (
                      <TableRow
                        className={styles.row}
                        hover
                        key={index}
                        onClick={() => handleClick(medicalBillReimbursement)}
                      >
                        <TableCell>
                          {moment(medicalBillReimbursement?.createdAt).format('MMMM DD, YYYY') ||
                            'n/a'}
                        </TableCell>
                        <TableCell>{medicalBillReimbursement?.referenceNumber || 'n/a'}</TableCell>
                        <TableCell>
                          {medicalBillReimbursement?.membershipAccountDetails?.accountName || 'n/a'}
                        </TableCell>
                        <TableCell>{`${
                          medicalBillReimbursement?.submittedByDetails?.firstName || 'n/a'
                        } ${
                          medicalBillReimbursement?.submittedByDetails?.lastName || 'n/a'
                        }`}</TableCell>
                        <TableCell className={styles.capitalize}>
                          {medicalBillReimbursement?.status}
                        </TableCell>
                      </TableRow>
                    ),
                )}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      {!isLoading && medicalBillReimbursements && medicalBillReimbursements.length > 0 && (
        <MedicalBillReimbursementsTablePagination
          currentPageIndex={currentPageIndex}
          queryParams={queryParams}
          pagerRange={pagerRange}
          medicalBillReimbursements={medicalBillReimbursements}
          handlePageChange={handlePageChange}
          isLoading={isLoading}
          isFetching={isFetching}
        />
      )}
    </>
  );
}
