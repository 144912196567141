import { AxiosError, AxiosResponse } from 'axios';
import CustomError, { handleError } from '../customError';

export class APIClientBase {
  /**
   * response
   * Returns the data from the Axios API request
   * @param resp the Axios response object
   * @returns response data
   */
  response = (resp: AxiosResponse) => {
    return (resp && resp.data) || null;
  };

  /**
   * error
   * Returns errors from an Axios API call in a consistent format
   * @param err the Axios error object received
   * @returns an CustomError object
   */
  error = (err: AxiosError | any): CustomError => {
    const errResponse: CustomError = handleError(err);
    return errResponse;
  };

  /**
   * converts an object to a query string
   * @param query an object of key value pairs to convert
   */
  toQueryString = (query?: any): string => {
    if (!query) {
      return '';
    }

    let qs = '';
    for (let prop in query) {
      qs += `${qs.length ? '&' : ''}${prop}=${query[prop]}`;
    }
    return qs;
  };
}
