import { FC } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { Member } from '../../types/print-request';
import PrintRequestDetailsMember from './PrintRequestDetailsMember';
import { hyphenString } from '../../../../lib/util';

interface PrintRequestDetailsManyMembersProps {
  header: string;
  members: Member[];
}

const PrintRequestDetailsManyMembers: FC<PrintRequestDetailsManyMembersProps> = ({
  header,
  members,
}) => {
  return (
    <>
      <Typography variant="h6" data-testid={hyphenString(header)}>
        {header.toUpperCase()}
      </Typography>
      <Grid container spacing={2}>
        {members.map(member => (
          <Grid item xs={3} key={`member-${member.id}`}>
            <PrintRequestDetailsMember name={member.name} dependentNumber={member.id} />
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default PrintRequestDetailsManyMembers;
