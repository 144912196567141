import React, { useEffect, useState } from 'react';
import {
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@material-ui/core';

import { AuditEvent } from '../../../../models/auditEvent';
import AuditEventRow from './AuditEventRow';

const useStyles = makeStyles({
  row: {
    cursor: 'pointer',
    '& > *': {
      borderBottom: 'unset',
    },
  },
  emptyRow: {
    cursor: 'default',
  },
  emptyRowCell: {
    textAlign: 'center',
  },
});

const renderPaginationLabel = (
  from: number,
  to: number,
  pageSize: number,
  rowCount: number,
): string => {
  if (rowCount === 0) {
    return `${from} - ${from}`;
  } else if (rowCount > pageSize) {
    return `${from} - ${to}`;
  } else {
    return `${from} - ${from + rowCount - 1}`;
  }
};

const renderCount = (rowsReturned: number, rowsPerPage: number, pageIndex: number): number => {
  return rowsReturned > rowsPerPage ? -1 : rowsPerPage * pageIndex + rowsReturned;
};

interface AuditEventsTableProps {
  auditEvents?: AuditEvent[];
  onPage: any;
  currentPage: number;
  pageSize?: number;
}

const AuditEventsTable = (props: AuditEventsTableProps) => {
  const { auditEvents, currentPage, onPage } = props;
  const [pageSize] = useState(props.pageSize || 15);
  const [pageIndex, setPageIndex] = useState(currentPage - 1);

  const styles = useStyles();

  const handlePageChange = (e: any, pageIndex: number) => {
    onPage({ page: pageIndex + 1, pageSize });
    setPageIndex(pageIndex);
  };

  useEffect(() => {
    setPageIndex(currentPage - 1);
  }, [currentPage]);

  const renderPaging = () =>
    auditEvents && (
      <TablePagination
        rowsPerPageOptions={[pageSize]}
        component="div"
        count={renderCount(auditEvents.length, pageSize, pageIndex)}
        labelDisplayedRows={({ from, to }) =>
          renderPaginationLabel(from, to, pageSize, auditEvents.length)
        }
        rowsPerPage={pageSize}
        page={pageIndex}
        onChangePage={handlePageChange}
      />
    );

  return (
    <div title="Audit Events">
      {renderPaging()}
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell width={60} />
              <TableCell width={250}>Event Date/Time</TableCell>
              <TableCell width={100}>Action</TableCell>
              <TableCell width={250}>Actor</TableCell>
              <TableCell>Description</TableCell>
              <TableCell width={130}>Result</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {auditEvents && !auditEvents.length && (
              <TableRow className={`${styles.row} ${styles.emptyRow}`}>
                <TableCell colSpan={6} className={styles.emptyRowCell}>
                  No events found
                </TableCell>
              </TableRow>
            )}
            {auditEvents &&
              auditEvents.map(
                (auditEvent: any, index: number) =>
                  (index < auditEvents.length - 1 || auditEvents.length <= pageSize) && (
                    <AuditEventRow key={auditEvent.uid} auditEvent={auditEvent} />
                  ),
              )}
          </TableBody>
        </Table>
      </TableContainer>
      {renderPaging()}
    </div>
  );
};

export default AuditEventsTable;
