import { TablePagination } from '@material-ui/core';
import { SearchMedicalBillReimbursementsQueryParams } from '../api/medical-bill-reimbursements.api.port';
import { MedicalBillReimbursementWithDetailsResponseDto } from '../types/medical-bill-reimbursement-with-details.response.dto';

interface MedicalBillReimbursementTablePaginationProps {
  currentPageIndex: number;
  queryParams: SearchMedicalBillReimbursementsQueryParams;
  pagerRange: any;
  handlePageChange: any;
  isLoading: boolean;
  isFetching: boolean;
  medicalBillReimbursements: MedicalBillReimbursementWithDetailsResponseDto[];
}

export function MedicalBillReimbursementsTablePagination(
  props: MedicalBillReimbursementTablePaginationProps,
) {
  const {
    currentPageIndex,
    queryParams,
    medicalBillReimbursements,
    pagerRange,
    handlePageChange,
    isLoading,
    isFetching,
  } = props;

  const renderPaginationLabel = (
    from: number,
    to: number,
    pageSize: number,
    rowCount: number,
  ): string => {
    if (rowCount > pageSize) {
      return `${from} - ${to}`;
    } else {
      return `${from} - ${from + rowCount - 1}`;
    }
  };

  const renderCount = (rowsReturned: number, rowsPerPage: number, pageIndex: number): number => {
    return rowsReturned > rowsPerPage ? -1 : rowsPerPage * pageIndex + rowsReturned;
  };

  return (
    <TablePagination
      rowsPerPageOptions={[queryParams.pageSize!]}
      component="div"
      count={renderCount(medicalBillReimbursements.length, queryParams.pageSize!, currentPageIndex)}
      labelDisplayedRows={({ from, to }) =>
        renderPaginationLabel(
          pagerRange.from,
          pagerRange.to!,
          queryParams.pageSize!,
          medicalBillReimbursements.length,
        )
      }
      rowsPerPage={queryParams.pageSize!}
      page={currentPageIndex}
      onChangePage={handlePageChange}
      backIconButtonProps={{ disabled: isLoading || isFetching || currentPageIndex === 0 }}
      nextIconButtonProps={{
        disabled:
          isLoading || isFetching || medicalBillReimbursements.length <= queryParams.pageSize!,
      }}
    />
  );
}
