import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  FormHelperText,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import { useMutation } from 'react-query';
import { useFormik } from 'formik';
import { isEmpty } from 'lodash';

import ButtonProgressIndicator from '../ButtonProgressIndicator';
import {
  Address,
  AddressAssociationForeignKey,
  ADDRESS_STATUS,
  CreateAddress,
} from '../../models/address';
import { showToastMessage } from '../../store/slices/toastMessage';
import states from '../../lib/states';
import apiClient from '../../lib/api';
import { AddressLabel } from './AddressLabel';

const useStyles = makeStyles(() => ({
  inputErrorText: {
    '& .MuiFormHelperText-root': {
      marginLeft: '0',
    },
  },
  addressValidationTitle: {
    fontSize: '1rem',
    marginBottom: 0,
  },
  addressValidationContent: {
    padding: '2rem 2.4rem',
  },
  addressValidationLabel: {
    lineHeight: '15px',
  },
  addressValidationText: {
    lineHeight: '15px',
    textAlign: 'center',
  },
  addressVerifiedValidationText: {
    lineHeight: '15px',
    textAlign: 'center',
    fontWeight: 600,
  },
}));

interface AddressFormProps {
  address: Address | null | undefined;
  addressAssociationForeignKey: AddressAssociationForeignKey;
  onCancel?: any;
  onAddressSaved?: any;
}

interface AddressValidationProps {
  currentAddress: any;
  onClose: any;
  onProceed: any;
}

const addressFormSchema = yup.object({
  address1: yup
    .string()
    .trim()
    .max(200, 'Address Line 1 cannot be longer than 200 characters.')
    .required('Address Line 1 is required'),
  address2: yup.string().trim().max(55, 'Address Line 2 cannot be longer than 55 characters.'),
  city: yup
    .string()
    .trim()
    .max(40, 'City cannot be longer than 40 characters.')
    .required('City is required'),
  state: yup.string().required('State is required'),
  postalCode: yup
    .string()
    .trim()
    .min(5, 'Postal Code must be at least 5 characters.')
    .max(20, 'Postal Code cannot be longer than 20 characters.')
    .required('Postal Code is required'),
});

export const AddressValidation: React.FC<AddressValidationProps> = ({
  currentAddress,
  onClose,
  onProceed,
}) => {
  const [verifiedAddress, setVerifiedAddress] = useState<any | null | undefined>(undefined);
  const styles = useStyles();

  useEffect(() => {
    const verifyAddress = async () => {
      setVerifiedAddress(await apiClient.addresses.verifyAddress(currentAddress as Address));
    };
    verifyAddress();
    // eslint-disable-next-line
  }, []);

  if (!verifiedAddress) return null;
  return (
    <Dialog
      fullWidth={true}
      maxWidth={'sm'}
      aria-labelledby="address-validation-dialog"
      open={Boolean(verifiedAddress)}
    >
      <DialogTitle id="address-validation-dialog">
        <span className={styles.addressValidationTitle}>
          {verifiedAddress.valid ? 'Verified Address Found' : 'Address Could Not Be Verified'}
        </span>
      </DialogTitle>
      <DialogContent dividers className={styles.addressValidationContent}>
        <Grid container spacing={0}>
          <Grid item xs={12} sm={4}>
            <Typography gutterBottom variant="body1" className={styles.addressValidationLabel}>
              Current Address:
            </Typography>
          </Grid>
          <Grid item xs={12} sm={8}>
            <AddressLabel
              addressStyles={styles.addressValidationText}
              address={currentAddress}
              variant="body2"
            />
          </Grid>
        </Grid>
        {verifiedAddress.valid && (
          <Grid container spacing={0} style={{ marginTop: '2rem' }}>
            <Grid item xs={12} sm={4}>
              <Typography gutterBottom variant="body1" className={styles.addressValidationLabel}>
                Verified Address:
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <AddressLabel
                addressStyles={styles.addressVerifiedValidationText}
                address={verifiedAddress}
                variant="body2"
              />
            </Grid>
          </Grid>
        )}
      </DialogContent>
      <DialogActions>
        {verifiedAddress.valid && (
          <Button onClick={() => onProceed(verifiedAddress)} color="primary" variant="contained">
            Use Verified Address
          </Button>
        )}
        <Button
          onClick={() => onProceed(currentAddress)}
          color={verifiedAddress.valid ? 'default' : 'primary'}
          variant="contained"
        >
          Use Curent Address
        </Button>
        <Button onClick={onClose} color="default" variant="contained">
          Edit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

/**
 * A reusable address component for MAUI.
 * @address The address object from Address model.
 * @onCancel A method to call when cancel button is clicked.
 * @onAddressSaved A method to call when address is created or updated.
 * @returns Address component
 */
const AddressForm: React.FC<AddressFormProps> = ({
  address,
  addressAssociationForeignKey,
  onCancel,
  onAddressSaved,
}) => {
  const [openAddressValidation, setOpenAddressValidation] = useState(false);
  const styles = useStyles();
  const dispatch = useDispatch();
  const updateAddress = useMutation((address: Address) => apiClient.addresses.update(address));
  const createAddress = useMutation((address: CreateAddress) =>
    apiClient.addresses.create(address),
  );

  const addressForm = useFormik({
    initialValues: {
      address1: address?.address1 || '',
      address2: address?.address2 || '',
      city: address?.city || '',
      state: address?.state.toUpperCase() || '',
      postalCode: address?.postalCode || '',
    },
    validationSchema: addressFormSchema,
    onSubmit: async values => {
      try {
        setOpenAddressValidation(true);
      } catch (err: any) {
        dispatch(
          showToastMessage({
            message: err.message || 'An error occurred while verifying the address',
            type: 'error',
          }),
        );
      }
    },
  });

  const onCreateAddress = async (changedAddress: any, verified: boolean = false) => {
    setOpenAddressValidation(false);

    const addressPayload: CreateAddress = {
      uid: address?.uid,
      addressType: address?.addressType,
      address1: changedAddress.address1,
      //verifiedAddress comes back empty but with a space.
      address2: changedAddress.address2.trim(),
      city: changedAddress.city,
      state: changedAddress.state,
      postalCode: changedAddress.postalCode,
      status: ADDRESS_STATUS.ACTIVE,
      ...addressAssociationForeignKey,
    };

    createAddress.mutate(addressPayload, {
      onSuccess: () => {
        if (onAddressSaved) onAddressSaved();
      },
      onError: (error: any) => {
        dispatch(
          showToastMessage({
            message: error.message || 'An error occurred while creating the address',
            type: 'error',
          }),
        );
      },
    });
  };

  const onUpdateAddress = async (changedAddress: any, verified: boolean | undefined) => {
    setOpenAddressValidation(false);

    const addressPayload: Address = {
      uid: address?.uid,
      addressType: address?.addressType,
      address1: changedAddress.address1,
      //verifiedAddress comes back empty but with a space.
      address2: changedAddress.address2.trim(),
      city: changedAddress.city,
      state: changedAddress.state,
      postalCode: changedAddress.postalCode,
      status: changedAddress.status,
      verified,
      ...addressAssociationForeignKey,
    };

    updateAddress.mutate(addressPayload, {
      onSuccess: () => {
        if (onAddressSaved) onAddressSaved();
      },
      onError: (error: any) => {
        dispatch(
          showToastMessage({
            message: error.message || 'An error occurred while updating the address',
            type: 'error',
          }),
        );
      },
    });
  };

  const isAddressModified = () => {
    return (
      addressForm.values.address1 !== address?.address1 ||
      addressForm.values.address2 !== address?.address2 ||
      addressForm.values.city !== address?.city ||
      addressForm.values.state !== address?.state ||
      addressForm.values.postalCode !== address?.postalCode
    );
  };

  const proceedButtonText = () => (address?.address1 ? 'Update' : 'Add');
  const proceedFunc = address && address.uid ? onUpdateAddress : onCreateAddress;

  return (
    <>
      <form noValidate onSubmit={addressForm.handleSubmit}>
        <TextField
          className={styles.inputErrorText}
          id="address1"
          name="address1"
          label="Address 1"
          margin="normal"
          fullWidth
          variant="outlined"
          autoComplete="none"
          value={addressForm.values.address1}
          onChange={addressForm.handleChange}
          onBlur={addressForm.handleBlur}
          disabled={updateAddress.isLoading}
          error={addressForm.touched.address1 && Boolean(addressForm.errors.address1)}
          helperText={addressForm.touched.address1 && addressForm.errors.address1}
          inputProps={{
            maxLength: 200,
          }}
        />
        <TextField
          className={styles.inputErrorText}
          id="address2"
          name="address2"
          label="Address 2"
          margin="normal"
          fullWidth
          variant="outlined"
          autoComplete="none"
          value={addressForm.values.address2}
          onChange={addressForm.handleChange}
          onBlur={addressForm.handleBlur}
          disabled={updateAddress.isLoading}
          error={addressForm.touched.address2 && Boolean(addressForm.errors.address2)}
          helperText={addressForm.touched.address2 && addressForm.errors.address2}
          inputProps={{
            maxLength: 55,
          }}
        ></TextField>
        <TextField
          className={styles.inputErrorText}
          id="city"
          name="city"
          label="City"
          margin="normal"
          fullWidth
          variant="outlined"
          autoComplete="none"
          value={addressForm.values.city}
          onChange={addressForm.handleChange}
          onBlur={addressForm.handleBlur}
          disabled={updateAddress.isLoading}
          error={addressForm.touched.city && Boolean(addressForm.errors.city)}
          helperText={addressForm.touched.city && addressForm.errors.city}
          inputProps={{
            maxLength: 40,
          }}
        ></TextField>
        <FormControl
          variant="outlined"
          fullWidth
          margin="normal"
          error={addressForm.touched.state && Boolean(addressForm.errors.state)}
        >
          <InputLabel id="status-label">State</InputLabel>
          <Select
            className={styles.inputErrorText}
            id="state"
            name="state"
            label="State"
            labelId="state-label"
            value={addressForm.values.state}
            onChange={addressForm.handleChange}
            onBlur={addressForm.handleBlur}
            disabled={updateAddress.isLoading}
            error={addressForm.touched.state && Boolean(addressForm.errors.state)}
          >
            {states.map((s, index) => (
              <MenuItem value={s.code} key={index}>
                {s.name}
              </MenuItem>
            ))}
          </Select>
          {addressForm.touched.state && Boolean(addressForm.errors.state) && (
            <FormHelperText>{addressForm.errors.state}</FormHelperText>
          )}
        </FormControl>
        <TextField
          className={styles.inputErrorText}
          id="postalCode"
          name="postalCode"
          label="Postal Code"
          margin="normal"
          fullWidth
          variant="outlined"
          autoComplete="none"
          value={addressForm.values.postalCode}
          onChange={addressForm.handleChange}
          onBlur={addressForm.handleBlur}
          disabled={updateAddress.isLoading}
          error={addressForm.touched.postalCode && Boolean(addressForm.errors.postalCode)}
          helperText={addressForm.touched.postalCode && addressForm.errors.postalCode}
          inputProps={{
            maxLength: 20,
          }}
        ></TextField>
        <Grid container spacing={1} direction="row">
          <Grid item xs={6}>
            <Button
              color="primary"
              variant="contained"
              size="large"
              onClick={addressForm.submitForm}
              disabled={
                !isAddressModified() ||
                updateAddress.isLoading ||
                createAddress.isLoading ||
                !isEmpty(addressForm.errors)
              }
              fullWidth
            >
              {!updateAddress.isLoading && !createAddress.isLoading ? (
                proceedButtonText()
              ) : (
                <ButtonProgressIndicator />
              )}
            </Button>
          </Grid>
          {onCancel && (
            <Grid item xs={6}>
              <Button
                color="default"
                variant="contained"
                size="large"
                onClick={onCancel}
                disabled={updateAddress.isLoading}
                fullWidth
              >
                Cancel
              </Button>
            </Grid>
          )}
        </Grid>
      </form>
      {openAddressValidation && (
        <AddressValidation
          currentAddress={addressForm.values}
          onClose={() => setOpenAddressValidation(false)}
          onProceed={proceedFunc}
        />
      )}
    </>
  );
};

export default AddressForm;
