import { useEffect, useState } from 'react';
import { Button } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useMutation, useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import moment from 'moment';

import Dialog from '../../../../components/Dialog';
import ProgramChange from './ProgramChange';
import ccmCoreAPI, { ProgramChangePayload } from '../../../../lib/api';
import EligibleProgram from '../../../../models/eligibleProgram';
import ProgramEnrollment from '../../../../models/programEnrollment';
import MemberProgram from '../../../../models/memberProgram';
import MembershipAccount from '../../../../models/membershipAccount';
import Member from '../../../../models/member';
import { showToastMessage } from '../../../../store/slices/toastMessage';
import { MAX_CCM_PROGRAM_DATE, MIN_CCM_PROGRAM_DATE } from '../../../../lib/util';
import ButtonProgressIndicator from '../../../ButtonProgressIndicator';

interface ProgramChangeDialogProps {
  open: boolean;
  membershipAccount: MembershipAccount;
  memberProgram: MemberProgram;
  toggleShowDialog: Function;
  onProgramChanged: Function;
}

const ProgramChangeDialog = (props: ProgramChangeDialogProps) => {
  const { open, toggleShowDialog, membershipAccount, memberProgram, onProgramChanged } = props;
  const dispatch = useDispatch();
  const [programChangeModalTitle, setProgramChangeModalTitle] = useState('Switch Program');
  const [currentTab, setCurrentTab] = useState(0);
  const [selectedPrograms, setSelectedPrograms] = useState<EligibleProgram[] | undefined>();
  const [remainingMembers, setRemainingMembers] = useState(0);
  const [hasError, setHasError] = useState(false);
  const [footer, setFooter] = useState<any>();

  // the first of the month following the current program's start date or the first of next month, which ever is greater
  let defaultStartDate = moment().add(1, 'month').format('YYYY-MM-01');
  if (
    moment(defaultStartDate, 'YYYY-MM-DD').isSameOrBefore(
      moment(memberProgram.started, 'YYYY-MM-DD'),
    )
  ) {
    defaultStartDate = moment(memberProgram.started, 'YYYY-MM-DD')
      .add(1, 'month')
      .format('YYYY-MM-01');
  }
  const [startDate, setStartDate] = useState<string>(defaultStartDate);

  // retrieves eligible programs
  const { data, isLoading, isFetching } = useQuery(
    ['eligiblePrograms', membershipAccount.uid, startDate, memberProgram, selectedPrograms],
    () => {
      if (startDate && memberProgram) {
        return ccmCoreAPI.membershipPrograms.prices(
          membershipAccount.uid,
          startDate,
          memberProgram.uid,
          selectedPrograms,
        );
      }
    },
  );

  // submits program change
  const changeProgram = useMutation((programChangePayload: ProgramChangePayload) =>
    ccmCoreAPI.membershipPrograms.changeProgramEnrollments(programChangePayload),
  );

  const eligiblePrograms = data && data.programs;

  // filter the members that are enrolled in the selected program
  const members = (() => {
    if (memberProgram && membershipAccount.programEnrollments && membershipAccount.members) {
      const programEnrollments = membershipAccount.programEnrollments.filter(
        (enrollment: ProgramEnrollment) => enrollment.memberProgram === memberProgram.uid,
      );
      return membershipAccount.members.filter((member: Member) => {
        return (
          member.status === 'active' &&
          programEnrollments.some(p => {
            if (typeof p.member === 'string') {
              return p.member === member.uid && !p.ended || moment(p.ended).isAfter(moment());
            } else {
              return p.member.uid === member.uid && !p.ended || moment(p.ended).isAfter(moment());
            }
          })
        );
      });
    }

    return [];
  })();

  useEffect(() => {
    if (data?.selectedPrograms) {
      setSelectedPrograms(data.selectedPrograms);
    }
  }, [data]);

  useEffect(() => {
    let selectedMembers: string[] = [];
    if (selectedPrograms) {
      for (let i = 0; i < selectedPrograms?.length; i++) {
        selectedMembers = selectedMembers.concat(selectedPrograms[i].eligibleMembers);
      }
    }

    setRemainingMembers(members.length - selectedMembers.length);
  }, [selectedPrograms, members]);

  useEffect(() => {
    if (selectedPrograms && selectedPrograms.length > 0 && remainingMembers > 0) {
      setFooter(
        <Alert variant="outlined" severity="info">
          {remainingMembers} member{remainingMembers > 1 && 's'} will remain on the current program
        </Alert>,
      );
    } else {
      setFooter('');
    }
  }, [selectedPrograms, remainingMembers]);

  const handleCloseDialog = () => {
    toggleShowDialog();
    setCurrentTab(0);
    setSelectedPrograms([]);
    setStartDate(defaultStartDate);
    setRemainingMembers(0);
    setFooter('');
  };

  const isStartDateValid = (testDate: string = startDate) => {
    return (
      moment(testDate).isValid() &&
      moment(testDate).isSameOrAfter(MIN_CCM_PROGRAM_DATE) &&
      moment(testDate).isSameOrBefore(MAX_CCM_PROGRAM_DATE)
    );
  };

  const renderButtons = () => {
    const nextButton = (
      <Button
        key="nextButton"
        color="primary"
        variant="contained"
        disabled={
          isLoading ||
          !selectedPrograms ||
          (selectedPrograms && !selectedPrograms.length) ||
          !isStartDateValid() ||
          hasError
        }
        onClick={() => {
          setCurrentTab(currentTab + 1);
          setProgramChangeModalTitle('Switch Program: Confirmation');
        }}
      >
        Next
      </Button>
    );
    const submitButton = (
      <Button
        key="submitButton"
        color="primary"
        variant="contained"
        disabled={isLoading}
        onClick={handleSubmit}
      >
        {!changeProgram.isLoading ? 'Submit' : <ButtonProgressIndicator size={24} />}
      </Button>
    );
    const cancelButton = (
      <Button key="cancelButton" color="default" variant="contained" onClick={handleCloseDialog}>
        Cancel
      </Button>
    );
    if (currentTab === 0) {
      return [nextButton, cancelButton];
    } else {
      return [submitButton, cancelButton];
    }
  };

  // submits program change to API
  const handleSubmit = () => {
    if (selectedPrograms) {
      // for each selected program, if the program has program options and none are selected and APLL is an option, set that to selected = true
      selectedPrograms.forEach(selectedProgram => {
        selectedProgram.programLevels.forEach(programLevel => {
          if (programLevel.options) {
            const selectedOption = programLevel.options.some(o => o.selected === true);
            if (!selectedOption) {
              programLevel.options.forEach(option => {
                if (option.slug === 'APLL') {
                  option.selected = true;
                }
              });
            }
          }
        });
      });

      const payload: ProgramChangePayload = {
        memberProgram: memberProgram.uid,
        selectedPrograms,
        startDate,
        allowFail: true,
      };

      changeProgram.mutate(payload, {
        onSuccess: data => {
          dispatch(showToastMessage({ message: `Program enrollment updated`, type: 'success' }));
          handleCloseDialog();
          onProgramChanged();
        },
        onError: (error: any) => {
          dispatch(
            showToastMessage({
              message: error.message || 'An error occurred when updating program enrollment',
              type: 'error',
            }),
          );
        },
      });
    } else {
      dispatch(
        showToastMessage({
          message: 'No new programs selected',
          type: 'error',
        }),
      );
    }
  };

  const handleChageStartDate = (selectedStartDate: string) => {
    setStartDate(selectedStartDate); // expected format YYYY-MM-DD
  };

  const handleHasError = (hasError: boolean) => {
    setHasError(hasError);
  };

  const handleSelectPrograms = (selectedPrograms: EligibleProgram[]) => {
    setSelectedPrograms(selectedPrograms);
  };

  const handleTabClick = (tabIndex: number) => {
    tabIndex > 0
      ? setProgramChangeModalTitle('Switch Program: Confirmation')
      : setProgramChangeModalTitle('Switch Program');
    setCurrentTab(tabIndex);
  };

  return (
    <Dialog open={open} title={programChangeModalTitle} buttons={renderButtons()} footer={footer}>
      <ProgramChange
        isFetching={isFetching}
        memberProgram={memberProgram}
        members={members}
        remainingMembers={remainingMembers}
        eligiblePrograms={eligiblePrograms}
        currentTab={currentTab}
        startDate={startDate}
        onTabClick={handleTabClick}
        onChangeStartDate={handleChageStartDate}
        onSelectPrograms={handleSelectPrograms}
        handleError={handleHasError}
        hasError={hasError}
        isStartDateValid={isStartDateValid}
      />
    </Dialog>
  );
};

export default ProgramChangeDialog;
