import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { FC } from 'react';
import { PrintRequest } from '../../types/print-request';
import PrintRequestTableRow from './PrintRequestTableRow';

interface PrintRequestTableProps {
  printRequests: PrintRequest[];
  onCancelRequest: (printRequestUId: string) => void;
}

const PrintRequestTable: FC<PrintRequestTableProps> = ({ printRequests, onCancelRequest }) => {
  return (
    <TableContainer component={Paper} data-testid="print-request-table">
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell data-testid="request-date">Request Date</TableCell>
            <TableCell data-testid="send-at">Send At</TableCell>
            <TableCell data-testid="job-type">Job Type</TableCell>
            <TableCell data-testid="member-program">Member Program</TableCell>
            <TableCell data-testid="status">Status</TableCell>
            <TableCell data-testid="cancel"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {printRequests.map(printRequest => (
            <PrintRequestTableRow
              printRequest={printRequest}
              onCancelRequest={onCancelRequest}
              key={printRequest.uid}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default PrintRequestTable;
