import React from 'react';
import { useQuery, UseQueryResult } from 'react-query';
import { Box, Typography } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import Alert from '@material-ui/lab/Alert';

import apiClient from '../lib/api';
import { AppSetting } from '../models/appSettings';
import Page from '../components/Page';
import Spacer from '../components/Spacer';
import AppSettingsTable from '../components/appSettings/AppSettingsTable';
import AppSettingsAddButton from '../components/appSettings/AppSettingsAddButton';

const renderFetchState = (appSettingsFetch: UseQueryResult<AppSetting[], unknown>) => {
  if (appSettingsFetch.isLoading) {
    return (
      <>
        <Skeleton
          variant="rect"
          animation="wave"
          height={400}
          width="100%"
          style={{ marginBottom: 6 }}
        />
      </>
    );
  }

  if (appSettingsFetch.isError) {
    return (
      <Alert variant="outlined" severity="warning">
        There was an error loading the application settings. Please try again.
      </Alert>
    );
  }

  if (appSettingsFetch.isSuccess) {
    return <AppSettingsTable appSettings={appSettingsFetch.data} />;
  }
};

export const AppSettings: React.FC<{}> = () => {
  const appSettingsFetch = useQuery('appSettings', () => apiClient.appSettings.getAppSettings());

  const breadcrumbs = [
    { href: '/', label: 'Home' },
    { href: '#', label: 'System Administration' },
    { href: '/system-admin/app-settings', label: 'Application Settings' },
  ];

  return (
    <Page breadcrumbs={breadcrumbs}>
      <Spacer size={15} />
      <Box display="flex" justifyContent="space-between" alignItems="flex-start">
        <Typography variant="h2">Application Settings</Typography>
        <AppSettingsAddButton />
      </Box>

      {renderFetchState(appSettingsFetch)}
    </Page>
  );
};

export default AppSettings;
