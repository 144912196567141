import { Link } from '@material-ui/core';
import LaunchIcon from '@material-ui/icons/Launch';

export interface SalesforceCaseLaunchButtonProps {
  adminCaseId: string | undefined;
}

export const SalesforceCaseLaunchButton = ({ adminCaseId }: SalesforceCaseLaunchButtonProps) => {
  const url = process.env.REACT_APP_SALESFORCE_CASE_VIEW_URL + `/${adminCaseId}`;

  return (
    <>
      {adminCaseId && (
        <Link href={url} target="_blank" color="inherit" aria-label="Launch Salesforce Case Link">
          <LaunchIcon
            style={{ fontSize: '0.9rem', marginLeft: 10 }}
            role="button"
            aria-label="Launch Salesforce Case Button"
          />
        </Link>
      )}
    </>
  );
};

export default SalesforceCaseLaunchButton;
