import { startCase } from 'lodash';

export enum UserRoles {
  SystemAdmin = 'system_admin',
  BillingSpecialist = 'billing_specialist',
  BillingManager = 'billing_manager',
  User = 'user',
}

/**
 * User
 * The user data model stores the authenticated user's non-sensitive
 * information on the client. This will be extended to include roles
 * and permissions.
 */
export default class User {
  uid = '';
  // authId is an id obtained from the auth provider and will only be present
  // in certain circumstances
  authId: string | null = null;
  firstName = '';
  lastName = '';
  email = '';
  groups: string[] = []; // this is set from the decoded access token when the user successfully authenticates
  roles: string[] = [];
  permissions: string[] = [];
  settings: any = {};
  // isActiveDirectoryUser is only set when verifying a user to register for new
  // access to this application
  isActiveDirectoryUser: boolean = false;
  status = '';

  constructor(data: any = {}) {
    this.uid = data.uid;
    this.firstName = data.firstName;
    this.lastName = data.lastName;
    this.email = data.email;

    this.groups = data.groups;
    this.roles = data.roles;
    this.permissions = data.permissions;
    this.settings = data.settings;
    this.status = data.status;
  }

  get fullName() {
    return this.firstName + ' ' + this.lastName;
  }

  static formatRoles(roles: string[] = []) {
    const formattedRoles = roles.map((role: string) => startCase(role));
    return formattedRoles.join(', ');
  }
}
