import { createSlice, PayloadAction, ThunkDispatch, AnyAction } from '@reduxjs/toolkit';
import apiClient from '../../lib/api';
import MembershipAccount from '../../models/membershipAccount';
import CustomError from '../../lib/customError';
import { hideToastMessage, showToastMessage } from './toastMessage';
import { Thunk } from '..';
interface MembershipAccountState {
  account: MembershipAccount;
  loading: boolean;
  saved: boolean;
  error: CustomError | null;
}

interface AccountRetrieveOptions {
  includeMembers: boolean;
  includeMemberPrograms: boolean;
  includeProgramEnrollments: boolean;
  includeAddresses: boolean;
}

interface AccountSuccessPayload {
  account: MembershipAccount;
}

interface AccountErrorPayload {
  error: CustomError;
}

export const initialState = {
  account: {},
  loading: false,
  error: null,
} as MembershipAccountState;

const membershipAccountSlice = createSlice({
  name: 'membershipAccount',
  initialState,
  reducers: {
    saveAccountRequest(state) {
      state.error = null;
      state.loading = true;
      state.saved = false;
    },
    saveAccountSuccess(state, action: PayloadAction<AccountSuccessPayload>) {
      state.account = new MembershipAccount(action.payload.account);
      state.error = null;
      state.loading = false;
      state.saved = true;
    },
    saveAccountError(state, action: PayloadAction<AccountErrorPayload>) {
      state.error = action.payload.error;
      state.loading = false;
      state.saved = false;
    },
    getAccountRequest(state) {
      state.error = null;
      state.loading = true;
    },
    getAccountSuccess(state, action: PayloadAction<AccountSuccessPayload>) {
      state.account = action.payload.account;
      state.error = null;
      state.loading = false;
    },
    getAccountError(state, action: PayloadAction<AccountErrorPayload>) {
      state.error = action.payload.error;
      state.loading = false;
    },
  },
});

export const getAccount =
  (uid: string, options?: AccountRetrieveOptions) =>
  async (dispatch: ThunkDispatch<any, any, AnyAction>) => {
    try {
      dispatch(hideToastMessage());
      dispatch(getAccountRequest());
      const account = await apiClient.membershipAccounts.get(uid, options);
      dispatch(getAccountSuccess({ account }));
    } catch (err: any) {
      dispatch(getAccountError({ error: err }));
      dispatch(showToastMessage({ message: err.message, type: 'error' }));
    }
  };

/**
 * @deprecated
 * DO NOT USE Saves an account using provided data
 * @param data - account data to save
 * @returns - a thunk for dispatching a save account action
 */
export const saveAccount =
  (data: any): Thunk =>
  async dispatch => {
    try {
      dispatch(hideToastMessage());
      dispatch(saveAccountRequest());

      const account = await apiClient.membershipAccounts.save(data);
      dispatch(saveAccountSuccess({ account }));
      dispatch(
        showToastMessage({
          message: 'The account has been saved',
          type: 'success',
          duration: 3000,
        }),
      );
    } catch (err: any) {
      dispatch(saveAccountError({ error: err }));
      dispatch(showToastMessage({ message: err.message, type: 'error' }));
    }
  };

export const importSalesforceAccount =
  (salesforceId: string): Thunk =>
  async dispatch => {
    try {
      dispatch(hideToastMessage());
      const { membershipAccount, existingAccount } = await apiClient.membershipAccounts.import(
        salesforceId,
      );
      if (!existingAccount) {
        dispatch(
          showToastMessage({
            message: 'Successfully imported account',
            type: 'success',
            duration: 10000,
          }),
        );
      } else {
        dispatch(
          showToastMessage({
            message: 'The account you are trying to import already exists',
            type: 'info',
            duration: 10000,
          }),
        );
      }
      return membershipAccount;
    } catch (err: any) {
      dispatch(showToastMessage({ message: err.message, type: 'error' }));
      throw err;
    }
  };

/* Actions & Reducer */
/* prettier-ignore */
export const {
  saveAccountRequest, saveAccountSuccess, saveAccountError,
  getAccountRequest, getAccountSuccess, getAccountError
} = membershipAccountSlice.actions;

export default membershipAccountSlice.reducer;
