import React, { useEffect, useState } from 'react';
import {
  Tab,
  Tabs,
  Box,
  Modal,
  Paper,
  Backdrop,
  IconButton,
  Typography,
  Button,
  Grid,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';
import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import ProgramMembers from './ProgramMembers';
import ConfirmProgramReactivation from './ConfirmProgramReactivation';
import apiClient from '../../../lib/api';
import { showToastMessage } from '../../../store/slices/toastMessage';
import MembershipAccount from '../../../models/membershipAccount';
import InactiveProgram from './InactiveProgram';
import MemberProgram from '../../../models/memberProgram';
import ProgramEnrollment, { sortNewestToOldestByEndDate } from '../../../models/programEnrollment';
import Spacer from '../../Spacer';
import { useFormik } from 'formik';
import ButtonProgressIndicator from '../../ButtonProgressIndicator';
import Member from '../../../models/member';

export enum CancellationType {
  MembershipAccount = 'membershipAccount',
  MemberProgram = 'memberProgram',
}

export interface DataValues {
  membershipAccount: string;
  programEnrollments: ProgramEnrollment[];
}

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalBody: {
    width: 1260,
  },
  modalTitleWrapper: {
    display: 'flex',
    margin: '1.5rem',
    justifyContent: 'space-between',
    '& > div > h2': {
      paddingTop: 10,
    },
  },
  modalBodyTabs: {
    flexGrow: 1,
    display: 'flex',
    height: 560,
  },
  tabNavigation: {
    borderRight: '1px solid #ccc',
  },
  tabBody: {
    width: '100%',
    '& .MuiBox-root': {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
  },
  buttonWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-end',
    '& > :first-child': {
      marginRight: '2rem',
    },
    alignSelf: 'flex-end',
  },
  spacer: {
    display: 'flex',
    flex: 1,
  },
}));

/**
 * TabPanel
 */
interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

const TabPanel = (props: TabPanelProps) => {
  const styles = useStyles();
  const { children, value, index, ...other } = props;
  return (
    <div
      className={styles.tabBody}
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
};

interface MembershipReactivationProps {
  membershipAccount: MembershipAccount;
  selectedMemberProgram: MemberProgram;
  open: boolean;
  onClose: () => void;
}

const MembershipReactivationDialog: React.FC<MembershipReactivationProps> = ({
  membershipAccount,
  selectedMemberProgram,
  open,
  onClose,
}) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const styles = useStyles();
  const [tabIndex, setTabIndex] = useState(0);
  const [reactivationData, setReactivationData] = useState<DataValues | null>(null);
  const [selectedEnrollments, setSelectedEnrollments] = useState<ProgramEnrollment[]>([]);
  const reactivateMemberPrograms = useMutation(
    (payload: { programEnrollments: string[]; memberProgram: string }) =>
      apiClient.membershipPrograms.reactivateMemberPrograms(payload),
  );
  const [priorEnrollments, setPriorEnrollments] = useState<ProgramEnrollment[] | []>([]);
  const allowPartialReactivation = false;

  useEffect(() => {
    const activeEnrollmentsMembers = membershipAccount?.programEnrollments?.filter(pe => pe.ended === null).map(pe => pe.member);
    const sortedProgramEnrollmentsByEndDate = membershipAccount.programEnrollments
      ?.sort(sortNewestToOldestByEndDate)
      .filter(pe => pe.memberProgram === selectedMemberProgram.uid);
    const latestProgramEnrollmentDate =
      sortedProgramEnrollmentsByEndDate &&
      sortedProgramEnrollmentsByEndDate[0] &&
      sortedProgramEnrollmentsByEndDate[0].ended;
    const priorEnrollments =
      membershipAccount?.programEnrollments?.filter((enrollment: ProgramEnrollment) => {
        const { memberProgram, member }: { memberProgram: string | MemberProgram, member: string | Member } = enrollment;
        const memberUid = typeof member === 'string' ? member : member.uid;
        if (activeEnrollmentsMembers!.includes(memberUid)) return false;
        if (typeof memberProgram === 'string') {
          return (
            memberProgram === selectedMemberProgram?.uid &&
            enrollment.ended === latestProgramEnrollmentDate
          );
        } else {
          return (
            memberProgram.uid === selectedMemberProgram?.uid &&
            enrollment.ended === latestProgramEnrollmentDate
          );
        }
      }) || [];

    if (!allowPartialReactivation) setSelectedEnrollments(priorEnrollments);
    setPriorEnrollments(priorEnrollments);
  }, [allowPartialReactivation, membershipAccount, selectedMemberProgram]);

  const onCloseModal = () => {
    setTabIndex(0);
    formik.setFieldValue('programEnrollments', []);
    setReactivationData(null);
    setSelectedEnrollments([]);
    onClose();
  };

  const onMoveToConfirm = () => {
    if (!allowPartialReactivation) formik.setFieldValue('programEnrollments', priorEnrollments);
    setReactivationData({
      programEnrollments: selectedEnrollments,
      membershipAccount: membershipAccount.uid,
    });
    setTabIndex(2);
  };

  const onMoveToMembers = () => {
    setTabIndex(1);
  };

  const handleTabChange = (event: React.ChangeEvent<{}>, tabIndex: number) => {
    setTabIndex(tabIndex);
  };

  const onConfirmReactivation = () => {
    const programEnrollments = selectedEnrollments.map((e: ProgramEnrollment) => {
      return e.uid;
    });

    const payload = { programEnrollments, memberProgram: selectedMemberProgram.uid };

    reactivateMemberPrograms.mutate(payload, {
      onSuccess: () => {
        onCloseModal();
        queryClient.invalidateQueries(['membershipAccount', membershipAccount.uid]);
        dispatch(
          showToastMessage({
            message: 'The membership program has been reactivated',
            type: 'success',
          }),
        );
      },
      onError: (error: any) => {
        onCloseModal();
        dispatch(
          showToastMessage({
            message: `Could not reactivate the membership program ${
              error.message ? `. ${error.message}` : ''
            }`,
            type: 'error',
          }),
        );
      },
    });
  };

  const formik = useFormik<DataValues>({
    initialValues: {
      programEnrollments: allowPartialReactivation ? [] : priorEnrollments,
      membershipAccount: membershipAccount.uid,
    },
    onSubmit: values => {
      onConfirmReactivation();
    },
  });

  const onMemberSelected = (checked: boolean, value: ProgramEnrollment) => {
    const values = formik.values.programEnrollments;
    if (checked) {
      values.push(value);
    } else {
      values.splice(values.indexOf(value), 1);
    }
    formik.setFieldValue('programEnrollments', values);
    setSelectedEnrollments(formik.values.programEnrollments);
  };

  useEffect(() => {
    setTabIndex(0);
  }, [selectedMemberProgram, membershipAccount]);

  const renderProgramTabButtons = () => {
    return (
      <>
        <Button
          type="submit"
          color="primary"
          variant="contained"
          size="large"
          onClick={() => onMoveToMembers()}
        >
          Continue
        </Button>
        <Button color="default" variant="contained" size="large" onClick={() => onCloseModal()}>
          Cancel
        </Button>
      </>
    );
  };

  const renderConfirmTabButtons = () => {
    return (
      <>
        <Button
          type="submit"
          color="primary"
          variant="contained"
          size="large"
          disabled={
            formik?.values?.programEnrollments?.length < 1 || reactivateMemberPrograms.isLoading
          }
        >
          {reactivateMemberPrograms.isLoading ? <ButtonProgressIndicator /> : 'Confirm'}
        </Button>
        <Button
          color="default"
          variant="contained"
          size="large"
          onClick={() => onCloseModal()}
          disabled={reactivateMemberPrograms.isLoading}
        >
          Cancel
        </Button>
      </>
    );
  };

  return (
    <Modal
      className={styles.modal}
      open={open}
      onClose={onClose}
      closeAfterTransition
      disableBackdropClick
      disableEscapeKeyDown
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Paper className={styles.modalBody}>
        <div className={styles.modalTitleWrapper}>
          <Box>
            <Typography variant="h2">
              {tabIndex <= 1 ? 'Reactivation' : 'Reactivation: Confirmation'}
            </Typography>
          </Box>
          <IconButton title="cancel" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </div>
        <Box>
          <form onSubmit={formik.handleSubmit}>
            <div className={styles.modalBodyTabs}>
              <Tabs
                orientation="vertical"
                variant="scrollable"
                value={tabIndex}
                onChange={handleTabChange}
                aria-label="Account or Program Reactivation"
                className={styles.tabNavigation}
                indicatorColor="primary"
              >
                <Tab label="Program" />
                <Tab label="Members" disabled={tabIndex < 1} />
                <Tab label="Confirmation" disabled={tabIndex < 2} />
              </Tabs>
              <TabPanel value={tabIndex} index={0}>
                <Typography variant="h2">Program</Typography>
                <p>The program below will be reactivated:</p>
                <Spacer size={20} />
                <Grid container>
                  <Grid item xs={6}>
                    <InactiveProgram program={selectedMemberProgram} />
                  </Grid>
                </Grid>
                <div className={styles.spacer}></div>
                <div className={styles.buttonWrapper}>{renderProgramTabButtons()}</div>
              </TabPanel>
              <TabPanel value={tabIndex} index={1}>
                <Typography variant="h2">Members</Typography>
                <p>
                  {allowPartialReactivation
                    ? 'Choose which members to reactivate:'
                    : 'The members below will be reactivated:'}
                </p>
                <Spacer size={20} />
                <ProgramMembers
                  priorEnrollments={priorEnrollments}
                  onMemberSelected={onMemberSelected}
                  onClose={onCloseModal}
                  onContinue={onMoveToConfirm}
                  selectedEnrollments={selectedEnrollments}
                  allowPartialReactivation={allowPartialReactivation}
                />
              </TabPanel>
              <TabPanel value={tabIndex} index={2}>
                <ConfirmProgramReactivation
                  reactivationData={reactivationData!}
                  selectedEnrollments={selectedEnrollments}
                  onMemberSelected={onMemberSelected}
                  program={selectedMemberProgram}
                  allowPartialReactivation={allowPartialReactivation}
                />
                <div className={styles.buttonWrapper}>{renderConfirmTabButtons()}</div>
              </TabPanel>
            </div>
          </form>
        </Box>
      </Paper>
    </Modal>
  );
};

export default MembershipReactivationDialog;
