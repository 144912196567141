import { Alert } from '@material-ui/lab';
import { AccountErrors as AccountErrorsType } from '../../../models/membershipAccount';
import { Typography, createStyles, makeStyles } from '@material-ui/core';

interface Props {
  errors: AccountErrorsType;
}

const useStyles = makeStyles(() =>
  createStyles({
    accountError: {
      marginBottom: '16px',
    },
  }),
);

const MissingMembersPersonErrors: React.FC<Props> = ({ errors }) => {
  const missingPersonMembers = errors && errors.missingMembersPerson;

  if (missingPersonMembers.length === 0) {
    return null;
  }

  return (
    <>
      <Typography variant="h5">
        Members UID missing person data (not displayed in the account):
      </Typography>
      <ul>
        {missingPersonMembers.map((mpm, i) => (
          <li key={i}>{mpm}</li>
        ))}
      </ul>
    </>
  );
};

const AccountErrors: React.FC<Props> = ({ errors }) => {
  const styles = useStyles();
  return (
    <Alert severity="error" className={styles.accountError}>
      <MissingMembersPersonErrors errors={errors} />
    </Alert>
  );
};

export default AccountErrors;
