import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { MedicalBillReimbursementStatus } from '../medical-bill-reimbursement.constants';
import MedicalBillReimbursementAttachments from './MedicalBillReimbursementAttachments';
import MedicalBillReimbursementDetails from './MedicalBillReimbursementDetails';
import MedicalBillReimbursementHeader from './MedicalBillReimbursementHeader';
import MedicalBillReimbursementMedicalBillNumbers from './MedicalBillReimbursementMedicalBillNumbers';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    gappedGrid: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(2),
    },
  }),
);

interface MedicalBillReimbursementProps {
  submissionDate: string;
  status: MedicalBillReimbursementStatus;
  referenceNumber: string | undefined;
  adminCaseId: string | undefined;
  accountName: string;
  membershipAccount: string;
  submitterName: string;
  patientName: string;
  dateOfServiceStart: string;
  dateOfServiceEnd?: string;
  hospitalVisit: boolean;
  conditionsTreated: string;
  servicesPerformed: string;
  totalAmountBilled: number;
  files: Array<{ fileName: string }>;
  medicalBillNumbers: string[];
  isEditMode: boolean;
  onStatusChange: (status: MedicalBillReimbursementStatus) => void;
  onMedicalBillNumbersChange: (medicalBillNumbers: string[]) => void;
  onAttachmentClick: (fileId: string) => void;
}

const MedicalBillReimbursement = ({
  submissionDate,
  status,
  referenceNumber,
  adminCaseId,
  accountName,
  membershipAccount,
  submitterName,
  patientName,
  dateOfServiceStart,
  dateOfServiceEnd,
  hospitalVisit,
  conditionsTreated,
  servicesPerformed,
  totalAmountBilled,
  files,
  medicalBillNumbers,
  isEditMode,
  onStatusChange,
  onMedicalBillNumbersChange,
  onAttachmentClick,
}: MedicalBillReimbursementProps) => {
  const styles = useStyles();

  return (
    <div className={styles.gappedGrid}>
      <MedicalBillReimbursementHeader
        submissionDate={submissionDate}
        status={status}
        referenceNumber={referenceNumber}
        adminCaseId={adminCaseId}
        accountName={accountName}
        membershipAccount={membershipAccount}
        submitterName={submitterName}
        isEditMode={isEditMode}
        onStatusChange={onStatusChange}
      />

      <MedicalBillReimbursementMedicalBillNumbers
        medicalBillNumbers={medicalBillNumbers}
        onChange={onMedicalBillNumbersChange}
        isEditMode={isEditMode}
      />

      <MedicalBillReimbursementDetails
        patientName={patientName}
        dateOfServiceStart={dateOfServiceStart}
        dateOfServiceEnd={dateOfServiceEnd}
        hospitalVisit={hospitalVisit}
        conditionsTreated={conditionsTreated}
        servicesPerformed={servicesPerformed}
        totalAmountBilled={totalAmountBilled}
      />

      <MedicalBillReimbursementAttachments files={files} onClick={onAttachmentClick} />
    </div>
  );
};

export default MedicalBillReimbursement;
