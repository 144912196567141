import {
  Paper,
  Box,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  makeStyles,
  createStyles,
  Theme,
  Button,
  TextField,
} from '@material-ui/core';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';
import { SubjectType } from '../../../../models/auditEvent';
import { removeUnderscoresFromString } from '../../../../lib/util';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    filterControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    capitalize: {
      textTransform: 'capitalize',
    },
  }),
);

const actions = [
  'login',
  'logout',
  'view',
  'create',
  'update',
  'delete',
  'cancel',
  'export',
  'activate',
  'deactivate',
  'other',
];

export const AuditEventsFilter = (props: any) => {
  const styles = useStyles();
  const { queryParams, onFilterChange, showAllFilters } = props;

  const handleFilterChange = (event: any) => {
    const target = event.target;
    const value: string = target.value;
    const name: string = target.name;
    const newQueryParams = { ...queryParams, page: 1 };
    if (name === 'action') {
      if (value === 'any') {
        delete newQueryParams.action;
      } else {
        newQueryParams.action = value;
      }
    } else if (name === 'result') {
      if (value === 'any') {
        delete newQueryParams.result;
      } else {
        newQueryParams.result = value;
      }
    } else if (name === 'actorName') {
      if (value === '') {
        delete newQueryParams.actorName;
      } else {
        newQueryParams.actorName = value;
      }
    } else if (name === 'subject') {
      if (value === 'any') {
        delete newQueryParams.subject;
      } else {
        newQueryParams.subject = value;
      }
    }
    onFilterChange(newQueryParams);
  };

  const handleFilterDateChange = (name: string, value: Date | null) => {
    const newQueryParams = { ...queryParams, page: 1 };
    if (name === 'startDate') {
      if (value === null) {
        delete newQueryParams.startDate;
      } else {
        newQueryParams.startDate = moment(moment(value).format('YYYY-MM-DD'))
          .utc()
          .format('YYYY-MM-DD HH:mm:ssZ');
      }
    } else if (name === 'endDate') {
      if (value === null) {
        delete newQueryParams.endDate;
      } else {
        newQueryParams.endDate = moment(moment(value).format('YYYY-MM-DD'))
          .utc()
          .format('YYYY-MM-DD HH:mm:ssZ');
      }
    }
    onFilterChange(newQueryParams);
  };

  const resetFilter = () => {
    const newQueryParams = { ...queryParams };
    delete newQueryParams.action;
    delete newQueryParams.result;
    delete newQueryParams.startDate;
    delete newQueryParams.endDate;
    delete newQueryParams.actorName;
    newQueryParams.page = 1;
    onFilterChange(newQueryParams);
  };

  return (
    <Paper>
      <Box marginBottom={2} padding={2}>
        <Typography variant="h5">Filter</Typography>
        <Box style={{ display: 'flex', flexDirection: 'row' }}>
          {showAllFilters && (
            <>
              <FormControl className={styles.filterControl}>
                <TextField
                  label="Actor"
                  id="actorName"
                  name="actorName"
                  value={queryParams.actorName}
                  onChange={handleFilterChange}
                />
              </FormControl>
              <FormControl className={styles.filterControl}>
                <InputLabel id="subject">Subject</InputLabel>
                <Select
                  labelId="subject"
                  id="subject"
                  name="subject"
                  value={queryParams.subject || 'any'}
                  onChange={handleFilterChange}
                >
                  <MenuItem value={'any'}>Any / All</MenuItem>
                  {Object.entries(SubjectType).map((entry: [string, string]) => (
                    <MenuItem key={entry[1]} value={entry[1]} className={styles.capitalize}>
                      {removeUnderscoresFromString(entry[1])}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </>
          )}
          <FormControl className={styles.filterControl}>
            <InputLabel id="action">Action</InputLabel>
            <Select
              labelId="action"
              id="action"
              name="action"
              value={queryParams.action || 'any'}
              onChange={handleFilterChange}
            >
              <MenuItem value={'any'}>Any / All</MenuItem>
              {actions.map((action: string, index: number) => (
                <MenuItem key={index} value={action} className={styles.capitalize}>
                  {action}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl className={styles.filterControl}>
            <InputLabel id="result">Result</InputLabel>
            <Select
              labelId="result"
              id="result"
              name="result"
              value={queryParams.result || 'any'}
              onChange={handleFilterChange}
            >
              <MenuItem value={'any'}>Any / All</MenuItem>
              <MenuItem value={'success'}>Success</MenuItem>
              <MenuItem value={'fail'}>Fail</MenuItem>
            </Select>
          </FormControl>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <FormControl className={styles.filterControl}>
              <KeyboardDatePicker
                id="startDate"
                name="startDate"
                label="Start Date"
                autoOk
                disableToolbar
                variant="inline"
                format="MM/dd/yyyy"
                margin="none"
                value={moment(queryParams.startDate).toDate() || null}
                onChange={value => handleFilterDateChange('startDate', value)}
                KeyboardButtonProps={{
                  'aria-label': 'change start date',
                }}
              />
            </FormControl>
          </MuiPickersUtilsProvider>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <FormControl className={styles.filterControl}>
              <KeyboardDatePicker
                id="endDate"
                name="endDate"
                label="End Date"
                autoOk
                disableToolbar
                variant="inline"
                format="MM/dd/yyyy"
                margin="none"
                value={moment(queryParams.endDate).toDate() || null}
                onChange={value => handleFilterDateChange('endDate', value)}
                KeyboardButtonProps={{
                  'aria-label': 'change end date',
                }}
              />
            </FormControl>
          </MuiPickersUtilsProvider>
          <Box
            style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' }}
            marginBottom={1}
            marginLeft={2}
          >
            <Button variant="contained" color="default" size="small" onClick={resetFilter}>
              clear
            </Button>
          </Box>
        </Box>
      </Box>
    </Paper>
  );
};

export default AuditEventsFilter;
