import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Thunk } from '..';
import apiClient from '../../lib/api';
import Organization from '../../models/organization';
import CustomError from '../../lib/customError';
import { showToastMessage } from './toastMessage';

interface OrganizationsState {
  organizations: Organization[];
  loading: boolean;
  error: CustomError | null;
}

interface OrganizationsSuccessPayload {
  organizations: Organization[];
}

interface OrganizationsErrorPayload {
  error: CustomError;
}

export const initialState = {
  organizations: [],
  loading: false,
  error: null,
} as OrganizationsState;

const organizationsSlice = createSlice({
  name: 'organizations',
  initialState,
  reducers: {
    getOrganizationsRequest(state) {
      state.error = null;
      state.loading = true;
    },
    getOrganizationsSuccess(state, action: PayloadAction<OrganizationsSuccessPayload>) {
      state.organizations = action.payload.organizations;
      state.error = null;
      state.loading = false;
    },
    getOrganizationsError(state, action: PayloadAction<OrganizationsErrorPayload>) {
      state.organizations = [];
      state.error = action.payload.error;
      state.loading = false;
    },
  },
});

/* Thunks */
export const getOrganizations = (query?: any): Thunk => async dispatch => {
  try {
    dispatch(getOrganizationsRequest());
    const organizations = await apiClient.organizations.list(query);
    dispatch(getOrganizationsSuccess({ organizations }));
  } catch (err: any) {
    dispatch(getOrganizationsError({ error: err }));
    dispatch(showToastMessage({ message: err.message, type: 'error' }));
  }
};

// this is a special function that loads organizations and groups
// on the groups page
export const getOrganizationsWithGroups = (query?: any): Thunk => async dispatch => {
  try {
    dispatch(getOrganizationsRequest());
    const organizations = await apiClient.organizations.list();

    // for each organization, get the associated groups
    for (let i = 0; i < organizations.length; i++) {
      const groups = await apiClient.groups.list({
        organization: organizations[i].uid,
        groupName: (query && query.groupName) || '',
        limit: (query && query.groupCount) || 10,
        orderBy: 'groupName',
        sort: 'asc',
      });
      organizations[i].groups = groups;
    }

    dispatch(getOrganizationsSuccess({ organizations }));
  } catch (err: any) {
    dispatch(getOrganizationsError({ error: err }));
    dispatch(showToastMessage({ message: err.message, type: 'error' }));
  }
};

/* Actions & Reducer */
/* prettier-ignore */
export const {
  getOrganizationsRequest, getOrganizationsSuccess, getOrganizationsError
} = organizationsSlice.actions;

export default organizationsSlice.reducer;
