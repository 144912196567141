import React from "react";
import { createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import ProgramEnrollment from "../../../models/programEnrollment";
import Member, { Role } from "../../../models/member";
import { removeUnderscoresFromString, sortProgramEnrollments } from "../../../lib/util";
import MonetizationOnOutlinedIcon from "@material-ui/icons/MonetizationOnOutlined";


const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		label: {
			fontSize: '14px',
			fontWeight: 600,
			lineHeight: '18px',
			textTransform: 'capitalize'
		},
		memberInfoWrapper: {
			display: 'flex',
			flexDirection: 'column',
			marginLeft: '20px',
			'& .badges': {
				display: 'flex',
				flexWrap: 'wrap',
				gap: '5px',
				marginTop: '5px',
			},
			'& h6': {
				display: 'flex',
				marginLeft: 'auto',
				marginBottom: 0,
				lineHeight: '18px',
				fontWeight: 600
			},
		},
	}),
);

interface MemberRoleDisplayProps {
	member: Member,
	programEnrollments?: ProgramEnrollment[]
};
const MemberRole: React.FC<MemberRoleDisplayProps> = ({member, programEnrollments}) => {

	const styles = useStyles();

	/**
	 * Gets the most recent program enrollment role of the member, or returns a badge with a Not Enrolled label
	 * @param programEnrollments - the program enrollments of the member
	 */
	const getEnrollmentRole = (member: Member, programEnrollments: ProgramEnrollment[]) => {
		const recentProgramEnrollment = programEnrollments.sort(sortProgramEnrollments('DESC'))[0];
		if (recentProgramEnrollment) {
			return removeUnderscoresFromString(recentProgramEnrollment.role);
		}

		return member.dependentNumber === '00' ? 'Head of Household' : 'Not enrolled';
	};

	return (
		<div className={styles.memberInfoWrapper}>
			<Typography variant="h6">
				{member.role === Role.AccountOwner &&
            <><MonetizationOnOutlinedIcon style={{color: 'green', fontSize: 'large'}}/>&nbsp;&nbsp;</>}
				{removeUnderscoresFromString(member.role)}
			</Typography>
			{
				programEnrollments &&
          <Typography variant="h6">{getEnrollmentRole(member, programEnrollments)}</Typography>
			}
			<Typography variant="h6">DEP {member.dependentNumber}</Typography>
		</div>

	);
}

export default MemberRole;