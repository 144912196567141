import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { Box, Typography, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import SearchInput from '../../../SearchInput';
import apiClient from '../../../../lib/api';
import { MembershipAccountQueryParams } from '../../../../lib/api/membershipAccounts';
import CustomModal from '../../../Modal';
import AccountRow from '../../AccountRow';
import MembershipAccount from '../../../../models/membershipAccount';
import { useDispatch } from 'react-redux';
import { showToastMessage } from '../../../../store/slices/toastMessage';
import CustomError from '../../../../lib/customError';

interface ExceptionLogFilterAccountProps {
  handleChangeAccount: (
    account: { membershipAccountId: string; sfAccountId: string } | null,
  ) => void;
  resetAccount?: boolean;
}

const defaultQueryParams: MembershipAccountQueryParams = {
  orderBy: 'accountName',
  sort: 'asc',
  searchQuery: '',
  includeMembers: true,
  includeArchived: true,
  includeInactive: true,
};

const ExceptionLogFilterAccount: React.FC<ExceptionLogFilterAccountProps> = ({
  handleChangeAccount,
  resetAccount,
}) => {
  const [nameSearch, setNameSearch] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState<MembershipAccount | null>(null);
  const dispatch = useDispatch();

  const { isLoading, isFetching, data: queryResults, error: queryError, refetch } = useQuery(
    ['membershipAccountsException', nameSearch],
    () => {
      if (nameSearch === '') return [];
      return apiClient.membershipAccounts.search({
        query: {
          ...defaultQueryParams,
          searchQuery: nameSearch,
        },
      });
    },
    { keepPreviousData: true, cacheTime: 0, enabled: false },
  );

  const handleNameSearch = (val: string) => {
    const searchString = val.trim();
    setNameSearch(searchString);
  };

  const handleSubmit = () => {
    if (nameSearch && nameSearch.length >= 3) {
      refetch();
    }
  };

  const handleAccountRowClick = (account: MembershipAccount) => {
    setOpenModal(false);
    setSelectedAccount(account);
    setNameSearch('');
  };

  //display a message if the query has no accounts, otherwise open up the modal with the list of accounts
  useEffect(() => {
    if (queryResults && queryResults.length > 0) {
      setOpenModal(true);
    } else if (queryResults && queryResults.length === 0) {
      dispatch(
        showToastMessage({
          message: 'No accounts were found',
          type: 'info',
        }),
      );
    }
  }, [dispatch, queryResults]);

  // set the account selected
  useEffect(() => {
    handleChangeAccount(
      selectedAccount
        ? { membershipAccountId: selectedAccount.uid, sfAccountId: selectedAccount.sfAccountId }
        : null,
    );
    // eslint-disable-next-line
  }, [selectedAccount]);

  // check if the account search throws an error
  useEffect(() => {
    if (queryError) {
      dispatch(
        showToastMessage({
          message:
            (queryError as CustomError).message ||
            'An error occured while retrieving membership accounts',
          type: 'error',
        }),
      );
    }
  }, [dispatch, queryError]);

  // account reset by parent component
  useEffect(() => {
    if (resetAccount) {
      setSelectedAccount(null);
    }
  }, [resetAccount]);

  return (
    <>
      {!selectedAccount ? (
        <SearchInput
          placeholder="Search logs by account"
          onSubmit={handleSubmit}
          onChange={handleNameSearch}
          submitOnEnter={true}
          disabled={isLoading || isFetching}
        />
      ) : (
        <div>
          <Box
            style={{
              display: 'flex',
              margin: '1rem',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography variant="body1">{selectedAccount.accountName}</Typography>
            <IconButton title="close" onClick={() => setSelectedAccount(null)}>
              <CloseIcon />
            </IconButton>
          </Box>
        </div>
      )}

      <CustomModal
        title="Accounts found"
        open={openModal}
        onClose={() => setOpenModal(false)}
        style={{
          height: '800px',
          overflowY: 'auto',
        }}
      >
        {queryResults &&
          queryResults.map(
            account =>
              account && (
                <AccountRow
                  key={account.uid}
                  account={account}
                  handleAccountRowClick={() => handleAccountRowClick(account)}
                />
              ),
          )}
      </CustomModal>
    </>
  );
};

export default ExceptionLogFilterAccount;
