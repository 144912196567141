import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core';
import { FormikProps } from 'formik';
import React, { useEffect, useState } from 'react';
import Member from '../../../../../models/member';
import Spacer from '../../../../Spacer';
import { DeathForm } from './DeathDialog';
import MembershipAccount from "../../../../../models/membershipAccount";
import {MemberSelector} from "../../../member";

export const DeceasedMemberSelector: React.FC<{
  onSkipContinuingMembers: Function;
  members: Member[];
  membershipAccount: MembershipAccount,
  form: FormikProps<DeathForm>;
}> = ({ onSkipContinuingMembers, members, form, membershipAccount }) => {
  const [selectedDeceasedMembers, setSelectedDeceasedMembers] = useState<{ [key: string]: Member }>(
    {},
  );

  useEffect(() => {
    setSelectedDeceasedMembers(form.values.deceasedMembers);
  }, [form]);

  const onDeceasedMemberSelected = (member: Member, selected: boolean) => {
    const temp = form.values.deceasedMembers;

    if (selected) {
      temp[member.uid] = member;
      form.values.event!.deceasedMembers.push(member.uid);
    } else {
      delete temp[member.uid];
      const index = form.values.event!.deceasedMembers.indexOf(member.uid);
      form.values.event!.deceasedMembers.splice(index, 1);
    }

    form.setFieldValue('deceasedMembers', temp);
    setSelectedDeceasedMembers(form.values.deceasedMembers);
  };

  const isShowSurvivingMembersQuestion = (): boolean => {
    // Display when deceased members have been selected and the account has other enrollments
    return form.values.event!.deceasedMembers.length > 0 && members.length > 1;
  };

  const handleSurvivingMembersQuestionOptionChanged = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const hasContinuingMembers = event.target.value;
    form.setFieldValue('hasContinuingMembers', hasContinuingMembers);

    if (hasContinuingMembers === 'yes') {
      onSkipContinuingMembers(false);
    } else {
      onSkipContinuingMembers(true);
    }
  };

  return (
    <>
      <Typography variant="h3">Deceased Members</Typography>
      <Typography variant="h5">Please select all that apply</Typography>
      <Spacer size={20} />
      <MemberSelector
        members={members}
        selectedMembers={selectedDeceasedMembers}
        membershipAccount={membershipAccount}
        onMemberSelected={onDeceasedMemberSelected}
        title="Enrolled Members"
      />
      <Spacer size={30} />
      {isShowSurvivingMembersQuestion() && (
        <FormControl variant="outlined" fullWidth>
          <FormLabel id="surviving-members-question">
            Do surviving members wish to continue membership?
          </FormLabel>
          <RadioGroup
            aria-labelledby="surviving-members-question"
            name="continuingMembers"
            value={form.values.hasContinuingMembers}
            onChange={handleSurvivingMembersQuestionOptionChanged}
          >
            <FormControlLabel value="yes" control={<Radio />} label="Yes" />
            <FormControlLabel value="no" control={<Radio />} label="No" />
          </RadioGroup>
        </FormControl>
      )}
    </>
  );
};
