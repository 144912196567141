import { FC } from 'react';
import { Typography } from '@material-ui/core';
import { Member } from '../../types/print-request';
import PrintRequestDetailsMember from './PrintRequestDetailsMember';
import { hyphenString } from '../../../../lib/util';

interface PrintRequestDetailsSingleMemberProps {
  header: string;
  member: Member;
}

const PrintRequestDetailsSingleMember: FC<PrintRequestDetailsSingleMemberProps> = ({
  header,
  member,
}) => {
  return (
    <>
      <Typography variant="h6" data-testid={hyphenString(header)}>
        {header.toUpperCase()}
      </Typography>
      <PrintRequestDetailsMember name={member.name} dependentNumber={member.id} />
    </>
  );
};

export default PrintRequestDetailsSingleMember;
