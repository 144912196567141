import { FC } from 'react';
import { Alert } from '@material-ui/lab';

interface PrintRequestErrorProps {
  text: string;
}

const PrintRequestError: FC<PrintRequestErrorProps> = ({ text }) => {
  return (
    <Alert color="error" severity="error" variant="outlined" data-testid="print-request-error">
      {text}
    </Alert>
  );
};

export default PrintRequestError;
