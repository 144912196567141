import React from 'react';
import moment from 'moment';
import {
  createStyles,
  makeStyles,
  Theme,
  Typography,
  Card,
  CardContent,
  IconButton,
  useTheme,
  Box,
} from '@material-ui/core';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import CheckIcon from '@material-ui/icons/Check';

import { removeUnderscoresFromString } from '../../../../../lib/util';
import { ProgramMember } from '.';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    memberCard: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: '10px',
      borderRadius: 10,
      opacity: 0.8,
      transition: 'all 0.2s ease-in-out',
      '&:hover': {
        opacity: 1,
      },
    },
    memberCardContent: {
      padding: '16px!important',
      backgroundColor: theme.backgroundColors.body,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      flexGrow: 1,
    },
    memberDetail: {
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between',
      padding: '2px 0',
    },
    memberName: {
      fontWeight: 'bold',
    },
    memberDetailLabel: {
      fontWeight: 'bold',
      marginRight: '3px',
    },
  }),
);

interface MemberCardProps {
  programMember: ProgramMember;
  removeProgramMember?(id: string): void;
  editable?: boolean;
  showEnrollmentDates?: boolean;
  showRetired?: boolean;
}

const MemberCard = (props: MemberCardProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const { programMember, removeProgramMember, editable, showEnrollmentDates, showRetired } = props;

  const getEnrollmentTextColor = (programMember: ProgramMember) => {
    if (
      (programMember.enrollment.startDate &&
        programMember.enrollment.originalStartDate &&
        moment(programMember.enrollment.startDate).format('YYYY-MM-DD') !==
          moment(programMember.enrollment.originalStartDate).format('YYYY-MM-DD')) ||
      (programMember.enrollment.endDate &&
        programMember.enrollment.originalEndDate &&
        moment(programMember.enrollment.endDate).format('YYYY-MM-DD') !==
          moment(programMember.enrollment.originalEndDate).format('YYYY-MM-DD')) ||
      (programMember.enrollment.startDate && !programMember.enrollment.originalStartDate) ||
      (!programMember.enrollment.startDate && programMember.enrollment.originalStartDate) ||
      (programMember.enrollment.endDate && !programMember.enrollment.originalEndDate) ||
      (!programMember.enrollment.endDate && programMember.enrollment.originalEndDate)
    ) {
      return theme.textColors.success;
    }
    return theme.textColors.default;
  };

  return (
    <Card key={programMember.id} className={classes.memberCard}>
      <CardContent className={classes.memberCardContent}>
        {editable && (
          <div style={{ flexGrow: 0, flexShrink: 0, width: '60px' }}>
            {(programMember.canRemove && removeProgramMember && (
              <IconButton onClick={() => removeProgramMember(programMember.id)}>
                <DeleteForeverIcon color="error" />
              </IconButton>
            )) || (
              <IconButton disabled={true}>
                <CheckIcon style={{ color: theme.textColors.default }} />
              </IconButton>
            )}
          </div>
        )}
        <div style={{ flexGrow: 1 }}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="h5" className={classes.memberName}>
              {programMember.name}
            </Typography>
            <Box style={{ display: 'flex', flexDirection: 'row' }}>
              {showRetired && programMember.retired && (
                <Typography
                  variant="h6"
                  style={{ color: theme.textColors.info, marginRight: '10px' }}
                >
                  retired
                </Typography>
              )}
              {programMember.canRemove && (
                <Typography variant="h6" style={{ color: theme.textColors.success }}>
                  new
                </Typography>
              )}
              {!programMember.canRemove && <Typography variant="h6">enrolled</Typography>}
            </Box>
          </div>
          <div className={classes.memberDetail}>
            <div>
              <span className={classes.memberDetailLabel}>Role: </span>
              <span>{removeUnderscoresFromString(programMember.role)}</span>
            </div>
            <div>
              <span className={classes.memberDetailLabel}>Dependent #:</span>{' '}
              <span>{programMember.dependentNumber}</span>
            </div>
          </div>
          {showEnrollmentDates && (
            <div className={classes.memberDetail}>
              <div>
                <span className={classes.memberDetailLabel}>Effective: </span>
                <span style={{ color: getEnrollmentTextColor(programMember) }}>
                  {moment(programMember.enrollment.startDate).isValid()
                    ? moment(programMember.enrollment.startDate).format('MMMM D, YYYY')
                    : 'N/A'}
                  {moment(programMember.enrollment.endDate).isValid()
                    ? ' - ' + moment(programMember.enrollment.endDate).format('MMMM D, YYYY')
                    : ''}
                </span>
              </div>
            </div>
          )}
        </div>
      </CardContent>
    </Card>
  );
};

export default MemberCard;
