import { APIClientBase } from './apiClientBase';
import apiClient from './client';

class Resources extends APIClientBase {
  private static _instance: Resources;

  static getInstance(): Resources {
    if (Resources._instance === undefined) {
      Resources._instance = new Resources();
    }
    return Resources._instance;
  }

  get = async (resource: string): Promise<string> => {
    try {
      const url = `/membership/getObject/${resource}`;
      const response = await apiClient.get(url);
      const data = this.response(response);
      return data;
    } catch (err: any) {
      const error = this.error(err);
      throw error;
    }
  };
}

export default Resources.getInstance();
