import moment from 'moment';
import { Box } from '@material-ui/core';

import apiClient from '../../../../lib/api';
import PaginationTable from '../../../PaginationTable';
import StatusBadge from '../../../StatusBadge';
import { BenefitsManagementEDIFilesStatus } from '../../../../models/benefitsManagement';
import { removeUnderscoresFromString } from '../../../../lib/util';
import DownloadEDIButton from './DownloadEDIFileButton';
import SendEDIButton from './SendEDIFileButton';

export const getStatusBadgeVariant = (status: string) => {
  if (status === BenefitsManagementEDIFilesStatus.Processed) {
    return 'success';
  } else if (status === BenefitsManagementEDIFilesStatus.Pending) {
    return 'info';
  } else if (status === BenefitsManagementEDIFilesStatus.Sent) {
    return 'primary';
  } else if (status === BenefitsManagementEDIFilesStatus.Deleted) {
    return 'danger';
  } else if (status === BenefitsManagementEDIFilesStatus.SendFailed) {
    return 'warn';
  } else if (status === BenefitsManagementEDIFilesStatus.SendDisabled) {
    return 'default';
  }

  return 'default';
};

const EDIFilesTable = (props: any) => {
  return (
    <PaginationTable
      title="Benefits Management EDI Files"
      columns={[
        {
          width: 68,
          name: 'uid',
          as: '',
          format: uid => (
            <div style={{ textAlign: 'center' }}>
              <Box
                style={{
                  width: '68px',
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <DownloadEDIButton uid={uid} />
                <SendEDIButton uid={uid} />
              </Box>
            </div>
          ),
        },
        {
          width: 180,
          name: 'createdAt',
          as: 'Date/Time',
          format: createdAt => moment(createdAt).format('YYYY-MM-DD HH:mm:ss'),
        },
        { name: 'fileName', as: 'File Name' },
        { width: 150, name: 'controlNumber', as: 'Control Number' },
        {
          width: 180,
          name: 'processedAt',
          as: 'Processed At',
          format: processedAt =>
            processedAt ? moment(processedAt).format('YYYY-MM-DD HH:mm:ss') : '',
        },
        {
          width: 50,
          name: 'status',
          format: status => (
            <StatusBadge
              variant={getStatusBadgeVariant(status)}
              label={removeUnderscoresFromString(status)}
            />
          ),
        },
      ]}
      queryKeys={['benefitsManagementEDIFiles']}
      queryFunction={queryParams => apiClient.benefitsManagement.getAllEDIFiles(queryParams)}
      pageSize={25}
    />
  );
};

export default EDIFilesTable;
