import { Card, CardContent, Typography } from '@material-ui/core';
import { useState } from 'react';
import MedicalBillReimbursementMedicalBillNumberInput from './MedicalBillReimbursementMedicalBillNumberInput';
import MedicalBillReimbursementMedicalBillNumbersList from './MedicalBillReimbursementMedicalBillNumbersList';

interface MedicalBillReimbursementMedicalBillNumbersProps {
  medicalBillNumbers: string[];
  onChange: (medicalBillNumbers: string[]) => void;
  isEditMode: boolean;
}

const MedicalBillReimbursementMedicalBillNumbers = ({
  medicalBillNumbers,
  onChange,
  isEditMode,
}: MedicalBillReimbursementMedicalBillNumbersProps) => {
  const [newValue, setNewValue] = useState('');
  const [isDuplicate, setIsDuplicate] = useState<Boolean>(false);

  const reset = () => {
    setNewValue('');
    setIsDuplicate(false);
  };

  if (!isEditMode && newValue) {
    reset();
  }

  const handleNewValue = (medicalBillNumber: string) => {
    setNewValue(medicalBillNumber);

    if (isDuplicate) {
      handleIsDuplicate(medicalBillNumber);
    }
  };

  const handleAdd = () => {
    const value = newValue.trim();

    if (!value) {
      return;
    }

    if (handleIsDuplicate(value)) {
      return;
    }

    onChange(medicalBillNumbers?.concat(value) || [value]);

    reset();
  };

  const handleRemove = (medicalBillNumber: string) => {
    onChange(medicalBillNumbers.filter(item => item !== medicalBillNumber));

    handleIsDuplicate(newValue.trim());
  };

  const handleIsDuplicate = (value: string): Boolean => {
    let newIsDuplicate: Boolean = false;

    if (value && medicalBillNumbers.includes(value)) {
      newIsDuplicate = true;
    }

    setIsDuplicate(newIsDuplicate);

    return newIsDuplicate;
  };

  return (
    <Card>
      <CardContent>
        <Typography variant="h6">Medical Bill Numbers</Typography>
        {isEditMode && (
          <MedicalBillReimbursementMedicalBillNumberInput
            value={newValue}
            onChange={handleNewValue}
            onBlur={() => handleIsDuplicate(newValue.trim())}
            onSubmit={handleAdd}
            errorText={
              isDuplicate ? 'The entered medical bill number has already been added.' : undefined
            }
          />
        )}
        <MedicalBillReimbursementMedicalBillNumbersList
          medicalBillNumbers={medicalBillNumbers}
          onRemove={handleRemove}
          isEditMode={isEditMode}
        />
      </CardContent>
    </Card>
  );
};

export default MedicalBillReimbursementMedicalBillNumbers;
