import React, { useRef, useState } from 'react';
import {
  Button,
  ButtonGroup,
  ClickAwayListener,
  Grow,
  MenuList,
  MenuItem,
  Paper,
  Popper,
  Box,
} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { Action, Roles, Subject } from '@ccm-innovation/auth-membership-service';

import MembershipAccount from '../../../models/membershipAccount';
import Member from '../../../models/member';
import { useSession } from '../../../context/session';
import SessionUser from '../../../context/session/sessionUser';

const lifeEventsEnabled = () => {
  return process.env.REACT_APP_LIFE_EVENTS_ENABLED && process.env.REACT_APP_LIFE_EVENTS_ENABLED === 'true';
}

interface AccountActionOption {
  id: string;
  title: string;
}
const accountActions: AccountActionOption[] = [
  {
    id: 'add_on',
    title: 'Administrative Add-On',
  },
  {
    id: 'withdrawal',
    title: 'Withdrawal',
  },
  {
    id: 'cancellation',
    title: 'Cancellation',
  },
  {
    id: 'change_group',
    title: 'Change Group',
  },
  {
    id: 'create_billing_records',
    title: 'Create Billing Records',
  },
  {
    id: 'life_events',
    title: 'Life Events',
  }
];

interface AccountOptionParameters {
  option: AccountActionOption;
  membershipAccount: MembershipAccount;
  hasActiveMembers: boolean;
  onClickEvent: Function;
  user: SessionUser;
}
const renderAccountActionOption = ({
  option,
  membershipAccount,
  hasActiveMembers,
  onClickEvent,
  user,
}: AccountOptionParameters) => {
  if (option.id === 'add_on' && user.cannot(Action.AdminAddOn, Subject.Member)) {
    return;
  } else if (option.id === 'withdrawal' && user.cannot(Action.Withdraw, Subject.Member)) {
    return;
  } else if (option.id === 'change_group' && user.cannot(Action.Switch, Subject.Group)) {
    return;
  } else if (
    option.id === 'cancellation' &&
    user.cannot(Action.Cancel, Subject.MembershipAccount)
  ) {
    return;
  } else if (
    option.id === 'create_billing_records' &&
    !(
      user.hasRole(Roles.SystemAdmin) ||
      user.hasRole(Roles.BillingAdmin) ||
      user.hasRole(Roles.GroupAdmin) ||
      user.hasRole(Roles.BillingUser)
    )
  ) {
    return;
  } else if (
    option.id === 'life_events' && (
    !(
      user.hasRole(Roles.SystemAdmin) ||
      user.hasRole(Roles.BillingAdmin) ||
      user.hasRole(Roles.BillingUser)
    ) || !lifeEventsEnabled())
  ) {
    return;
  }

  return (
    <MenuItem
      key={option.id}
      disabled={
        (membershipAccount.status !== 'active' && option.id !== 'change_group') ||
        (option.id === 'withdrawal' && !hasActiveMembers)
      }
      onClick={() => onClickEvent(option)}
    >
      <Box style={{ display: 'flex' }}>
        <span>{option.title}</span>
      </Box>
    </MenuItem>
  );
};

const hasActiveMembers = (membershipAccount: MembershipAccount) => {
  return (
    membershipAccount.members &&
    membershipAccount.members.filter((m: Member) => !m.ended).length > 0
  );
};

const AccountActions = (props: any) => {
  const { user } = useSession();
  const { setDialogsOpen, initialDialogState, membershipAccount } = props;
  const [accountActionsOpen, setAccountActionsOpen] = useState(false);
  const accountActionsRef = useRef<HTMLDivElement>(null);

  const handleAccountActionToggle = () => {
    setAccountActionsOpen((isOpen: boolean) => !isOpen);
  };

  const handleAccountActionsClose = (event: React.MouseEvent<Document, MouseEvent>) => {
    if (
      accountActionsRef.current &&
      accountActionsRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }
    setAccountActionsOpen(false);
  };

  const handleAccountActionMenuItemClick = (action: AccountActionOption) => {
    switch (action.id) {
      case 'add_on':
        setDialogsOpen({ ...initialDialogState, addOn: true });
        break;
      case 'withdrawal':
        setDialogsOpen({ ...initialDialogState, withdrawal: true });
        break;
      case 'cancellation':
        setDialogsOpen({ ...initialDialogState, cancellation: true });
        break;
      case 'change_group':
        setDialogsOpen({ ...initialDialogState, changeGroup: true });
        break;
      case 'create_billing_records':
        setDialogsOpen({ ...initialDialogState, createBillingRecords: true });
        break;
      case 'life_events':
        setDialogsOpen({ ...initialDialogState, lifeEvents: true });
        break;  
    }

    setAccountActionsOpen(false);
  };

  return (
    <>
      <ButtonGroup
        variant="contained"
        ref={accountActionsRef}
        color="primary"
        aria-label="account actions"
      >
        <Button>Account Actions</Button>
        <Button
          color="primary"
          size="small"
          aria-controls={accountActionsOpen ? 'account-actions-menu' : undefined}
          aria-expanded={accountActionsOpen ? 'true' : undefined}
          aria-label="select an account action"
          aria-haspopup="menu"
          onClick={handleAccountActionToggle}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper
        open={accountActionsOpen}
        anchorEl={accountActionsRef.current}
        role={undefined}
        transition
        disablePortal
        style={{ zIndex: 10000 }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleAccountActionsClose}>
                <MenuList id="account-actions-menu">
                  {accountActions.map((option, index) =>
                    renderAccountActionOption({
                      option,
                      membershipAccount,
                      hasActiveMembers: hasActiveMembers(membershipAccount) || false,
                      onClickEvent: handleAccountActionMenuItemClick,
                      user,
                    }),
                  )}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export default AccountActions;
