import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Switch,
  Tooltip,
  Typography,
  makeStyles,
  Theme,
  createStyles,
} from '@material-ui/core';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router';

import { renderFires } from './Fire';
import CustomDialog from '../Dialog';
import Spacer from '../Spacer';
import apiClient from '../../lib/api';
import { useDispatch } from 'react-redux';
import { showToastMessage } from '../../store/slices/toastMessage';

const ARMAGEDDON_ENABLED = process.env.REACT_APP_ARMAGEDDON_ENABLED;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    acknowledgement: { fontSize: '9pt' },
    fireButton: { fontSize: '16pt', padding: 0 },
    backdrop: {
      position: 'absolute',
      top: 0,
      left: 0,
      height: '100vh',
      width: '100vw',
      backgroundColor: 'rgba(0,0,0,0.5)',
      zIndex: 1300,
    },
  }),
);

const fires = renderFires(5);

const DoomsDay = (props: any) => {
  const { membershipAccount } = props;
  const styles = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const [torched, setTorched] = useState(false);
  const [torchEnabled, setTorchEnabled] = useState(false);
  const [showMessageDialog, setShowMessageDialog] = useState(false);
  const [messageDialogShown, setMessageDialogShown] = useState(false);
  const [showNukeItDialog, setShowNukeItDialog] = useState(false);
  const [options, setOptions] = React.useState({
    torchMembershipData: false,
    torchPersonData: false,
    torchRejoiceData: false,
    importAccount: false,
    acknowledgement: false,
  });
  const torchData = useMutation((options: any) =>
    apiClient.membershipAccounts.torchData(membershipAccount.uid, options),
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.name === 'torchMembershipData' && !event.target.checked) {
      setOptions({ ...options, [event.target.name]: event.target.checked, torchPersonData: false });
    } else {
      setOptions({ ...options, [event.target.name]: event.target.checked });
    }
  };

  useEffect(() => {
    if (
      options.acknowledgement &&
      (options.torchMembershipData || options.torchPersonData || options.torchRejoiceData)
    ) {
      setTorchEnabled(true);
    } else {
      setTorchEnabled(false);
    }
  }, [options]);

  if (!membershipAccount || !ARMAGEDDON_ENABLED) {
    return null;
  }

  const resetOptions = () => {
    setTimeout(() => {
      setOptions({
        torchMembershipData: false,
        torchPersonData: false,
        torchRejoiceData: false,
        importAccount: false,
        acknowledgement: false,
      });
    }, 500);
  };

  const onClose = () => {
    setShowNukeItDialog(false);
    resetOptions();
  };

  const onTorchIt = () => {
    onClose();
    setTorched(true);

    setTimeout(() => {
      // torch the data
      torchData.mutate(options, {
        onSuccess: (data, variables, context) => {
          setTorched(false);
          dispatch(
            showToastMessage({
              message: '🔥 This account has been thrown into the Lake of Fire 🔥',
              type: 'success',
            }),
          );

          if (options.importAccount) {
            if (data.membershipAccount) {
              history.push(`/membership/accounts/${data.membershipAccount.uid}`);
            } else {
              history.push(`/membership/accounts`);
            }
          } else {
            history.push(`/membership/accounts`);
          }
        },
        onError: (error: any, variables, context) => {
          setTorched(false);
          //setShowBackdrop(false);
          dispatch(
            showToastMessage({
              message:
                error.status !== 403
                  ? error.message
                  : '🔥 The Lake of Fire is not accepting new clients at this time. 🔥',
              type: 'error',
            }),
          );
        },
      });
    }, 5000);
  };

  return (
    <>
      {torched && fires}
      <Tooltip title="Do Not Push!!!">
        <Button
          className={styles.fireButton}
          onClick={() => {
            if (!messageDialogShown) {
              setShowMessageDialog(true);
              setTimeout(() => {
                setMessageDialogShown(true);
                setShowMessageDialog(false);
                setShowNukeItDialog(true);
              }, 2500);
            } else {
              setShowNukeItDialog(true);
            }
          }}
        >
          🔥
        </Button>
      </Tooltip>

      {/* Warning dialog */}
      <CustomDialog open={showMessageDialog} maxWidth="sm">
        <Typography variant="h5">You may want to work on your listening skills...</Typography>
      </CustomDialog>

      {/* Nuke It dialog */}
      <CustomDialog
        open={showNukeItDialog}
        maxWidth="sm"
        title={'Nuke All The Things'}
        buttons={[
          <Button
            variant="contained"
            color="primary"
            disabled={!torchEnabled}
            onClick={onTorchIt}
            key={'nukeButton'}
          >
            Torch It
          </Button>,
          <Button variant="contained" color="default" onClick={onClose} key={'cancelButton'}>
            Cancel
          </Button>,
        ]}
      >
        <Grid container style={{ justifyContent: 'center' }}>
          <Grid item style={{ display: 'flex', flexDirection: 'column' }}>
            <Typography variant="h6">Send to the Lake of Fire:</Typography>
            <FormControlLabel
              control={
                <Switch
                  checked={options.torchMembershipData}
                  onChange={handleChange}
                  name="torchMembershipData"
                  color="primary"
                />
              }
              label="Membership Account"
            />
            <FormControlLabel
              control={
                <Switch
                  checked={!options.torchMembershipData ? false : options.torchPersonData}
                  onChange={handleChange}
                  disabled={!options.torchMembershipData}
                  name="torchPersonData"
                  color="primary"
                />
              }
              label="Person Records"
            />
            <FormControlLabel
              control={
                <Switch
                  checked={options.torchRejoiceData}
                  onChange={handleChange}
                  name="torchRejoiceData"
                  color="primary"
                />
              }
              label="Billing Account (Rejoice)"
            />
          </Grid>
          <Grid item style={{ display: 'flex', flexDirection: 'column' }}>
            <Typography variant="h6">Resurrect:</Typography>
            <FormControlLabel
              control={
                <Switch
                  checked={options.importAccount}
                  onChange={handleChange}
                  name="importAccount"
                  color="primary"
                />
              }
              label="Membership Account"
            />
          </Grid>
        </Grid>

        <Spacer size={20} />

        <Box style={{ display: 'flex', flexDirection: 'row' }}>
          <Box>
            <Checkbox onChange={handleChange} name="acknowledgement" color="primary"></Checkbox>
          </Box>
          <p className={styles.acknowledgement}>
            I acknowledge that I am responsible for all undesireable events that may occur by
            destroying #allthethings including, but not limited to, 7 years of pestilence, Rick
            Astley releasing a new album, alien invasions, random firing of CCM employees,
            especially myself, and/or the opening of wormholes to sketchy parallel universes that
            may or may not contain blood sucking demons.
          </p>
        </Box>
      </CustomDialog>
    </>
  );
};

export default DoomsDay;
