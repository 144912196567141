import React, { useEffect, useState } from 'react';
import { Box, Button, Grid, makeStyles, Theme, createStyles } from '@material-ui/core';
import { Link, Redirect, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Action, Subject } from '@ccm-innovation/auth-membership-service';

import Page, { Breadcrumb } from '../../components/Page';
import Modal from '../../components/Modal';
import OrganizationCard from '../../components/organizations/OrganizationCard';
import OrganizationForm from '../../components/organizations/OrganizationForm';
import { saveOrganization, resetOrganization } from '../../store/slices/organization';
import { getOrganizations } from '../../store/slices/organizations';
import { RootState } from '../../store';
import { useSession } from '../../context/session';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    pageTitle: {
      display: 'flex',
      justifyContent: 'space-between',
    },
  }),
);

const OrganizationsView = (props: any) => {
  const styles = useStyles();
  const { user } = useSession();
  const [modalOpen, setModalOpen] = useState(false);
  const [formDisabled, setFormDisabled] = useState(false);
  const [organizationSaving, setOrganizationSaving] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const organizations = useSelector((state: RootState) => state.organizations.organizations);
  const organizationState = useSelector((state: RootState) => state.organization);

  const breadcrumbs: Breadcrumb[] = [
    { href: '/', label: 'Home' },
    { href: '#', label: 'Membership' },
    { label: 'Organizations' },
  ];

  const onSaveOrganization = (data: any) => {
    setFormDisabled(true);
    setOrganizationSaving(true);
    dispatch(saveOrganization(data));
  };

  useEffect(() => {
    if (organizationState.saved && organizationSaving) {
      setModalOpen(false);
      history.push(`/membership/organizations/${organizationState.organization?.uid}`);
    }
    if (!organizationState.loading && !organizationSaving) {
      setFormDisabled(false);
    }
  }, [organizationState, history, dispatch, organizationSaving]);

  // onMount get organizations
  useEffect(() => {
    dispatch(resetOrganization());
    dispatch(getOrganizations());
  }, [dispatch]);

  // check permissions
  if (user && user.cannot(Action.View, Subject.Organization)) {
    return <Redirect to={{ pathname: '/not-found' }} />;
  }

  return (
    <Page
      title={
        <Box className={styles.pageTitle}>
          <div>Organizations</div>
          {user && user.can(Action.Create, Subject.Organization) && (
            <Button color="primary" variant="contained" onClick={() => setModalOpen(true)}>
              Add Organization
            </Button>
          )}
        </Box>
      }
      breadcrumbs={breadcrumbs}
    >
      <Modal
        open={modalOpen}
        title="Create Organization"
        onClose={() => setModalOpen(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        disableBackdropClick
      >
        <OrganizationForm
          onSubmit={onSaveOrganization}
          cancel={() => setModalOpen(false)}
          disabled={formDisabled}
        />
      </Modal>
      <Grid container spacing={3}>
        {organizations &&
          organizations.map((organization: any) => {
            return (
              <Grid item xs={3} key={organization.uid}>
                <Link to={`/membership/organizations/${organization.uid}`}>
                  <OrganizationCard organization={organization} />
                </Link>
              </Grid>
            );
          })}
      </Grid>
    </Page>
  );
};

export default OrganizationsView;
