import { APIClientBase } from './apiClientBase';
import apiClient from './client';
import { AuditEvent } from '../../models/auditEvent';
import { AuditEventsQueryParams } from './membershipAccounts';

class AuditEvents extends APIClientBase {
  private static _instance: AuditEvents;

  static getInstance(): AuditEvents {
    if (AuditEvents._instance === undefined) {
      AuditEvents._instance = new AuditEvents();
    }
    return AuditEvents._instance;
  }

  getMetadata = async (uid: string): Promise<string> => {
    try {
      const url = `/membership/audit/events/${uid}/metadata`;
      const response = await apiClient.get(url);
      const data = this.response(response);
      return data;
    } catch (err: any) {
      const error = this.error(err);
      throw error;
    }
  };

  getAuditEvents = async (query: AuditEventsQueryParams): Promise<AuditEvent[]> => {
    try {
      const queryString = this.toQueryString(query);
      const url = `/membership/audit/events?${queryString}`;
      const response = await apiClient.get(url);

      return this.response(response);
    } catch (err: any) {
      const error = this.error(err);
      throw error;
    }
  };
}

export default AuditEvents.getInstance();
