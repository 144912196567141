import React, { useState } from 'react';
import { AuditEventsQueryParams } from '../lib/api/membershipAccounts';
import { useQuery, useQueryClient } from 'react-query';
import apiClient from '../lib/api';
import { AuditEvent } from '../models/auditEvent';
import { Box, IconButton, Tooltip, Typography } from '@material-ui/core';
import { FilterList, Refresh } from '@material-ui/icons';
import AuditEventsFilter from '../components/membership/account/audit-events/AuditEventsFilter';
import AuditEventsTable from '../components/membership/account/audit-events/AuditEventsTable';
import Page from '../components/Page';
import Spacer from '../components/Spacer';

const AuditEventsView = () => {
  const breadcrumbs = [
    { href: '/', label: 'Home' },
    { href: '#', label: 'System Administration' },
    { href: '/system-admin/audit', label: 'Audit Events' },
  ];

  const [queryParams, setQueryParams] = useState<AuditEventsQueryParams>({
    page: 1,
    pageSize: 25,
    plus1: true,
  });
  const [showFilter, setShowFilter] = useState(false);
  const queryClient = useQueryClient();

  const auditEventsFetch = useQuery(
    ['auditEvents', queryParams],
    () => apiClient.auditEvents.getAuditEvents(queryParams),
    { keepPreviousData: true },
  );

  const auditEvents: AuditEvent[] | undefined = auditEventsFetch.data;

  const handlePaging = ({ page, pageSize }: AuditEventsQueryParams) => {
    const newQueryParams = {
      ...queryParams,
    };
    if (page) {
      newQueryParams.page = page;
    }
    if (pageSize) {
      newQueryParams.pageSize = pageSize;
    }
    setQueryParams(newQueryParams);
  };

  const handleRefreshEvents = () => {
    queryClient.invalidateQueries(['auditEvents', queryParams]);
  };

  const filterChange = (newQueryParams: AuditEventsQueryParams) => {
    setQueryParams(newQueryParams);
  };

  return (
    <Page breadcrumbs={breadcrumbs}>
      <Spacer size={15} />
      {/* Title */}
      <div style={{ display: 'flex' }}>
        <Box marginRight={2}>
          <Typography variant="h3">Audit Events</Typography>
        </Box>
        <Box>
          <Tooltip title={'filter list'}>
            <IconButton color="default" size="small" onClick={() => setShowFilter(!showFilter)}>
              <FilterList />
            </IconButton>
          </Tooltip>
        </Box>
        <Box>
          <Tooltip title={'refresh events'}>
            <IconButton color="default" size="small" onClick={handleRefreshEvents}>
              <Refresh />
            </IconButton>
          </Tooltip>
        </Box>
      </div>

      {/* Audit Events Filter */}
      {showFilter && (
        <AuditEventsFilter
          queryParams={queryParams}
          onFilterChange={filterChange}
          showAllFilters={true}
        />
      )}

      {/* Audit Events Table */}
      <AuditEventsTable
        auditEvents={auditEvents}
        onPage={handlePaging}
        pageSize={queryParams.pageSize}
        currentPage={queryParams.page || 1}
      />
    </Page>
  );
};

export default AuditEventsView;
