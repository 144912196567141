import React from 'react';
import { Typography, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { DataValues } from './MembershipReactivationDialog';
import MemberProgram from '../../../models/memberProgram';
import InactiveProgram from './InactiveProgram';
import ProgramEnrollment from '../../../models/programEnrollment';
import InactiveEnrollment from './InactiveEnrollment';

const useStyles = makeStyles(() => ({
  memberWrapper: {
    height: 400,
    overflowY: 'auto',
    marginBottom: 20,
    overflowX: 'hidden',
    paddingTop: 3,
  },
}));

interface ConfirmProgramReactivationProps {
  allowPartialReactivation: boolean;
  reactivationData: DataValues;
  program: MemberProgram;
  onMemberSelected: Function;
  selectedEnrollments: ProgramEnrollment[];
}

const ConfirmProgramReactivation: React.FC<ConfirmProgramReactivationProps> = ({
  allowPartialReactivation,
  reactivationData,
  program,
  onMemberSelected,
  selectedEnrollments,
}) => {
  const styles = useStyles();

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Typography variant="h3">Program</Typography>
          <Grid container spacing={2}>
            <Grid item xs={11}>
              <InactiveProgram program={program} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="h3">Members</Typography>
          <div className={styles.memberWrapper}>
            <Grid container spacing={2}>
              {reactivationData?.programEnrollments?.map(
                (enrollment: ProgramEnrollment, index: number) => (
                  <Grid item xs={11} key={index}>
                    <InactiveEnrollment
                      enrollment={enrollment}
                      onMemberSelected={onMemberSelected}
                      checked={selectedEnrollments.some(e => e.uid === enrollment.uid)}
                      disabled={!allowPartialReactivation}
                    />
                  </Grid>
                ),
              )}
            </Grid>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default ConfirmProgramReactivation;
