import { FC, useState } from 'react';
import { Button, Theme, Typography, createStyles, makeStyles } from '@material-ui/core';
import { PrintRequest } from '../types/print-request';
import MembershipAccount from '../../../models/membershipAccount';
import CreatePrintRequest from './CreatePrintRequest';
import Modal from '../../../components/Modal';
interface PrintRequestHeaderProps {
  printRequests: PrintRequest[];
  membershipAccount: MembershipAccount | undefined;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      gap: theme.spacing(2),
    },
  }),
);

const PrintRequestHeader: FC<PrintRequestHeaderProps> = ({ printRequests, membershipAccount }) => {
  const styles = useStyles();
  const [modalOpen, setModalOpen] = useState(false);
  const onCloseDialog = () => {
    setModalOpen(false);
  };

  return (
    <div className={styles.header} data-testid="print-request-header">
      <Typography variant="h3">Print Requests</Typography>
      <Button color="primary" variant="contained" size="small" onClick={() => setModalOpen(true)}>
        Create Print Request
      </Button>
      <Modal open={modalOpen}>
        <CreatePrintRequest
          membershipAccount={membershipAccount}
          printRequests={printRequests}
          open={true}
          onDialogClose={onCloseDialog}
        />
      </Modal>
    </div>
  );
};

export default PrintRequestHeader;
